.blue-cta-block {
    background: linear-gradient(90deg, $blue 0%, $blue-highlight-2 100%);
    color: $white;
    margin-bottom: 40px;
    padding: 22px 25px;
    @include media-breakpoint-up(lg) {
        padding: 26px 30px;
        margin-bottom: 60px;
    }

    h2 {
        font-weight: $font-weight-demi-web;
        color: $white;
        font-size: 26px;
        line-height: 1.077;
        position: relative;
        margin-bottom: 25px;
        letter-spacing: 0.04em;
        padding-bottom: 18px;

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            height: 4px;
            width: 130px;
            background-color: $orange;
            left: -25px;
            @include media-breakpoint-up(lg) {
                left: -30px;
            }
        }

        @include media-breakpoint-up(lg) {
            font-size: 28px;
            line-height: 1.214;
        }
    }

    .tertiary-button {
        font-size: 20px;
        @include media-breakpoint-up(lg) {
            font-size: 26px;
        }
    }
}