.image-slider {
    @include default-margin-bottom;
}

.splide--image-slider {
    background-color: $grey-highlight-3;

    &:before {
        content:'';
        background-color: $grey-highlight-1;
        width: 4px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 0;
    }

    .figure {
        margin-bottom: 0;
        width: 100%;

        &-caption {
            padding-bottom: 0;
        }

        &-img {
            width: 100%;
        }
    }

    .splide {

        &__slide__container {
            border-left: 4px solid $orange;

            &__caption {

                @include caption-style;
                padding-bottom: 0;
            }

            &__image {
                width: 100%;
            }
        }

        &__arrows {
            display: flex;
            justify-content: center;
            padding-top: 12px;
            padding-bottom: 14px;
        }

        &__arrow {
            background-color: transparent;
            border: 0 none;
            transition: color 0.35s ease;

            svg {
                display: none;
            }

            &:before {
                content: '\f061';
                @include fontawesome(400);
            }

            &--prev {
                &:before {
                    content: '\f060';
                }
            }

            &[disabled] {
                color: rgba($black, 0.5);
            }

        }

        &__pagination {
            padding-left: 22px;
            padding-right: 22px;
            counter-reset: pagination-num;

            > li:last-child {
                .splide__pagination__page {
                    width: auto;
                    height: auto;
                    visibility: visible;
                    font-size: 19px;
                    display: block;

                    &.is-active {
                        &:before {
                            counter-increment: pagination-num;
                            content: counter(pagination-num) " / " counter(pagination-num);
                        }
                        &:after {
                            display: none;
                        }
                    }
                }
            }

            &__page {
                padding: 0;
                border: 0 none;
                line-height: 1.1;
                background-color: transparent;
                font-weight: 500;
                width: 0;
                height: 0;
                overflow: hidden;
                visibility: hidden;
                font-size: 0;
                pointer-events: none;

                &:before {
                    display: inline-block;
                }

                &.is-active {
                    font-size: 19px;
                    display: block;
                    width: auto;
                    height: auto;
                    visibility: visible;

                    &:after {
                        content: '/';
                        padding: 0 4px;
                    }
                }

                &:before {
                    counter-increment: pagination-num;
                    content: counter(pagination-num);
                }
            }
        }

        &__video {
            &__play {
                background-color: #171711;
                border: 4px solid $white;
                width: 75px;
                height: 75px;
                opacity: 0.75;

                &:after {
                    border: 0 none;
                    color: $white;
                    font-size: 28px;
                    content: '\f04b';
                    @include fontawesome;
                }
            }
        }
    }
}
