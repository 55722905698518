.success-stories-listing {

    .loadmore .btn {
        min-width:  95px;
        padding-top: 0;
        padding-bottom: 0;

        > [class*='fa'] {
            font-size: 24px;
        }
    }

    .post {

        &:first-of-type {
            padding-top: 25px;
            border-top: 4px solid $grey-highlight-2;
        }

        @include media-breakpoint-up(sm) {
            display: flex;
        }

        &-body {
            @include media-breakpoint-up(sm) {
                margin-left: 0 !important;
            }

            p {
                margin-top: 17px;
            }
        }

    }

    .post-img {
        img {
            border-left: 4px solid $orange;

        }

        @include media-breakpoint-up(sm) {
            order: 2;
            margin-left: 50px;
            width: 40% !important;

        }
        @include media-breakpoint-up(lg) {
            width: 30% !important;
        }

        + .post-body {
            @include media-breakpoint-up(sm) {
                width: 60%;
            }

            @include media-breakpoint-up(lg) {
                width: 70%;
            }
        }
    }

    .post-title {
        font-weight: $font-weight-demi-web;
        margin-bottom: 0;
    }

    .post-meta {

        li {
            font-style: normal;
            text-transform: none;
            letter-spacing: 0.05em;
            color: $grey-lowlight-2;
            font-size: 16px;
            line-height: 1.375;
            @include media-breakpoint-up(lg) {
                font-size: 20px;
                line-height: 1.3;
            }

            a {
                color: $blue-highlight-3;
                font-weight: $font-weight-normal;
                @include link-underline;

                &:hover,
                &:focus {
                    color: $link-hover-color;
                }
            }

            &:not(:last-child) {
                &:after {
                    content: ",";
                    padding-left: 0;
                    font-size: inherit;
                    position: static;
                }
            }
        }
    }
}