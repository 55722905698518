.news-detail {
    &__meta {
        background-color: $grey-highlight-3;
        padding: 20px 30px 18px;
        display: flex;
        margin-bottom: 40px;
        flex-wrap: wrap;
        @include media-breakpoint-up(lg) {
            flex-wrap: nowrap;
            margin-bottom: 70px;
        }

        .post-meta {
            padding-left: 0;

            li {
                text-transform: none;
                color: $black;
                font-weight: $font-weight-normal;
                font-size: 18px;
                font-family: $font-family-primary;

                &::after {
                    display: none;
                }
            }

            + .post-category {
                @include media-breakpoint-up(lg) {
                    padding-left: 20px;
                    margin-left: 20px;
                    position: relative;

                    &:before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 0;
                        bottom: 0;
                        width: 1px;
                        background-color: $grey-highlight-1;
                    }
                }

            }
        }

        .post-category {
            @include media-breakpoint-down(lg) {
                width: 100%;
            }

            span,
            a {
                letter-spacing: 0.05em;
                text-transform: none;
                font-size: 18px;
                font-family: $font-family-primary;
                font-weight: $font-weight-bold;
            }

            span {
                color: $black;
            }

            a {
                color: $blue-highlight-3;
                text-decoration: underline;

                &:hover,
                &:focus {
                    color: $link-hover-color;
                }
            }
        }
    }

    &__tags {
        border-top: 1px solid $grey-highlight-2;
        padding-top: 26px;
        margin-top: 50px;
        display: flex;
        margin-bottom: 20px;

        h2 {
            line-height: 1;
            padding-right: 7px;
            @include media-breakpoint-up(md) {
                line-height: 1.1;
            }
        }

        .post-tag {
            line-height: 1;

            span,
            a {
                display: inline-block;
                font-family: $font-family-primary;
                font-weight: $font-weight-bold;
                font-size: 18px;
                line-height: 1;
                letter-spacing: 0.05em;
                margin-bottom: 3px;
                @include link-underline;

                &:not(:last-child) {
                    &::after {
                        content: ',';
                        display: inline-block;
                    }
                }
            }
        }
        .news-detail__sharethis + & {
            border-top: 0;
            margin-top: -40px;
        }
    }

    &__sharethis {
        border-bottom: 1px solid $grey-highlight-2;
        padding-bottom: 40px;
        margin-bottom: 40px;

        h2 {
            @include media-breakpoint-up(md) {
                margin-bottom: 0;
            }
        }

    }
}

.post {
    > .post-body {
        &:first-child {
            margin-left: 0 !important;
        }
    }
}

.section--news-teaser {
    p {
        font-style: italic;
        font-weight: $font-weight-medium;
        color: $blue-lowlight-1;
        font-size: 18px;
        letter-spacing: 0.02em;
        line-height: 1.278;
        padding-left: 24px;
        @include media-breakpoint-up(lg) {
            padding-left: 40px;
            line-height: 1.545;
            font-size: 22px;
            font-weight: $font-weight-normal;
        }
    }

    .herofeature + & {
        margin-top: -45px;
        @include media-breakpoint-up(lg) {
            margin-top: -65px;
        }
    }
    .herofeature--has-thumbnail + & {
        margin-top: -20px;
        @include media-breakpoint-up(lg) {
            margin-top: -45px;
        }
    }
}

.aside-widget {
    .col-lg-4 {

        .post-title {
            font-family: $font-family-secondary;
            font-size: 34px !important;
            line-height: 1.17;
            font-weight: $font-weight-medium-web;

            a {
                text-decoration: none;
            }
        }

        .post-meta {
            padding-left: 0;

            li {
                text-transform: none;
                color: $grey-lowlight-3;
            }
        }
    }
}