.alertset {

    @include default-margin-bottom();

    &__alerts {
        background: $grey-highlight-3;
    }

    @include media-breakpoint-up(lg) {
        &--multiple &__alerts {
            display: flex;
            flex-wrap: nowrap;
        }
        &--multiple &__alert {
            flex-grow:0;
            width:100%;
        }
    }

    &__alert {
        position:relative;
        &--hidden {
            display:none;
        }
    }
    &__alert:not(&__alert--hidden) + &__alert {
        &:before {
            content: "";
            height: 1px;
            background: $grey-highlight-2;
            position: absolute;
            left: 80px;
            right: 80px;
            top: 0;
        }
    }

    @include media-breakpoint-up(lg) {
        &--multiple &__alert:not(&__alert--hidden) + &__alert {
            &:before {
                content: "";
                width: 1px;
                height: auto;
                top: 16px;
                bottom: 16px;
                left: 0;
            }
        }
    }
}