.form-check {
    .form-check-label {
        font-size: 18px;
        @include media-breakpoint-up(lg) {
            font-size: 20px;
        }
        color: $grey-lowlight-3;
    }

    .form-check-inline {
        margin-right: 30px;
    }

    .form-check-input {
        margin-top: 4px;

        &:checked {
            background-color: $blue-highlight-3;
            border-color: $blue-highlight-3;
        }
    }

    &--round {
        .form-check-input {
            border-radius: 100px;
            width: 20px;
            height: 20px;
            @include media-breakpoint-up(lg) {
                width: 25px;
                height: 25px;
            }

        }
    }

    &--white {
        .form-check-label {
            color: $white;
        }
    }
}