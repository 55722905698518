.container .container {
    padding-left: 0;
    padding-right: 0;
}

.visually-hidden-on-mobile {
    @include media-breakpoint-down(md) {
        @include visually-hidden;
    }
}

@include media-breakpoint-up(sm) {
    .position-sm-absolute { position: absolute !important; }
    .position-sm-static { position: static !important; }
}
@include media-breakpoint-up(md) {
    .position-md-absolute { position: absolute !important; }
    .position-md-static { position: static !important; }
}
@include media-breakpoint-up(lg) {
    .position-lg-absolute { position: absolute !important; }
    .position-lg-static { position: static !important; }
}
@include media-breakpoint-up(xl) {
    .position-xl-absolute { position: absolute !important; }
    .position-xl-static { position: static !important; }
}