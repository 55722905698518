@mixin fontawesome($fontweight: 900) {
    font-family: 'Font Awesome 6 Pro';
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    font-weight: $fontweight;
}

@mixin rfs-value-lg($fontsize) {
    font-size: rfs-fluid-value($fontsize);
    @include media-breakpoint-up(lg) {
        font-size: $fontsize;
    }
}

@mixin text-shadow() {
    text-shadow: 2px 2px rgba(0, 0, 0, 0.3);
}

@mixin default-margin-bottom() {
    margin-bottom: 80px;
    @include media-breakpoint-up(lg) {
        margin-bottom: 100px;
    }
}

@mixin link-underline($link-underline-color: $blue-highlight-3, $link-underline-color-hover: transparent) {
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-decoration-color: $link-underline-color;
    text-underline-offset: 4px;
    transition: color 0.35s ease, text-decoration-color 0.35s ease;

    &:hover,
    &:focus {
        text-decoration-color: $link-underline-color-hover;
    }
}

/**
    Useful for building composite elements where the underline may be responding to hover on outer wrappers.
 */
@mixin underline($underline-color: inherit) {
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-decoration-color: $underline-color;
    text-underline-offset: 4px;
    transition: color 0.35s ease, text-decoration-color 0.35s ease;
}


@mixin cover-all() {
    position: absolute;
    left:0;
    right:0;
    top:0;
    bottom: 0;
}
@mixin before-overlay($background) {
    &:before {
        content: "";
        background: $background;
        @include cover-all;
        transition: background-color 0.35s ease, opacity 0.35s ease;
    }
}
@mixin after-overlay($background) {
    &:after {
        content: "";
        background: $background;
        @include cover-all;
        transition: background-color 0.35s ease, opacity 0.35s ease;
    }
}

@mixin caption-style() {
    font-style: italic;
    letter-spacing: 0.05em;
    color: $grey-lowlight-2;
    border-left: 4px solid $grey-highlight-1;
    background-color: $grey-highlight-3;
    padding: 17px;
    font-size: 14px;
    line-height: 1.714;
    @include media-breakpoint-up(lg) {
        font-size: 16px;
        line-height: 1.625;
    }
}

@mixin splide-controls() {
    height: 70px;
    display:flex;
    align-items: center;
    position:relative;

    .splide__arrows {
        display:flex;
        width:100%;
        align-items:center;
        justify-content: space-between;
    }
    .splide__pagination {
        position:absolute;
        left:0;
        right:0;
        top:0;
        bottom:0;
        height: 70px;
        display:flex;
        align-items: center;
        justify-content: center;
    }

    .splide__arrow {
        position:absolute;
        z-index:1;
        background: transparent;
        border:0;
        svg {
            display:none;
        }
        &:before {
            @include fontawesome(400);
            font-size: 24px;
            color: #797978;
            transition: color 0.35s ease;
        }
        &:hover:before {
            color: $body-color;
        }
    }
    .splide__arrow--prev {
        position: absolute;
        left: calc(50% - 110px);
        &:before {
            content: "\f060";
        }
    }
    .splide__arrow--next {
        position: absolute;
        right: calc(50% - 110px);
        &:before {
            content: "\f061";
        }
    }

    .splide__pagination__page {
        background:transparent;
        border:none;
        display:block;
        padding: 5px;
        margin: 0 2px;
        &:before {
            content: "";
            display:block;
            height: 12px;
            width: 12px;
            border-radius: 100px;
            background: #9b9b99;
            transition: background-color 0.35s ease;
        }
        &.is-active:before,
        &:hover:before {
            background: $orange;
        }
    }
}