.sectional-navigation {
    border-bottom: 1px solid $grey-highlight-2;
    background: white;
    transition: background-color 0.2s;
    margin-bottom: 40px;
    margin-top: -80px;
    @include media-breakpoint-up(lg) {
        margin-bottom: 80px;
        margin-top: -100px;
    }

    &--expanded {
        background: #f1f0eb;
    }

    &__explore {
        button {
            display:block;
            padding:0;
            width: 100%;
            font-family: $font-family-sans-serif;
            font-weight:$font-weight-demi-web;
            font-size: 22px;
            color: $grey-lowlight-3;
            border:0;
            background:transparent;
            text-align: left;

            .sectional-navigation__title {
                > span {
                    position: relative;

                    &:before {
                        content: '';
                        position: absolute;
                        height: 2px;
                        left: 0;
                        bottom: -2px;
                        width: 0;
                        background-color: $blue-highlight-1;
                        transition: width 0.5s ease;
                    }
                }
            }

            &:hover {
                .sectional-navigation__title {
                    > span {
                        &:before {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    &__explore button:hover,
    &__explore button:hover &__title:after {
        color: $blue-highlight-1;
    }

    &__title {
        display:flex;
        justify-content: flex-start;
        align-items: center;
        padding: 35px 20px 20px 20px;
    }

    &__links {
        padding: 0 20px 35px 20px;
    }

    &__title:after {
        display:block;
        position:relative;
        margin-left: 30px;
        font-size: 22px;
        content: "\f078";
        @include fontawesome(600);
        color: $blue-highlight-3;
        transition: transform 0.2s;
    }
    &--expanded &__title:after {
        transform: rotate(180deg);
    }

    h2 {
        margin:0;
    }
    a {
        font-family: $font-family-sans-serif;
        font-size: 20px;
        font-weight: $font-weight-medium-web;
        letter-spacing: 0.050em;

        @include link-underline;
    }

    ul {
        display:block;
        margin:0;
        padding:0;
    }
    li {
        flex-grow:0;
        flex-shrink:0;
        display:block;
        margin:20px 50px 0 0;
        padding:0;
        &:first-child {
            margin-top:0;
        }
    }

    @include media-breakpoint-up(md) {

        ul {
            display:flex;
            flex-wrap: wrap;
        }
        li, li:first-child {
            margin-top:0;
            margin-bottom: 10px;
        }
    }

    @include media-breakpoint-up(lg) {

        &__title {
            padding:40px 40px 30px 40px;
        }

        button {

        }
        &__links {
            padding:0 40px 30px 40px;
        }
        &__explore {
            button {
                font-size: 24px;
                &:after {
                    font-size: 22px;
                }
            }
        }
    }
}