.multi-link-feature {

    @include default-margin-bottom;

    &__link {
        position: relative;
        z-index:0;
        display: block;
        height: 150px;

        border-radius: 5px;
        margin-bottom: 10px;

        &:after {
            content:"";
            position:absolute;
            z-index:-1;
            left:0;
            right:0;
            height: 20px;
            bottom: 5px;
            border-radius:5px;
            transition: bottom 0.35s ease;
            background: $orange;
        }

    }
    &__background-wrap {
        height: 150px;
    }
    &--image &__link {
        height: 260px;
    }
    &--image &__background-wrap {
        height: 260px;
    }
    &__background-wrap {
        position:relative;
        overflow:hidden;
        border-radius: 5px;
    }
    &__background {
        @include cover-all;
        background-position: center;
        background-size:cover;
        overflow:hidden;
        transition: transform 1.25s ease;
    }
    &--image &__background {
        @include before-overlay(linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.75) 100%));
    }

    &:not(&--image) &__background {
        background: linear-gradient(to bottom, rgba(#062f6e, 0) 0, rgba(#062f6e, 1) 100%), url('../images/overlay-cards-cta.png'), linear-gradient(to bottom, #062f6e 0, #062f6e 100%) !important;
        background-position: left bottom !important;
        background-size: cover !important;
    }

    &--image &__link:hover &__background,
    &--image &__link:focus &__background {
        transform: scale(1.1);
    }

    &__text {
        @include cover-all;
        display:flex;
        height:100%;
        flex-direction: column;
        justify-content: flex-end;
        text-align:center;
        padding:36px;
        transition: transform 1.25s ease;
        color: white;
        font-family: $font-family-sans-serif;
        font-size: 24px;
        line-height: 28px;
        @include media-breakpoint-up(md) {
            font-size: 28px;
            line-height: 32px;
        }
        font-weight: $font-weight-demi-web;
        text-transform: uppercase;
        z-index:1;
        text-decoration: none;
    }
    &:not(&--image) &__text {
        justify-content: center;
        font-size: 20px;
        line-height: 24px;
        @include media-breakpoint-up(md) {
            font-size: 24px;
            line-height: 28px;
        }
    }

    &__link:hover:after {
        bottom: -5px;
    }
    &:not(&--image) &__background{
        @include after-overlay(rgba(#001a3d, 0.00));
    }
    &:not(&--image) &__link:hover &__background {
        @include after-overlay(rgba(#001a3d, 0.60));
    }
    &--image &__link:hover &__text {
        transform: translateY(-18px);
    }
}