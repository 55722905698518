.tabs {
    border-top: 1px solid $grey-highlight-2;
    background: $grey-highlight-3;
}
.nav-tabs {
    border: none;
}
.tabs.tabs--horizontal {
    @include media-breakpoint-down(md) {
        > .container {
            padding: 0;
        }
    }
}
.nav-tabs--extra-space {
    padding-top: 60px !important;
}
.nav-tabs.nav-tabs--default.nav-tabs--horizontal {
    @include media-breakpoint-up(md) {
        padding-top: 16px;
    }

    border-bottom: none;
    justify-content: center;
    align-items: stretch;
    flex-wrap:nowrap;

    .nav-item {
        display: flex;
        align-items: stretch;
        justify-content: stretch;
        flex-grow:1;
        max-width: 415px;
    }
    .nav-item .nav-link {
        flex-grow: 1;
        font-family: $font-family-sans-serif;
        font-weight: $font-weight-demi-web;
        text-transform: uppercase;
        color: $blue-highlight-3;
        border:none;
        border-radius: 0;
        border-left:0;
        border-right:0;
        border-top: 5px solid transparent;
        border-bottom: 4px solid transparent;
        margin:0;
        padding: 24px;

        &.active {
            border-top-color: $orange !important;
            border-bottom-color: transparent !important;
            color: $blue-lowlight-1;
        }
        &:hover, &:focus {
            border-bottom-color:$orange;
            color: $blue-lowlight-1;
            border-top-color:transparent;
        }
    }

}
.tabs.tabs--vertical {
    border-top: none;
    height:100%;
}
.nav-tabs.nav-tabs--default.nav-tabs--vertical {
    padding: 16px 0 16px 16px;
    border-top: none;

    .nav-item {
        display: flex;
        align-items: stretch;
        justify-content: stretch;
        flex-grow:1;
    }
    .nav-item .nav-link {
        flex-grow:1;
        font-family: $font-family-sans-serif;
        font-weight: $font-weight-demi-web;
        text-transform: uppercase;
        color: $blue-highlight-3;
        border-bottom: none;
        border-left: 5px solid transparent;
        border-right: 4px solid transparent;
        border-top:none;
        text-align: left;
        border-radius: 0;
        margin:0;
        padding: 24px;
        position:relative;
        &:after {
            content: "";
            position:absolute;
            height: 1px;
            background: $grey-highlight-2;
            bottom: 0;
            left:0;
            right:-4px;
        }

        &.active {
            border-left-color: $orange;
            border-right-color: transparent !important;
            border-top-color: transparent !important;
            &:after {
                background: transparent;
            }
            color: $blue-lowlight-1;
            position:relative;

            // cover the rule from the above element
            top: -1px;
            padding-top: 25px;
            margin-bottom: -1px;
        }

        &:hover, &:focus {
            border-right-color: $orange;
            color: $blue-lowlight-1;
        }
    }
    .nav-item:last-child .nav-link {
        &:after {
            content: none;
        }
    }
}