.card {

    &--image {
        display:flex;
        align-items: stretch;
        height: calc(100% - 24px);
        margin-bottom: 24px;

        > a {
            text-decoration: none;
            border-bottom: 5px solid $grey-highlight-3;
            transition: border-bottom-color 0.35s ease;
        }

        .card__image {
            height: 260px;
            position:relative;
            overflow:hidden;
        }
        .card__background-image {
            @include cover-all;
            @include before-overlay(linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.75) 100%));
            @include after-overlay(rgba($black, 0.00));
            background-position: center;
            background-size:cover;

            transition: transform 1.25s ease;

            overflow:hidden;
        }
        &:hover .card__background-image,
        &:focus .card__background-image {
            transform: scale(1.1);
        }
        &:hover .card__background-image {
            @include after-overlay(rgba($black, 0.20));
        }
        .card__overlay-heading {
            @include cover-all;
            display:flex;
            height:100%;
            flex-direction: column;
            justify-content: flex-end;
            text-align:center;
            padding:36px 36px 62px 32px;

            h2, h3 {
                transform: translateY(50%);
                font-family: $font-family-sans-serif;
                font-size: 24px;
                font-weight: $font-weight-demi-web;
                color: white;
                text-transform: uppercase;
                line-height: 32px;
                z-index: 1;
                text-decoration: none;
            }

            .card__overlay-heading-icon {
                white-space:nowrap;
            }
            .card__overlay-heading-icon:after {
                content: '\f0a9';
                position: relative;
                margin-top: 5px;
                margin-left: 8px;
                @include fontawesome;
                transition: transform 0.35s ease;
                color: $gold;
                font-size: 20px;
                @include media-breakpoint-up(lg) {
                    font-size: 22px;
                }
            }
        }
        &.card--large-title {
            .card__overlay-heading h2, h3 {
                @include media-breakpoint-up(lg) {
                    font-size: 28px;
                }
            }
        }

        .card-body {
            color: $body-color;
            font-size: 18px;
            padding: 26px 0;
            p:last-child {
                margin-bottom: 0;
            }
        }

        > a {
            &:hover,
            &:focus {
                border-bottom-color: $orange;

                .card__overlay-heading {
                    h2, h3 {
                        &:after {
                            transform: translateX(12px);
                        }
                    }
                }
            }
        }
    }


    &--contact {
        margin-bottom: 10px;
        background-color: $grey-highlight-3;
        padding: 17px;
        @include media-breakpoint-up(md) {
            padding: 30px;
        }

        .card-header {
            position: relative;
            padding-bottom: 18px;
            margin-bottom: 22px;

            h2,
            h3 {
                color: $grey-lowlight-3;
                font-size: 26px;
                line-height: 1.231;
                margin-bottom: 2px;
                @include media-breakpoint-up(md) {
                    font-size: 28px;
                    line-height: 1.214;
                }
            }

            p {
                font-style: italic;
                color: $grey-lowlight-2;
                margin-bottom: 0;
                @include media-breakpoint-down(md) {
                    font-size: 18px;
                }
            }

            &::after {
                content: '';
                width: 89px;
                height: 4px;
                background-color: $orange-highlight-1;
                position: absolute;
                left: 0;
                bottom: 0;
            }
        }

        .card-body {
            > *:last-child {
                margin-bottom: 5px;
            }

            a {
                overflow-wrap: break-word;
            }
        }

        &.animate-in-view {
            .card-header {
                &::after {
                    width: 0;
                    transition: width 1.75s ease-out;
                }
            }

            &--intersecting {
                .card-header {
                    &::after {
                        width: 89px;
                    }
                }
            }
        }
    }
    &--contact.card--contact-directory {
        border-radius: 5px;
        height: 100%;

        @include media-breakpoint-down(md){
            padding: 30px;
        }

        h3 {
            font-size: 30px;
            margin-bottom: 16px;
        }
        p {
            font-style: normal;
            margin-bottom: 5px;
        }
        .card-header::after {
            content:none;
        }
        .card-header {
            border-bottom: 1px solid $grey-highlight-1;

            a {
                @include link-underline();
            }
            strong {
                font-weight: $font-weight-semibold;
            }
        }
        .card-body {
        }
    }

    &--row {
        margin-bottom: 0;
        @include media-breakpoint-up(lg) {
            padding: 30px 30px 20px;
            display: flex;
            .card-header {
                width: 40%;
                margin-right: 10%;
            }
            .card-body {
                width: 50%;
            }
        }

        .card-header {
            margin-bottom: 0;

            &::after {
                display: none;
            }
        }

        + .card--row {
            position: relative;

            &::before {
                content: '';
                position: absolute;
                background-color: $grey-highlight-2;
                height: 1px;
                top: 0;
                left: 17px;
                right: 17px;
                @include media-breakpoint-up(md) {
                    left: 30px;
                    right: 30px;
                }
            }
        }
    }

    &--content {
        margin-bottom: 24px;
        height: calc(100% - 24px);

        > a {
            height: 100%;
            padding: 30px;
            border-color: $grey-highlight-2 transparent;
            border-style: solid;
            border-width: 4px 1px 1px 1px;
            display: flex;
            flex-direction: column;
            text-decoration: none;
            position: relative;
            transition: border 0.35s ease;

            &::before {
                content: '';
                background-color: $grey-highlight-2;
                height: 4px;
                top: -4px;
                position: absolute;
                left: -1px;
                right: -1px;
                transition: background-color 0.35s ease;
            }

            &:hover,
            &:focus {
                border-color: $grey-highlight-1;

                &::before {
                    background-color: $orange;
                }

                .card-header {
                    h2, h3 {
                        color: $blue-highlight-3;
                        &:after {
                            transform: translateX(12px);
                        }
                    }
                }
            }
        }


        .card-header {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-top: 24px;
            padding-bottom: 17px;
            margin-top: -30px;
            hyphens: auto;
            
            @include media-breakpoint-up(lg) {
                min-height: 109px;
            }

            h2, h3 {
                letter-spacing: 0.05em;
                transition: color 0.35s ease;
                text-transform: uppercase;
                font-weight: $font-weight-demi-web;
                padding-right: 30px;
                font-size: 24px;
                line-height: 1.167;
                @include media-breakpoint-up(lg) {
                    font-size: 28px;
                    line-height: 1.143;
                }

                &:after {
                    content: '\f0a9';
                    position: absolute;
                    margin-top: 5px;
                    margin-left: 8px;
                    @include fontawesome;
                    transition: transform 0.35s ease;
                    color: $gold;
                    font-size: 20px;
                    @include media-breakpoint-up(lg) {
                        font-size: 22px;
                    }
                }

                span {
                    hyphens: none;
                }
            }
        }
        .card-body {
            > *:last-child {
                margin-bottom: 0;
            }
        }

        .card-body {
            color: $grey-lowlight-3;

            p {
                font-size: 18px;
                line-height: 1.334;
            }
        }

    }

    &--blue {
        background: linear-gradient(to bottom, $blue 0%, $blue-highlight-1 100%);

        > a {
            border-color: $blue-highlight-1;
            border-width: 4px 0px 0px 0px;
            transition: border 0.35s ease, background-color 0.35s ease;

            &::before {
                background-color: transparent;
                left: 0;
                right: 0;
            }

            &:hover,
            &:focus {
                background-color: $blue;

                .card-header {
                    h2, h3 {
                        color: $white;
                    }
                }
            }
        }

        .card-header {

            h2, h3 {
                color: $white;
            }
        }

        .card-body {
            color: $white;
        }

    }

    &--compact {
        > a {
            padding: 26px;
        }

        .card-header {
            margin-top: -26px;

            h2, h3 {
                @include media-breakpoint-up(lg) {
                    font-size: 24px;
                    line-height: 1.167;
                }
            }
        }
    }

}