.news-thumbnail {

    a {
        text-decoration: none;
        display:block;
    }

    &__link {
        font-family: $font-family-sans-serif;
        font-weight: $font-weight-medium-web;
        font-size: 28px;
        line-height: 32px;
        @include media-breakpoint-up(lg) {
            font-size: 34px;
            line-height: 40px;
        }

        text-decoration: none;
        color: $blue-highlight-2;

        margin-bottom: 15px;
    }

    &__details {
        color:$blue-lowlight-1;
        font-size: 16px;
        font-family: $font-family-sans-serif;
        text-transform: uppercase;
        margin-bottom: 10px;
    }
    &__category {
        font-weight: $font-weight-demi-web;
        margin-right: 8px;
    }
    &__date {
        font-weight: $font-weight-medium-web;
    }

    a:hover &__link {
        color: $blue-highlight-3;
    }
    a:hover &__link {
        color: $blue-highlight-3;
    }

    &__background-wrap {
        position:relative;
        overflow:hidden;
        width: 100%;
        height:0;
        padding-bottom: 68%;

        margin-bottom: 20px;
        @include media-breakpoint-up(lg) {
            margin-bottom: 30px;
        }
    }
    &__background {
        @include cover-all;
        background-position: center;
        background-size:cover;
        overflow:hidden;
        transition: transform 1.25s ease;
    }
    a:hover &__background,
    a:focus &__background {
        transform: scale(1.1);
    }


}