.animate-in-view--fade-in-and-up {
    html:not(.no-js) & {
        opacity: 0;
    }

    &.animate-in-view--intersecting {
        animation: fadeInAndUp .75s cubic-bezier(.645, .045, .355, 1) 0s forwards;
    }
}

@for $i from 1 through 20 {

    .animate-in-view__stagger [class*='col']:nth-of-type(#{$i}) .animate-in-view--fade-in-and-up {
        animation-delay: $i * 0.15s;
    }
}

@keyframes fadeInAndUp {
    0% {
        opacity: 0;
        transform: translate3d(0,2rem,0)
    }

    to {
        opacity: 1;
        transform: translateZ(0)
    }
}


@media screen and (prefers-reduced-motion: reduce), (update: slow) {
    .animate-in-view {
        animation-duration: 0.0001ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.0001ms !important;
        opacity: 1 !important;
    }

    .title--bottom-divider.animate-in-view {
        &:after {
            width: 130px !important;
        }
    }

    .animate-in-view {
        .card-header {
            &::after {
                width: 89px !important;
            }
        }
    }
}