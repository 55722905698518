h1, .h1 {
    margin-bottom: 21px;

    @include media-breakpoint-down(sm) {
        font-size: 44px;
    }
}

h2, .h2 {
    color: $blue-lowlight-1;
    letter-spacing: -0.01em;
    margin-bottom: 11px;

    @include media-breakpoint-down(sm) {
        font-size: 40px;
    }
}

h3, .h3 {
    font-weight: $font-weight-demi-web;
    @include media-breakpoint-down(sm) {
        font-size: 32px;
    }
}

h4, .h4 {
    color: $grey-lowlight-1;

    @include media-breakpoint-down(sm) {
        font-size: 28px;
    }
}

h5, .h5 {
    color: $blue-lowlight-1;
    margin-bottom: 5px;

    @include media-breakpoint-down(sm) {
        font-size: 24px;
    }
}

h6, .h6 {
    font-weight: $font-weight-bold;
    letter-spacing: 0.02em;
    margin-bottom: 8px;

    @include media-breakpoint-down(sm) {
        font-size: 18px;
    }
}

//paragraph + header spacing
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
    p + &,
    ul + &,
    ol + & {
        margin-top: 42px;
    }

    .subtitle {
        text-transform: uppercase;
        font-size: 20px;
        font-weight: $font-weight-demi-web;
        letter-spacing: 0.05em;
        color: $blue-highlight-2;
        display:block;
    }
}

.title {
    &--bottom-divider {
        position: relative;
        padding-bottom: 20px;
        margin-bottom: 36px;

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 130px;
            height: 4px;
            background-color: $orange;
        }

        &.title--center {
            &:after {
                left: 50%;
                transform: translateX(-50%);
            }
        }

        &.animate-in-view {
            &:after {
                width: 0;
                transition: width 1.75s ease-out;
            }

            &--intersecting {
                &:after {
                    width: 130px;
                }
            }
        }
    }

    &--compact {
        text-transform: uppercase;
        font-size: 20px;
        font-weight: $font-weight-demi-web;
        letter-spacing: 0.05em;
        color: $blue-highlight-1;
    }

    &--center {
        text-align: center;
    }
}

.tab-heading {
    font-family: $font-family-sans-serif;
    font-size: 32px;
    font-weight: $font-weight-demi-web;
    color: $blue-highlight-1;
}