.filter-band {
    margin-bottom: 50px;

    &__top {
        background:white;
        color: #001a3d;
        padding: 20px 0 30px 0;
        @include media-breakpoint-up(md) {
            padding: 35px 0;
        }
    }
    &__bottom {
        background-color: $blue-highlight-1;
        padding: 20px 0;
        @include media-breakpoint-up(md) {
            padding: 30px 0;
        }
    }

    &__text {
        display:flex;
        flex-wrap:nowrap;
        align-items: center;

        > a {
            flex-shrink:0;
            margin-left: 12px;
        }
        > *:first-child {
            margin-left: 0;
        }
    }

    &__button {
        display:table;
        margin:5px auto 0 auto;
    }

    .multiselect-dropdown {
        margin-bottom: 15px;
        a:focus {
            box-shadow: inset 0 0.0625rem 0.125rem rgb(0 0 0 / 8%), 0 0 0.15rem 0.15rem $gold;
        }
    }

    .form-check {
        margin-bottom: 15px;
        input:focus {
            box-shadow: inset 0 0.0625rem 0.125rem rgb(0 0 0 / 8%), 0 0 0.15rem 0.15rem $gold;
        }
    }

    &__controls {
        margin-top: 20px;
        color: white;
        display:flex;
        align-items: center;


        @include media-breakpoint-down(sm) {
            margin-top: 8px;
            flex-wrap: wrap;
        }

        a {
            color: white;
            text-decoration: none !important;
        }

        &__grid,
        &__list {
            font-size: 28px;
            margin-right: 24px;

            &:hover,
            &:focus {
                color: $gold;
            }

            @include media-breakpoint-down(lg) {
                display: none;
            }
        }

        &__list + &__count {
            border-left: 2px solid #a6c7f4;
            padding-left: 15px;

            @include media-breakpoint-down(lg) {
                border-left:none;
                padding-left:0;
                padding-right: 10px;
            }
            @include media-breakpoint-down(sm) {
                width: 100%;
                order: 3;
                margin-top: 20px;
            }
        }

        &__filter {
            @include media-breakpoint-up(sm) {
                margin-left: auto;
            }

            a {
                color: black;
            }
        }

        &__reset {
            a {
                white-space: nowrap;
                padding-right: 0 !important;
                color: white !important;
                span { text-decoration: none !important; }
                [class*="fa"]  {
                    color: $gold;
                }
            }
        }
    }

    ~ .filter-band__results {
        .filter-band__results__cards {
            display: grid;
            gap: 24px;
            grid-template-columns: 1fr;
        }
    }

    &--grid {

        .filter-band__controls__grid {
            color: $gold;
        }

        + .filter-band__results {
            .filter-band__results__cards {
                @include media-breakpoint-up(md) {
                    grid-template-columns: 1fr 1fr;
                }
                @include media-breakpoint-up(lg) {
                    grid-template-columns: 1fr 1fr 1fr;
                }
                @include media-breakpoint-up(xl) {
                    grid-template-columns: 1fr 1fr 1fr 1fr;
                }
            }
        }
    }

    &--list {

        .filter-band__controls__list {
            color: $gold;
        }

        + .filter-band__results {
            max-width: 857px;
            margin-left: auto;
            margin-right: auto;
        }
    }


    &--no-filters {
        margin-top: 0;
    }
    &--no-filters &__top,
    &--no-filters &__bottom,
    &--no-filters &__controls {
        padding: 0;
        margin: 0;
    }
    &--no-filters &__bottom {
        background: transparent;
    }
    &--no-filters &__controls a {
        color: $body-color;
    }
    &--no-filters.filter-band--grid &__controls__grid {
        color: $gold;
    }
    &--no-filters.filter-band--list &__controls__list {
        color: $gold;
    }
    &--no-filters &__controls__count {
        color: $body-color;
        border-left-color: $grey;
    }



    .btn[disabled] {
        background-color: #042453;
        border-color: #042453;
        color: #999999;
        pointer-events: none;
    }
}