.text-and-image {

    &__image {

        .figure {
            margin-bottom: 0;

            &--circle {
                padding-bottom: 0;
                width: 155px;
                height: 155px;
                @include media-breakpoint-up(md) {
                    width: 291px;
                    height: 291px;
                }
            }
        }
    }

    &__image-right {

        @include media-breakpoint-up(lg) {
            background: linear-gradient(20deg,  rgba($blue,1) 0%,rgba(#0e4aa5,1) 40%);
            @include cover-all;
        }

        .figure {
            margin-bottom: 0;
            border-left: 0 none;

            @include media-breakpoint-up(lg) {
                width: 60%;
                height: 100%;
                display: flex;
                margin-left: auto;
                img {
                    object-fit: cover;
                    object-position: right;
                    width: 100%;
                    height: 100%;
                }

                &:before {
                    content: '';
                    background: transparent url('../images/overlay-featured-destination-cta-style-3.png') no-repeat scroll center top;
                    background-size: auto 100%;
                    position: absolute;
                    left: -21%;
                    top: 0;
                    bottom: 0;
                    right: 0;
                }
            }

            @include media-breakpoint-up(xxl) {
                width: 760px;
                position: relative;
                &:before {
                    left: -100%;
                }
            }

        }
    }

    &__content {
        padding-top: 27px;

        .blockquote-figure {
            border: 0 none;
            padding-bottom: 0;
            @include media-breakpoint-up(lg) {
                padding-bottom: 1px;
            }

            .blockquote {
                border: 0 none;
                padding-top: 0;
                padding-left: 38px;
                @include media-breakpoint-up(lg) {
                    padding-left: 0;
                }
                @include media-breakpoint-down(lg) {
                    padding-bottom: 42px;
                }

                p {
                    text-align: left;
                    font-size: 28px;
                    line-height: 1.143;
                    font-family: $font-family-secondary;
                    letter-spacing: -0.005em;

                    @include media-breakpoint-up(xl) {
                        font-size: 46px;
                        line-height: 1.043;
                    }

                    &:first-of-type {
                        &:before {
                            left: 0;
                            top: 2px;
                            @include media-breakpoint-up(lg) {
                                left: -56px;
                                top: -5px;
                            }
                        }
                    }
                }
            }
            .blockquote-footer {
                letter-spacing: 0.05em;
                font-size: 16px;
                text-transform: uppercase;
                color: $link-hover-color;
                font-family: $font-family-secondary;
                font-weight: $font-weight-demi-web;
                padding-left: 0;
                @include media-breakpoint-up(lg) {
                    font-size: 20px;
                }

                &::before {
                    display: none;
                }

                small {
                    letter-spacing: normal;
                    text-transform: none;
                    color: $grey-lowlight-3;
                    font-family: $font-family-primary;
                    font-weight: $font-weight-normal;
                    @include media-breakpoint-down(lg) {
                        font-size: 16px;
                    }
                    @include media-breakpoint-down(sm) {
                        margin-top: 2px;
                    }
                }
            }
        }

        @include media-breakpoint-down(lg) {
            text-align: center;
        }

        @include media-breakpoint-down(md) {
            h2 {
                font-size: 34px;
                line-height: 1.059;

                small {
                    font-size: 16px;
                }
            }
        }

        &.text-center {
            .button-set {
                justify-content: center;
            }
        }

        &--white-text {
            color: $white;

            h2, h3 {
                color: $white;
            }
        }
    }

    .button-set {
        @include media-breakpoint-down(lg) {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    &__full-background {
        background-size: cover;
        background-position: center;
        position: relative;
        padding-top: 38px;
        padding-bottom: 80px;

        .text-and-image__content {
            padding-left: 35px;
            padding-right: 35px;
            position: relative;
            z-index: 3;
            color: $white;

            h2, h3, h4 {
                color: $white;
            }

            .button-set {
                margin-top: 30px;
            }
        }

        &:before {
            content: '';
            @include cover-all;
            z-index: 1;
            background: linear-gradient(to bottom,  rgba(10,27,52,0.65) 0%,rgba(2,6,12,0.74) 76%,rgba(0,0,0,0.86) 100%);
        }

        &:after {
            content: '';
            z-index: 2;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            width: 100%;
            background: transparent url(../images/overlay-banner-cta.png) no-repeat scroll right -350px top;
            background-size: auto 100%;
            @include media-breakpoint-up(lg) {
                background-position: right top;
                background-size: auto 100%;
                width: 90%;
            }

        }
    }
}