.embedded-video {
    margin-bottom: 50px;

    @include media-breakpoint-down(sm) {
        margin-left: -12px;
        margin-right: -12px;
    }

    &__wrapper {
        border-left: 4px solid $orange;
        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        height: 0;

        object,
        embed,
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &__caption {
        @include caption-style;

        p {
            margin-bottom: 0;
        }
    }

    &.float-end,
    &.float-start {
        margin-top: 10px;
        margin-bottom: 30px;

        @include media-breakpoint-up(md) {
            max-width: 45%;
        }
        @include media-breakpoint-down(md) {
            float: none !important;
        }

        .embedded-video__wrapper {
            padding-bottom: 71.2%;
        }
    }

    &.float-end {
        @include media-breakpoint-up(md) {
            margin-left: 1.5em;
            margin-right: -65px;
        }
        @include media-breakpoint-up(lg) {
            margin-right: -160px;
        }
        @include media-breakpoint-up(xl) {
            margin-right: -191px;
        }
        @include media-breakpoint-up(xxl) {
            margin-right: -220px;
        }
    }

    &.float-start {
        @include media-breakpoint-up(md) {
            margin-right: 1.5em;
            margin-left: -65px;
        }
        @include media-breakpoint-up(lg) {
            margin-left: -160px;
        }
        @include media-breakpoint-up(xl) {
            margin-left: -191px;
        }
        @include media-breakpoint-up(xxl) {
            margin-left: -220px;
        }
    }
}