.quickfacts {
    @include default-margin-bottom();

    padding-top: 35px;
    padding-bottom: 40px;

    &__heading {
        text-align: center;
        font-weight: $font-weight-medium-web;
    }

    &__facts {
        width: 100%;
        margin-top: 80px;
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
    }
    &__fact {
        position:relative;
        width: 100%;
        min-height:100%;
        flex-grow:0;
        flex-shrink:0;
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        text-align: center;
        padding: 0 30px 40px 30px;
    }

    @include media-breakpoint-up(xl) {
        &--four &__fact {
            width: 25%;
        }
        &--three &__fact {
            width: 33.3%;
        }
    }

    &__facts &__fact + &__fact {
        &:before {
            content: "";
            position:absolute;
            display:block;
            width: 1px;
            top: 83px;
            bottom: 40px;
            left:0;
            background: $grey-highlight-2;
        }
    }
    @include media-breakpoint-between(lg, xl) {
        &__rotator .splide__slide:not(.is-visible) &__fact,
        &__rotator .splide__slide.is-visible + .is-visible &__fact {
            &:before {
                content: "";
                position: absolute;
                display: block;
                width: 1px;
                top: 83px;
                bottom: 40px;
                left: 0;
                background: $grey-highlight-2;
            }
        }
    }

    &__icon {
        background: $orange;
        width: 110px;
        height: 110px;
        margin-top: -55px;
        color: white;
        border: 4px solid white;
        border-radius: 1000px;
        display:flex;
        justify-content: center;
        align-items: center;
        font-size: 50px;
    }

    &__statistic {
        color: #1c5ec2;
        font-size: 65px;
        font-weight: $font-weight-demi-web;
        font-family: $font-family-sans-serif;
    }

    &__body {
        font-size: 21px;
        p {
            margin-bottom: 18px;
            line-height: 26px;
        }
        em {
            font-size: 16px;
            line-height: 24px;
            font-style: italic;
            color: $grey-lowlight-2;
        }
    }

    &__link {
        font-size: 18px;
        @include media-breakpoint-up(md) {
            font-size: 20px;
        }
    }

    &__button {
        display:table;
        margin: 30px auto 0 auto;
    }

    &--light &__fact,
    &--light &__controls, {
        background: $grey-highlight-3;
    }

    &--dark {
        background: url('../images/quickfacts-background.jpg');
        background-size:cover;
        background-position: center ;
    }
    &--dark &__heading {
        color: white;
    }
    &--dark &__fact,
    &--dark &__controls {
        background: $white;
    }

    &__rotator-item {
        padding-top: 80px;
        height:100%;
    }

    &__controls {
        @include splide-controls;
    }
}