.hh-mashup {
    .grid-item {
        box-shadow: none;
        background-color: $grey-highlight-3;

        a {
            .fullLink {
                border-top: 4px solid transparent;
                transition: border-color 0.35s ease;
            }

            &:hover,
            &:focus {
                .fullLink {
                    border-top-color: $orange;
                }
            }

            &:focus-visible {
                .fullLink {
                    outline-offset: 1px;
                    outline: $orange auto 1px;
                }
            }
        }
    }

    button.btn-filter {
        &:focus-visible {
            outline-offset: 1px;
            outline: #005fcc auto 1px;
        }

        i {
            color: $blue-highlight-1;
        }
    }
    .filter-button-group button {
        &:hover {
            opacity: 1;
            i {
                color: $blue-highlight-3;
            }
        }
    }
}