.tertiary-button {
    a {
        font-family: $font-family-sans-serif;
        font-size: inherit;
        font-weight: $font-weight-medium;
        letter-spacing: 0.030em;
        text-decoration: underline;
        @include link-underline(transparent, inherit);
        &:after {
            content: "\f0a9";
            font-size: 85%;
            color: $gold;
            @include fontawesome();
            margin-left:15px;
            line-height:0;
        }

    }

    &--has-shadow a {
        @include text-shadow();
    }

    &--light-blue a {
        color: $blue-highlight-3;
        &:hover,
        &:focus {
            color: $blue-highlight-3;
        }
    }
    &--grey a {
        color: $grey-highlight-2;
        &:after {
            color: $gold;
        }

        &:hover,
        &:focus {
            color: white;
        }
    }
    &--dark-grey a {
        color: #101113;
        &:after {
            color: $gold;
        }

        &:hover,
        &:focus {
            //color: white;
        }
    }

    &--white a {
        color: white;
        &:after {
            color: $gold;
        }

        &:hover,
        &:focus {
            color: white;
        }
    }

    &--dark-blue a {
        color: $blue-highlight-1;
        &:after {
            color: $orange;
        }

        &:hover,
        &:focus {
            color: $blue-highlight-1;
        }
    }


    @keyframes tertiary_link_bounce {
        0% {
            transform:translateY(0);
        }
        33% {
            transform:translateY(-3px);
        }
        66% {
            transform:translateY(2px);
        }
        100% {
            transform:translateY(0);
        }
    }

    &--anchor a:after {
        content:"\f0ab";
    }
    &--anchor a:hover:after {
        animation-duration:0.6s;
        animation-name: tertiary_link_bounce;
    }
    &:not(&--anchor) {
        a:after {
            margin-left: 12px;
            transition: transform 0.35s ease;
            will-change: transform; //Added for Firefox jumping bug
        }
        a:hover:after,
        a:focus:after {
            transform: translateX(12px);
        }
    }
}