.site-header {
    .navbar {
        padding: 0;
        position: absolute;
        z-index:10;
        width: 100%;
        align-items: flex-start;
        &::before {
            content: '';
            height: 5px;
            width: 100%;
            background: linear-gradient(to right,  $blue 0%,$blue-highlight-2 100%);
        }
    }
    .navbar-brand {
        margin-top: 20px;
        img {
            height: auto;
            filter: drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.5));
            width: 195px;
            @include media-breakpoint-up(md) {
                width: 313px;
            }
            @include media-breakpoint-down(md) {
                position: relative;
                top: -16px;
            }
        }
    }
    &__main-menu {

        @include media-breakpoint-between(md, lg) {
            position:relative;
            top: 34px;
        }

        ul {
            display: flex;
            padding: 0;
        }
        li {
            display: block;
            @include media-breakpoint-up(md) {
                margin-left: 10px;
                &:last-child {
                    margin-left: 20px;
                }
            }
        }
    }
    &__utility-menu {
        margin-top: 15px;

        ul {
            display: flex;
        }
        li {
            display: block;
            margin-left: 20px;
        }

        a {
            color: #dad9d2;
            //text-decoration: none;
            font-family: $font-family-sans-serif;
            font-weight: $font-weight-book;
            text-shadow: 2px 2px rgba(0, 0, 0, 0.23);

            @include link-underline(transparent, $white);
            i {
                font-size: 16px;
            }
            &:hover {
                color: white;
            }
        }

        .alert-active {
            padding-left: 23px;
            position: relative;

            &:before {
                content: '!';
                display: inline-flex;
                justify-content: center;
                align-items: center;
                font-family: $font-family-sans-serif;
                font-weight: $font-weight-bold;
                font-size: 13px;
                color: white;
                width: 18px;
                height: 18px;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                background: $red;
                border-radius: 100%;
                box-shadow: 2px 2px 1px -1px rgba(0, 0, 0, 0.3);
                text-shadow: none;
            }
        }
    }

    &--has-alert &__main-menu {
        @include media-breakpoint-down(lg) {
            position:relative;
            &:before {
                content: '!';
                display: inline-flex;
                justify-content: center;
                align-items: center;
                font-family: $font-family-sans-serif;
                font-weight: $font-weight-bold;
                font-size: 13px;
                color: white;
                width: 18px;
                height: 18px;
                position: absolute;
                top: 0;
                right: 0;
                transform: translateY(-50%);
                background: $red;
                border-radius: 100%;
                box-shadow: 2px 2px 1px -1px rgba(0, 0, 0, 0.3);
                text-shadow: none;
            }
        }
    }

    .sticky-nav {
        position:fixed;
        z-index:1000;
        top: 55px;
        right:0;
        //width:100%;

        display:none;

        @include media-breakpoint-up(md) {
            display:block;
            transform: translateX(200px);
            &.show {
                transform: translateX(0);
                animation: sticky_header_flyin 0.5s;
            }
            &.hide {
                transform: translateX(200px);
                animation: sticky_header_flyout 0.5s;
            }
        }

        ul, li {
            display:block;
            padding:0;
            margin:0;
        }

        a {
            //box-shadow: 0 0 5px 0 rgba(0,0,0,1);
            height: 48px;
            padding-left: 24px;
            padding-right: 16px;

            &:before {
                margin-top: -2px;
                width: 36px;
            }

            span {
                display:none;
            }

            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    @keyframes sticky_header_flyin {
        0% {
            transform: translateX(200px);
        }
        100% {
            transform: translateX(0);
        }
    }
    @keyframes sticky_header_flyout {
        0% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(200px);
        }
    }


}
