.event-card {
    position:relative;
    display:flex;
    text-decoration: none;
    color: $body-color;


    background: #d8e6fa;
    border-radius: 5px;

    padding: 25px;
    @include media-breakpoint-up(lg) {
        padding: 36px 30px;
    }

    height: 100%;

    &:not(&--featured) {
        @include before-overlay(linear-gradient(to bottom, #edf4ff, #d8e6fa));

        &:before {
            z-index: 0;
            opacity: 0;
            border-radius: 5px;
        }

        > * {
            position: relative;
            z-index: 1;
        }

        &:hover:before,
        &:focus:before {
            opacity: 1;
        }

        &:after {
            content: "";
            position: absolute;
            z-index: -1;
            left: 0;
            right: 0;
            height: 20px;
            bottom: 5px;
            border-radius: 5px;
            transition: bottom 0.35s ease;
            background: $orange;
        }
    }

    &:hover:after,
    &:focus:after {
        bottom: -4px;
    }



    &__date {
        font-family: $font-family-sans-serif;
        padding-right: 15px;
        @include media-breakpoint-up(lg) {
            padding-right: 25px;
        }
        flex-grow:0;
        flex-shrink:0;
        display:flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        border-right: 1px solid $blue-highlight-6;
    }
    &__month {
        color: #0d1013;
        font-size: 14px;
        line-height: 14px;
        @include media-breakpoint-up(lg) {
            font-size: 22px;
            line-height: 22px;
        }
        font-weight: $font-weight-medium-web;
        text-transform: uppercase;
    }
    &__day {
        color: $blue;
        font-size: 34px;
        line-height: 34px;
        @include media-breakpoint-up(lg) {
            font-size: 46px;
            line-height: 40px;
        }
        font-weight: $font-weight-heavy;
    }

    &__info {
        padding-left: 15px;
        @include media-breakpoint-up(lg) {
            padding-left: 25px;
        }
    }
    &__small-title {
        color: $blue;
        font-size: 16px;
        @include media-breakpoint-up(lg) {
            font-size: 20px;
        }
        font-family: $font-family-sans-serif;
        font-weight: $font-weight-demi-web;
        text-transform: uppercase;
    }
    &__title {
        font-size: 28px;
        line-height: 32px;
        font-family: $font-family-sans-serif;
        font-weight: $font-weight-medium-web;
        color: $body-color;
        margin-bottom: 10px;
    }

    &__button {
        margin-top: 28px;
    }

    &__detail-icon {
        width: 24px;
    }
    &__detail-icon .fa {
        margin-right: 0px;
        color: #0e468f;
    }
    &__detail {
        font-size: 16px;
        margin-bottom: 4px;
        padding-left:24px;

        &--bold {
            font-weight: $font-weight-bold;
        }
        &--italic {
            font-style: italic;
            color: $grey-lowlight-2;
        }
    }
    &__detail-wrap {
        margin-left: -24px;
    }

    &--featured {
        display: block;
        @include media-breakpoint-up(lg) {
            display:flex;
            align-items: stretch;
        }
        padding:0;
        overflow:hidden;
    }
    &--featured &__title {
        font-size: 28px;
        line-height: 32px;
        @include media-breakpoint-up(lg) {
            font-size: 44px;
            line-height: 50px;
            margin-bottom: 30px;
        }
    }
    &--featured &__thumbnail {
        width: 100%;
        @include media-breakpoint-up(lg) {
            width: 50%;
            flex-grow: 0;
            flex-shrink: 0;
        }

        img {
            width:100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &--featured &__info {
        padding: 20px;
        @include media-breakpoint-up(lg) {
            padding: 60px 55px;
        }
    }

    &--featured &__detail-icon .fa {
        @include media-breakpoint-up(lg) {
            margin-right: 16px;
        }
    }
    &--featured &__detail {
        @include media-breakpoint-up(lg) {
            margin-bottom: 10px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 20px;
            padding-left: 40px;
        }
    }
    &--featured &__detail-wrap {
        @include media-breakpoint-up(lg) {
            margin-left: -40px;
        }
    }

    .splide-track {
        padding-bottom: 6px;
    }
}