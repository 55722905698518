.body-content { //update to wysiwyg wrapper class name

    ul {

        > li {
            list-style: none;

            &:before {
                content: "\2022";
                color: $orange-highlight-2;
                font-weight: bold;
                display: inline-block;
                width: 1em;
                position: absolute;
                line-height: 1.6;
                font-size: 13.5px;
                margin-left: -21px;
                @include media-breakpoint-up(md) {
                    font-size: 17px;
                    margin-left: -24px;
                }
            }

            ul, ol {
                margin-top: 0.5rem;
            }
        }

        ol {
            > li {
                &:before {
                    top: 0;
                    line-height: 1.45;
                    @include media-breakpoint-up(md) {
                        line-height: 1.5;
                        font-size: 20px;
                    }
                }
            }
        }

    }

    ul, ol {
        margin-bottom: 1.4rem;
        padding-left: 33px;
        @include media-breakpoint-up(md) {
            padding-left: 52px;
        }
        > li {
            line-height: 1.5;

            &:not(:last-child) {
                margin-bottom: 17px;
            }
        }
        ul {
            @include media-breakpoint-down(md) {
                padding-left: 21px;
            }
        }
        ol {
            @include media-breakpoint-down(md) {
                padding-left: 35px;
            }
        }
    }

    ol {
        counter-reset: step-counter;
        list-style: none;

        > li {
            counter-increment: step-counter;
            position: relative;

            &:before {
                content: counters(step-counter, '.') ". ";
                color: $blue;
                font-weight: $font-weight-bold;
                display: inline-block;
                text-align: right;
                width: 2em;
                position: absolute;
                top: 0;
                font-size: 16px;
                left: -41px;
                @include media-breakpoint-up(md) {
                    left: -49px;
                    font-size: 20px;
                }
            }
        }
        ol,ul {
            margin-top: 0.5rem;
        }

        ul {
            counter-reset: step-counter;
        }

        ol ol {
            > li {
                &:before {
                    left: -53px;
                }
            }
            ol {
                > li {
                    &:before {
                        left: -68px;
                    }
                }
            }
        }
    }
}

.list {
    &--linked {
        margin-bottom: 40px;
        padding-left: 33px;

        > li {
            list-style: none;
            line-height: 1.5;

            &:before {
                position: absolute;
                display: inline-block;
                width: 1em;
                line-height: 1.6;
                margin-left: -21px;
                @include media-breakpoint-up(md) {
                    margin-left: -24px;
                }
            }

            &:not(:last-child) {
                margin-bottom: 17px;
            }
        }

        a {
            @include link-underline;
        }

        &,
        .body-content & {
            @include media-breakpoint-up(md) {
                padding-left: 59px;
            }
            li {
                position: relative;

                &:before {
                    content: '\f0a9';
                    @include fontawesome;
                    font-size: 16px;
                    color: $gold;
                    top: 5px;
                    @include media-breakpoint-up(md) {
                        top: 8px;
                    }
                }
            }
        }
    }

    &--icon-with-text {
        list-style: none;

        &,
        .body-content & {
            padding-left: 0;
            margin-bottom: 21px;
        }

        li {
            position: relative;
            padding-left: 28px;
            @include media-breakpoint-up(md) {
                padding-left: 41px;
            }

            &::before {
                display: none;
            }

            &:not(:last-child) {
                &,
                .body-content & {
                    margin-bottom: 6px;
                }
            }
        }

        [class*="fa"] {
            position: absolute;
            margin-right: 10px;
            width: 15px;
            text-align: center;
            color: $grey-lowlight-2;
            font-size: 14px;
            left: 0;
            top: 4px;
            @include media-breakpoint-up(md) {
                top: 6px;
                margin-right: 15px;
                width: 20px;
                font-size: 18px;
            }
        }

        a {
            @include link-underline();
        }
    }
}