.program-finder-landing {

    @include default-margin-bottom();

    &--at-page-top {
        margin-bottom: 40px;
        margin-top: -80px;
        @include media-breakpoint-up(lg) {
            margin-bottom: 80px;
            margin-top: -100px;
        }
    }


}