//Copied from HH
.hh-events .loading-spinner {
    color:#43739c;
    margin-bottom:50px;
}
/* Event details */
/* Sidebar */
.datepicker.datepicker-dropdown {
    z-index: 21 !important;
}
.hh-events #mini-calendar .input-daterange input {
    width: 100%;
    height: 40px;
    padding: 0 15px;
    background-color: #fff;
    border-radius: 2px;
    border: 2px solid #e8eaed;
}
/* Accessibility fix */
.hh-events :focus {
    outline-offset: 2px;
    outline-color: #43739c;
}
/* Bootstrap Tooltips */
.hh-events .tooltip {
    font-family: muli, sans-serif;
    font-size: 14px;
}
/* Opposite button */
.hh-events .primary-button-white {
    color: #43739c;
    background-color: white;
}
.hh-events .primary-button-white:hover, .hh-events .primary-button-white:focus {
    color: white;
    background-color: #43739c;
    outline: none;
}
.hh-events .primary-button.primary-button-white.dropdown-toggle {
    width: 100%;
    height: 50px;
    color: #11151c;
    font-weight: bold;
    background-color: white;
    border: 2px solid #e8eaed;
    border-radius: 2px;
    padding-left: 10px;
    cursor: pointer;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    text-align:left;
    -webkit-box-shadow: none;
    box-shadow: none;
    text-transform: capitalize;
}
.hh-events .primary-button.primary-button-white.dropdown-toggle:focus, .hh-events .primary-button.primary-button-white.dropdown-toggle:active{
    border-color: #e8eaed;
    outline: none;
}
.hh-events .primary-button.primary-button-white.dropdown-toggle:hover{
    border-color: #43739c;
    outline: none;
}
.hh-events .sidebar .dropdown-menu{
    margin-top: 0px;
    width: 100%;
    border: 2px solid #e8eaed;
    border-radius: 0;
    box-shadow: none;
    padding: 10px;
    border-top: none;
    background-clip: border-box;
    max-height: 30vh;
    overflow-y: auto;
    padding-bottom: 0;
}
/* .hh-events .sidebar .dropdown.open:hover .dropdown-menu{
	 */
/* border-color: #43739c;
 */
/*
}
*/
.hh-events .sidebar .dropdown.open:hover .primary-button.primary-button-white.dropdown-toggle{
    border-color: #43739c;
}
/* Events on Listing */
.hh-events#main {
    margin-top: 30px;
}
.hh-events .page-title h1 {
    display: inline-block;
}
.hh-events .events-results {
    color: #11151c;
    font-size: 14px;
    font-family: montserrat, sans-serif;
    margin-bottom: 20px;
}
.hh-events .events-results .event-count span, .hh-events .events-results .filter-count span {
    font-weight: bold;
}
.hh-events .events .event {
    margin-bottom: 30px;
}
@media only screen and (max-width: 991px) {
    .hh-events .events .event:not(:last-child) {
        padding-bottom: 20px;
        box-shadow: 0px 16px 1px -15px #e8eaed;
    }
}
.hh-events .event .event-image {
    border-radius: 2px;
    height: 140px;
    display: block;
    background-position: center;
    background-size: cover;
}
.hh-events .featured-events-list .event-image {
    height: 70px;
}
.hh-events .featured-events-list .event-details {
    height: 70px;
    display:flex;
    align-items: center;
}
.hh-events .event .image {
    height: 100%;
    margin-bottom: 10px;
}
.hh-events .date-img {
    text-align: center;
    font-size: 30px;
    text-transform: uppercase;
    border: 2px solid #43739c;
    border-radius: 2px;
    height: 100%;
    box-shadow: 1px 1px #e8eaed;
}
.hh-events .date-img .month {
    background: #43739c;
    color: #fff;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
    margin: -1px;
}
.hh-events .date-img .day {
    padding: 10px;
    height: calc(100% - 42px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.hh-events .sidebar .date-img .day {
    height: auto;
}
.hh-events .date-img .weekday {
    font-size: 20px;
}
.hh-events .sidebar .date-img .weekday {
    font-size: 14px;
}
.hh-events .sidebar .date-img .weekday span {
    display:none;
}
.hh-events a:hover .date-img {
    border-color: #23527c;
}
.hh-events a:hover .date-img .month {
    background: #23527c;
}
.hh-events .events-list .row.event {
    display: none;
}
.hh-events .events-list .row.event.filter-match {
    display: block;
}
.hh-events .events .event .title {
    margin-bottom: 10px;
}
.hh-events .event a:hover .title, .hh-events .event a:focus .title {
    color: #43739c;
}
.hh-events .details {
    color: #505050;
    font-family: montserrat, serif;
    font-size: 12px;
    line-height: 1.5;
    text-transform: uppercase;
    margin-bottom: 10px;
}
.hh-events .details div:not(.tooltip):not(.tooltip-arrow):not(.tooltip-inner) {
    display: inline;
    margin-right: 8px;
    margin-top: 10px;
    font-weight: 500;
}
.hh-events .details .date:before, .hh-events .details .location:before {
    content: '\2022';
    display: inline-block;
    margin-right: 10px;
}
.hh-events .details .featured:after {
    content: '\2022';
    display: inline-block;
    margin-left: 4px;
}
.hh-events .details .categories:before, .hh-events .details .time:before {
    content: '';
}
.hh-events .dot {
    margin-right: 3px;
    font-size: 10px;
    height: 14px;
    vertical-align: middle;
}
.hh-events .dot span.sr-only {
    color: #11151c;
}
.hh-events .events .event p {
    color: #11151c;
    margin: 0;
}
.hh-events .events .load-more button:focus {
    outline: none;
}
.hh-events .events .load-more {
    display: none;
    margin-bottom: 30px;
    text-align: center;
}
.hh-events .events .events-message {
    display: none;
    text-align: center;
}
/* Sidebar on Events Listing */
@media only screen and (max-width: 991px) {
    .hh-events .sidebar {
        border: 2px solid #e8eaed;
        border-radius: 2px;
        padding: 25px 15px 0 15px;
        margin-top: 10px;
    }
    .hh-events .sidebar .section-title:nth-child(2) {
        margin-top: 20px;
    }
    .hh-events .sidebar.collapsed .gradient-overlay {
        color: black;
        -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)));
        max-height: 200px;
        overflow: auto;
    }
}
.hh-events .sidebar .row {
    margin-bottom: 20px;
}
.hh-events .mobile-title {
    position: absolute;
    background-color: white;
    text-transform: uppercase;
    padding: 0 10px;
    z-index: 30;
    top: -3px;
    left: 20px;
}
.hh-events .mobile-title i {
    color: #43739c;
}
.hh-events .search-expand {
    color: #43739c;
    font-size: 25.629px;
    top: -20px;
    position: relative;
    text-align: center;
    z-index: 2;
}
.hh-events .search-expand i {
    background-color: white;
    padding: 0 10px;
}
.hh-events .plus-toggle {
    cursor: pointer;
}
.hh-events .sidebar input:focus {
    outline: 2px solid #43739c;
}
.hh-events .sidebar .date-views {
    margin-top: 20px;
    font-weight: bold;
    font-size: 14px;
}
.hh-events .sidebar .date-views button {
    color: #11151c;
    text-align: center;
    display: block;
    padding: 15px 10px;
    border: 2px solid #e8eaed;
    border-radius: 2px;
    background-color: white;
}
.hh-events .sidebar .date-views button:hover, .hh-events .sidebar .date-views button:focus, .hh-events .sidebar .date-views button.active {
    color: #43739c;
    border-color: #43739c;
    background-color: white;
    z-index: 1;
    outline: none;
}
.hh-events .sidebar .row.event {
    margin-bottom: 5px;
}
.hh-events .sidebar .event h3.title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0;
}
.hh-events .sidebar .event .image .date-img {
    font-size: 16px;
}
.hh-events .sidebar .event .image .date-img .day {
    padding: 2px;
}
.hh-events .sidebar .event .details .featured, .hh-events .sidebar .event .details .location, .hh-events .sidebar .event .details .categories, .hh-events .sidebar .event p {
    display: none !important;
}
.hh-module .sidebar .primary-button.btn-block, .hh-module .sidebar .secondary-button.btn-block {
    padding: 10px 10px;
}
/* Remove duplicate borders for certain screen sizes */
@media only screen and (max-width: 991px) and (min-width: 768px), (min-width: 1200px) {
    .hh-events .sidebar .date-views button:nth-child(3n+2), .hh-events .sidebar .date-views button:nth-child(3n+3) {
        margin-left: -2px;
    }
    .hh-events .sidebar .date-views button:nth-child(n+4) {
        margin-top: -2px;
    }
}
@media only screen and (max-width: 767px), (min-width: 992px) and (max-width: 1199px) {
    .hh-events .sidebar .date-views button:nth-child(even) {
        margin-left: -2px;
    }
    .hh-events .sidebar .date-views button:nth-child(n+3) {
        margin-top: -2px;
    }
}
.hh-events .sidebar .checkboxes {
    color: #11151c;
}
.hh-events .sidebar .checkboxes label {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}
.hh-events .sidebar .checkboxes input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.hh-events .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border-radius: 12px;
    background-color: #767676;
}
.hh-events .sidebar .checkboxes label:hover, .hh-events .sidebar .checkboxes label input:focus~span {
    color: #43739c;
}
.hh-events .sidebar .checkboxes label:hover .checkmark, .hh-events .sidebar .checkboxes label input:focus~.checkmark {
    -webkit-box-shadow: inset 0 -200px 0 0 rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 -200px 0 0 rgba(0, 0, 0, 0.2);
}
.hh-events .sidebar .checkboxes .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
.hh-events .sidebar .checkboxes label input:checked~.checkmark:after {
    display: block;
}
.hh-events .sidebar .checkboxes label .checkmark:after {
    left: 9px;
    top: 4px;
    width: 8px;
    height: 14px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.hh-events .sidebar .dropdown select, .hh-events .sidebar-detail .dropdown select {
    width: 100%;
    height: 50px;
    color: #11151c;
    font-weight: bold;
    background-color: white;
    border: 2px solid #e8eaed;
    border-radius: 2px;
    padding-left: 10px;
    cursor: pointer;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}
.hh-events .sidebar .dropdown select option, .hh-events .sidebar-detail .dropdown select option {
    font-weight: normal;
}
.hh-events .sidebar .dropdown select:hover, .hh-events .sidebar .dropdown select:focus, .hh-events .sidebar-detail .dropdown select:hover, .hh-events .sidebar-detail .dropdown select:focus {
    border-color: #43739c;
    outline: none;
}
.hh-events .sidebar .dropdown:after, .hh-events .sidebar-detail .dropdown:after {
    content: "\f107";
    font-family: "Font Awesome 5 Free";
    position: absolute;
    top: 10px;
    right: 15px;
    z-index: 5;
    font-weight: 900;
    font-size: 24px;
    pointer-events: none;
}
.hh-events .feeds a {
    color: #11151c;
    margin-right: 15px;
    white-space: nowrap;
}
.hh-events .feeds a:hover {
    color: #43739c;
}
.hh-events .back-to-top {
    background-color: #43739c;
    cursor: pointer;
    position: fixed;
    bottom: 15px;
    right: 15px;
    border-radius: 2px;
    display: none;
    z-index: 10;
}
.hh-events .back-to-top:hover {
    background-color: #23527c;
}
/* Event Page Details */
.hh-events.breadcrumbs {
    background-color: #f5f5f5;
}
.hh-events.breadcrumbs ol {
    padding-top: 15px;
    padding-bottom: 15px;
}
.hh-events.breadcrumbs ol li {
    display: inline-block;
}
.hh-events.breadcrumbs ol li+li:before {
    display: inline-block;
    padding-right: .5rem;
    padding-left: .5rem;
    color: #767676;
    content: "/";
}
.hh-events.breadcrumbs ol li a {
    color: #11151c;
}
.hh-events.breadcrumbs ol li:hover a {
    color: #43739c;
}
.hh-events .content-detail {
    margin-bottom: 40px;
}
.hh-events .sidebar-detail .row {
    margin-bottom: 20px;
}
.hh-events .sidebar-detail .save-date {
    margin-bottom: 20px;
}
.hh-events .info>div {
    margin-bottom: 10px;
    display: table;
}
.hh-events .info>div>div {
    display: table-cell;
    vertical-align: middle
}
.hh-events .icon {
    width: 40px;
    height: 40px;
    color: #43739c;
    border: 1px solid #e8eaed;
    line-height: 40px;
    border-radius: 50%;
    text-align: center;
    margin-right: 10px;
}
.hh-events .filters .filter {
    font-family: montserrat, serif;
    font-size: 12px;
}
.hh-events .filters .filter .filter-label {
    font-weight: bold;
    margin-bottom: 5px;
    margin-top: 20px;
    text-transform: uppercase;
    color: #11151c;
    font-size: 12px;
}
.hh-events .filters .filter .dot+.dot {
    margin-left: 10px;
}
.hh-events .filters .filter div {
    display: inline-block;
    margin-right: 8px;
}
.hh-events .filters .filter:not(.dots) div+div:before {
    content: '\2022';
    display: inline-block;
    margin-right: 10px;
}
.hh-events .icon-list {
    margin-bottom: 20px;
}
.hh-events .icon-list span {
    color: #11151c;
    font-size: 16px;
    margin-right: 10px;
}
.hh-events .icon-list a:hover span {
    color: #43739c;
}
.hh-events .feeds {
    margin-bottom: 20px
}
.hh-events .related-events .event,.hh-events.hh-events-block .event {
    margin-bottom: 30px;
}
.hh-events .related-events .event .title,.hh-events.hh-events-block .event .title {
    font-size: 16px;
    padding-top: 10px;
}
.hh-events .related-events.mt-5 {
    margin-top: 3rem!important;
}
.hh-events .show-hhe {
    display:block !important;
}
.hh-events .hide-hhe {
    display:none !important;
}
.hh-events .related-events.mobile {
    display:block;
}
.hh-events .related-events.desktop {
    display:none;
}
@media (min-width: 767px){
    .hh-events .related-events.mobile {
        display:none;
    }
    .hh-events .related-events.desktop {
        display:block;
    }
}