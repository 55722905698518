.accordion {

    &--default {
        .accordion-item {
            margin-bottom: 5px;
            border-radius: 5px;

            &:not(:first-of-type) {
                border-top: 2px solid #d8e6fa;
            }
        }

        .accordion-button {
            font-family: $font-family-secondary;
            font-weight: $font-weight-medium;
            padding-right: 45px;
            font-size: 20px;
            line-height: 1.2;
            @include media-breakpoint-down(sm) {
                padding-left: 16px;
            }

            @include media-breakpoint-up(lg) {
                font-size: 24px;
                line-height: 1.167;
                padding-right: 117px;
            }

            &::after {
                position: absolute;
                top: 14px;
                right: 10px;
                @include media-breakpoint-up(lg) {
                    top: 15px;
                    right: 17px;
                }
            }


            &::before {
                content: '';
                width: 89px;
                height: 4px;
                background-color: $orange-highlight-1;
                position: absolute;
                bottom: -4px;
                left: 22px;
                opacity: 0;
                transition: opacity 0.35s ease;
            }

            &:not(.collapsed) {
                box-shadow: none;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;

                &::after {
                    background-image: var(--bs-accordion-btn-icon);
                }

                &::before {
                    opacity: 1;
                }
            }

            &:hover {
                background-color: #edf2f9;
            }

            &:hover,
            &:focus {
                color: $blue-highlight-2;

                &::after {
                    background-image: var(--bs-accordion-btn-active-icon);
                }
            }
            &:focus-visible {
                @include box-shadow($input-box-shadow, $input-focus-box-shadow);
            }

        }

        .accordion-body {
            @include media-breakpoint-up(md) {
                overflow: auto;
            }

            p {
                line-height: 1.75;
                @include media-breakpoint-up(lg) {
                    line-height: 1.6;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }

            .figure {
                &.float-end {
                    @include media-breakpoint-up(md) {
                        margin-right: 0;
                    }
                }
                &.float-start {
                    @include media-breakpoint-up(md) {
                        margin-left: 0;
                    }
                }
            }
        }
    }

    &--flyout-menu {
        // make sure absolute positioned arrow animations on links remain anchored when the off-canvas scrolls
        position:relative;

        .accordion-item {
            background-color: $white;
            margin-bottom: 0;
            border: 0 none;
            border-bottom: 1px solid $grey-highlight-2;
            &:first-child {
                border-top: 1px solid $grey-highlight-2;
            }

            &--info-for {
                margin-top: 20px;
                background-color: #032455;
                border-bottom: 0 none;
                @include media-breakpoint-up(md) {
                    border-radius: 5px;
                }
                @include media-breakpoint-down(md) {
                    margin-left: -1px;
                    margin-right: -1px;
                }

                .accordion-header {
                    background-color: $blue-highlight-5;
                    @include media-breakpoint-up(md) {
                        border-radius: 5px;
                    }
                }

                .accordion-button {
                    width: 100%;
                    position: static;
                    font-weight: $font-weight-book;
                    background-color: $blue-highlight-5;
                    color: $white;
                    letter-spacing: 0.07em;
                    padding: 17px 0 17px 25px;
                    @include media-breakpoint-down(lg) {
                        padding-left: 17px;
                    }
                    @include media-breakpoint-up(md) {
                        border-radius: 5px !important;
                        &[aria-expanded="true"] {
                            border-bottom-left-radius: 0 !important;
                            border-bottom-right-radius: 0 !important;
                        }
                    }

                    &:after {
                        color: $white;
                        margin-left: auto;
                        width: 60px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                    }

                    &:hover,
                    &:focus {
                        background-color: $blue-highlight-2;
                        color: $white;

                        &:after {
                            color: $white;
                        }
                    }
                }

                .accordion-body {
                    padding-top: 15px;

                    ul {
                        padding-left: 32px;
                        @include media-breakpoint-up(lg) {
                            padding-left: 58px;
                        }
                    }

                    a {
                        color: $white;
                        @include link-underline($white, transparent);
                        transition: padding 0.35s ease, text-decoration-color 0.35s ease;

                        &:hover,
                        &:focus {
                            color: $white;
                        }
                    }

                }
            }
        }

        ul {
            list-style: none;
            li:not(:first-child) {
                margin-top: 12px;
            }
        }

        .accordion-header {
            display: flex;
            position: relative;
        }

        .accordion-link {
            margin-right: 60px;
            padding: 17px 10px 17px 25px;
            @include media-breakpoint-down(lg) {
                padding-left: 17px;
            }
            color: $grey-lowlight-3;
            text-decoration: none;
            font-weight: $font-weight-demi-web;
            letter-spacing: 0.04em;
            background-color: $white;
            font-family: $font-family-sans-serif;
            font-size: 22px;
            width: 100%;

            > span {
                position: relative;

                &:after {
                    content: '';
                    position: absolute;
                    height: 2px;
                    left: 0;
                    bottom: -2px;
                    width: 0;
                    background-color: $blue-lowlight-1;
                    transition: width 0.5s ease;
                }
            }

            &:hover {
                color: $blue-highlight-3;
            }
        }
        .accordion-item--nav-active .accordion-link {
            color: $grey-lowlight-3 !important;
            font-weight: $font-weight-semibold;
            > span:after {
                content:none;
            }
        }


        .accordion-button {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            width: 60px;
            background-color: $white;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            box-shadow: none;

            &:after {
                content: '\f078';
                @include fontawesome($font-weight-normal);
                background-image: none;
                color: $blue-highlight-3;
                margin-left: 0;
            }

            &:hover {
                background-color: $grey-highlight-3;
                color: $blue-highlight-2;

                &:after {
                    color: $blue-lowlight-1;
                }
            }
            &:focus {
                box-shadow: var(--bs-accordion-btn-focus-box-shadow);
            }

            &[aria-expanded="true"] {
                + .accordion-link {
                    color: $blue;

                    > span {
                        &:after {
                            width: 100%;
                            transition: width 0.5s ease;
                        }
                    }

                    &:hover,
                    &:focus {
                        color: $blue-highlight-3;
                        > span {
                            &:after {
                                width: 0;
                            }
                        }
                    }
                }
            }
         }

        .accordion-body {
            padding: 10px 0 5px;

            @include media-breakpoint-down(lg) {
                padding-left: 17px;
                padding-right: 17px;
            }
            
            ul {
                padding-left: 32px;
                @include media-breakpoint-up(lg) {
                    padding-left: 58px;
                }
            }

            a {
                color: $blue;
                display: block;
                line-height: 1.3;
                font-weight: $font-weight-medium-web;
                font-family: $font-family-secondary;
                font-size: 19px;
                letter-spacing: 0.05em;
                @include link-underline($blue, transparent);
                transition: padding 0.35s ease, text-decoration-color 0.35s ease;

                &:after {
                    content: '\f061';
                    @include fontawesome(400);
                    font-size: 14px;
                    color: $blue-highlight-5;
                    opacity: 0;
                    line-height: 1.9;
                    position: absolute;
                    transition: opacity 0.35s ease, margin 0.35s ease;
                }

                &:hover,
                &:focus {
                    color: $blue-highlight-3;
                    //text-decoration: none;
                    padding-left: 11px;

                    &:after {
                        opacity: 1;
                        margin-left: 10px;
                    }

                }
            }
            .active-nav a {
                font-weight: $font-weight-semibold;
                text-decoration: none;
                color: $grey-lowlight-3 !important;
                &:hover,
                &:focus {
                    color: $grey-lowlight-3 !important;
                    padding-left: 0;
                    &:after {
                        display: none;
                    }
                }
            }
        }
    }

    &--tabs {

        .accordion-item {
            border:none;
            border-bottom: 1px solid $grey-highlight-2;

            border-radius: 0 !important;
            .accordion-button {
                border-radius: 0 !important;
            }
        }
        .accordion-body {
            @include media-breakpoint-up(md) {
                overflow: auto;
            }
            .figure {
                &.float-end {
                    @include media-breakpoint-up(md) {
                        margin-right: 0;
                    }
                }
                &.float-start {
                    @include media-breakpoint-up(md) {
                        margin-left: 0;
                    }
                }
            }
        }
        .accordion-button {
            background:$grey-highlight-3;
            border-top: 5px solid transparent;
            padding: 20px 18px;

            font-family: $font-family-sans-serif;
            font-weight: $font-weight-demi-web;
            font-size: 20px;
            text-transform: uppercase;
            color: $blue-highlight-3;

            &:after {
                display:block;
                font-weight: 700;
                padding-left: 40px;
                padding-right: 40px;
                margin-right: -20px;
                background-position: center;
            }

            &:not(.collapsed) {
                background:$white;
                border-top: 5px solid $orange;
                box-shadow:none;
                color: $blue-highlight-1;

                &:before {
                    content: "";
                    position:absolute;
                    bottom:0;
                    left: 18px;
                    right: 18px;
                    height:1px;
                    background-color: $grey-highlight-2;
                }
            }

            position:relative;
        }


    }
}
