input[type=file] {
    position:relative;
    height: 48px;
    padding-top:0;
    padding-bottom:0;
}
input[type=file]::file-selector-button {
    height:52px;
    background-color: $grey-highlight-3;
    color: $body-color;
    transition: background-color 0.35s ease;
}
input[type=file]:hover::file-selector-button {
    background-color: $grey-highlight-2;
}