.button-matrix {

    $button-matrix-bg-color: $grey-highlight-3;
    $button-matrix-fg-color: $grey-highlight-2;

    &__panel {
        position:relative;
        &:before {
            content:"";
            position:absolute;
            left:0;
            top:0;
            right:0;
            bottom:0;
            background-image:
                    linear-gradient(45deg, $button-matrix-bg-color 25%, transparent 25%),
                    linear-gradient(135deg, $button-matrix-bg-color 25%, transparent 25%),
                    linear-gradient(45deg, transparent 75%, $button-matrix-bg-color 75%),
                    linear-gradient(135deg, transparent 75%, $button-matrix-bg-color 75%),
                    linear-gradient(to bottom, $button-matrix-fg-color 0, $button-matrix-fg-color 100%);
            background-size: 20px 20px; /* Must be a square */
            background-position: 0 0, 10px 0, 10px -10px, 0px 10px; /* Must be half of one side of the square */
            opacity: 0.5;
            z-index:-1;
        }
        border: 2px solid $grey-highlight-1;
        padding: 20px;
        margin-bottom: 20px;
    }

    &__wrap--horizontal {
        display:flex;
        justify-content: flex-start;
        flex-wrap: wrap;
    }

    &__wrap--horizontal &__button {
        margin-right:15px;
        @include media-breakpoint-up(lg) {
            margin-right: 20px;
        }
        &:last-child {
            margin-right:0;
        }
    }

    &__wrap &__button {
        margin-bottom: 15px;
        @include media-breakpoint-up(lg) {
            margin-bottom: 20px;
        }
        &:last-child {
            margin-bottom:0;
        }
    }
}