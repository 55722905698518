.program-card {
    display:block;
    height: 100%;
    color: $body-color;
    &:hover {
        color: $body-color;
    }
    text-decoration: none;

    background: $grey-highlight-3;
    padding: 32px;
    border-radius: 5px;
    position:relative;

    word-break: break-word;

    &:after {
        content:"";
        position:absolute;
        z-index:-1;
        left:0;
        right:0;
        height: 20px;
        bottom: 5px;
        border-radius:5px;
        transition: bottom 0.35s ease;
        background: $orange;
    }

    &:hover:after,
    &:focus:after {
        bottom: -4px;
    }


    &__subtitle {
        color: $blue;
        font-size: 12px;
        font-weight: $font-weight-bold;
        letter-spacing: 0.050em;
        text-transform: uppercase;
        font-family: $font-family-base;
        margin-bottom: 8px;
    }
    &__coursecode {
        font-size: 14px;
        color: $grey-lowlight-2;
        font-family: $font-family-base;
        font-weight: $font-weight-bold;
    }
    &__title h3 {
        font-size: 26px;
        color: $body-color;
        margin-bottom: 10px;
    }

    &__title h2::after,
    &__title h3::after {
        content: '\f0a9';
        background: white;
        border-radius: 100px;
        position: absolute;
        margin-top: 9px;
        margin-left: 8px;
        @include fontawesome;
        transition: transform 0.35s ease;
        color: $gold;
        font-size: 18px;
        margin-top: 6px;
        @include media-breakpoint-up(md) {
            font-size: 22px;
            margin-top: 6px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 18px;
            margin-top: 6px;
        }
        @include media-breakpoint-up(xl) {
            font-size: 16px;
            margin-top: 5px;
        }
        will-change: transform
    }

    &:hover &__title,
    &:focus &__title {
        h2, h3 {
            color: $blue-lowlight-1 !important;
            &::after {
                transform: translateX(12px);
            }
        }
    }

    @include before-overlay(linear-gradient(to bottom, #f7f7f5, #f1f0eb));
    &:before {
        z-index:0;
        opacity: 0;
        border-radius: 5px;
    }
    > * {
        position:relative;
        z-index:1;
    }

    &--featured {
        @include before-overlay(linear-gradient(to bottom, #edf4ff, #d8e6fa));
        &:before {
            z-index:0;
            opacity: 0;
            border-radius: 5px;
        }
        > * {
            position:relative;
            z-index:1;
        }
    }

    &:hover:before,
    &:focus:before {
        opacity: 1;
    }
    &__details {
        padding-bottom: 10px;
        border-bottom: 1px solid $grey-highlight-1;
        margin-bottom: 18px;
    }
    &__detail-icon {
        width: 24px;
    }
    &__detail-icon .fa {
        margin-right: 8px;
        color: #0e468f;
    }
    &__detail {
        font-size: 16px;
        margin-bottom: 8px;
        padding-left:32px;

        &--bold {
            font-weight: $font-weight-bold;
        }
        &--italic {
            font-style: italic;
            color: $grey-lowlight-2;
        }
    }
    &__detail-wrap {
        margin-left: -32px;
    }
    &__teaser {
        font-size: 16px;
        font-family: $font-family-base;

        p:last-child {
            margin-bottom:0;
        }
    }
    &__preq {
        font-size: 14px;
        font-family: $font-family-sans-serif;
        font-weight: $font-weight-demi-web;
        color: #0c3c7a;
    }

    &--featured {
        background: #d8e6fa;
    }
    &--featured &__details {
        border-bottom: 1px solid #a6c7f4;
    }


    &--list &__details {
        display:flex;
        flex-wrap:wrap;
        border-bottom: none;
        margin-bottom:0;
    }
    &--list &__detail {
        font-size: 18px;
        margin-right: 20px;
    }
    &--list &__teaser {
        font-size: 20px;
    }

    @keyframes program_card_show {
        0% {
            opacity:0;
        }
        100% {
            opacity:1;
        }
    }

    @keyframes program_card_after_show {
        0% {
            opacity:0;
        }
        100% {
            opacity:0;
        }
    }

    &--list,
    &:not(&--list) {
        animation-duration:1.5s;
        animation-name: program_card_show;
    }
    &--list:after,
    &:not(&--list):after {
        animation-duration:1.5s;
        animation-name: program_card_after_show;
    }
}