.menu-flyout {
    padding: 1px;
    @include media-breakpoint-up(md) {
        padding: 50px 75px 50px 50px;
    }
    @include media-breakpoint-up(lg) {
        padding-right: 100px;
    }

    &__logo {
        width: 100%;
        @include media-breakpoint-down(lg) {
            margin-bottom: 17px;
        }

        img {
            width: 146px;
        }
    }

    &__mobile-action-links,
    &__mobile-utility-links {
        ul,
        li {
            display: block;
            margin: 0;
            padding: 0;
        }
        ul {
            display: flex;
            justify-content: space-evenly;
        }
        a {
            display:block;
            padding: 5px;
            text-transform: uppercase;
            font-family: $font-family-sans-serif;
            font-size: 18px;
            font-weight: 500;

            color: $blue-highlight-3;
            text-decoration: none;
            &:hover {
                color: $blue-highlight-3;
                text-decoration: underline;
            }
        }
    }
    &__action-links,
    &__utility-links {

        ul {

            display:block;
            margin:0;
            padding:0;
        }
        li {
            display:block;
            margin: 0;
            padding: 0;
            @include media-breakpoint-down(md) {
                a {
                    padding-left: 10px;
                    padding-right: 10px;
                }
            }
        }
        @include media-breakpoint-up(md) {
            padding-left: 95px;
        }

    }

    &__close-button {
        width: 42px;
        height: 42px;
        @include media-breakpoint-up(md) {
            width: 48px;
            height: 48px;
            margin-bottom: 6px;
        }
        background: $blue-highlight-3;
        color: $white;
        display: flex;
        padding:0;
        justify-content: center;
        align-items: center;
        border: none;
        border-radius: 100%;
        font-size: 27px;
        transition: $btn-transition;

        &:after {
            @include fontawesome(400);
            content: '\f00d';
        }

        &:hover,
        &:focus {
               background-color: $blue-highlight-2;
        }
    }

}
