.filter-band__results {
    margin-bottom: 50px;
    
    &__group + &__group {
        margin-top: 50px;
    }
    &__message {
        font-size: 30px;
        font-weight: $font-weight-medium-web;
        font-family: $font-family-sans-serif;
    }
    &__heading {
        h2 {
            font-size: 24px;
            font-weight: $font-weight-book;
            strong {
                font-size: 30px;
                font-weight: $font-weight-medium-web;
            }
        }
    }
    &__cards {
        list-style:none;
        padding:0;
    }
    &__more-button {
        display:table;
        margin:25px auto 0 auto;
        a {
            &.btn-dark {
                min-width: 95px !important;
                padding-top: 0 !important;
                padding-bottom: 0 !important;
            }

            > [class*='fa'] {
                padding-right: 0 !important;
                font-size: 24px !important;
            }
        }
    }
}