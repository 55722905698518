.directory {
    &__intro h2 {
        font-family: $font-family-primary;
        font-size: 22px;
        line-height: 34px;
        @include media-breakpoint-up(lg) {
            font-size: 26px;
            line-height: 38px;
        }
        color: #0c3c7a;
        margin-bottom: 40px;
    }
    &__count {
        font-size: 24px;
        font-family: $font-family-sans-serif;
        font-weight: $font-weight-medium-web;
        color: $grey-lowlight-2;
    }
}