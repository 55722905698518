.events-listing {
    &.hh-events {
        .date-img {
            font-family: $font-family-secondary;
            border-color: $blue;
            transition: border-color 0.35s ease;
            display: flex;
            flex-direction: column;

            .month {
                background-color: $blue;
                padding: 7px;
                transition: background-color 0.35s ease;
            }

            .day {
                color: #1c5ec2;
                line-height: 1.1;
                font-weight: $font-weight-demi-web;
                height: calc(100% - 54px);
                font-size: 65px;
                @include media-breakpoint-up(sm) {
                    font-size: 60px;
                }
                @include media-breakpoint-up(lg) {
                    font-size: 70px;
                    line-height: 1.2;
                }
            }

            .weekday {
                font-weight: $font-weight-bold;
                font-family: $font-family-primary;
                font-size: 21px;
                text-transform: none;
                color: $grey-lowlight-3;
            }
        }

        .event {
            border-bottom: 1px solid $grey-highlight-2;
            padding-bottom: 30px;
            margin-bottom: 30px;

            .image {
                overflow: hidden;
                @include media-breakpoint-down(md) {
                    height: auto;
                    margin-bottom: 20px;
                }
            }
            .event-image {
                transition: transform 1.25s ease;
                height: 180px;

                @include media-breakpoint-up(lg) {
                    height: 230px;
                }
            }
        }

        a {
            text-decoration: none;

            &:hover,
            &:focus {
                .date-img {
                    border-color: $blue-highlight-3;

                    .month {
                        background-color: $blue-highlight-3;
                    }
                }
                .event-details .title {
                    color: $blue;

                    &:after {
                        padding-left: 16px;
                    }
                }

                .event-image:not(.date-img) {
                    transform: scale(1.1);
                }
            }
        }

        .details {
            color: $grey-lowlight-2;
            font-size: 20px;
            font-family: $font-family-secondary;
            text-transform: uppercase;
            font-style: normal;
            font-weight: $font-weight-heavy;

            .date {
                &:before {
                    content: "|";
                    font-size: 16px;
                    font-weight: $font-weight-heavy;
                    position: relative;
                    top: -2px;
                }
            }

            .recurrence,
            .featured {
                &:after {
                    content: "|";
                    padding-left: 11px;
                    font-size: 16px;
                    position: relative;
                    top: -2px;
                }
            }
        }

        .sub-details {
            display: flex;
            color: $grey-lowlight-2;
            font-family: $font-family-primary;
            font-style: italic;
            font-size: 18px;
            font-weight: $font-weight-normal;
            letter-spacing: 0.05em;

            .location {
                &:before {
                    content: "|";
                    font-size: 16px;
                    font-weight: $font-weight-heavy;
                    position: relative;
                    top: -2px;
                    padding-left: 10px;
                    padding-right: 11px;
                }
            }
        }

        .feeds {
            margin-bottom: 0;
            a {
                color: $blue-highlight-3;
                font-size: 18px;
            }
        }
    }

    .hh-events__filters {
        border-bottom: 5px solid $grey-highlight-2;
        padding-bottom: 40px;
        margin-bottom: 40px;

        .title--compact {
            margin-bottom: 8px;
            @include media-breakpoint-up(lg) {
                margin-top: -4px;
            }
        }
    }

    .event-details {
        .title {
            color: $black;
            font-size: 30px;
            letter-spacing: 0.04em;
            padding-right: 28px;
            position: relative;

            &:after {
                content: '\f0a9';
                color: $gold;
                font-size: 22px;
                padding-left: 10px;
                @include fontawesome;
                transition: padding 0.35s ease;
                position: absolute;
                margin-top: 8px;
            }
        }
    }

    .summary {
        margin-top: 22px;
        font-size: 20px;
        letter-spacing: 0.05em;
        color: $grey-lowlight-3;
    }
}