.table {
    margin-bottom: 50px;

    th {
        background-color: $blue;
        color: $white;
        font-family: $font-family-secondary;
        line-height: 1.3;
        font-size: 18px;
        @include media-breakpoint-up(lg) {
            font-size: 22px;
        }
    }

    td {
        line-height: 1.3;
    }

    &-responsive {
        margin-bottom: 50px;
        @include media-breakpoint-down(sm) {
            margin-right: -12px;
        }

        .table {
            min-width: 550px;
            margin-bottom: 0;
        }
    }
}