.detail-box {
    background-color: $grey-highlight-3;
    color: $black;
    margin-bottom: 45px;
    padding: 20px 20px 10px 20px;
    @include media-breakpoint-up(lg) {
        padding-top: 30px;
        padding-left: 30px;
        padding-right: 30px;
    }

    h2 {
        color: $blue-highlight-1;
        text-transform: uppercase;
        font-size: 20px;
        font-weight: $font-weight-demi-web;
        letter-spacing: 0.05em;
        margin-bottom: 0;
        margin-top: 0;
    }

    p {
        font-size: 18px;
    }

    [class*="col"] + [class*="col"] {
        position: relative;

        @include media-breakpoint-down(lg) {
            padding-top: 20px;
        }

        &:before {
            content: '';
            width: 56px;
            height: 1px;
            background-color: $grey-highlight-1;
            position: absolute;
            top: 0;
            @include media-breakpoint-up(lg) {
                bottom: 20px;
                left: -20px;
                width: 1px;
                height: auto;
            }

        }
    }

    .col-lg-4 + .col-lg-4 {
        &:before {
            @include media-breakpoint-up(lg) {
                left: -8px;
            }
        }
    }

    .link--icon {
        [class*="fa"] {
            margin-right: 10px;
        }
    }

    &--decrease-top-margin {
        margin-top: -30px;
        @include media-breakpoint-up(lg) {
            margin-top: -40px;
        }
    }
}