.figure {
    display: table;
    margin-bottom: 50px;
    border-left: 4px solid $orange;

    &--full-width {
        img {
            width: 100%;
        }
    }

    &.float-end,
    &.float-start {
        margin-top: 10px;
        margin-bottom: 30px;
        @include media-breakpoint-up(md) {
            max-width: 45%;
        }
        @include media-breakpoint-down(md) {
            float: none !important;
        }
    }

    &.float-end {
        @include media-breakpoint-up(md) {
            margin-left: 1.5em;
            margin-right: -65px;
        }
        @include media-breakpoint-up(lg) {
            margin-right: -160px;
        }
        @include media-breakpoint-up(xl) {
            margin-right: -191px;
        }
        @include media-breakpoint-up(xxl) {
            margin-right: -220px;
        }
    }

    &.float-start {
        @include media-breakpoint-up(md) {
            margin-right: 1.5em;
            margin-left: -65px;
        }
        @include media-breakpoint-up(lg) {
            margin-left: -160px;
        }
        @include media-breakpoint-up(xl) {
            margin-left: -191px;
        }
        @include media-breakpoint-up(xxl) {
            margin-left: -220px;
        }
    }

    &--circle {
        display: block;
        border-radius: 100%;
        overflow: hidden;
        width: 100%;
        height: 0;
        padding-bottom: 100%;
        position: relative;
        border: 0 none;

        img {
            @include cover-all;
            object-fit: cover;
            position: relative;
            z-index: 2;
            width: 100%;
            height: 100%;
        }
    }

    &--half-border {
        position: relative;
        border: 0 none;

        &::before {
            content: '';
            @include cover-all;
            height: 100%;
            width: 50%;
            z-index: 1;
            background-color: $orange;
            opacity: 0;
            animation-delay: 0.75s;
            animation-duration: 0.5s;
            animation-iteration-count: 1;
            animation-timing-function: ease-out;
            animation-name: fade-in;
            animation-fill-mode: forwards;
        }

        img {
            padding: 4px;
            border-radius: 50%;
        }
    }
}

.figure-img {
    margin-bottom: 0;
}

.figure-caption {
    display: table-caption;
    caption-side: bottom;
    @include caption-style;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}