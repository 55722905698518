.ccbc-alert {

    height:100%;
    border-top: 5px solid $red;

    background: $grey-lowlight-2;

    &__panel {
        padding: 20px;
    }

    &__icon {
        width: 45px;
        height: 45px;
        background: $red;
        border-radius: 100%;
        margin-bottom: 20px;

        display: flex;
        justify-content: center;
        align-items: center;

        &:before {
            content: '\f848';
            font-size: 26px;
            line-height: 0;
            letter-spacing: 0;
            @include fontawesome(300);
            color: white;
        }
    }

    &__body {
        color: white;
    }
    &__info {
        color: #C14915;
        font-family: $font-family-sans-serif;
        font-weight: $font-weight-heavy;
        text-transform: uppercase;
        font-size: 18px;
        @include media-breakpoint-up(md) {
            font-size: 20px;
        }
        letter-spacing: 0.050em;
        margin-bottom: 10px;
        &:before {
            content: "\f05a" " ";
            @include fontawesome();
            font-weight: 500;
        }

        &--collapsed {
            font-size: 16px;
            margin: 0 30px 0 0;
        }
    }
    &__title {
        color: $white;
        font-size: 24px;
        line-height: 1.1667;
        @include media-breakpoint-up(md) {
            font-size: 32px;
            line-height: 1.1875;
        }
        font-family: $font-family-sans-serif;
        font-weight: $font-weight-medium-web;
        margin-bottom: 15px;

        &--collapsed {
            font-size: 20px;
            margin:0;
        }
    }
    &__text {
        color: $grey-highlight-2;
        font-size: 17px;
        line-height: 22px;
        @include media-breakpoint-up(lg) {
            font-size: 18px;
            line-height: 1.556;
        }
    }
    &__links {
        @include media-breakpoint-down(md) {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
        @include media-breakpoint-up(md) {
            display: flex;
            flex-wrap: wrap;
        }
    }
    &__link {
        border-top: 1px solid $grey-lowlight-1;
        padding-top: 15px;
        padding-bottom: 15px;
        font-size: 20px;
        @include media-breakpoint-up(md) {
            flex-grow:0;
            margin-right: 50px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 24px;
        }
    }

    &--advisory {
        background: transparent;
        border-top:0;
    }
    &--advisory &__collapsed {
        padding: 20px 30px;
    }
    &--advisory &__panel {
        padding: 30px 35px 15px 35px;
    }
    &--advisory &__title {
        color: black;
    }
    &--advisory &__collapsed &__title {
        @include underline(transparent);
    }
    &--advisory &__collapsed:hover &__title {
        @include underline(black);
    }
    &--advisory &__text {
        color: black;
    }
    &--advisory &__collapsed &__info {
        margin-bottom: 5px;
        transition: color 0.3s;
    }
    &--advisory &__collapsed:hover &__info,
    &--advisory &__collapsed:focus &__info {
        color: $orange-lowlight-1;
    }
    &--advisory &__link {
        border-top: 1px solid $grey-highlight-1;
    }

    @include media-breakpoint-down(md) {
        &--advisory &__panel {
            padding-top: 20px;
            padding-bottom: 30px;
        }
    }

    @keyframes advisory_show {
        0% {
            opacity:0;
        }
        100% {
            opacity:1;
        }
    }

    &--advisory &__collapsed,
    &--advisory &__panel {
        animation-duration:1.5s;
        animation-name: advisory_show;
    }
}

@include media-breakpoint-up(lg) {
    .alertset--single {
        .ccbc-alert--advisory {
            .ccbc-alert__collapsed-inner {
                display: flex;
                flex-wrap: nowrap;
            }
        }
    }
}