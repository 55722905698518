.form-control,
.form-select {
    font-size: 18px;
    color: $grey-lowlight-3;

    @include media-breakpoint-up(lg) {
        font-size: 20px;

        &--large {
            font-size: 24px;
            height: 60px;
        }
    }
}

.form-control,
.form-select {
    line-height:1.3;
    padding: 8px;
    @include media-breakpoint-up(md) {
        padding: 9px 11px;
    }
    box-shadow: inset 2px 2px 5px -2px rgba($black, 0.2);
}

.form-label {
    font-size: 18px;
    font-weight: 700;
}
.form-label.required:after {
    content: " (required)";
    font-size: 0.8em;
    font-style: italic;

}

input::placeholder {
    color: $grey-lowlight-1;
    font-style: italic;
}

.input-group {
    &--dates {
        .visually-hidden:first-child + input {
            margin-left: 0 !important;
            border-top-left-radius: $input-border-radius !important;
            border-bottom-left-radius: $input-border-radius !important;
        }

        .input-group-text {
            border: 0 none;
            background-color: transparent;
        }
    }
}