.card {
    &--course {

        abbr {
            text-decoration: none;
        }

        .card-body {
            display:flex;
            flex-direction: column;

            background-color: $grey-highlight-3;
            color: #262523;
            border-radius: 5px;
            padding: 15px 20px;
            @include media-breakpoint-up(md) {
                padding-left: 28px;
                padding-right: 28px;
            }
        }

        .btn--toggle {
            padding-right: 0;
            position: relative;
            padding-left: 26px;
            margin-bottom: 15px;

            @include link-underline;

            [class*="fa"] {
                font-size: 14px;
                padding-right: 11px;
                position: absolute;
                margin-right: 10px;
                width: 15px;
                text-align: center;
                left: 0;
                top: 4px;
                @include media-breakpoint-up(md) {
                    top: 6px;
                    margin-right: 15px;
                    width: 28px;
                }
            }
        }

        .list--icon-with-text {
            margin-bottom: 0;

            li {
                font-size: 16px;
                padding-left: 28px;
            }

            [class*="fa-"] {
                font-size: 14px;
                color: $grey-lowlight-2;
            }
        }

        .card { //css path only

            &__time,
            &__days {
                font-size: 20px;
                line-height: 1.2;
                color: $grey-lowlight-3;
                font-family: $font-family-secondary;
                font-weight: $font-weight-demi-web;
            }

            &__days {
                .filter-band--list ~ .filter-band__results & {
                    @include media-breakpoint-up(lg) {
                        position: relative;
                        margin-left: 30px;

                        &:before {
                            content: "";
                            position: absolute;
                            height: 20px;
                            width: 1px;
                            top: 3px;
                            left: -15px;
                            background-color: $grey-lowlight-3;
                        }
                    }
                }
            }

            &__location {
                position: relative;
                padding-left: 26px;
                font-size: 16px;
                font-style: italic;

                [class*="fa"] {
                    color: $grey-lowlight-2;
                    font-size: 14px;
                    padding-right: 11px;
                    position: absolute;
                    margin-right: 10px;
                    width: 15px;
                    text-align: center;
                    left: 0;
                    top: 4px;
                    @include media-breakpoint-up(md) {
                        top: 6px;
                        margin-right: 15px;
                        width: 28px;
                    }
                }

                .filter-band--list ~ .filter-band__results & {
                    @include media-breakpoint-up(lg) {
                        position: relative;
                        margin-left: 30px;

                        &:before {
                            content: "";
                            position: absolute;
                            height: 20px;
                            width: 1px;
                            top: 3px;
                            left: -15px;
                            background-color: $grey-lowlight-3;
                        }
                    }
                }
            }

            &__time-slots {
                padding:0;
                margin-bottom: 13px;

                margin-left: -10px;
                margin-right: -10px;
                @include media-breakpoint-up(lg) {
                    margin-left: -15px;
                    margin-right: -15px;
                }
                .filter-band--list ~ .filter-band__results & {
                    @include media-breakpoint-up(lg) {
                        display: flex;

                        .card__time-slot {
                            flex: 1;
                            + .card__time-slot {
                                margin-left: 3px;
                            }
                        }
                    }
                }
            }

            &__time-slot {
                list-style: none;
                padding: 8px 15px;
                background-color: rgba($grey-highlight-1, 0.3);
                margin-bottom: 2px;

                .filter-band--list ~ .filter-band__results & {
                    @include media-breakpoint-up(lg) {
                        display: flex;
                    }
                }

                .filter-band--grid ~ .filter-band__results & {
                    @include media-breakpoint-up(lg) {
                        min-height: 86px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                    }
                }
            }

            &__collapse {
                font-size: 16px;
                line-height: 1.375;

                .list--icon-with-text {
                    margin-bottom: 20px;
                    margin-top: 7px;
                }

                p {
                    margin-bottom: 14px;
                }
            }
        }

        .btn.btn-outline-primary {
            font-size: 20px;
        }

        .card__details-button {
            order: 1;
        }
        .card__buttons {
            order: 2;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .filter-band--list ~ .filter-band__results & {
                @include media-breakpoint-up(lg) {
                    flex-direction: row;
                    justify-content: space-between;

                    .btn-toggle {
                        margin-bottom: 0;
                    }
                }
            }
        }

        &.card--double {
            .filter-band--list ~ .filter-band__results & {
                @include media-breakpoint-up(lg) {
                    .card__time-slot {
                        flex-wrap: wrap;
                    }

                    .card__location {
                        margin-left: 0;
                        width: 100%;

                        &:before {
                            display: none;
                        }
                    }
                }
            }
        }
        &.card--triple {
            .filter-band--list ~ .filter-band__results & {
                @include media-breakpoint-up(lg) {
                    .card__time-slot {
                        flex-wrap: wrap;
                    }

                    .card__days,
                    .card__location {
                        margin-left: 0;
                        width: 100%;

                        &:before {
                            display: none;
                        }
                    }
                }
            }
        }

        &.card--full {
            .card-body {
                border: 1px solid $grey-highlight-2;
                background-color: rgba($grey-highlight-3, 0.2);
                color: #898985;
            }

            .card__days,
            .card__time,
            .card__location,
            .list--icon-with-text {
                &,
                [class*="fa"] {
                    color: #898985;
                }
            }

            .card__time-slot {
                background-color: rgba($grey-highlight-1, 0.15);
            }
        }
    }
}