@charset "UTF-8";
/**
    Useful for building composite elements where the underline may be responding to hover on outer wrappers.
 */
:root,
[data-bs-theme=light] {
  --bs-blue: #002f6d;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #c20101;
  --bs-orange: #e36113;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000000;
  --bs-white: #ffffff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #f0bf20;
  --bs-secondary: #ffffff;
  --bs-success: #198754;
  --bs-info: #002f6d;
  --bs-warning: #ffc107;
  --bs-danger: #c20101;
  --bs-light: #f3f3f1;
  --bs-dark: #13120e;
  --bs-primary-rgb: 240, 191, 32;
  --bs-secondary-rgb: 255, 255, 255;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 0, 47, 109;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 194, 1, 1;
  --bs-light-rgb: 243, 243, 241;
  --bs-dark-rgb: 19, 18, 14;
  --bs-primary-text-emphasis: #604c0d;
  --bs-secondary-text-emphasis: #666666;
  --bs-success-text-emphasis: #0a3622;
  --bs-info-text-emphasis: #00132c;
  --bs-warning-text-emphasis: #664d03;
  --bs-danger-text-emphasis: #4e0000;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #fcf2d2;
  --bs-secondary-bg-subtle: white;
  --bs-success-bg-subtle: #d1e7dd;
  --bs-info-bg-subtle: #ccd5e2;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-danger-bg-subtle: #f3cccc;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #f9e5a6;
  --bs-secondary-border-subtle: white;
  --bs-success-border-subtle: #a3cfbb;
  --bs-info-border-subtle: #99acc5;
  --bs-warning-border-subtle: #ffe69c;
  --bs-danger-border-subtle: #e79999;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: futura-pt, sans-serif;
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: lato, sans-serif;
  --bs-body-font-size: 1.25rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.4;
  --bs-body-color: #13120e;
  --bs-body-color-rgb: 19, 18, 14;
  --bs-body-bg: #ffffff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(19, 18, 14, 0.75);
  --bs-secondary-color-rgb: 19, 18, 14;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: rgba(19, 18, 14, 0.5);
  --bs-tertiary-color-rgb: 19, 18, 14;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: #114594;
  --bs-link-color: #1464de;
  --bs-link-color-rgb: 20, 100, 222;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #0c3c7a;
  --bs-link-hover-color-rgb: 12, 60, 122;
  --bs-code-color: #d63384;
  --bs-highlight-color: #13120e;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 8px;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(240, 191, 32, 0.25);
  --bs-form-valid-color: #198754;
  --bs-form-valid-border-color: #198754;
  --bs-form-invalid-color: #c20101;
  --bs-form-invalid-border-color: #c20101;
}

[data-bs-theme=dark] {
  color-scheme: dark;
  --bs-body-color: #dee2e6;
  --bs-body-color-rgb: 222, 226, 230;
  --bs-body-bg: #212529;
  --bs-body-bg-rgb: 33, 37, 41;
  --bs-emphasis-color: #ffffff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(222, 226, 230, 0.75);
  --bs-secondary-color-rgb: 222, 226, 230;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: rgba(222, 226, 230, 0.5);
  --bs-tertiary-color-rgb: 222, 226, 230;
  --bs-tertiary-bg: #2b3035;
  --bs-tertiary-bg-rgb: 43, 48, 53;
  --bs-primary-text-emphasis: #f6d979;
  --bs-secondary-text-emphasis: white;
  --bs-success-text-emphasis: #75b798;
  --bs-info-text-emphasis: #6682a7;
  --bs-warning-text-emphasis: #ffda6a;
  --bs-danger-text-emphasis: #da6767;
  --bs-light-text-emphasis: #f8f9fa;
  --bs-dark-text-emphasis: #dee2e6;
  --bs-primary-bg-subtle: #302606;
  --bs-secondary-bg-subtle: #333333;
  --bs-success-bg-subtle: #051b11;
  --bs-info-bg-subtle: #000916;
  --bs-warning-bg-subtle: #332701;
  --bs-danger-bg-subtle: #270000;
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-primary-border-subtle: #907313;
  --bs-secondary-border-subtle: #999999;
  --bs-success-border-subtle: #0f5132;
  --bs-info-border-subtle: #001c41;
  --bs-warning-border-subtle: #997404;
  --bs-danger-border-subtle: #740101;
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-heading-color: inherit;
  --bs-link-color: #f6d979;
  --bs-link-hover-color: #f8e194;
  --bs-link-color-rgb: 246, 217, 121;
  --bs-link-hover-color-rgb: 248, 225, 148;
  --bs-code-color: #e685b5;
  --bs-highlight-color: #dee2e6;
  --bs-highlight-bg: #664d03;
  --bs-border-color: #495057;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #75b798;
  --bs-form-valid-border-color: #75b798;
  --bs-form-invalid-color: #da6767;
  --bs-form-invalid-border-color: #da6767;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 60px 0 20px 0;
  color: #e1e0db;
  border: 0;
  border-top: 1px solid;
  opacity: 1;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.25rem;
  font-family: futura-pt, sans-serif;
  font-weight: 400;
  line-height: 1.17;
  color: var(--bs-heading-color);
}

h1, .h1 {
  font-size: calc(2.85rem + 3vw);
}
@media (min-width: 1280px) {
  h1, .h1 {
    font-size: 5.25rem;
  }
}

h2, .h2 {
  font-size: calc(2.1rem + 1.59375vw);
}
@media (min-width: 1280px) {
  h2, .h2 {
    font-size: 3.375rem;
  }
}

h3, .h3 {
  font-size: calc(1.75rem + 0.9375vw);
}
@media (min-width: 1280px) {
  h3, .h3 {
    font-size: 2.5rem;
  }
}

h4, .h4 {
  font-size: calc(1.65rem + 0.75vw);
}
@media (min-width: 1280px) {
  h4, .h4 {
    font-size: 2.25rem;
  }
}

h5, .h5 {
  font-size: calc(1.5rem + 0.46875vw);
}
@media (min-width: 1280px) {
  h5, .h5 {
    font-size: 1.875rem;
  }
}

h6, .h6 {
  font-size: calc(1.3rem + 0.09375vw);
}
@media (min-width: 1280px) {
  h6, .h6 {
    font-size: 1.375rem;
  }
}

p {
  margin-top: 0;
  margin-bottom: 1.4rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.1875em;
  color: var(--bs-highlight-color);
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
}
a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 12px;
  padding-bottom: 12px;
  color: var(--bs-secondary-color);
  text-align: left;
}

th {
  font-weight: 500;
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.35rem + 0.1875vw);
  line-height: inherit;
}
@media (min-width: 1280px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: calc(1.375rem + 0.234375vw);
  font-weight: 300;
}
@media (min-width: 1280px) {
  .lead {
    font-size: 1.5625rem;
  }
}

.display-1 {
  font-size: calc(2.75rem + 2.8125vw);
  font-weight: 300;
  line-height: 1.17;
}
@media (min-width: 1280px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(2.55rem + 2.4375vw);
  font-weight: 300;
  line-height: 1.17;
}
@media (min-width: 1280px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(2.35rem + 2.0625vw);
  font-weight: 300;
  line-height: 1.17;
}
@media (min-width: 1280px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(2.15rem + 1.6875vw);
  font-weight: 300;
  line-height: 1.17;
}
@media (min-width: 1280px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.95rem + 1.3125vw);
  font-weight: 300;
  line-height: 1.17;
}
@media (min-width: 1280px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.75rem + 0.9375vw);
  font-weight: 300;
  line-height: 1.17;
}
@media (min-width: 1280px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: calc(1.375rem + 0.234375vw);
}
@media (min-width: 1280px) {
  .blockquote {
    font-size: 1.5625rem;
  }
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  box-shadow: var(--bs-box-shadow-sm);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}
.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(240, 191, 32, 0.25);
}
.nav-link.disabled, .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: var(--bs-border-width);
  --bs-nav-tabs-border-color: var(--bs-border-color);
  --bs-nav-tabs-border-radius: var(--bs-border-radius);
  --bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg) var(--bs-secondary-bg) var(--bs-border-color);
  --bs-nav-tabs-link-active-color: var(--bs-emphasis-color);
  --bs-nav-tabs-link-active-bg: var(--bs-body-bg);
  --bs-nav-tabs-link-active-border-color: var(--bs-border-color) var(--bs-border-color) var(--bs-body-bg);
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: var(--bs-border-radius);
  --bs-nav-pills-link-active-color: #ffffff;
  --bs-nav-pills-link-active-bg: #f0bf20;
}
.nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-underline {
  --bs-nav-underline-gap: 1rem;
  --bs-nav-underline-border-width: 0.125rem;
  --bs-nav-underline-link-active-color: var(--bs-emphasis-color);
  gap: var(--bs-nav-underline-gap);
}
.nav-underline .nav-link {
  padding-right: 0;
  padding-left: 0;
  border-bottom: var(--bs-nav-underline-border-width) solid transparent;
}
.nav-underline .nav-link:hover, .nav-underline .nav-link:focus {
  border-bottom-color: currentcolor;
}
.nav-underline .nav-link.active,
.nav-underline .show > .nav-link {
  font-weight: 700;
  color: var(--bs-nav-underline-link-active-color);
  border-bottom-color: currentcolor;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(var(--bs-emphasis-color-rgb), 0.65);
  --bs-navbar-hover-color: rgba(var(--bs-emphasis-color-rgb), 0.8);
  --bs-navbar-disabled-color: rgba(var(--bs-emphasis-color-rgb), 0.3);
  --bs-navbar-active-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-padding-y: 0.28125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.5625rem;
  --bs-navbar-brand-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-hover-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.5625rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2819, 18, 14, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(var(--bs-emphasis-color-rgb), 0.15);
  --bs-navbar-toggler-border-radius: 1000px;
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}
.navbar > .container-xxl, .navbar > .container-xl, .navbar > .container-lg, .navbar > .container-md, .navbar > .container-sm, .navbar > .container,
.navbar > .container-fluid {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: var(--bs-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  box-shadow: none;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark,
.navbar[data-bs-theme=dark] {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #ffffff;
  --bs-navbar-brand-color: #ffffff;
  --bs-navbar-brand-hover-color: #ffffff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

[data-bs-theme=dark] .navbar-toggler-icon {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 830px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 0;
  --bs-offcanvas-padding-y: 0;
  --bs-offcanvas-color: var(--bs-body-color);
  --bs-offcanvas-bg: var(--bs-body-bg);
  --bs-offcanvas-border-width: var(--bs-border-width);
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: var(--bs-box-shadow-sm);
  --bs-offcanvas-transition: transform 0.3s ease-in-out;
  --bs-offcanvas-title-line-height: 1.4;
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--bs-offcanvas-box-shadow);
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--bs-offcanvas-box-shadow);
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--bs-offcanvas-box-shadow);
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--bs-offcanvas-box-shadow);
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--bs-offcanvas-box-shadow);
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  box-shadow: var(--bs-offcanvas-box-shadow);
  transition: var(--bs-offcanvas-transition);
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.8;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5) calc(var(--bs-offcanvas-padding-x) * 0.5);
  margin-top: calc(-0.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-0.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-0.5 * var(--bs-offcanvas-padding-y));
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--bs-offcanvas-title-line-height);
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

.g-6,
.gx-6 {
  --bs-gutter-x: 4rem;
}

.g-6,
.gy-6 {
  --bs-gutter-y: 4rem;
}

.g-7,
.gx-7 {
  --bs-gutter-x: 5rem;
}

.g-7,
.gy-7 {
  --bs-gutter-y: 5rem;
}

.g-8,
.gx-8 {
  --bs-gutter-x: 6rem;
}

.g-8,
.gy-8 {
  --bs-gutter-y: 6rem;
}

.g-9,
.gx-9 {
  --bs-gutter-x: 7rem;
}

.g-9,
.gy-9 {
  --bs-gutter-y: 7rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
  .g-sm-6,
  .gx-sm-6 {
    --bs-gutter-x: 4rem;
  }
  .g-sm-6,
  .gy-sm-6 {
    --bs-gutter-y: 4rem;
  }
  .g-sm-7,
  .gx-sm-7 {
    --bs-gutter-x: 5rem;
  }
  .g-sm-7,
  .gy-sm-7 {
    --bs-gutter-y: 5rem;
  }
  .g-sm-8,
  .gx-sm-8 {
    --bs-gutter-x: 6rem;
  }
  .g-sm-8,
  .gy-sm-8 {
    --bs-gutter-y: 6rem;
  }
  .g-sm-9,
  .gx-sm-9 {
    --bs-gutter-x: 7rem;
  }
  .g-sm-9,
  .gy-sm-9 {
    --bs-gutter-y: 7rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
  .g-md-6,
  .gx-md-6 {
    --bs-gutter-x: 4rem;
  }
  .g-md-6,
  .gy-md-6 {
    --bs-gutter-y: 4rem;
  }
  .g-md-7,
  .gx-md-7 {
    --bs-gutter-x: 5rem;
  }
  .g-md-7,
  .gy-md-7 {
    --bs-gutter-y: 5rem;
  }
  .g-md-8,
  .gx-md-8 {
    --bs-gutter-x: 6rem;
  }
  .g-md-8,
  .gy-md-8 {
    --bs-gutter-y: 6rem;
  }
  .g-md-9,
  .gx-md-9 {
    --bs-gutter-x: 7rem;
  }
  .g-md-9,
  .gy-md-9 {
    --bs-gutter-y: 7rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
  .g-lg-6,
  .gx-lg-6 {
    --bs-gutter-x: 4rem;
  }
  .g-lg-6,
  .gy-lg-6 {
    --bs-gutter-y: 4rem;
  }
  .g-lg-7,
  .gx-lg-7 {
    --bs-gutter-x: 5rem;
  }
  .g-lg-7,
  .gy-lg-7 {
    --bs-gutter-y: 5rem;
  }
  .g-lg-8,
  .gx-lg-8 {
    --bs-gutter-x: 6rem;
  }
  .g-lg-8,
  .gy-lg-8 {
    --bs-gutter-y: 6rem;
  }
  .g-lg-9,
  .gx-lg-9 {
    --bs-gutter-x: 7rem;
  }
  .g-lg-9,
  .gy-lg-9 {
    --bs-gutter-y: 7rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
  .g-xl-6,
  .gx-xl-6 {
    --bs-gutter-x: 4rem;
  }
  .g-xl-6,
  .gy-xl-6 {
    --bs-gutter-y: 4rem;
  }
  .g-xl-7,
  .gx-xl-7 {
    --bs-gutter-x: 5rem;
  }
  .g-xl-7,
  .gy-xl-7 {
    --bs-gutter-y: 5rem;
  }
  .g-xl-8,
  .gx-xl-8 {
    --bs-gutter-x: 6rem;
  }
  .g-xl-8,
  .gy-xl-8 {
    --bs-gutter-y: 6rem;
  }
  .g-xl-9,
  .gx-xl-9 {
    --bs-gutter-x: 7rem;
  }
  .g-xl-9,
  .gy-xl-9 {
    --bs-gutter-y: 7rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
  .g-xxl-6,
  .gx-xxl-6 {
    --bs-gutter-x: 4rem;
  }
  .g-xxl-6,
  .gy-xxl-6 {
    --bs-gutter-y: 4rem;
  }
  .g-xxl-7,
  .gx-xxl-7 {
    --bs-gutter-x: 5rem;
  }
  .g-xxl-7,
  .gy-xxl-7 {
    --bs-gutter-y: 5rem;
  }
  .g-xxl-8,
  .gx-xxl-8 {
    --bs-gutter-x: 6rem;
  }
  .g-xxl-8,
  .gy-xxl-8 {
    --bs-gutter-y: 6rem;
  }
  .g-xxl-9,
  .gx-xxl-9 {
    --bs-gutter-x: 7rem;
  }
  .g-xxl-9,
  .gy-xxl-9 {
    --bs-gutter-y: 7rem;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #000000 !important;
  background-color: RGBA(var(--bs-primary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #6a6964 !important;
  background-color: RGBA(var(--bs-secondary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-success-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-info-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000000 !important;
  background-color: RGBA(var(--bs-warning-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-danger-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #6a6964 !important;
  background-color: RGBA(var(--bs-light-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-dark-rgb), var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: RGBA(var(--bs-primary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-primary:hover, .link-primary:focus {
  color: RGBA(243, 204, 77, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(243, 204, 77, var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary {
  color: RGBA(var(--bs-secondary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: RGBA(255, 255, 255, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 255, 255, var(--bs-link-underline-opacity, 1)) !important;
}

.link-success {
  color: RGBA(var(--bs-success-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-success:hover, .link-success:focus {
  color: RGBA(20, 108, 67, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(20, 108, 67, var(--bs-link-underline-opacity, 1)) !important;
}

.link-info {
  color: RGBA(var(--bs-info-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-info:hover, .link-info:focus {
  color: RGBA(0, 38, 87, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(0, 38, 87, var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning {
  color: RGBA(var(--bs-warning-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-warning:hover, .link-warning:focus {
  color: RGBA(255, 205, 57, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 205, 57, var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger {
  color: RGBA(var(--bs-danger-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-danger:hover, .link-danger:focus {
  color: RGBA(155, 1, 1, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(155, 1, 1, var(--bs-link-underline-opacity, 1)) !important;
}

.link-light {
  color: RGBA(var(--bs-light-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-light:hover, .link-light:focus {
  color: RGBA(245, 245, 244, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(245, 245, 244, var(--bs-link-underline-opacity, 1)) !important;
}

.link-dark {
  color: RGBA(var(--bs-dark-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-dark:hover, .link-dark:focus {
  color: RGBA(15, 14, 11, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(15, 14, 11, var(--bs-link-underline-opacity, 1)) !important;
}

.link-body-emphasis {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-body-emphasis:hover, .link-body-emphasis:focus {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 0.75)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 0.75)) !important;
}

.focus-ring:focus {
  outline: 0;
  box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color);
}

.icon-link {
  display: inline-flex;
  gap: 0.375rem;
  align-items: center;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 0.5));
  text-underline-offset: 0.25em;
  backface-visibility: hidden;
}
.icon-link > .bi {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  fill: currentcolor;
  transition: 0.2s ease-in-out transform;
}
@media (prefers-reduced-motion: reduce) {
  .icon-link > .bi {
    transition: none;
  }
}

.icon-link-hover:hover > .bi, .icon-link-hover:focus-visible > .bi {
  transform: var(--bs-icon-link-transform, translate3d(0.25em, 0, 0));
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.visually-hidden:not(caption),
.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: var(--bs-border-width);
  min-height: 1em;
  background-color: currentcolor;
  opacity: 1;
}

.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + 2px);
  padding-bottom: calc(0.375rem + 2px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.4;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 2px);
  padding-bottom: calc(0.5rem + 2px);
  font-size: calc(1.375rem + 0.234375vw);
}
@media (min-width: 1280px) {
  .col-form-label-lg {
    font-size: 1.5625rem;
  }
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 2px);
  padding-bottom: calc(0.25rem + 2px);
  font-size: 1rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.4;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: 2px solid #bcbbb6;
  border-radius: 5px;
  box-shadow: var(--bs-box-shadow-inset);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #bcbbb6;
  outline: 0;
  box-shadow: var(--bs-box-shadow-inset), 0 0 0 0.25rem rgba(240, 191, 32, 0.25);
}
.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.4em;
  margin: 0;
}
.form-control::-webkit-datetime-edit {
  display: block;
  padding: 0;
}
.form-control::placeholder {
  color: var(--bs-secondary-color);
  opacity: 1;
}
.form-control:disabled {
  background-color: var(--bs-secondary-bg);
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: var(--bs-body-color);
  background-color: var(--bs-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 2px;
  border-radius: 0;
  transition: color 0.35s ease, background-color 0.35s ease, border-color 0.35s ease, box-shadow 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--bs-secondary-bg);
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.4;
  color: var(--bs-body-color);
  background-color: transparent;
  border: solid transparent;
  border-width: 2px 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.4em + 0.5rem + calc(2px * 2));
  padding: 0.25rem 0.5rem;
  font-size: 1rem;
  border-radius: var(--bs-border-radius-sm);
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.4em + 1rem + calc(2px * 2));
  padding: 0.5rem 1rem;
  font-size: calc(1.375rem + 0.234375vw);
  border-radius: var(--bs-border-radius-lg);
}
@media (min-width: 1280px) {
  .form-control-lg {
    font-size: 1.5625rem;
  }
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.4em + 0.75rem + calc(2px * 2));
}
textarea.form-control-sm {
  min-height: calc(1.4em + 0.5rem + calc(2px * 2));
}
textarea.form-control-lg {
  min-height: calc(1.4em + 1rem + calc(2px * 2));
}

.form-control-color {
  width: 3rem;
  height: calc(1.4em + 0.75rem + calc(2px * 2));
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: 5px;
}
.form-control-color::-webkit-color-swatch {
  border: 0 !important;
  border-radius: 5px;
}
.form-control-color.form-control-sm {
  height: calc(1.4em + 0.5rem + calc(2px * 2));
}
.form-control-color.form-control-lg {
  height: calc(1.4em + 1rem + calc(2px * 2));
}

.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%2319519a' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.4;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 21px 21px;
  border: 2px solid #bcbbb6;
  border-radius: 5px;
  box-shadow: var(--bs-box-shadow-inset);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #bcbbb6;
  outline: 0;
  box-shadow: var(--bs-box-shadow-inset), 0 0 0 0.25rem rgba(20, 100, 222, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: var(--bs-secondary-bg);
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--bs-body-color);
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 1rem;
  border-radius: var(--bs-border-radius-sm);
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: calc(1.375rem + 0.234375vw);
  border-radius: var(--bs-border-radius-lg);
}
@media (min-width: 1280px) {
  .form-select-lg {
    font-size: 1.5625rem;
  }
}

[data-bs-theme=dark] .form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23dee2e6' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check {
  display: block;
  min-height: 24px;
  padding-left: 40px;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -40px;
}

.form-check-reverse {
  padding-right: 40px;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -40px;
  margin-left: 0;
}

.form-check-input {
  --bs-form-check-bg: var(--bs-body-bg);
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  margin-top: -11.3px;
  vertical-align: top;
  appearance: none;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 2px solid #bcbbb6;
  print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 3px;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #bcbbb6;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(240, 191, 32, 0.25);
}
.form-check-input:checked {
  background-color: #f0bf20;
  border-color: #f0bf20;
}
.form-check-input:checked[type=checkbox] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23ffffff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #f0bf20;
  border-color: #f0bf20;
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  width: 2em;
  margin-left: -2.5em;
  background-image: var(--bs-form-switch-bg);
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23bcbbb6'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

[data-bs-theme=dark] .form-switch .form-check-input:not(:checked):not(:focus) {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  appearance: none;
  background-color: transparent;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.25rem rgba(240, 191, 32, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.25rem rgba(240, 191, 32, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  appearance: none;
  background-color: #f0bf20;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #fbecbc;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: var(--bs-box-shadow-inset);
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #f0bf20;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #fbecbc;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: var(--bs-box-shadow-inset);
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--bs-secondary-color);
}
.form-range:disabled::-moz-range-thumb {
  background-color: var(--bs-secondary-color);
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + calc(2px * 2));
  min-height: calc(3.5rem + calc(2px * 2));
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  padding: 1rem 0.75rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: 2px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-select ~ label::after {
  position: absolute;
  inset: 1rem 0.375rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: var(--bs-body-bg);
  border-radius: 5px;
}
.form-floating > .form-control:-webkit-autofill ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: 2px 0;
}
.form-floating > :disabled ~ label,
.form-floating > .form-control:disabled ~ label {
  color: #6c757d;
}
.form-floating > :disabled ~ label::after,
.form-floating > .form-control:disabled ~ label::after {
  background-color: var(--bs-secondary-bg);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.4;
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-tertiary-bg);
  border: 2px solid #bcbbb6;
  border-radius: 5px;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: calc(1.375rem + 0.234375vw);
  border-radius: var(--bs-border-radius-lg);
}
@media (min-width: 1280px) {
  .input-group-lg > .form-control,
  .input-group-lg > .form-select,
  .input-group-lg > .input-group-text,
  .input-group-lg > .btn {
    font-size: 1.5625rem;
  }
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 1rem;
  border-radius: var(--bs-border-radius-sm);
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(2px * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-valid-color);
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 1rem;
  color: #fff;
  background-color: var(--bs-success);
  border-radius: var(--bs-border-radius);
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: var(--bs-form-valid-border-color);
  padding-right: calc(1.4em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.35em + 0.1875rem) center;
  background-size: calc(0.7em + 0.375rem) calc(0.7em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.4em + 0.75rem);
  background-position: top calc(0.35em + 0.1875rem) right calc(0.35em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 21px 21px, calc(0.7em + 0.375rem) calc(0.7em + 0.375rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.4em + 0.75rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: var(--bs-form-valid-color);
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: var(--bs-form-valid-color);
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-invalid-color);
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 1rem;
  color: #fff;
  background-color: var(--bs-danger);
  border-radius: var(--bs-border-radius);
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.4em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23c20101'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23c20101' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.35em + 0.1875rem) center;
  background-size: calc(0.7em + 0.375rem) calc(0.7em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.4em + 0.75rem);
  background-position: top calc(0.35em + 0.1875rem) right calc(0.35em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23c20101'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23c20101' stroke='none'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 21px 21px, calc(0.7em + 0.375rem) calc(0.7em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.4em + 0.75rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: var(--bs-form-invalid-color);
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: var(--bs-form-invalid-color);
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.4;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 2px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 1000px;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: none;
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  box-shadow: var(--bs-btn-box-shadow);
  transition: color 0.35s ease, background-color 0.35s ease, border-color 0.35s ease, box-shadow 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check + .btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}
.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-box-shadow), var(--bs-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-box-shadow), var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
  box-shadow: var(--bs-btn-active-shadow);
}
.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-active-shadow), var(--bs-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
  box-shadow: none;
}

.btn-primary {
  --bs-btn-color: #000000;
  --bs-btn-bg: #f0bf20;
  --bs-btn-border-color: #f0bf20;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #f2c941;
  --bs-btn-hover-border-color: #f2c536;
  --bs-btn-focus-shadow-rgb: 204, 162, 27;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #f3cc4d;
  --bs-btn-active-border-color: #f2c536;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #f0bf20;
  --bs-btn-disabled-border-color: #f0bf20;
}

.btn-secondary {
  --bs-btn-color: #6a6964;
  --bs-btn-bg: #ffffff;
  --bs-btn-border-color: #ffffff;
  --bs-btn-hover-color: #6a6964;
  --bs-btn-hover-bg: white;
  --bs-btn-hover-border-color: white;
  --bs-btn-focus-shadow-rgb: 233, 233, 232;
  --bs-btn-active-color: #6a6964;
  --bs-btn-active-bg: white;
  --bs-btn-active-border-color: white;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #6a6964;
  --bs-btn-disabled-bg: #ffffff;
  --bs-btn-disabled-border-color: #ffffff;
}

.btn-success {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #157347;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754;
}

.btn-info {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #002f6d;
  --bs-btn-border-color: #002f6d;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #00285d;
  --bs-btn-hover-border-color: #002657;
  --bs-btn-focus-shadow-rgb: 38, 78, 131;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #002657;
  --bs-btn-active-border-color: #002352;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #002f6d;
  --bs-btn-disabled-border-color: #002f6d;
}

.btn-warning {
  --bs-btn-color: #000000;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107;
}

.btn-danger {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #c20101;
  --bs-btn-border-color: #c20101;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #a50101;
  --bs-btn-hover-border-color: #9b0101;
  --bs-btn-focus-shadow-rgb: 203, 39, 39;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #9b0101;
  --bs-btn-active-border-color: #920101;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #c20101;
  --bs-btn-disabled-border-color: #c20101;
}

.btn-light {
  --bs-btn-color: #6a6964;
  --bs-btn-bg: #f3f3f1;
  --bs-btn-border-color: #f3f3f1;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #cfcfcd;
  --bs-btn-hover-border-color: #c2c2c1;
  --bs-btn-focus-shadow-rgb: 222, 222, 220;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #c2c2c1;
  --bs-btn-active-border-color: #b6b6b5;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #6a6964;
  --bs-btn-disabled-bg: #f3f3f1;
  --bs-btn-disabled-border-color: #f3f3f1;
}

.btn-dark {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #13120e;
  --bs-btn-border-color: #13120e;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #363632;
  --bs-btn-hover-border-color: #2b2a26;
  --bs-btn-focus-shadow-rgb: 54, 54, 50;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #42413e;
  --bs-btn-active-border-color: #2b2a26;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #13120e;
  --bs-btn-disabled-border-color: #13120e;
}

.btn-outline-primary {
  --bs-btn-color: #f0bf20;
  --bs-btn-border-color: #f0bf20;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #f0bf20;
  --bs-btn-hover-border-color: #f0bf20;
  --bs-btn-focus-shadow-rgb: 240, 191, 32;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #f0bf20;
  --bs-btn-active-border-color: #f0bf20;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f0bf20;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f0bf20;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #ffffff;
  --bs-btn-border-color: #ffffff;
  --bs-btn-hover-color: #6a6964;
  --bs-btn-hover-bg: #ffffff;
  --bs-btn-hover-border-color: #ffffff;
  --bs-btn-focus-shadow-rgb: 255, 255, 255;
  --bs-btn-active-color: #6a6964;
  --bs-btn-active-bg: #ffffff;
  --bs-btn-active-border-color: #ffffff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffffff;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #198754;
  --bs-btn-hover-border-color: #198754;
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #198754;
  --bs-btn-active-border-color: #198754;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #198754;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #198754;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #002f6d;
  --bs-btn-border-color: #002f6d;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #002f6d;
  --bs-btn-hover-border-color: #002f6d;
  --bs-btn-focus-shadow-rgb: 0, 47, 109;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #002f6d;
  --bs-btn-active-border-color: #002f6d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #002f6d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #002f6d;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #c20101;
  --bs-btn-border-color: #c20101;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #c20101;
  --bs-btn-hover-border-color: #c20101;
  --bs-btn-focus-shadow-rgb: 194, 1, 1;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #c20101;
  --bs-btn-active-border-color: #c20101;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #c20101;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #c20101;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #f3f3f1;
  --bs-btn-border-color: #f3f3f1;
  --bs-btn-hover-color: #6a6964;
  --bs-btn-hover-bg: #f3f3f1;
  --bs-btn-hover-border-color: #f3f3f1;
  --bs-btn-focus-shadow-rgb: 243, 243, 241;
  --bs-btn-active-color: #6a6964;
  --bs-btn-active-bg: #f3f3f1;
  --bs-btn-active-border-color: #f3f3f1;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f3f3f1;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f3f3f1;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #13120e;
  --bs-btn-border-color: #13120e;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #13120e;
  --bs-btn-hover-border-color: #13120e;
  --bs-btn-focus-shadow-rgb: 19, 18, 14;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #13120e;
  --bs-btn-active-border-color: #13120e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #13120e;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #13120e;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: 0 0 0 #000;
  --bs-btn-focus-shadow-rgb: 55, 123, 227;
  text-decoration: underline;
}
.btn-link:focus-visible {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: calc(1.375rem + 0.234375vw);
  --bs-btn-border-radius: var(--bs-border-radius-lg);
}
@media (min-width: 1280px) {
  .btn-lg {
    --bs-btn-font-size: 1.5625rem;
  }
}

.btn-sm {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 1rem;
  --bs-btn-border-radius: 1000px;
}

.accordion {
  --bs-accordion-color: #13120e;
  --bs-accordion-bg: #ffffff;
  --bs-accordion-transition: color 0.35s ease, background-color 0.35s ease, border-color 0.35s ease, box-shadow 0.35s ease, border-radius 0.15s ease;
  --bs-accordion-border-color: #d8e6fa;
  --bs-accordion-border-width: 2px;
  --bs-accordion-border-radius: 5px;
  --bs-accordion-inner-border-radius: 3px;
  --bs-accordion-btn-padding-x: 22px;
  --bs-accordion-btn-padding-y: 10px;
  --bs-accordion-btn-color: #13120e;
  --bs-accordion-btn-bg: #d8e6fa;
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%231464de' viewBox='0 0 512 512'%3E%3C!--! Font Awesome Pro 6.2.1 by %40fontawesome - https://fontawesome.com License - https://fontawesome.com/license %28Commercial License%29 Copyright 2022 Fonticons  Inc. --%3E%3Cpath d='M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z'/%3E%3C/svg%3E");
  --bs-accordion-btn-icon-width: 19px;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23001a3d' viewBox='0 0 512 512'%3E%3C!--! Font Awesome Pro 6.2.1 by %40fontawesome - https://fontawesome.com License - https://fontawesome.com/license %28Commercial License%29 Copyright 2022 Fonticons  Inc. --%3E%3Cpath d='M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z'/%3E%3C/svg%3E");
  --bs-accordion-btn-focus-border-color: #002f6d;
  --bs-accordion-btn-focus-box-shadow: 0 0 2px 2px #002f6d;
  --bs-accordion-body-padding-x: 23px;
  --bs-accordion-body-padding-y: 20px;
  --bs-accordion-active-color: #13120e;
  --bs-accordion-active-bg: #ffffff;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1.25rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}

[data-bs-theme=dark] .accordion-button::after {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23f6d979'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23f6d979'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: var(--bs-border-width) solid var(--bs-alert-border-color);
  --bs-alert-border-radius: var(--bs-border-radius);
  --bs-alert-link-color: inherit;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
  color: var(--bs-alert-link-color);
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  --bs-alert-color: var(--bs-primary-text-emphasis);
  --bs-alert-bg: var(--bs-primary-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-border-subtle);
  --bs-alert-link-color: var(--bs-primary-text-emphasis);
}

.alert-secondary {
  --bs-alert-color: var(--bs-secondary-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-text-emphasis);
}

.alert-success {
  --bs-alert-color: var(--bs-success-text-emphasis);
  --bs-alert-bg: var(--bs-success-bg-subtle);
  --bs-alert-border-color: var(--bs-success-border-subtle);
  --bs-alert-link-color: var(--bs-success-text-emphasis);
}

.alert-info {
  --bs-alert-color: var(--bs-info-text-emphasis);
  --bs-alert-bg: var(--bs-info-bg-subtle);
  --bs-alert-border-color: var(--bs-info-border-subtle);
  --bs-alert-link-color: var(--bs-info-text-emphasis);
}

.alert-warning {
  --bs-alert-color: var(--bs-warning-text-emphasis);
  --bs-alert-bg: var(--bs-warning-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-border-subtle);
  --bs-alert-link-color: var(--bs-warning-text-emphasis);
}

.alert-danger {
  --bs-alert-color: var(--bs-danger-text-emphasis);
  --bs-alert-bg: var(--bs-danger-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-border-subtle);
  --bs-alert-link-color: var(--bs-danger-text-emphasis);
}

.alert-light {
  --bs-alert-color: var(--bs-light-text-emphasis);
  --bs-alert-bg: var(--bs-light-bg-subtle);
  --bs-alert-border-color: var(--bs-light-border-subtle);
  --bs-alert-link-color: var(--bs-light-text-emphasis);
}

.alert-dark {
  --bs-alert-color: var(--bs-dark-text-emphasis);
  --bs-alert-bg: var(--bs-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-dark-border-subtle);
  --bs-alert-link-color: var(--bs-dark-text-emphasis);
}

.table {
  --bs-table-color-type: initial;
  --bs-table-bg-type: initial;
  --bs-table-color-state: initial;
  --bs-table-bg-state: initial;
  --bs-table-color: #262523;
  --bs-table-bg: var(--bs-body-bg);
  --bs-table-border-color: #e1e0db;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #262523;
  --bs-table-striped-bg: #f7f7f5;
  --bs-table-active-color: #262523;
  --bs-table-active-bg: rgba(var(--bs-emphasis-color-rgb), 0.1);
  --bs-table-hover-color: #262523;
  --bs-table-hover-bg: rgba(var(--bs-emphasis-color-rgb), 0.075);
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 12px 22px;
  color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));
  background-color: var(--bs-table-bg);
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(var(--bs-border-width) * 2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: var(--bs-border-width) 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 var(--bs-border-width);
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(even) > * {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-active {
  --bs-table-color-state: var(--bs-table-active-color);
  --bs-table-bg-state: var(--bs-table-active-bg);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: var(--bs-table-hover-bg);
}

.table-primary {
  --bs-table-color: #6a6964;
  --bs-table-bg: #fcf2d2;
  --bs-table-border-color: #dfd7bc;
  --bs-table-striped-bg: #f5ebcd;
  --bs-table-striped-color: #6a6964;
  --bs-table-active-bg: #ede4c7;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #f1e8ca;
  --bs-table-hover-color: #000000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #6a6964;
  --bs-table-bg: white;
  --bs-table-border-color: #e1e1e0;
  --bs-table-striped-bg: #f8f8f7;
  --bs-table-striped-color: #6a6964;
  --bs-table-active-bg: #f0f0f0;
  --bs-table-active-color: #6a6964;
  --bs-table-hover-bg: #f4f4f3;
  --bs-table-hover-color: #6a6964;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000000;
  --bs-table-bg: #d1e7dd;
  --bs-table-border-color: #a7b9b1;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000000;
  --bs-table-bg: #ccd5e2;
  --bs-table-border-color: #a3aab5;
  --bs-table-striped-bg: #c2cad7;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #b8c0cb;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #bdc5d1;
  --bs-table-hover-color: #000000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #6a6964;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #e1d7b8;
  --bs-table-striped-bg: #f8ecc8;
  --bs-table-striped-color: #6a6964;
  --bs-table-active-bg: #f0e5c3;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #f4e9c5;
  --bs-table-hover-color: #6a6964;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000000;
  --bs-table-bg: #f3cccc;
  --bs-table-border-color: #c2a3a3;
  --bs-table-striped-bg: #e7c2c2;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #dbb8b8;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #e1bdbd;
  --bs-table-hover-color: #000000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #6a6964;
  --bs-table-bg: #f3f3f1;
  --bs-table-border-color: #d8d7d5;
  --bs-table-striped-bg: #ececea;
  --bs-table-striped-color: #6a6964;
  --bs-table-active-bg: #e5e5e3;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #e9e9e6;
  --bs-table-hover-color: #6a6964;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #ffffff;
  --bs-table-bg: #13120e;
  --bs-table-border-color: #42413e;
  --bs-table-striped-bg: #1f1e1a;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #2b2a26;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #252420;
  --bs-table-hover-color: #ffffff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 1rem;
  --bs-tooltip-color: var(--bs-body-bg);
  --bs-tooltip-bg: var(--bs-emphasis-color);
  --bs-tooltip-border-radius: var(--bs-border-radius);
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  margin: var(--bs-tooltip-margin);
  font-family: lato, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  object-fit: contain !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.object-fit-fill {
  object-fit: fill !important;
}

.object-fit-scale {
  object-fit: scale-down !important;
}

.object-fit-none {
  object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: var(--bs-box-shadow) !important;
}

.shadow-sm {
  box-shadow: var(--bs-box-shadow-sm) !important;
}

.shadow-lg {
  box-shadow: var(--bs-box-shadow-lg) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-success {
  --bs-focus-ring-color: rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-warning {
  --bs-focus-ring-color: rgba(var(--bs-warning-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-danger {
  --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-light {
  --bs-focus-ring-color: rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity));
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-6 {
  margin: 4rem !important;
}

.m-7 {
  margin: 5rem !important;
}

.m-8 {
  margin: 6rem !important;
}

.m-9 {
  margin: 7rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-6 {
  margin-right: 4rem !important;
  margin-left: 4rem !important;
}

.mx-7 {
  margin-right: 5rem !important;
  margin-left: 5rem !important;
}

.mx-8 {
  margin-right: 6rem !important;
  margin-left: 6rem !important;
}

.mx-9 {
  margin-right: 7rem !important;
  margin-left: 7rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-6 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.my-7 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.my-8 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important;
}

.my-9 {
  margin-top: 7rem !important;
  margin-bottom: 7rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-6 {
  margin-top: 4rem !important;
}

.mt-7 {
  margin-top: 5rem !important;
}

.mt-8 {
  margin-top: 6rem !important;
}

.mt-9 {
  margin-top: 7rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-6 {
  margin-right: 4rem !important;
}

.me-7 {
  margin-right: 5rem !important;
}

.me-8 {
  margin-right: 6rem !important;
}

.me-9 {
  margin-right: 7rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-6 {
  margin-bottom: 4rem !important;
}

.mb-7 {
  margin-bottom: 5rem !important;
}

.mb-8 {
  margin-bottom: 6rem !important;
}

.mb-9 {
  margin-bottom: 7rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-6 {
  margin-left: 4rem !important;
}

.ms-7 {
  margin-left: 5rem !important;
}

.ms-8 {
  margin-left: 6rem !important;
}

.ms-9 {
  margin-left: 7rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.p-6 {
  padding: 4rem !important;
}

.p-7 {
  padding: 5rem !important;
}

.p-8 {
  padding: 6rem !important;
}

.p-9 {
  padding: 7rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.px-6 {
  padding-right: 4rem !important;
  padding-left: 4rem !important;
}

.px-7 {
  padding-right: 5rem !important;
  padding-left: 5rem !important;
}

.px-8 {
  padding-right: 6rem !important;
  padding-left: 6rem !important;
}

.px-9 {
  padding-right: 7rem !important;
  padding-left: 7rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-6 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.py-7 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.py-8 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important;
}

.py-9 {
  padding-top: 7rem !important;
  padding-bottom: 7rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pt-6 {
  padding-top: 4rem !important;
}

.pt-7 {
  padding-top: 5rem !important;
}

.pt-8 {
  padding-top: 6rem !important;
}

.pt-9 {
  padding-top: 7rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pe-6 {
  padding-right: 4rem !important;
}

.pe-7 {
  padding-right: 5rem !important;
}

.pe-8 {
  padding-right: 6rem !important;
}

.pe-9 {
  padding-right: 7rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.pb-6 {
  padding-bottom: 4rem !important;
}

.pb-7 {
  padding-bottom: 5rem !important;
}

.pb-8 {
  padding-bottom: 6rem !important;
}

.pb-9 {
  padding-bottom: 7rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.ps-6 {
  padding-left: 4rem !important;
}

.ps-7 {
  padding-left: 5rem !important;
}

.ps-8 {
  padding-left: 6rem !important;
}

.ps-9 {
  padding-left: 7rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.gap-6 {
  gap: 4rem !important;
}

.gap-7 {
  gap: 5rem !important;
}

.gap-8 {
  gap: 6rem !important;
}

.gap-9 {
  gap: 7rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: 0.25rem !important;
}

.row-gap-2 {
  row-gap: 0.5rem !important;
}

.row-gap-3 {
  row-gap: 1rem !important;
}

.row-gap-4 {
  row-gap: 1.5rem !important;
}

.row-gap-5 {
  row-gap: 3rem !important;
}

.row-gap-6 {
  row-gap: 4rem !important;
}

.row-gap-7 {
  row-gap: 5rem !important;
}

.row-gap-8 {
  row-gap: 6rem !important;
}

.row-gap-9 {
  row-gap: 7rem !important;
}

.column-gap-0 {
  column-gap: 0 !important;
}

.column-gap-1 {
  column-gap: 0.25rem !important;
}

.column-gap-2 {
  column-gap: 0.5rem !important;
}

.column-gap-3 {
  column-gap: 1rem !important;
}

.column-gap-4 {
  column-gap: 1.5rem !important;
}

.column-gap-5 {
  column-gap: 3rem !important;
}

.column-gap-6 {
  column-gap: 4rem !important;
}

.column-gap-7 {
  column-gap: 5rem !important;
}

.column-gap-8 {
  column-gap: 6rem !important;
}

.column-gap-9 {
  column-gap: 7rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(2.85rem + 3vw) !important;
}

.fs-2 {
  font-size: calc(2.1rem + 1.59375vw) !important;
}

.fs-3 {
  font-size: calc(1.75rem + 0.9375vw) !important;
}

.fs-4 {
  font-size: calc(1.65rem + 0.75vw) !important;
}

.fs-5 {
  font-size: calc(1.5rem + 0.46875vw) !important;
}

.fs-6 {
  font-size: calc(1.3rem + 0.09375vw) !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.4 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important;
}

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis) !important;
}

.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis) !important;
}

.text-success-emphasis {
  color: var(--bs-success-text-emphasis) !important;
}

.text-info-emphasis {
  color: var(--bs-info-text-emphasis) !important;
}

.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis) !important;
}

.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis) !important;
}

.text-light-emphasis {
  color: var(--bs-light-text-emphasis) !important;
}

.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis) !important;
}

.link-opacity-10 {
  --bs-link-opacity: 0.1;
}

.link-opacity-10-hover:hover {
  --bs-link-opacity: 0.1;
}

.link-opacity-25 {
  --bs-link-opacity: 0.25;
}

.link-opacity-25-hover:hover {
  --bs-link-opacity: 0.25;
}

.link-opacity-50 {
  --bs-link-opacity: 0.5;
}

.link-opacity-50-hover:hover {
  --bs-link-opacity: 0.5;
}

.link-opacity-75 {
  --bs-link-opacity: 0.75;
}

.link-opacity-75-hover:hover {
  --bs-link-opacity: 0.75;
}

.link-opacity-100 {
  --bs-link-opacity: 1;
}

.link-opacity-100-hover:hover {
  --bs-link-opacity: 1;
}

.link-offset-1 {
  text-underline-offset: 0.125em !important;
}

.link-offset-1-hover:hover {
  text-underline-offset: 0.125em !important;
}

.link-offset-2 {
  text-underline-offset: 0.25em !important;
}

.link-offset-2-hover:hover {
  text-underline-offset: 0.25em !important;
}

.link-offset-3 {
  text-underline-offset: 0.375em !important;
}

.link-offset-3-hover:hover {
  text-underline-offset: 0.375em !important;
}

.link-underline-primary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-success {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-info {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-warning {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-danger {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-underline-opacity-0 {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-10 {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-25 {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-50 {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-75 {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-100 {
  --bs-link-underline-opacity: 1;
}

.link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .object-fit-sm-contain {
    object-fit: contain !important;
  }
  .object-fit-sm-cover {
    object-fit: cover !important;
  }
  .object-fit-sm-fill {
    object-fit: fill !important;
  }
  .object-fit-sm-scale {
    object-fit: scale-down !important;
  }
  .object-fit-sm-none {
    object-fit: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-inline-grid {
    display: inline-grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-6 {
    margin: 4rem !important;
  }
  .m-sm-7 {
    margin: 5rem !important;
  }
  .m-sm-8 {
    margin: 6rem !important;
  }
  .m-sm-9 {
    margin: 7rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-6 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-sm-7 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-sm-8 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-sm-9 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-6 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-sm-7 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-sm-8 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-sm-9 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-6 {
    margin-top: 4rem !important;
  }
  .mt-sm-7 {
    margin-top: 5rem !important;
  }
  .mt-sm-8 {
    margin-top: 6rem !important;
  }
  .mt-sm-9 {
    margin-top: 7rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-6 {
    margin-right: 4rem !important;
  }
  .me-sm-7 {
    margin-right: 5rem !important;
  }
  .me-sm-8 {
    margin-right: 6rem !important;
  }
  .me-sm-9 {
    margin-right: 7rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-6 {
    margin-bottom: 4rem !important;
  }
  .mb-sm-7 {
    margin-bottom: 5rem !important;
  }
  .mb-sm-8 {
    margin-bottom: 6rem !important;
  }
  .mb-sm-9 {
    margin-bottom: 7rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-6 {
    margin-left: 4rem !important;
  }
  .ms-sm-7 {
    margin-left: 5rem !important;
  }
  .ms-sm-8 {
    margin-left: 6rem !important;
  }
  .ms-sm-9 {
    margin-left: 7rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .p-sm-6 {
    padding: 4rem !important;
  }
  .p-sm-7 {
    padding: 5rem !important;
  }
  .p-sm-8 {
    padding: 6rem !important;
  }
  .p-sm-9 {
    padding: 7rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-sm-6 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-sm-7 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-sm-8 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-sm-9 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-sm-6 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-sm-7 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-sm-8 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-sm-9 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pt-sm-6 {
    padding-top: 4rem !important;
  }
  .pt-sm-7 {
    padding-top: 5rem !important;
  }
  .pt-sm-8 {
    padding-top: 6rem !important;
  }
  .pt-sm-9 {
    padding-top: 7rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pe-sm-6 {
    padding-right: 4rem !important;
  }
  .pe-sm-7 {
    padding-right: 5rem !important;
  }
  .pe-sm-8 {
    padding-right: 6rem !important;
  }
  .pe-sm-9 {
    padding-right: 7rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pb-sm-6 {
    padding-bottom: 4rem !important;
  }
  .pb-sm-7 {
    padding-bottom: 5rem !important;
  }
  .pb-sm-8 {
    padding-bottom: 6rem !important;
  }
  .pb-sm-9 {
    padding-bottom: 7rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .ps-sm-6 {
    padding-left: 4rem !important;
  }
  .ps-sm-7 {
    padding-left: 5rem !important;
  }
  .ps-sm-8 {
    padding-left: 6rem !important;
  }
  .ps-sm-9 {
    padding-left: 7rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .gap-sm-6 {
    gap: 4rem !important;
  }
  .gap-sm-7 {
    gap: 5rem !important;
  }
  .gap-sm-8 {
    gap: 6rem !important;
  }
  .gap-sm-9 {
    gap: 7rem !important;
  }
  .row-gap-sm-0 {
    row-gap: 0 !important;
  }
  .row-gap-sm-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-sm-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-sm-3 {
    row-gap: 1rem !important;
  }
  .row-gap-sm-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-sm-5 {
    row-gap: 3rem !important;
  }
  .row-gap-sm-6 {
    row-gap: 4rem !important;
  }
  .row-gap-sm-7 {
    row-gap: 5rem !important;
  }
  .row-gap-sm-8 {
    row-gap: 6rem !important;
  }
  .row-gap-sm-9 {
    row-gap: 7rem !important;
  }
  .column-gap-sm-0 {
    column-gap: 0 !important;
  }
  .column-gap-sm-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-sm-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-sm-3 {
    column-gap: 1rem !important;
  }
  .column-gap-sm-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-sm-5 {
    column-gap: 3rem !important;
  }
  .column-gap-sm-6 {
    column-gap: 4rem !important;
  }
  .column-gap-sm-7 {
    column-gap: 5rem !important;
  }
  .column-gap-sm-8 {
    column-gap: 6rem !important;
  }
  .column-gap-sm-9 {
    column-gap: 7rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .object-fit-md-contain {
    object-fit: contain !important;
  }
  .object-fit-md-cover {
    object-fit: cover !important;
  }
  .object-fit-md-fill {
    object-fit: fill !important;
  }
  .object-fit-md-scale {
    object-fit: scale-down !important;
  }
  .object-fit-md-none {
    object-fit: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-inline-grid {
    display: inline-grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-6 {
    margin: 4rem !important;
  }
  .m-md-7 {
    margin: 5rem !important;
  }
  .m-md-8 {
    margin: 6rem !important;
  }
  .m-md-9 {
    margin: 7rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-6 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-md-7 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-md-8 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-md-9 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-6 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-md-7 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-md-8 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-md-9 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-6 {
    margin-top: 4rem !important;
  }
  .mt-md-7 {
    margin-top: 5rem !important;
  }
  .mt-md-8 {
    margin-top: 6rem !important;
  }
  .mt-md-9 {
    margin-top: 7rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-6 {
    margin-right: 4rem !important;
  }
  .me-md-7 {
    margin-right: 5rem !important;
  }
  .me-md-8 {
    margin-right: 6rem !important;
  }
  .me-md-9 {
    margin-right: 7rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-6 {
    margin-bottom: 4rem !important;
  }
  .mb-md-7 {
    margin-bottom: 5rem !important;
  }
  .mb-md-8 {
    margin-bottom: 6rem !important;
  }
  .mb-md-9 {
    margin-bottom: 7rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-6 {
    margin-left: 4rem !important;
  }
  .ms-md-7 {
    margin-left: 5rem !important;
  }
  .ms-md-8 {
    margin-left: 6rem !important;
  }
  .ms-md-9 {
    margin-left: 7rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .p-md-6 {
    padding: 4rem !important;
  }
  .p-md-7 {
    padding: 5rem !important;
  }
  .p-md-8 {
    padding: 6rem !important;
  }
  .p-md-9 {
    padding: 7rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-md-6 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-md-7 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-md-8 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-md-9 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-md-6 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-md-7 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-md-8 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-md-9 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pt-md-6 {
    padding-top: 4rem !important;
  }
  .pt-md-7 {
    padding-top: 5rem !important;
  }
  .pt-md-8 {
    padding-top: 6rem !important;
  }
  .pt-md-9 {
    padding-top: 7rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pe-md-6 {
    padding-right: 4rem !important;
  }
  .pe-md-7 {
    padding-right: 5rem !important;
  }
  .pe-md-8 {
    padding-right: 6rem !important;
  }
  .pe-md-9 {
    padding-right: 7rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .pb-md-6 {
    padding-bottom: 4rem !important;
  }
  .pb-md-7 {
    padding-bottom: 5rem !important;
  }
  .pb-md-8 {
    padding-bottom: 6rem !important;
  }
  .pb-md-9 {
    padding-bottom: 7rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .ps-md-6 {
    padding-left: 4rem !important;
  }
  .ps-md-7 {
    padding-left: 5rem !important;
  }
  .ps-md-8 {
    padding-left: 6rem !important;
  }
  .ps-md-9 {
    padding-left: 7rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .gap-md-6 {
    gap: 4rem !important;
  }
  .gap-md-7 {
    gap: 5rem !important;
  }
  .gap-md-8 {
    gap: 6rem !important;
  }
  .gap-md-9 {
    gap: 7rem !important;
  }
  .row-gap-md-0 {
    row-gap: 0 !important;
  }
  .row-gap-md-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-md-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-md-3 {
    row-gap: 1rem !important;
  }
  .row-gap-md-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-md-5 {
    row-gap: 3rem !important;
  }
  .row-gap-md-6 {
    row-gap: 4rem !important;
  }
  .row-gap-md-7 {
    row-gap: 5rem !important;
  }
  .row-gap-md-8 {
    row-gap: 6rem !important;
  }
  .row-gap-md-9 {
    row-gap: 7rem !important;
  }
  .column-gap-md-0 {
    column-gap: 0 !important;
  }
  .column-gap-md-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-md-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-md-3 {
    column-gap: 1rem !important;
  }
  .column-gap-md-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-md-5 {
    column-gap: 3rem !important;
  }
  .column-gap-md-6 {
    column-gap: 4rem !important;
  }
  .column-gap-md-7 {
    column-gap: 5rem !important;
  }
  .column-gap-md-8 {
    column-gap: 6rem !important;
  }
  .column-gap-md-9 {
    column-gap: 7rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .object-fit-lg-contain {
    object-fit: contain !important;
  }
  .object-fit-lg-cover {
    object-fit: cover !important;
  }
  .object-fit-lg-fill {
    object-fit: fill !important;
  }
  .object-fit-lg-scale {
    object-fit: scale-down !important;
  }
  .object-fit-lg-none {
    object-fit: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-inline-grid {
    display: inline-grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-6 {
    margin: 4rem !important;
  }
  .m-lg-7 {
    margin: 5rem !important;
  }
  .m-lg-8 {
    margin: 6rem !important;
  }
  .m-lg-9 {
    margin: 7rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-6 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-lg-7 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-lg-8 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-lg-9 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-6 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-lg-7 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-lg-8 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-lg-9 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-6 {
    margin-top: 4rem !important;
  }
  .mt-lg-7 {
    margin-top: 5rem !important;
  }
  .mt-lg-8 {
    margin-top: 6rem !important;
  }
  .mt-lg-9 {
    margin-top: 7rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-6 {
    margin-right: 4rem !important;
  }
  .me-lg-7 {
    margin-right: 5rem !important;
  }
  .me-lg-8 {
    margin-right: 6rem !important;
  }
  .me-lg-9 {
    margin-right: 7rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-6 {
    margin-bottom: 4rem !important;
  }
  .mb-lg-7 {
    margin-bottom: 5rem !important;
  }
  .mb-lg-8 {
    margin-bottom: 6rem !important;
  }
  .mb-lg-9 {
    margin-bottom: 7rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-6 {
    margin-left: 4rem !important;
  }
  .ms-lg-7 {
    margin-left: 5rem !important;
  }
  .ms-lg-8 {
    margin-left: 6rem !important;
  }
  .ms-lg-9 {
    margin-left: 7rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .p-lg-6 {
    padding: 4rem !important;
  }
  .p-lg-7 {
    padding: 5rem !important;
  }
  .p-lg-8 {
    padding: 6rem !important;
  }
  .p-lg-9 {
    padding: 7rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-lg-6 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-lg-7 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-lg-8 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-lg-9 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-lg-6 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-lg-7 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-lg-8 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-lg-9 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pt-lg-6 {
    padding-top: 4rem !important;
  }
  .pt-lg-7 {
    padding-top: 5rem !important;
  }
  .pt-lg-8 {
    padding-top: 6rem !important;
  }
  .pt-lg-9 {
    padding-top: 7rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pe-lg-6 {
    padding-right: 4rem !important;
  }
  .pe-lg-7 {
    padding-right: 5rem !important;
  }
  .pe-lg-8 {
    padding-right: 6rem !important;
  }
  .pe-lg-9 {
    padding-right: 7rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pb-lg-6 {
    padding-bottom: 4rem !important;
  }
  .pb-lg-7 {
    padding-bottom: 5rem !important;
  }
  .pb-lg-8 {
    padding-bottom: 6rem !important;
  }
  .pb-lg-9 {
    padding-bottom: 7rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .ps-lg-6 {
    padding-left: 4rem !important;
  }
  .ps-lg-7 {
    padding-left: 5rem !important;
  }
  .ps-lg-8 {
    padding-left: 6rem !important;
  }
  .ps-lg-9 {
    padding-left: 7rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .gap-lg-6 {
    gap: 4rem !important;
  }
  .gap-lg-7 {
    gap: 5rem !important;
  }
  .gap-lg-8 {
    gap: 6rem !important;
  }
  .gap-lg-9 {
    gap: 7rem !important;
  }
  .row-gap-lg-0 {
    row-gap: 0 !important;
  }
  .row-gap-lg-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-lg-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-lg-3 {
    row-gap: 1rem !important;
  }
  .row-gap-lg-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-lg-5 {
    row-gap: 3rem !important;
  }
  .row-gap-lg-6 {
    row-gap: 4rem !important;
  }
  .row-gap-lg-7 {
    row-gap: 5rem !important;
  }
  .row-gap-lg-8 {
    row-gap: 6rem !important;
  }
  .row-gap-lg-9 {
    row-gap: 7rem !important;
  }
  .column-gap-lg-0 {
    column-gap: 0 !important;
  }
  .column-gap-lg-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-lg-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-lg-3 {
    column-gap: 1rem !important;
  }
  .column-gap-lg-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-lg-5 {
    column-gap: 3rem !important;
  }
  .column-gap-lg-6 {
    column-gap: 4rem !important;
  }
  .column-gap-lg-7 {
    column-gap: 5rem !important;
  }
  .column-gap-lg-8 {
    column-gap: 6rem !important;
  }
  .column-gap-lg-9 {
    column-gap: 7rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .object-fit-xl-contain {
    object-fit: contain !important;
  }
  .object-fit-xl-cover {
    object-fit: cover !important;
  }
  .object-fit-xl-fill {
    object-fit: fill !important;
  }
  .object-fit-xl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xl-none {
    object-fit: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-inline-grid {
    display: inline-grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-6 {
    margin: 4rem !important;
  }
  .m-xl-7 {
    margin: 5rem !important;
  }
  .m-xl-8 {
    margin: 6rem !important;
  }
  .m-xl-9 {
    margin: 7rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-6 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-xl-7 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-xl-8 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-xl-9 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-6 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-xl-7 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-xl-8 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-xl-9 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-6 {
    margin-top: 4rem !important;
  }
  .mt-xl-7 {
    margin-top: 5rem !important;
  }
  .mt-xl-8 {
    margin-top: 6rem !important;
  }
  .mt-xl-9 {
    margin-top: 7rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-6 {
    margin-right: 4rem !important;
  }
  .me-xl-7 {
    margin-right: 5rem !important;
  }
  .me-xl-8 {
    margin-right: 6rem !important;
  }
  .me-xl-9 {
    margin-right: 7rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-6 {
    margin-bottom: 4rem !important;
  }
  .mb-xl-7 {
    margin-bottom: 5rem !important;
  }
  .mb-xl-8 {
    margin-bottom: 6rem !important;
  }
  .mb-xl-9 {
    margin-bottom: 7rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-6 {
    margin-left: 4rem !important;
  }
  .ms-xl-7 {
    margin-left: 5rem !important;
  }
  .ms-xl-8 {
    margin-left: 6rem !important;
  }
  .ms-xl-9 {
    margin-left: 7rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .p-xl-6 {
    padding: 4rem !important;
  }
  .p-xl-7 {
    padding: 5rem !important;
  }
  .p-xl-8 {
    padding: 6rem !important;
  }
  .p-xl-9 {
    padding: 7rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-xl-6 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-xl-7 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-xl-8 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-xl-9 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-xl-6 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-xl-7 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-xl-8 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-xl-9 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pt-xl-6 {
    padding-top: 4rem !important;
  }
  .pt-xl-7 {
    padding-top: 5rem !important;
  }
  .pt-xl-8 {
    padding-top: 6rem !important;
  }
  .pt-xl-9 {
    padding-top: 7rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pe-xl-6 {
    padding-right: 4rem !important;
  }
  .pe-xl-7 {
    padding-right: 5rem !important;
  }
  .pe-xl-8 {
    padding-right: 6rem !important;
  }
  .pe-xl-9 {
    padding-right: 7rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pb-xl-6 {
    padding-bottom: 4rem !important;
  }
  .pb-xl-7 {
    padding-bottom: 5rem !important;
  }
  .pb-xl-8 {
    padding-bottom: 6rem !important;
  }
  .pb-xl-9 {
    padding-bottom: 7rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .ps-xl-6 {
    padding-left: 4rem !important;
  }
  .ps-xl-7 {
    padding-left: 5rem !important;
  }
  .ps-xl-8 {
    padding-left: 6rem !important;
  }
  .ps-xl-9 {
    padding-left: 7rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .gap-xl-6 {
    gap: 4rem !important;
  }
  .gap-xl-7 {
    gap: 5rem !important;
  }
  .gap-xl-8 {
    gap: 6rem !important;
  }
  .gap-xl-9 {
    gap: 7rem !important;
  }
  .row-gap-xl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xl-5 {
    row-gap: 3rem !important;
  }
  .row-gap-xl-6 {
    row-gap: 4rem !important;
  }
  .row-gap-xl-7 {
    row-gap: 5rem !important;
  }
  .row-gap-xl-8 {
    row-gap: 6rem !important;
  }
  .row-gap-xl-9 {
    row-gap: 7rem !important;
  }
  .column-gap-xl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xl-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xl-3 {
    column-gap: 1rem !important;
  }
  .column-gap-xl-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xl-5 {
    column-gap: 3rem !important;
  }
  .column-gap-xl-6 {
    column-gap: 4rem !important;
  }
  .column-gap-xl-7 {
    column-gap: 5rem !important;
  }
  .column-gap-xl-8 {
    column-gap: 6rem !important;
  }
  .column-gap-xl-9 {
    column-gap: 7rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .object-fit-xxl-contain {
    object-fit: contain !important;
  }
  .object-fit-xxl-cover {
    object-fit: cover !important;
  }
  .object-fit-xxl-fill {
    object-fit: fill !important;
  }
  .object-fit-xxl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xxl-none {
    object-fit: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-inline-grid {
    display: inline-grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-6 {
    margin: 4rem !important;
  }
  .m-xxl-7 {
    margin: 5rem !important;
  }
  .m-xxl-8 {
    margin: 6rem !important;
  }
  .m-xxl-9 {
    margin: 7rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-6 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-xxl-7 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-xxl-8 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-xxl-9 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-6 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-xxl-7 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-xxl-8 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-xxl-9 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-6 {
    margin-top: 4rem !important;
  }
  .mt-xxl-7 {
    margin-top: 5rem !important;
  }
  .mt-xxl-8 {
    margin-top: 6rem !important;
  }
  .mt-xxl-9 {
    margin-top: 7rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-6 {
    margin-right: 4rem !important;
  }
  .me-xxl-7 {
    margin-right: 5rem !important;
  }
  .me-xxl-8 {
    margin-right: 6rem !important;
  }
  .me-xxl-9 {
    margin-right: 7rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-6 {
    margin-bottom: 4rem !important;
  }
  .mb-xxl-7 {
    margin-bottom: 5rem !important;
  }
  .mb-xxl-8 {
    margin-bottom: 6rem !important;
  }
  .mb-xxl-9 {
    margin-bottom: 7rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-6 {
    margin-left: 4rem !important;
  }
  .ms-xxl-7 {
    margin-left: 5rem !important;
  }
  .ms-xxl-8 {
    margin-left: 6rem !important;
  }
  .ms-xxl-9 {
    margin-left: 7rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .p-xxl-6 {
    padding: 4rem !important;
  }
  .p-xxl-7 {
    padding: 5rem !important;
  }
  .p-xxl-8 {
    padding: 6rem !important;
  }
  .p-xxl-9 {
    padding: 7rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-xxl-6 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-xxl-7 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-xxl-8 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-xxl-9 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-xxl-6 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-xxl-7 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-xxl-8 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-xxl-9 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pt-xxl-6 {
    padding-top: 4rem !important;
  }
  .pt-xxl-7 {
    padding-top: 5rem !important;
  }
  .pt-xxl-8 {
    padding-top: 6rem !important;
  }
  .pt-xxl-9 {
    padding-top: 7rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pe-xxl-6 {
    padding-right: 4rem !important;
  }
  .pe-xxl-7 {
    padding-right: 5rem !important;
  }
  .pe-xxl-8 {
    padding-right: 6rem !important;
  }
  .pe-xxl-9 {
    padding-right: 7rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .pb-xxl-6 {
    padding-bottom: 4rem !important;
  }
  .pb-xxl-7 {
    padding-bottom: 5rem !important;
  }
  .pb-xxl-8 {
    padding-bottom: 6rem !important;
  }
  .pb-xxl-9 {
    padding-bottom: 7rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .ps-xxl-6 {
    padding-left: 4rem !important;
  }
  .ps-xxl-7 {
    padding-left: 5rem !important;
  }
  .ps-xxl-8 {
    padding-left: 6rem !important;
  }
  .ps-xxl-9 {
    padding-left: 7rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .gap-xxl-6 {
    gap: 4rem !important;
  }
  .gap-xxl-7 {
    gap: 5rem !important;
  }
  .gap-xxl-8 {
    gap: 6rem !important;
  }
  .gap-xxl-9 {
    gap: 7rem !important;
  }
  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xxl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xxl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xxl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xxl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xxl-5 {
    row-gap: 3rem !important;
  }
  .row-gap-xxl-6 {
    row-gap: 4rem !important;
  }
  .row-gap-xxl-7 {
    row-gap: 5rem !important;
  }
  .row-gap-xxl-8 {
    row-gap: 6rem !important;
  }
  .row-gap-xxl-9 {
    row-gap: 7rem !important;
  }
  .column-gap-xxl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xxl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xxl-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xxl-3 {
    column-gap: 1rem !important;
  }
  .column-gap-xxl-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xxl-5 {
    column-gap: 3rem !important;
  }
  .column-gap-xxl-6 {
    column-gap: 4rem !important;
  }
  .column-gap-xxl-7 {
    column-gap: 5rem !important;
  }
  .column-gap-xxl-8 {
    column-gap: 6rem !important;
  }
  .column-gap-xxl-9 {
    column-gap: 7rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1280px) {
  .fs-1 {
    font-size: 5.25rem !important;
  }
  .fs-2 {
    font-size: 3.375rem !important;
  }
  .fs-3 {
    font-size: 2.5rem !important;
  }
  .fs-4 {
    font-size: 2.25rem !important;
  }
  .fs-5 {
    font-size: 1.875rem !important;
  }
  .fs-6 {
    font-size: 1.375rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-inline-grid {
    display: inline-grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
.container .container {
  padding-left: 0;
  padding-right: 0;
}

@media (max-width: 767.98px) {
  .visually-hidden-on-mobile {
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
  }
  .visually-hidden-on-mobile:not(caption) {
    position: absolute !important;
  }
}

@media (min-width: 576px) {
  .position-sm-absolute {
    position: absolute !important;
  }
  .position-sm-static {
    position: static !important;
  }
}
@media (min-width: 768px) {
  .position-md-absolute {
    position: absolute !important;
  }
  .position-md-static {
    position: static !important;
  }
}
@media (min-width: 992px) {
  .position-lg-absolute {
    position: absolute !important;
  }
  .position-lg-static {
    position: static !important;
  }
}
@media (min-width: 1200px) {
  .position-xl-absolute {
    position: absolute !important;
  }
  .position-xl-static {
    position: static !important;
  }
}
h1, .h1 {
  margin-bottom: 21px;
}
@media (max-width: 575.98px) {
  h1, .h1 {
    font-size: 44px;
  }
}

h2, .h2 {
  color: #001a3d;
  letter-spacing: -0.01em;
  margin-bottom: 11px;
}
@media (max-width: 575.98px) {
  h2, .h2 {
    font-size: 40px;
  }
}

h3, .h3 {
  font-weight: 500;
}
@media (max-width: 575.98px) {
  h3, .h3 {
    font-size: 32px;
  }
}

h4, .h4 {
  color: #6a6964;
}
@media (max-width: 575.98px) {
  h4, .h4 {
    font-size: 28px;
  }
}

h5, .h5 {
  color: #001a3d;
  margin-bottom: 5px;
}
@media (max-width: 575.98px) {
  h5, .h5 {
    font-size: 24px;
  }
}

h6, .h6 {
  font-weight: 700;
  letter-spacing: 0.02em;
  margin-bottom: 8px;
}
@media (max-width: 575.98px) {
  h6, .h6 {
    font-size: 18px;
  }
}

p + h2, ul + h2, ol + h2, p + .h2, ul + .h2, ol + .h2,
p + h3,
ul + h3,
ol + h3, p + .h3, ul + .h3, ol + .h3,
p + h4,
ul + h4,
ol + h4, p + .h4, ul + .h4, ol + .h4,
p + h5,
ul + h5,
ol + h5, p + .h5, ul + .h5, ol + .h5,
p + h6,
ul + h6,
ol + h6, p + .h6, ul + .h6, ol + .h6 {
  margin-top: 42px;
}
h2 .subtitle, .h2 .subtitle,
h3 .subtitle, .h3 .subtitle,
h4 .subtitle, .h4 .subtitle,
h5 .subtitle, .h5 .subtitle,
h6 .subtitle, .h6 .subtitle {
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.05em;
  color: #19519a;
  display: block;
}

.title--bottom-divider {
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 36px;
}
.title--bottom-divider:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 130px;
  height: 4px;
  background-color: #e36113;
}
.title--bottom-divider.title--center:after {
  left: 50%;
  transform: translateX(-50%);
}
.title--bottom-divider.animate-in-view:after {
  width: 0;
  transition: width 1.75s ease-out;
}
.title--bottom-divider.animate-in-view--intersecting:after {
  width: 130px;
}
.title--compact {
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.05em;
  color: #114594;
}
.title--center {
  text-align: center;
}

.tab-heading {
  font-family: futura-pt, sans-serif;
  font-size: 32px;
  font-weight: 500;
  color: #114594;
}

.text--intro {
  line-height: 1.46;
  color: #002f6d;
  font-weight: 400;
  font-size: 22px;
}
@media (min-width: 992px) {
  .text--intro {
    font-size: 26px;
  }
}
.text--subhead {
  font-style: italic;
  font-size: 20px;
  line-height: 1.5;
}
@media (min-width: 992px) {
  .text--subhead {
    font-size: 30px;
  }
}
.title--bottom-divider + .text--subhead {
  margin-top: -24px;
}

p {
  letter-spacing: 0.02em;
}

.body-content ul > li {
  list-style: none;
}
.body-content ul > li:before {
  content: "•";
  color: #f4955c;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  position: absolute;
  line-height: 1.6;
  font-size: 13.5px;
  margin-left: -21px;
}
@media (min-width: 768px) {
  .body-content ul > li:before {
    font-size: 17px;
    margin-left: -24px;
  }
}
.body-content ul > li ul, .body-content ul > li ol {
  margin-top: 0.5rem;
}
.body-content ul ol > li:before {
  top: 0;
  line-height: 1.45;
}
@media (min-width: 768px) {
  .body-content ul ol > li:before {
    line-height: 1.5;
    font-size: 20px;
  }
}
.body-content ul, .body-content ol {
  margin-bottom: 1.4rem;
  padding-left: 33px;
}
@media (min-width: 768px) {
  .body-content ul, .body-content ol {
    padding-left: 52px;
  }
}
.body-content ul > li, .body-content ol > li {
  line-height: 1.5;
}
.body-content ul > li:not(:last-child), .body-content ol > li:not(:last-child) {
  margin-bottom: 17px;
}
@media (max-width: 767.98px) {
  .body-content ul ul, .body-content ol ul {
    padding-left: 21px;
  }
}
@media (max-width: 767.98px) {
  .body-content ul ol, .body-content ol ol {
    padding-left: 35px;
  }
}
.body-content ol {
  counter-reset: step-counter;
  list-style: none;
}
.body-content ol > li {
  counter-increment: step-counter;
  position: relative;
}
.body-content ol > li:before {
  content: counters(step-counter, ".") ". ";
  color: #002f6d;
  font-weight: 700;
  display: inline-block;
  text-align: right;
  width: 2em;
  position: absolute;
  top: 0;
  font-size: 16px;
  left: -41px;
}
@media (min-width: 768px) {
  .body-content ol > li:before {
    left: -49px;
    font-size: 20px;
  }
}
.body-content ol ol, .body-content ol ul {
  margin-top: 0.5rem;
}
.body-content ol ul {
  counter-reset: step-counter;
}
.body-content ol ol ol > li:before {
  left: -53px;
}
.body-content ol ol ol ol > li:before {
  left: -68px;
}

.list--linked {
  margin-bottom: 40px;
  padding-left: 33px;
}
.list--linked > li {
  list-style: none;
  line-height: 1.5;
}
.list--linked > li:before {
  position: absolute;
  display: inline-block;
  width: 1em;
  line-height: 1.6;
  margin-left: -21px;
}
@media (min-width: 768px) {
  .list--linked > li:before {
    margin-left: -24px;
  }
}
.list--linked > li:not(:last-child) {
  margin-bottom: 17px;
}
.list--linked a {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-decoration-color: #1464de;
  text-underline-offset: 4px;
  transition: color 0.35s ease, text-decoration-color 0.35s ease;
}
.list--linked a:hover, .list--linked a:focus {
  text-decoration-color: transparent;
}
@media (min-width: 768px) {
  .list--linked, .body-content .list--linked {
    padding-left: 59px;
  }
}
.list--linked li, .body-content .list--linked li {
  position: relative;
}
.list--linked li:before, .body-content .list--linked li:before {
  content: "\f0a9";
  font-family: "Font Awesome 6 Pro";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-weight: 900;
  font-size: 16px;
  color: #f0bf20;
  top: 5px;
}
@media (min-width: 768px) {
  .list--linked li:before, .body-content .list--linked li:before {
    top: 8px;
  }
}
.list--icon-with-text {
  list-style: none;
}
.list--icon-with-text, .body-content .list--icon-with-text {
  padding-left: 0;
  margin-bottom: 21px;
}
.list--icon-with-text li {
  position: relative;
  padding-left: 28px;
}
@media (min-width: 768px) {
  .list--icon-with-text li {
    padding-left: 41px;
  }
}
.list--icon-with-text li::before {
  display: none;
}
.list--icon-with-text li:not(:last-child), .body-content .list--icon-with-text li:not(:last-child) {
  margin-bottom: 6px;
}
.list--icon-with-text [class*=fa] {
  position: absolute;
  margin-right: 10px;
  width: 15px;
  text-align: center;
  color: #464540;
  font-size: 14px;
  left: 0;
  top: 4px;
}
@media (min-width: 768px) {
  .list--icon-with-text [class*=fa] {
    top: 6px;
    margin-right: 15px;
    width: 20px;
    font-size: 18px;
  }
}
.list--icon-with-text a {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-decoration-color: #1464de;
  text-underline-offset: 4px;
  transition: color 0.35s ease, text-decoration-color 0.35s ease;
}
.list--icon-with-text a:hover, .list--icon-with-text a:focus {
  text-decoration-color: transparent;
}

hr.border-2 {
  margin: 40px 0;
}
hr.border-small-top {
  margin-top: 16px;
}

.table {
  margin-bottom: 50px;
}
.table th {
  background-color: #002f6d;
  color: #ffffff;
  font-family: futura-pt, sans-serif;
  line-height: 1.3;
  font-size: 18px;
}
@media (min-width: 992px) {
  .table th {
    font-size: 22px;
  }
}
.table td {
  line-height: 1.3;
}
.table-responsive {
  margin-bottom: 50px;
}
@media (max-width: 575.98px) {
  .table-responsive {
    margin-right: -12px;
  }
}
.table-responsive .table {
  min-width: 550px;
  margin-bottom: 0;
}

.back-link {
  margin-bottom: 20px;
}
.back-link a {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.05em;
  text-decoration: none;
  color: white;
}
.back-link a:before {
  content: "\f053";
  font-size: 12px;
  padding-right: 10px;
  color: #a6c7f4;
  font-family: "Font Awesome 6 Pro";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-weight: 900;
  transition: transform 0.35s ease;
}
.back-link a:hover, .back-link a:focus {
  text-decoration: underline;
}
.back-link a:hover:before, .back-link a:focus:before {
  transform: translateX(-5px);
}

.blockquote {
  margin-bottom: 30px;
  position: relative;
  border-color: #e1e0db;
  border-style: solid;
  border-width: 1px 0;
  padding: 26px 5px 24px 39px;
}
@media (min-width: 992px) {
  .blockquote {
    padding: 56px 30px 56px 113px;
  }
}
.blockquote a {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-decoration-color: #1464de;
  text-underline-offset: 4px;
  transition: color 0.35s ease, text-decoration-color 0.35s ease;
}
.blockquote a:hover, .blockquote a:focus {
  text-decoration-color: transparent;
}
.blockquote p {
  font-family: lato, sans-serif;
  color: #001a3d;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.6;
}
@media (min-width: 992px) {
  .blockquote p {
    font-size: 26px;
    line-height: 1.46;
  }
}
.blockquote p:first-of-type::before {
  content: url("../images/icon-quotation-mark.svg");
  position: absolute;
  left: 0;
  top: 24px;
  width: 26px;
  height: 18px;
}
@media (min-width: 992px) {
  .blockquote p:first-of-type::before {
    left: 48px;
    top: 62px;
    width: 38px;
    height: 27px;
  }
}
.blockquote p:last-of-type::after {
  content: '"';
}
.blockquote-figure {
  margin-bottom: 30px;
  padding-bottom: 12px;
  border-bottom: 1px solid #e1e0db;
}
@media (min-width: 992px) {
  .blockquote-figure {
    padding-bottom: 40px;
  }
}
.blockquote-figure a {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-decoration-color: #1464de;
  text-underline-offset: 4px;
  transition: color 0.35s ease, text-decoration-color 0.35s ease;
}
.blockquote-figure a:hover, .blockquote-figure a:focus {
  text-decoration-color: transparent;
}
.blockquote-figure .blockquote {
  margin-bottom: 0;
  border-bottom: 0 none;
  padding-bottom: 32px;
}
@media (min-width: 992px) {
  .blockquote-figure .blockquote {
    padding-bottom: 37px;
  }
}
.blockquote-figure .blockquote-footer {
  position: relative;
  padding-left: 88px;
  font-size: 18px;
}
@media (min-width: 992px) {
  .blockquote-figure .blockquote-footer {
    font-size: 24px;
    padding-left: 170px;
  }
}
.blockquote-figure .blockquote-footer::before {
  content: "";
  width: 41px;
  height: 5px;
  background-color: #f27930;
  position: absolute;
  top: 12px;
  left: 38px;
}
@media (min-width: 992px) {
  .blockquote-figure .blockquote-footer::before {
    top: 16px;
    left: 113px;
  }
}
.blockquote-figure small, .blockquote-figure .small {
  font-size: 12px;
  font-style: normal;
}
@media (min-width: 992px) {
  .blockquote-figure small, .blockquote-figure .small {
    font-size: 18px;
  }
}
@media (max-width: 575.98px) {
  .blockquote-figure small, .blockquote-figure .small {
    display: block;
    margin-top: 8px;
  }
}

.form-check .form-check-label {
  font-size: 18px;
  color: #13120e;
}
@media (min-width: 992px) {
  .form-check .form-check-label {
    font-size: 20px;
  }
}
.form-check .form-check-inline {
  margin-right: 30px;
}
.form-check .form-check-input {
  margin-top: 4px;
}
.form-check .form-check-input:checked {
  background-color: #1464de;
  border-color: #1464de;
}
.form-check--round .form-check-input {
  border-radius: 100px;
  width: 20px;
  height: 20px;
}
@media (min-width: 992px) {
  .form-check--round .form-check-input {
    width: 25px;
    height: 25px;
  }
}
.form-check--white .form-check-label {
  color: #ffffff;
}

.form-control,
.form-select {
  font-size: 18px;
  color: #13120e;
}
@media (min-width: 992px) {
  .form-control,
  .form-select {
    font-size: 20px;
  }
  .form-control--large,
  .form-select--large {
    font-size: 24px;
    height: 60px;
  }
}

.form-control,
.form-select {
  line-height: 1.3;
  padding: 8px;
  box-shadow: inset 2px 2px 5px -2px rgba(0, 0, 0, 0.2);
}
@media (min-width: 768px) {
  .form-control,
  .form-select {
    padding: 9px 11px;
  }
}

.form-label {
  font-size: 18px;
  font-weight: 700;
}

.form-label.required:after {
  content: " (required)";
  font-size: 0.8em;
  font-style: italic;
}

input::placeholder {
  color: #6a6964;
  font-style: italic;
}

.input-group--dates .visually-hidden:first-child + input {
  margin-left: 0 !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}
.input-group--dates .input-group-text {
  border: 0 none;
  background-color: transparent;
}

.multiselect-dropdown {
  position: relative;
}
.multiselect-dropdown a {
  text-decoration: none;
  transition: background-color 0.35s ease;
}
.multiselect-dropdown ul {
  padding: 0;
  margin: 0;
}
.multiselect-dropdown ul, .multiselect-dropdown li {
  display: block;
}
.multiselect-dropdown__panel {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  padding: 4px 13px 0 13px;
  background: white;
  border: 2px solid #e1e0db;
  border-top: none;
  z-index: 3;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.multiselect-dropdown__checkbox {
  padding: 8px;
  border-bottom: 1px solid #bcbbb6;
}
.multiselect-dropdown--open > a {
  background-color: #e1e0db;
  border-color: #bcbbb6;
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.multiselect-dropdown__close {
  text-align: right;
}
.multiselect-dropdown__close a {
  display: inline-block;
  font-family: futura-pt, sans-serif;
  font-weight: 500;
  font-size: 16px;
  margin: 8px 0;
  color: #13120e;
}
.multiselect-dropdown__close a:after {
  content: " " "\f0d8";
  font-family: "Font Awesome 6 Pro";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-weight: 900;
  color: #19519a;
  position: relative;
  top: 2px;
}

input[type=file] {
  position: relative;
  height: 48px;
  padding-top: 0;
  padding-bottom: 0;
}

input[type=file]::file-selector-button {
  height: 52px;
  background-color: #f7f7f5;
  color: #13120e;
  transition: background-color 0.35s ease;
}

input[type=file]:hover::file-selector-button {
  background-color: #e1e0db;
}

.figure {
  display: table;
  margin-bottom: 50px;
  border-left: 4px solid #e36113;
}
.figure--full-width img {
  width: 100%;
}
.figure.float-end, .figure.float-start {
  margin-top: 10px;
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .figure.float-end, .figure.float-start {
    max-width: 45%;
  }
}
@media (max-width: 767.98px) {
  .figure.float-end, .figure.float-start {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .figure.float-end {
    margin-left: 1.5em;
    margin-right: -65px;
  }
}
@media (min-width: 992px) {
  .figure.float-end {
    margin-right: -160px;
  }
}
@media (min-width: 1200px) {
  .figure.float-end {
    margin-right: -191px;
  }
}
@media (min-width: 1400px) {
  .figure.float-end {
    margin-right: -220px;
  }
}
@media (min-width: 768px) {
  .figure.float-start {
    margin-right: 1.5em;
    margin-left: -65px;
  }
}
@media (min-width: 992px) {
  .figure.float-start {
    margin-left: -160px;
  }
}
@media (min-width: 1200px) {
  .figure.float-start {
    margin-left: -191px;
  }
}
@media (min-width: 1400px) {
  .figure.float-start {
    margin-left: -220px;
  }
}
.figure--circle {
  display: block;
  border-radius: 100%;
  overflow: hidden;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  position: relative;
  border: 0 none;
}
.figure--circle img {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  object-fit: cover;
  position: relative;
  z-index: 2;
  width: 100%;
  height: 100%;
}
.figure--half-border {
  position: relative;
  border: 0 none;
}
.figure--half-border::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 50%;
  z-index: 1;
  background-color: #e36113;
  opacity: 0;
  animation-delay: 0.75s;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
  animation-name: fade-in;
  animation-fill-mode: forwards;
}
.figure--half-border img {
  padding: 4px;
  border-radius: 50%;
}

.figure-img {
  margin-bottom: 0;
}

.figure-caption {
  display: table-caption;
  caption-side: bottom;
  font-style: italic;
  letter-spacing: 0.05em;
  color: #464540;
  border-left: 4px solid #bcbbb6;
  background-color: #f7f7f5;
  padding: 17px;
  font-size: 14px;
  line-height: 1.714;
}
@media (min-width: 992px) {
  .figure-caption {
    font-size: 16px;
    line-height: 1.625;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.gsc-control-cse,
.gsc-control-cse .gsc-table-result,
.gsc-control-cse .gsc-results {
  font-family: lato, sans-serif !important;
}
.gsc-control-cse .gsc-search-box .gsc-input-box {
  border-width: 2px;
  border-radius: 5px;
  line-height: 1.3;
  box-shadow: inset 2px 2px 5px -2px rgba(0, 0, 0, 0.2);
  padding: 8px;
}
@media (min-width: 768px) {
  .gsc-control-cse .gsc-search-box .gsc-input-box {
    padding: 9px 11px;
  }
}
.gsc-control-cse .gsc-search-box .gsc-input-box .gsc-input {
  color: #13120e;
  font-size: 18px;
}
@media (min-width: 992px) {
  .gsc-control-cse .gsc-search-box .gsc-input-box .gsc-input {
    font-size: 20px;
  }
}
.gsc-control-cse .gsc-search-box .gsc-search-button .gsc-search-button-v2 {
  height: 52px;
  width: 52px;
  border-radius: 50%;
  padding: 0;
  position: relative;
}
.gsc-control-cse .gsc-search-box .gsc-search-button .gsc-search-button-v2:before {
  content: "";
  background: linear-gradient(to left, #cb9900 0, #fade78 100%);
  position: absolute;
  border-radius: 1000px;
  top: -1px;
  right: -1px;
  bottom: -1px;
  width: 35px;
  opacity: 0;
  transition: opacity 0.3s ease, width 0.7s ease;
}
.gsc-control-cse .gsc-search-box .gsc-search-button .gsc-search-button-v2:hover:before, .gsc-control-cse .gsc-search-box .gsc-search-button .gsc-search-button-v2:focus:before {
  opacity: 1;
  width: calc(100% + 2px);
  transition: opacity 0.15s ease, width 0.35s ease;
}
.gsc-control-cse .gsc-search-box .gsc-search-button .gsc-search-button-v2 svg {
  position: relative;
  width: 20px;
  height: 20px;
}
.gsc-control-cse .gsc-results .gs-result .gs-title,
.gsc-control-cse .gsc-results .gs-result .gs-title * {
  font-size: 30px;
  font-family: futura-pt, sans-serif;
  font-weight: 500;
  letter-spacing: 0.03em;
}
.gsc-control-cse .gsc-results .gs-result .gs-title a.gs-title {
  display: block;
  padding-right: 43px;
  line-height: 1.17;
}
.gsc-control-cse .gsc-results .gs-result .gs-title a.gs-title:after {
  content: "\f0a9";
  font-size: 85%;
  color: #f0bf20;
  font-family: "Font Awesome 6 Pro";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-weight: 900;
  margin-left: 15px;
  line-height: 0;
  position: absolute;
  margin-top: 19px;
  transition: padding 0.35s ease;
}
.gsc-control-cse .gsc-results .gs-result .gs-title a.gs-title:hover:after, .gsc-control-cse .gsc-results .gs-result .gs-title a.gs-title:focus:after {
  padding-left: 16px;
}
.gsc-control-cse .gsc-webResult.gsc-result {
  border-bottom: 1px solid #e1e0db !important;
  padding-bottom: 30px;
  margin-bottom: 15px;
}
.gsc-control-cse .gsc-webResult.gsc-result .gsc-url-top {
  padding-bottom: 16px;
  font-size: 15px !important;
}
.gsc-control-cse .gsc-webResult.gsc-result .gsc-table-result {
  font-size: 16px !important;
}
@media (min-width: 992px) {
  .gsc-control-cse .gsc-webResult.gsc-result .gsc-table-result {
    font-size: 20px !important;
  }
}
.gsc-control-cse .gsc-cursor-box {
  font-size: 16px !important;
  font-family: futura-pt, sans-serif;
  font-weight: 500;
  display: flex;
  justify-content: center;
}
.gsc-control-cse .gsc-cursor-box .gsc-cursor-page {
  color: #1464de !important;
  margin-right: 11px;
}
.gsc-control-cse .gsc-cursor-box .gsc-cursor-page.gsc-cursor-current-page {
  color: #000000 !important;
  border-bottom: 4px solid #f27930;
}
.gsc-control-cse .gsc-selected-option-container {
  background-color: transparent;
  border-color: #bcbbb6;
  border-width: 2px;
  border-radius: 5px;
  box-shadow: inset 2px 2px 5px -2px rgba(0, 0, 0, 0.2);
  color: #13120e;
  height: 30px;
}

.embedded-video {
  margin-bottom: 50px;
}
@media (max-width: 575.98px) {
  .embedded-video {
    margin-left: -12px;
    margin-right: -12px;
  }
}
.embedded-video__wrapper {
  border-left: 4px solid #e36113;
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}
.embedded-video__wrapper object,
.embedded-video__wrapper embed,
.embedded-video__wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.embedded-video__caption {
  font-style: italic;
  letter-spacing: 0.05em;
  color: #464540;
  border-left: 4px solid #bcbbb6;
  background-color: #f7f7f5;
  padding: 17px;
  font-size: 14px;
  line-height: 1.714;
}
@media (min-width: 992px) {
  .embedded-video__caption {
    font-size: 16px;
    line-height: 1.625;
  }
}
.embedded-video__caption p {
  margin-bottom: 0;
}
.embedded-video.float-end, .embedded-video.float-start {
  margin-top: 10px;
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .embedded-video.float-end, .embedded-video.float-start {
    max-width: 45%;
  }
}
@media (max-width: 767.98px) {
  .embedded-video.float-end, .embedded-video.float-start {
    float: none !important;
  }
}
.embedded-video.float-end .embedded-video__wrapper, .embedded-video.float-start .embedded-video__wrapper {
  padding-bottom: 71.2%;
}
@media (min-width: 768px) {
  .embedded-video.float-end {
    margin-left: 1.5em;
    margin-right: -65px;
  }
}
@media (min-width: 992px) {
  .embedded-video.float-end {
    margin-right: -160px;
  }
}
@media (min-width: 1200px) {
  .embedded-video.float-end {
    margin-right: -191px;
  }
}
@media (min-width: 1400px) {
  .embedded-video.float-end {
    margin-right: -220px;
  }
}
@media (min-width: 768px) {
  .embedded-video.float-start {
    margin-right: 1.5em;
    margin-left: -65px;
  }
}
@media (min-width: 992px) {
  .embedded-video.float-start {
    margin-left: -160px;
  }
}
@media (min-width: 1200px) {
  .embedded-video.float-start {
    margin-left: -191px;
  }
}
@media (min-width: 1400px) {
  .embedded-video.float-start {
    margin-left: -220px;
  }
}

.accordion--default .accordion-item {
  margin-bottom: 5px;
  border-radius: 5px;
}
.accordion--default .accordion-item:not(:first-of-type) {
  border-top: 2px solid #d8e6fa;
}
.accordion--default .accordion-button {
  font-family: futura-pt, sans-serif;
  font-weight: 500;
  padding-right: 45px;
  font-size: 20px;
  line-height: 1.2;
}
@media (max-width: 575.98px) {
  .accordion--default .accordion-button {
    padding-left: 16px;
  }
}
@media (min-width: 992px) {
  .accordion--default .accordion-button {
    font-size: 24px;
    line-height: 1.167;
    padding-right: 117px;
  }
}
.accordion--default .accordion-button::after {
  position: absolute;
  top: 14px;
  right: 10px;
}
@media (min-width: 992px) {
  .accordion--default .accordion-button::after {
    top: 15px;
    right: 17px;
  }
}
.accordion--default .accordion-button::before {
  content: "";
  width: 89px;
  height: 4px;
  background-color: #f27930;
  position: absolute;
  bottom: -4px;
  left: 22px;
  opacity: 0;
  transition: opacity 0.35s ease;
}
.accordion--default .accordion-button:not(.collapsed) {
  box-shadow: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.accordion--default .accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-icon);
}
.accordion--default .accordion-button:not(.collapsed)::before {
  opacity: 1;
}
.accordion--default .accordion-button:hover {
  background-color: #edf2f9;
}
.accordion--default .accordion-button:hover, .accordion--default .accordion-button:focus {
  color: #19519a;
}
.accordion--default .accordion-button:hover::after, .accordion--default .accordion-button:focus::after {
  background-image: var(--bs-accordion-btn-active-icon);
}
.accordion--default .accordion-button:focus-visible {
  box-shadow: var(--bs-box-shadow-inset), 0 0 0 0.25rem rgba(240, 191, 32, 0.25);
}
@media (min-width: 768px) {
  .accordion--default .accordion-body {
    overflow: auto;
  }
}
.accordion--default .accordion-body p {
  line-height: 1.75;
}
@media (min-width: 992px) {
  .accordion--default .accordion-body p {
    line-height: 1.6;
  }
}
.accordion--default .accordion-body p:last-child {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .accordion--default .accordion-body .figure.float-end {
    margin-right: 0;
  }
}
@media (min-width: 768px) {
  .accordion--default .accordion-body .figure.float-start {
    margin-left: 0;
  }
}
.accordion--flyout-menu {
  position: relative;
}
.accordion--flyout-menu .accordion-item {
  background-color: #ffffff;
  margin-bottom: 0;
  border: 0 none;
  border-bottom: 1px solid #e1e0db;
}
.accordion--flyout-menu .accordion-item:first-child {
  border-top: 1px solid #e1e0db;
}
.accordion--flyout-menu .accordion-item--info-for {
  margin-top: 20px;
  background-color: #032455;
  border-bottom: 0 none;
}
@media (min-width: 768px) {
  .accordion--flyout-menu .accordion-item--info-for {
    border-radius: 5px;
  }
}
@media (max-width: 767.98px) {
  .accordion--flyout-menu .accordion-item--info-for {
    margin-left: -1px;
    margin-right: -1px;
  }
}
.accordion--flyout-menu .accordion-item--info-for .accordion-header {
  background-color: #4377bd;
}
@media (min-width: 768px) {
  .accordion--flyout-menu .accordion-item--info-for .accordion-header {
    border-radius: 5px;
  }
}
.accordion--flyout-menu .accordion-item--info-for .accordion-button {
  width: 100%;
  position: static;
  font-weight: 400;
  background-color: #4377bd;
  color: #ffffff;
  letter-spacing: 0.07em;
  padding: 17px 0 17px 25px;
}
@media (max-width: 991.98px) {
  .accordion--flyout-menu .accordion-item--info-for .accordion-button {
    padding-left: 17px;
  }
}
@media (min-width: 768px) {
  .accordion--flyout-menu .accordion-item--info-for .accordion-button {
    border-radius: 5px !important;
  }
  .accordion--flyout-menu .accordion-item--info-for .accordion-button[aria-expanded=true] {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}
.accordion--flyout-menu .accordion-item--info-for .accordion-button:after {
  color: #ffffff;
  margin-left: auto;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.accordion--flyout-menu .accordion-item--info-for .accordion-button:hover, .accordion--flyout-menu .accordion-item--info-for .accordion-button:focus {
  background-color: #19519a;
  color: #ffffff;
}
.accordion--flyout-menu .accordion-item--info-for .accordion-button:hover:after, .accordion--flyout-menu .accordion-item--info-for .accordion-button:focus:after {
  color: #ffffff;
}
.accordion--flyout-menu .accordion-item--info-for .accordion-body {
  padding-top: 15px;
}
.accordion--flyout-menu .accordion-item--info-for .accordion-body ul {
  padding-left: 32px;
}
@media (min-width: 992px) {
  .accordion--flyout-menu .accordion-item--info-for .accordion-body ul {
    padding-left: 58px;
  }
}
.accordion--flyout-menu .accordion-item--info-for .accordion-body a {
  color: #ffffff;
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-decoration-color: #ffffff;
  text-underline-offset: 4px;
  transition: color 0.35s ease, text-decoration-color 0.35s ease;
  transition: padding 0.35s ease, text-decoration-color 0.35s ease;
}
.accordion--flyout-menu .accordion-item--info-for .accordion-body a:hover, .accordion--flyout-menu .accordion-item--info-for .accordion-body a:focus {
  text-decoration-color: transparent;
}
.accordion--flyout-menu .accordion-item--info-for .accordion-body a:hover, .accordion--flyout-menu .accordion-item--info-for .accordion-body a:focus {
  color: #ffffff;
}
.accordion--flyout-menu ul {
  list-style: none;
}
.accordion--flyout-menu ul li:not(:first-child) {
  margin-top: 12px;
}
.accordion--flyout-menu .accordion-header {
  display: flex;
  position: relative;
}
.accordion--flyout-menu .accordion-link {
  margin-right: 60px;
  padding: 17px 10px 17px 25px;
  color: #13120e;
  text-decoration: none;
  font-weight: 500;
  letter-spacing: 0.04em;
  background-color: #ffffff;
  font-family: futura-pt, sans-serif;
  font-size: 22px;
  width: 100%;
}
@media (max-width: 991.98px) {
  .accordion--flyout-menu .accordion-link {
    padding-left: 17px;
  }
}
.accordion--flyout-menu .accordion-link > span {
  position: relative;
}
.accordion--flyout-menu .accordion-link > span:after {
  content: "";
  position: absolute;
  height: 2px;
  left: 0;
  bottom: -2px;
  width: 0;
  background-color: #001a3d;
  transition: width 0.5s ease;
}
.accordion--flyout-menu .accordion-link:hover {
  color: #1464de;
}
.accordion--flyout-menu .accordion-item--nav-active .accordion-link {
  color: #13120e !important;
  font-weight: 600;
}
.accordion--flyout-menu .accordion-item--nav-active .accordion-link > span:after {
  content: none;
}
.accordion--flyout-menu .accordion-button {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 60px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: none;
}
.accordion--flyout-menu .accordion-button:after {
  content: "\f078";
  font-family: "Font Awesome 6 Pro";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-weight: 400;
  background-image: none;
  color: #1464de;
  margin-left: 0;
}
.accordion--flyout-menu .accordion-button:hover {
  background-color: #f7f7f5;
  color: #19519a;
}
.accordion--flyout-menu .accordion-button:hover:after {
  color: #001a3d;
}
.accordion--flyout-menu .accordion-button:focus {
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}
.accordion--flyout-menu .accordion-button[aria-expanded=true] + .accordion-link {
  color: #002f6d;
}
.accordion--flyout-menu .accordion-button[aria-expanded=true] + .accordion-link > span:after {
  width: 100%;
  transition: width 0.5s ease;
}
.accordion--flyout-menu .accordion-button[aria-expanded=true] + .accordion-link:hover, .accordion--flyout-menu .accordion-button[aria-expanded=true] + .accordion-link:focus {
  color: #1464de;
}
.accordion--flyout-menu .accordion-button[aria-expanded=true] + .accordion-link:hover > span:after, .accordion--flyout-menu .accordion-button[aria-expanded=true] + .accordion-link:focus > span:after {
  width: 0;
}
.accordion--flyout-menu .accordion-body {
  padding: 10px 0 5px;
}
@media (max-width: 991.98px) {
  .accordion--flyout-menu .accordion-body {
    padding-left: 17px;
    padding-right: 17px;
  }
}
.accordion--flyout-menu .accordion-body ul {
  padding-left: 32px;
}
@media (min-width: 992px) {
  .accordion--flyout-menu .accordion-body ul {
    padding-left: 58px;
  }
}
.accordion--flyout-menu .accordion-body a {
  color: #002f6d;
  display: block;
  line-height: 1.3;
  font-weight: 400;
  font-family: futura-pt, sans-serif;
  font-size: 19px;
  letter-spacing: 0.05em;
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-decoration-color: #002f6d;
  text-underline-offset: 4px;
  transition: color 0.35s ease, text-decoration-color 0.35s ease;
  transition: padding 0.35s ease, text-decoration-color 0.35s ease;
}
.accordion--flyout-menu .accordion-body a:hover, .accordion--flyout-menu .accordion-body a:focus {
  text-decoration-color: transparent;
}
.accordion--flyout-menu .accordion-body a:after {
  content: "\f061";
  font-family: "Font Awesome 6 Pro";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-weight: 400;
  font-size: 14px;
  color: #4377bd;
  opacity: 0;
  line-height: 1.9;
  position: absolute;
  transition: opacity 0.35s ease, margin 0.35s ease;
}
.accordion--flyout-menu .accordion-body a:hover, .accordion--flyout-menu .accordion-body a:focus {
  color: #1464de;
  padding-left: 11px;
}
.accordion--flyout-menu .accordion-body a:hover:after, .accordion--flyout-menu .accordion-body a:focus:after {
  opacity: 1;
  margin-left: 10px;
}
.accordion--flyout-menu .accordion-body .active-nav a {
  font-weight: 600;
  text-decoration: none;
  color: #13120e !important;
}
.accordion--flyout-menu .accordion-body .active-nav a:hover, .accordion--flyout-menu .accordion-body .active-nav a:focus {
  color: #13120e !important;
  padding-left: 0;
}
.accordion--flyout-menu .accordion-body .active-nav a:hover:after, .accordion--flyout-menu .accordion-body .active-nav a:focus:after {
  display: none;
}
.accordion--tabs .accordion-item {
  border: none;
  border-bottom: 1px solid #e1e0db;
  border-radius: 0 !important;
}
.accordion--tabs .accordion-item .accordion-button {
  border-radius: 0 !important;
}
@media (min-width: 768px) {
  .accordion--tabs .accordion-body {
    overflow: auto;
  }
}
@media (min-width: 768px) {
  .accordion--tabs .accordion-body .figure.float-end {
    margin-right: 0;
  }
}
@media (min-width: 768px) {
  .accordion--tabs .accordion-body .figure.float-start {
    margin-left: 0;
  }
}
.accordion--tabs .accordion-button {
  background: #f7f7f5;
  border-top: 5px solid transparent;
  padding: 20px 18px;
  font-family: futura-pt, sans-serif;
  font-weight: 500;
  font-size: 20px;
  text-transform: uppercase;
  color: #1464de;
  position: relative;
}
.accordion--tabs .accordion-button:after {
  display: block;
  font-weight: 700;
  padding-left: 40px;
  padding-right: 40px;
  margin-right: -20px;
  background-position: center;
}
.accordion--tabs .accordion-button:not(.collapsed) {
  background: #ffffff;
  border-top: 5px solid #e36113;
  box-shadow: none;
  color: #114594;
}
.accordion--tabs .accordion-button:not(.collapsed):before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 18px;
  right: 18px;
  height: 1px;
  background-color: #e1e0db;
}

@media (min-width: 992px) {
  .button-set {
    display: flex;
    align-items: center;
  }
}
.button-set .tertiary-button,
.button-set .btn {
  margin-bottom: 10px;
}
@media (min-width: 992px) {
  .button-set .tertiary-button + .btn,
  .button-set .btn + .btn {
    margin-left: 15px;
  }
  .button-set .tertiary-button + .tertiary-button,
  .button-set .btn + .tertiary-button {
    margin-left: 25px;
  }
}
@media (max-width: 991.98px) {
  .button-set .tertiary-button + .tertiary-button,
  .button-set .btn + .tertiary-button {
    margin-top: 10px;
  }
}

.button-matrix__panel {
  position: relative;
  border: 2px solid #bcbbb6;
  padding: 20px;
  margin-bottom: 20px;
}
.button-matrix__panel:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(45deg, #f7f7f5 25%, transparent 25%), linear-gradient(135deg, #f7f7f5 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #f7f7f5 75%), linear-gradient(135deg, transparent 75%, #f7f7f5 75%), linear-gradient(to bottom, #e1e0db 0, #e1e0db 100%);
  background-size: 20px 20px; /* Must be a square */
  background-position: 0 0, 10px 0, 10px -10px, 0px 10px; /* Must be half of one side of the square */
  opacity: 0.5;
  z-index: -1;
}
.button-matrix__wrap--horizontal {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.button-matrix__wrap--horizontal .button-matrix__button {
  margin-right: 15px;
}
@media (min-width: 992px) {
  .button-matrix__wrap--horizontal .button-matrix__button {
    margin-right: 20px;
  }
}
.button-matrix__wrap--horizontal .button-matrix__button:last-child {
  margin-right: 0;
}
.button-matrix__wrap .button-matrix__button {
  margin-bottom: 15px;
}
@media (min-width: 992px) {
  .button-matrix__wrap .button-matrix__button {
    margin-bottom: 20px;
  }
}
.button-matrix__wrap .button-matrix__button:last-child {
  margin-bottom: 0;
}

.btn {
  font-size: 18px;
  line-height: 22px;
  padding: 8px 35px;
  font-family: futura-pt, sans-serif;
  font-weight: 500;
  color: black;
}
.btn > [class*=fa] {
  line-height: 22px;
}
.btn:focus-visible {
  outline: -webkit-focus-ring-color auto 1px;
  outline-offset: 1px;
}
.btn-white-text {
  color: #ffffff !important;
}
.btn-sm {
  font-size: 16px;
  line-height: 22px;
  padding: 5px 16px;
}
.btn-sm > [class*=fa] {
  line-height: 22px;
}
@media (min-width: 768px) {
  .btn {
    font-size: 24px;
    line-height: 34px;
    padding: 5px 35px;
  }
  .btn > [class*=fa] {
    line-height: 34px;
  }
  .btn-sm {
    font-size: 16px;
    line-height: 22px;
    padding: 5px 16px;
  }
  .btn-sm > [class*=fa] {
    line-height: 22px;
  }
}
.btn-sm {
  font-weight: 400;
}
.btn > [class*=fa] {
  position: relative;
  font-size: 19px;
}
.btn-sm > [class*=fa] {
  font-size: 16px;
}
.btn > span + [class*=fa] {
  padding-left: 5px;
}
.btn [class*=fa]:first-child {
  padding-right: 6px;
}
.btn--white-text {
  color: #ffffff;
}
.btn--circle-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 42px;
  height: 42px;
}
.btn--circle-icon [class*=fa] {
  display: block;
  line-height: 0;
  letter-spacing: 0;
  padding: 0 !important;
  margin: 0;
  font-size: 22px;
}
@media (min-width: 768px) {
  .btn--circle-icon {
    width: 48px;
    height: 48px;
  }
}
.btn-sm.btn--circle-icon {
  width: 36px;
  height: 36px;
}
.btn--fullwidth {
  display: block;
}
.btn-primary {
  position: relative;
}
.btn-primary > i:first-child,
.btn-primary > span {
  position: relative;
}
.btn-primary:before {
  content: "";
  background: linear-gradient(to left, #cb9900 0, #fade78 100%);
  position: absolute;
  border-radius: 1000px;
  top: -2px;
  right: -2px;
  bottom: -2px;
  width: 50px;
  opacity: 0;
  transition: opacity 0.3s ease, width 0.7s ease;
}
.btn-primary.btn-sm:before {
  width: 35px;
}
.btn-primary:hover {
  outline-width: 10px;
}
.btn-primary:hover:before {
  opacity: 1;
  width: calc(100% + 4px);
  transition: opacity 0.15s ease, width 0.35s ease;
}
.btn-blue {
  background: #1464de;
  color: white;
}
.btn-blue:hover {
  background: #19519a;
  color: white;
}
.btn-dark {
  background: #6a6964;
  border-color: #6a6964;
  color: white;
}
.btn-dark:hover {
  background: #13120e;
  border-color: #13120e;
}
.btn-darker {
  background: #31302d;
  border-color: #31302d;
  color: white;
}
.btn-darker:hover {
  background: #151513;
  border-color: #151513;
  color: white;
}
.btn-utility {
  background: transparent;
  border-color: #f0bf20;
  font-size: 17px;
  font-weight: 500;
  text-transform: uppercase;
}
.btn-utility:hover {
  background: #f0bf20;
}
.btn-utility-link {
  color: #1464de;
  background: transparent;
  border-color: transparent;
  font-size: 18px;
  font-weight: 500;
  padding-left: 5px;
  padding-right: 5px;
}
.btn-utility-link:hover {
  color: #1464de;
}
@media (min-width: 768px) {
  .btn-utility-link {
    font-size: 20px;
  }
}
.btn-utility-link > span {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-decoration-color: transparent;
  text-underline-offset: 4px;
  transition: color 0.35s ease, text-decoration-color 0.35s ease;
}
.btn-utility-link:hover > span {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-decoration-color: #1464de;
  text-underline-offset: 4px;
  transition: color 0.35s ease, text-decoration-color 0.35s ease;
}
.btn-link {
  text-decoration: none;
  color: #1464de;
}
.btn-link:hover {
  color: #1464de;
}
.btn-link > span {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-decoration-color: transparent;
  text-underline-offset: 4px;
  transition: color 0.35s ease, text-decoration-color 0.35s ease;
}
.btn-link:hover > span {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-decoration-color: #1464de;
  text-underline-offset: 4px;
  transition: color 0.35s ease, text-decoration-color 0.35s ease;
}
.btn-alert-link {
  text-transform: uppercase;
  color: #c20101;
  padding-left: 5px;
  padding-right: 5px;
}
.btn-alert-link:hover {
  color: #c20101;
}
@media (min-width: 768px) {
  .btn-alert-link {
    font-size: 20px;
  }
}
.btn-alert-link > span {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-decoration-color: transparent;
  text-underline-offset: 4px;
  transition: color 0.35s ease, text-decoration-color 0.35s ease;
}
.btn-alert-link:hover > span {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-decoration-color: #c20101;
  text-underline-offset: 4px;
  transition: color 0.35s ease, text-decoration-color 0.35s ease;
}
.btn-alert-link:before {
  content: "!";
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: futura-pt, sans-serif;
  font-weight: 700;
  font-size: 13px;
  color: white;
  width: 18px;
  height: 18px;
  position: relative;
  top: -2px;
  margin-right: 6px;
  background: #c20101;
  border-radius: 100%;
  text-shadow: none;
}
.btn[class*=btn-outline] {
  font-weight: 400;
}
.btn-header {
  background: rgba(0, 0, 0, 0.3);
  color: white;
  text-transform: uppercase;
  font-size: 17px;
  line-height: 24px;
  padding: 7px 30px;
}
.btn-header:hover, .btn-header:focus {
  background: #f0bf20;
  color: black;
}
@media (min-width: 992px) {
  .btn-header {
    padding: 4px 15px;
  }
}
@media (min-width: 1200px) {
  .btn-header {
    padding: 7px 20px;
  }
}
.btn-menu {
  background: #1464de;
  color: white;
  text-transform: uppercase;
  font-size: 19px;
  line-height: 26px;
  padding: 3px 14px;
  display: flex;
  align-items: center;
}
.btn-menu:hover, .btn-menu:focus {
  background: #19519a;
  color: white;
}
@media (min-width: 768px) {
  .btn-menu {
    padding: 5px 30px;
  }
}
@media (min-width: 992px) {
  .btn-menu {
    padding: 3px 15px;
  }
}
@media (min-width: 1200px) {
  .btn-menu {
    padding: 5px 30px;
  }
}
.btn-menu:before {
  content: url("../images/icon-search-menu.svg");
  width: 32px;
  height: 22px;
  margin-top: 2px;
  margin-right: -4px;
}
@media (min-width: 768px) {
  .btn-menu:before {
    margin-right: 7px;
  }
}
.btn--animate-slide [class*=fa] {
  transition: transform 0.35s ease;
}
.btn--animate-slide:hover [class*=fa], .btn--animate-slide:focus [class*=fa] {
  transform: translateX(12px);
}
.btn--animate-slide.btn-sm:hover [class*=fa], .btn--animate-slide.btn-sm:focus [class*=fa] {
  transform: translateX(6px);
}
@keyframes button_bounce {
  0% {
    transform: translateY(0);
  }
  33% {
    transform: translateY(-3px);
  }
  66% {
    transform: translateY(2px);
  }
  100% {
    transform: translateY(0);
  }
}
.btn--animate-bounce:hover [class*=fa], .btn--animate-bounce:focus [class*=fa] {
  animation-duration: 0.6s;
  animation-name: button_bounce;
}
.btn--toggle .btn__icon--expand {
  display: inline-block;
}
.btn--toggle .btn__icon--collapse {
  display: none;
}
.btn--toggle[aria-expanded=true] .btn__icon--expand {
  display: none;
}
.btn--toggle[aria-expanded=true] .btn__icon--collapse {
  display: inline-block;
}

.tertiary-button a {
  font-family: futura-pt, sans-serif;
  font-size: inherit;
  font-weight: 500;
  letter-spacing: 0.03em;
  text-decoration: underline;
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-decoration-color: transparent;
  text-underline-offset: 4px;
  transition: color 0.35s ease, text-decoration-color 0.35s ease;
}
.tertiary-button a:hover, .tertiary-button a:focus {
  text-decoration-color: inherit;
}
.tertiary-button a:after {
  content: "\f0a9";
  font-size: 85%;
  color: #f0bf20;
  font-family: "Font Awesome 6 Pro";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-weight: 900;
  margin-left: 15px;
  line-height: 0;
}
.tertiary-button--has-shadow a {
  text-shadow: 2px 2px rgba(0, 0, 0, 0.3);
}
.tertiary-button--light-blue a {
  color: #1464de;
}
.tertiary-button--light-blue a:hover, .tertiary-button--light-blue a:focus {
  color: #1464de;
}
.tertiary-button--grey a {
  color: #e1e0db;
}
.tertiary-button--grey a:after {
  color: #f0bf20;
}
.tertiary-button--grey a:hover, .tertiary-button--grey a:focus {
  color: white;
}
.tertiary-button--dark-grey a {
  color: #101113;
}
.tertiary-button--dark-grey a:after {
  color: #f0bf20;
}
.tertiary-button--white a {
  color: white;
}
.tertiary-button--white a:after {
  color: #f0bf20;
}
.tertiary-button--white a:hover, .tertiary-button--white a:focus {
  color: white;
}
.tertiary-button--dark-blue a {
  color: #114594;
}
.tertiary-button--dark-blue a:after {
  color: #e36113;
}
.tertiary-button--dark-blue a:hover, .tertiary-button--dark-blue a:focus {
  color: #114594;
}
@keyframes tertiary_link_bounce {
  0% {
    transform: translateY(0);
  }
  33% {
    transform: translateY(-3px);
  }
  66% {
    transform: translateY(2px);
  }
  100% {
    transform: translateY(0);
  }
}
.tertiary-button--anchor a:after {
  content: "\f0ab";
}
.tertiary-button--anchor a:hover:after {
  animation-duration: 0.6s;
  animation-name: tertiary_link_bounce;
}
.tertiary-button:not(.tertiary-button--anchor) a:after {
  margin-left: 12px;
  transition: transform 0.35s ease;
  will-change: transform;
}
.tertiary-button:not(.tertiary-button--anchor) a:hover:after,
.tertiary-button:not(.tertiary-button--anchor) a:focus:after {
  transform: translateX(12px);
}

.card--image {
  display: flex;
  align-items: stretch;
  height: calc(100% - 24px);
  margin-bottom: 24px;
}
.card--image > a {
  text-decoration: none;
  border-bottom: 5px solid #f7f7f5;
  transition: border-bottom-color 0.35s ease;
}
.card--image .card__image {
  height: 260px;
  position: relative;
  overflow: hidden;
}
.card--image .card__background-image {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-position: center;
  background-size: cover;
  transition: transform 1.25s ease;
  overflow: hidden;
}
.card--image .card__background-image:before {
  content: "";
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: background-color 0.35s ease, opacity 0.35s ease;
}
.card--image .card__background-image:after {
  content: "";
  background: rgba(0, 0, 0, 0);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: background-color 0.35s ease, opacity 0.35s ease;
}
.card--image:hover .card__background-image, .card--image:focus .card__background-image {
  transform: scale(1.1);
}
.card--image:hover .card__background-image:after {
  content: "";
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: background-color 0.35s ease, opacity 0.35s ease;
}
.card--image .card__overlay-heading {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: flex-end;
  text-align: center;
  padding: 36px 36px 62px 32px;
}
.card--image .card__overlay-heading h2, .card--image .card__overlay-heading .h2, .card--image .card__overlay-heading h3, .card--image .card__overlay-heading .h3 {
  transform: translateY(50%);
  font-family: futura-pt, sans-serif;
  font-size: 24px;
  font-weight: 500;
  color: white;
  text-transform: uppercase;
  line-height: 32px;
  z-index: 1;
  text-decoration: none;
}
.card--image .card__overlay-heading .card__overlay-heading-icon {
  white-space: nowrap;
}
.card--image .card__overlay-heading .card__overlay-heading-icon:after {
  content: "\f0a9";
  position: relative;
  margin-top: 5px;
  margin-left: 8px;
  font-family: "Font Awesome 6 Pro";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-weight: 900;
  transition: transform 0.35s ease;
  color: #f0bf20;
  font-size: 20px;
}
@media (min-width: 992px) {
  .card--image .card__overlay-heading .card__overlay-heading-icon:after {
    font-size: 22px;
  }
}
@media (min-width: 992px) {
  .card--image.card--large-title .card__overlay-heading h2, .card--image.card--large-title .card__overlay-heading .h2, .card--image.card--large-title h3, .card--image.card--large-title .h3 {
    font-size: 28px;
  }
}
.card--image .card-body {
  color: #13120e;
  font-size: 18px;
  padding: 26px 0;
}
.card--image .card-body p:last-child {
  margin-bottom: 0;
}
.card--image > a:hover, .card--image > a:focus {
  border-bottom-color: #e36113;
}
.card--image > a:hover .card__overlay-heading h2:after, .card--image > a:hover .card__overlay-heading .h2:after, .card--image > a:hover .card__overlay-heading h3:after, .card--image > a:hover .card__overlay-heading .h3:after, .card--image > a:focus .card__overlay-heading h2:after, .card--image > a:focus .card__overlay-heading .h2:after, .card--image > a:focus .card__overlay-heading h3:after, .card--image > a:focus .card__overlay-heading .h3:after {
  transform: translateX(12px);
}
.card--contact {
  margin-bottom: 10px;
  background-color: #f7f7f5;
  padding: 17px;
}
@media (min-width: 768px) {
  .card--contact {
    padding: 30px;
  }
}
.card--contact .card-header {
  position: relative;
  padding-bottom: 18px;
  margin-bottom: 22px;
}
.card--contact .card-header h2, .card--contact .card-header .h2,
.card--contact .card-header h3,
.card--contact .card-header .h3 {
  color: #13120e;
  font-size: 26px;
  line-height: 1.231;
  margin-bottom: 2px;
}
@media (min-width: 768px) {
  .card--contact .card-header h2, .card--contact .card-header .h2,
  .card--contact .card-header h3,
  .card--contact .card-header .h3 {
    font-size: 28px;
    line-height: 1.214;
  }
}
.card--contact .card-header p {
  font-style: italic;
  color: #464540;
  margin-bottom: 0;
}
@media (max-width: 767.98px) {
  .card--contact .card-header p {
    font-size: 18px;
  }
}
.card--contact .card-header::after {
  content: "";
  width: 89px;
  height: 4px;
  background-color: #f27930;
  position: absolute;
  left: 0;
  bottom: 0;
}
.card--contact .card-body > *:last-child {
  margin-bottom: 5px;
}
.card--contact .card-body a {
  overflow-wrap: break-word;
}
.card--contact.animate-in-view .card-header::after {
  width: 0;
  transition: width 1.75s ease-out;
}
.card--contact.animate-in-view--intersecting .card-header::after {
  width: 89px;
}
.card--contact.card--contact-directory {
  border-radius: 5px;
  height: 100%;
}
@media (max-width: 767.98px) {
  .card--contact.card--contact-directory {
    padding: 30px;
  }
}
.card--contact.card--contact-directory h3, .card--contact.card--contact-directory .h3 {
  font-size: 30px;
  margin-bottom: 16px;
}
.card--contact.card--contact-directory p {
  font-style: normal;
  margin-bottom: 5px;
}
.card--contact.card--contact-directory .card-header::after {
  content: none;
}
.card--contact.card--contact-directory .card-header {
  border-bottom: 1px solid #bcbbb6;
}
.card--contact.card--contact-directory .card-header a {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-decoration-color: #1464de;
  text-underline-offset: 4px;
  transition: color 0.35s ease, text-decoration-color 0.35s ease;
}
.card--contact.card--contact-directory .card-header a:hover, .card--contact.card--contact-directory .card-header a:focus {
  text-decoration-color: transparent;
}
.card--contact.card--contact-directory .card-header strong {
  font-weight: 600;
}
.card--row {
  margin-bottom: 0;
}
@media (min-width: 992px) {
  .card--row {
    padding: 30px 30px 20px;
    display: flex;
  }
  .card--row .card-header {
    width: 40%;
    margin-right: 10%;
  }
  .card--row .card-body {
    width: 50%;
  }
}
.card--row .card-header {
  margin-bottom: 0;
}
.card--row .card-header::after {
  display: none;
}
.card--row + .card--row {
  position: relative;
}
.card--row + .card--row::before {
  content: "";
  position: absolute;
  background-color: #e1e0db;
  height: 1px;
  top: 0;
  left: 17px;
  right: 17px;
}
@media (min-width: 768px) {
  .card--row + .card--row::before {
    left: 30px;
    right: 30px;
  }
}
.card--content {
  margin-bottom: 24px;
  height: calc(100% - 24px);
}
.card--content > a {
  height: 100%;
  padding: 30px;
  border-color: #e1e0db transparent;
  border-style: solid;
  border-width: 4px 1px 1px 1px;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  position: relative;
  transition: border 0.35s ease;
}
.card--content > a::before {
  content: "";
  background-color: #e1e0db;
  height: 4px;
  top: -4px;
  position: absolute;
  left: -1px;
  right: -1px;
  transition: background-color 0.35s ease;
}
.card--content > a:hover, .card--content > a:focus {
  border-color: #bcbbb6;
}
.card--content > a:hover::before, .card--content > a:focus::before {
  background-color: #e36113;
}
.card--content > a:hover .card-header h2, .card--content > a:hover .card-header .h2, .card--content > a:hover .card-header h3, .card--content > a:hover .card-header .h3, .card--content > a:focus .card-header h2, .card--content > a:focus .card-header .h2, .card--content > a:focus .card-header h3, .card--content > a:focus .card-header .h3 {
  color: #1464de;
}
.card--content > a:hover .card-header h2:after, .card--content > a:hover .card-header .h2:after, .card--content > a:hover .card-header h3:after, .card--content > a:hover .card-header .h3:after, .card--content > a:focus .card-header h2:after, .card--content > a:focus .card-header .h2:after, .card--content > a:focus .card-header h3:after, .card--content > a:focus .card-header .h3:after {
  transform: translateX(12px);
}
.card--content .card-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 24px;
  padding-bottom: 17px;
  margin-top: -30px;
  hyphens: auto;
}
@media (min-width: 992px) {
  .card--content .card-header {
    min-height: 109px;
  }
}
.card--content .card-header h2, .card--content .card-header .h2, .card--content .card-header h3, .card--content .card-header .h3 {
  letter-spacing: 0.05em;
  transition: color 0.35s ease;
  text-transform: uppercase;
  font-weight: 500;
  padding-right: 30px;
  font-size: 24px;
  line-height: 1.167;
}
@media (min-width: 992px) {
  .card--content .card-header h2, .card--content .card-header .h2, .card--content .card-header h3, .card--content .card-header .h3 {
    font-size: 28px;
    line-height: 1.143;
  }
}
.card--content .card-header h2:after, .card--content .card-header .h2:after, .card--content .card-header h3:after, .card--content .card-header .h3:after {
  content: "\f0a9";
  position: absolute;
  margin-top: 5px;
  margin-left: 8px;
  font-family: "Font Awesome 6 Pro";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-weight: 900;
  transition: transform 0.35s ease;
  color: #f0bf20;
  font-size: 20px;
}
@media (min-width: 992px) {
  .card--content .card-header h2:after, .card--content .card-header .h2:after, .card--content .card-header h3:after, .card--content .card-header .h3:after {
    font-size: 22px;
  }
}
.card--content .card-header h2 span, .card--content .card-header .h2 span, .card--content .card-header h3 span, .card--content .card-header .h3 span {
  hyphens: none;
}
.card--content .card-body > *:last-child {
  margin-bottom: 0;
}
.card--content .card-body {
  color: #13120e;
}
.card--content .card-body p {
  font-size: 18px;
  line-height: 1.334;
}
.card--blue {
  background: linear-gradient(to bottom, #002f6d 0%, #114594 100%);
}
.card--blue > a {
  border-color: #114594;
  border-width: 4px 0px 0px 0px;
  transition: border 0.35s ease, background-color 0.35s ease;
}
.card--blue > a::before {
  background-color: transparent;
  left: 0;
  right: 0;
}
.card--blue > a:hover, .card--blue > a:focus {
  background-color: #002f6d;
}
.card--blue > a:hover .card-header h2, .card--blue > a:hover .card-header .h2, .card--blue > a:hover .card-header h3, .card--blue > a:hover .card-header .h3, .card--blue > a:focus .card-header h2, .card--blue > a:focus .card-header .h2, .card--blue > a:focus .card-header h3, .card--blue > a:focus .card-header .h3 {
  color: #ffffff;
}
.card--blue .card-header h2, .card--blue .card-header .h2, .card--blue .card-header h3, .card--blue .card-header .h3 {
  color: #ffffff;
}
.card--blue .card-body {
  color: #ffffff;
}
.card--compact > a {
  padding: 26px;
}
.card--compact .card-header {
  margin-top: -26px;
}
@media (min-width: 992px) {
  .card--compact .card-header h2, .card--compact .card-header .h2, .card--compact .card-header h3, .card--compact .card-header .h3 {
    font-size: 24px;
    line-height: 1.167;
  }
}

.card--course abbr {
  text-decoration: none;
}
.card--course .card-body {
  display: flex;
  flex-direction: column;
  background-color: #f7f7f5;
  color: #262523;
  border-radius: 5px;
  padding: 15px 20px;
}
@media (min-width: 768px) {
  .card--course .card-body {
    padding-left: 28px;
    padding-right: 28px;
  }
}
.card--course .btn--toggle {
  padding-right: 0;
  position: relative;
  padding-left: 26px;
  margin-bottom: 15px;
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-decoration-color: #1464de;
  text-underline-offset: 4px;
  transition: color 0.35s ease, text-decoration-color 0.35s ease;
}
.card--course .btn--toggle:hover, .card--course .btn--toggle:focus {
  text-decoration-color: transparent;
}
.card--course .btn--toggle [class*=fa] {
  font-size: 14px;
  padding-right: 11px;
  position: absolute;
  margin-right: 10px;
  width: 15px;
  text-align: center;
  left: 0;
  top: 4px;
}
@media (min-width: 768px) {
  .card--course .btn--toggle [class*=fa] {
    top: 6px;
    margin-right: 15px;
    width: 28px;
  }
}
.card--course .list--icon-with-text {
  margin-bottom: 0;
}
.card--course .list--icon-with-text li {
  font-size: 16px;
  padding-left: 28px;
}
.card--course .list--icon-with-text [class*=fa-] {
  font-size: 14px;
  color: #464540;
}
.card--course .card__time, .card--course .card__days {
  font-size: 20px;
  line-height: 1.2;
  color: #13120e;
  font-family: futura-pt, sans-serif;
  font-weight: 500;
}
@media (min-width: 992px) {
  .filter-band--list ~ .filter-band__results .card--course .card__days {
    position: relative;
    margin-left: 30px;
  }
  .filter-band--list ~ .filter-band__results .card--course .card__days:before {
    content: "";
    position: absolute;
    height: 20px;
    width: 1px;
    top: 3px;
    left: -15px;
    background-color: #13120e;
  }
}
.card--course .card__location {
  position: relative;
  padding-left: 26px;
  font-size: 16px;
  font-style: italic;
}
.card--course .card__location [class*=fa] {
  color: #464540;
  font-size: 14px;
  padding-right: 11px;
  position: absolute;
  margin-right: 10px;
  width: 15px;
  text-align: center;
  left: 0;
  top: 4px;
}
@media (min-width: 768px) {
  .card--course .card__location [class*=fa] {
    top: 6px;
    margin-right: 15px;
    width: 28px;
  }
}
@media (min-width: 992px) {
  .filter-band--list ~ .filter-band__results .card--course .card__location {
    position: relative;
    margin-left: 30px;
  }
  .filter-band--list ~ .filter-band__results .card--course .card__location:before {
    content: "";
    position: absolute;
    height: 20px;
    width: 1px;
    top: 3px;
    left: -15px;
    background-color: #13120e;
  }
}
.card--course .card__time-slots {
  padding: 0;
  margin-bottom: 13px;
  margin-left: -10px;
  margin-right: -10px;
}
@media (min-width: 992px) {
  .card--course .card__time-slots {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media (min-width: 992px) {
  .filter-band--list ~ .filter-band__results .card--course .card__time-slots {
    display: flex;
  }
  .filter-band--list ~ .filter-band__results .card--course .card__time-slots .card__time-slot {
    flex: 1;
  }
  .filter-band--list ~ .filter-band__results .card--course .card__time-slots .card__time-slot + .card__time-slot {
    margin-left: 3px;
  }
}
.card--course .card__time-slot {
  list-style: none;
  padding: 8px 15px;
  background-color: rgba(188, 187, 182, 0.3);
  margin-bottom: 2px;
}
@media (min-width: 992px) {
  .filter-band--list ~ .filter-band__results .card--course .card__time-slot {
    display: flex;
  }
}
@media (min-width: 992px) {
  .filter-band--grid ~ .filter-band__results .card--course .card__time-slot {
    min-height: 86px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
.card--course .card__collapse {
  font-size: 16px;
  line-height: 1.375;
}
.card--course .card__collapse .list--icon-with-text {
  margin-bottom: 20px;
  margin-top: 7px;
}
.card--course .card__collapse p {
  margin-bottom: 14px;
}
.card--course .btn.btn-outline-primary {
  font-size: 20px;
}
.card--course .card__details-button {
  order: 1;
}
.card--course .card__buttons {
  order: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
@media (min-width: 992px) {
  .filter-band--list ~ .filter-band__results .card--course .card__buttons {
    flex-direction: row;
    justify-content: space-between;
  }
  .filter-band--list ~ .filter-band__results .card--course .card__buttons .btn-toggle {
    margin-bottom: 0;
  }
}
@media (min-width: 992px) {
  .filter-band--list ~ .filter-band__results .card--course.card--double .card__time-slot {
    flex-wrap: wrap;
  }
  .filter-band--list ~ .filter-band__results .card--course.card--double .card__location {
    margin-left: 0;
    width: 100%;
  }
  .filter-band--list ~ .filter-band__results .card--course.card--double .card__location:before {
    display: none;
  }
}
@media (min-width: 992px) {
  .filter-band--list ~ .filter-band__results .card--course.card--triple .card__time-slot {
    flex-wrap: wrap;
  }
  .filter-band--list ~ .filter-band__results .card--course.card--triple .card__days,
  .filter-band--list ~ .filter-band__results .card--course.card--triple .card__location {
    margin-left: 0;
    width: 100%;
  }
  .filter-band--list ~ .filter-band__results .card--course.card--triple .card__days:before,
  .filter-band--list ~ .filter-band__results .card--course.card--triple .card__location:before {
    display: none;
  }
}
.card--course.card--full .card-body {
  border: 1px solid #e1e0db;
  background-color: rgba(247, 247, 245, 0.2);
  color: #898985;
}
.card--course.card--full .card__days,
.card--course.card--full .card__days [class*=fa],
.card--course.card--full .card__time,
.card--course.card--full .card__time [class*=fa],
.card--course.card--full .card__location,
.card--course.card--full .card__location [class*=fa],
.card--course.card--full .list--icon-with-text,
.card--course.card--full .list--icon-with-text [class*=fa] {
  color: #898985;
}
.card--course.card--full .card__time-slot {
  background-color: rgba(188, 187, 182, 0.15);
}

.detail-box {
  background-color: #f7f7f5;
  color: #000000;
  margin-bottom: 45px;
  padding: 20px 20px 10px 20px;
}
@media (min-width: 992px) {
  .detail-box {
    padding-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
  }
}
.detail-box h2, .detail-box .h2 {
  color: #114594;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.05em;
  margin-bottom: 0;
  margin-top: 0;
}
.detail-box p {
  font-size: 18px;
}
.detail-box [class*=col] + [class*=col] {
  position: relative;
}
@media (max-width: 991.98px) {
  .detail-box [class*=col] + [class*=col] {
    padding-top: 20px;
  }
}
.detail-box [class*=col] + [class*=col]:before {
  content: "";
  width: 56px;
  height: 1px;
  background-color: #bcbbb6;
  position: absolute;
  top: 0;
}
@media (min-width: 992px) {
  .detail-box [class*=col] + [class*=col]:before {
    bottom: 20px;
    left: -20px;
    width: 1px;
    height: auto;
  }
}
@media (min-width: 992px) {
  .detail-box .col-lg-4 + .col-lg-4:before {
    left: -8px;
  }
}
.detail-box .link--icon [class*=fa] {
  margin-right: 10px;
}
.detail-box--decrease-top-margin {
  margin-top: -30px;
}
@media (min-width: 992px) {
  .detail-box--decrease-top-margin {
    margin-top: -40px;
  }
}

.event-card {
  position: relative;
  display: flex;
  text-decoration: none;
  color: #13120e;
  background: #d8e6fa;
  border-radius: 5px;
  padding: 25px;
  height: 100%;
}
@media (min-width: 992px) {
  .event-card {
    padding: 36px 30px;
  }
}
.event-card:not(.event-card--featured):before {
  content: "";
  background: linear-gradient(to bottom, #edf4ff, #d8e6fa);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: background-color 0.35s ease, opacity 0.35s ease;
}
.event-card:not(.event-card--featured):before {
  z-index: 0;
  opacity: 0;
  border-radius: 5px;
}
.event-card:not(.event-card--featured) > * {
  position: relative;
  z-index: 1;
}
.event-card:not(.event-card--featured):hover:before, .event-card:not(.event-card--featured):focus:before {
  opacity: 1;
}
.event-card:not(.event-card--featured):after {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  height: 20px;
  bottom: 5px;
  border-radius: 5px;
  transition: bottom 0.35s ease;
  background: #e36113;
}
.event-card:hover:after, .event-card:focus:after {
  bottom: -4px;
}
.event-card__date {
  font-family: futura-pt, sans-serif;
  padding-right: 15px;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  border-right: 1px solid #a6c7f4;
}
@media (min-width: 992px) {
  .event-card__date {
    padding-right: 25px;
  }
}
.event-card__month {
  color: #0d1013;
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  text-transform: uppercase;
}
@media (min-width: 992px) {
  .event-card__month {
    font-size: 22px;
    line-height: 22px;
  }
}
.event-card__day {
  color: #002f6d;
  font-size: 34px;
  line-height: 34px;
  font-weight: 700;
}
@media (min-width: 992px) {
  .event-card__day {
    font-size: 46px;
    line-height: 40px;
  }
}
.event-card__info {
  padding-left: 15px;
}
@media (min-width: 992px) {
  .event-card__info {
    padding-left: 25px;
  }
}
.event-card__small-title {
  color: #002f6d;
  font-size: 16px;
  font-family: futura-pt, sans-serif;
  font-weight: 500;
  text-transform: uppercase;
}
@media (min-width: 992px) {
  .event-card__small-title {
    font-size: 20px;
  }
}
.event-card__title {
  font-size: 28px;
  line-height: 32px;
  font-family: futura-pt, sans-serif;
  font-weight: 400;
  color: #13120e;
  margin-bottom: 10px;
}
.event-card__button {
  margin-top: 28px;
}
.event-card__detail-icon {
  width: 24px;
}
.event-card__detail-icon .fa {
  margin-right: 0px;
  color: #0e468f;
}
.event-card__detail {
  font-size: 16px;
  margin-bottom: 4px;
  padding-left: 24px;
}
.event-card__detail--bold {
  font-weight: 700;
}
.event-card__detail--italic {
  font-style: italic;
  color: #464540;
}
.event-card__detail-wrap {
  margin-left: -24px;
}
.event-card--featured {
  display: block;
  padding: 0;
  overflow: hidden;
}
@media (min-width: 992px) {
  .event-card--featured {
    display: flex;
    align-items: stretch;
  }
}
.event-card--featured .event-card__title {
  font-size: 28px;
  line-height: 32px;
}
@media (min-width: 992px) {
  .event-card--featured .event-card__title {
    font-size: 44px;
    line-height: 50px;
    margin-bottom: 30px;
  }
}
.event-card--featured .event-card__thumbnail {
  width: 100%;
}
@media (min-width: 992px) {
  .event-card--featured .event-card__thumbnail {
    width: 50%;
    flex-grow: 0;
    flex-shrink: 0;
  }
}
.event-card--featured .event-card__thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.event-card--featured .event-card__info {
  padding: 20px;
}
@media (min-width: 992px) {
  .event-card--featured .event-card__info {
    padding: 60px 55px;
  }
}
@media (min-width: 992px) {
  .event-card--featured .event-card__detail-icon .fa {
    margin-right: 16px;
  }
}
@media (min-width: 992px) {
  .event-card--featured .event-card__detail {
    margin-bottom: 10px;
  }
}
@media (min-width: 992px) {
  .event-card--featured .event-card__detail {
    font-size: 20px;
    padding-left: 40px;
  }
}
@media (min-width: 992px) {
  .event-card--featured .event-card__detail-wrap {
    margin-left: -40px;
  }
}
.event-card .splide-track {
  padding-bottom: 6px;
}

.filter-band__results {
  margin-bottom: 50px;
}
.filter-band__results__group + .filter-band__results__group {
  margin-top: 50px;
}
.filter-band__results__message {
  font-size: 30px;
  font-weight: 400;
  font-family: futura-pt, sans-serif;
}
.filter-band__results__heading h2, .filter-band__results__heading .h2 {
  font-size: 24px;
  font-weight: 400;
}
.filter-band__results__heading h2 strong, .filter-band__results__heading .h2 strong {
  font-size: 30px;
  font-weight: 400;
}
.filter-band__results__cards {
  list-style: none;
  padding: 0;
}
.filter-band__results__more-button {
  display: table;
  margin: 25px auto 0 auto;
}
.filter-band__results__more-button a.btn-dark {
  min-width: 95px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.filter-band__results__more-button a > [class*=fa] {
  padding-right: 0 !important;
  font-size: 24px !important;
}

.filter-band {
  margin-bottom: 50px;
}
.filter-band__top {
  background: white;
  color: #001a3d;
  padding: 20px 0 30px 0;
}
@media (min-width: 768px) {
  .filter-band__top {
    padding: 35px 0;
  }
}
.filter-band__bottom {
  background-color: #114594;
  padding: 20px 0;
}
@media (min-width: 768px) {
  .filter-band__bottom {
    padding: 30px 0;
  }
}
.filter-band__text {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.filter-band__text > a {
  flex-shrink: 0;
  margin-left: 12px;
}
.filter-band__text > *:first-child {
  margin-left: 0;
}
.filter-band__button {
  display: table;
  margin: 5px auto 0 auto;
}
.filter-band .multiselect-dropdown {
  margin-bottom: 15px;
}
.filter-band .multiselect-dropdown a:focus {
  box-shadow: inset 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.08), 0 0 0.15rem 0.15rem #f0bf20;
}
.filter-band .form-check {
  margin-bottom: 15px;
}
.filter-band .form-check input:focus {
  box-shadow: inset 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.08), 0 0 0.15rem 0.15rem #f0bf20;
}
.filter-band__controls {
  margin-top: 20px;
  color: white;
  display: flex;
  align-items: center;
}
@media (max-width: 575.98px) {
  .filter-band__controls {
    margin-top: 8px;
    flex-wrap: wrap;
  }
}
.filter-band__controls a {
  color: white;
  text-decoration: none !important;
}
.filter-band__controls__grid, .filter-band__controls__list {
  font-size: 28px;
  margin-right: 24px;
}
.filter-band__controls__grid:hover, .filter-band__controls__grid:focus, .filter-band__controls__list:hover, .filter-band__controls__list:focus {
  color: #f0bf20;
}
@media (max-width: 991.98px) {
  .filter-band__controls__grid, .filter-band__controls__list {
    display: none;
  }
}
.filter-band__controls__list + .filter-band__controls__count {
  border-left: 2px solid #a6c7f4;
  padding-left: 15px;
}
@media (max-width: 991.98px) {
  .filter-band__controls__list + .filter-band__controls__count {
    border-left: none;
    padding-left: 0;
    padding-right: 10px;
  }
}
@media (max-width: 575.98px) {
  .filter-band__controls__list + .filter-band__controls__count {
    width: 100%;
    order: 3;
    margin-top: 20px;
  }
}
@media (min-width: 576px) {
  .filter-band__controls__filter {
    margin-left: auto;
  }
}
.filter-band__controls__filter a {
  color: black;
}
.filter-band__controls__reset a {
  white-space: nowrap;
  padding-right: 0 !important;
  color: white !important;
}
.filter-band__controls__reset a span {
  text-decoration: none !important;
}
.filter-band__controls__reset a [class*=fa] {
  color: #f0bf20;
}
.filter-band ~ .filter-band__results .filter-band__results__cards {
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr;
}
.filter-band--grid .filter-band__controls__grid {
  color: #f0bf20;
}
@media (min-width: 768px) {
  .filter-band--grid + .filter-band__results .filter-band__results__cards {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 992px) {
  .filter-band--grid + .filter-band__results .filter-band__results__cards {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (min-width: 1200px) {
  .filter-band--grid + .filter-band__results .filter-band__results__cards {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
.filter-band--list .filter-band__controls__list {
  color: #f0bf20;
}
.filter-band--list + .filter-band__results {
  max-width: 857px;
  margin-left: auto;
  margin-right: auto;
}
.filter-band--no-filters {
  margin-top: 0;
}
.filter-band--no-filters .filter-band__top, .filter-band--no-filters .filter-band__bottom, .filter-band--no-filters .filter-band__controls {
  padding: 0;
  margin: 0;
}
.filter-band--no-filters .filter-band__bottom {
  background: transparent;
}
.filter-band--no-filters .filter-band__controls a {
  color: #13120e;
}
.filter-band--no-filters.filter-band--grid .filter-band__controls__grid {
  color: #f0bf20;
}
.filter-band--no-filters.filter-band--list .filter-band__controls__list {
  color: #f0bf20;
}
.filter-band--no-filters .filter-band__controls__count {
  color: #13120e;
  border-left-color: #91908b;
}
.filter-band .btn[disabled] {
  background-color: #042453;
  border-color: #042453;
  color: #999999;
  pointer-events: none;
}

.news-thumbnail a {
  text-decoration: none;
  display: block;
}
.news-thumbnail__link {
  font-family: futura-pt, sans-serif;
  font-weight: 400;
  font-size: 28px;
  line-height: 32px;
  text-decoration: none;
  color: #19519a;
  margin-bottom: 15px;
}
@media (min-width: 992px) {
  .news-thumbnail__link {
    font-size: 34px;
    line-height: 40px;
  }
}
.news-thumbnail__details {
  color: #001a3d;
  font-size: 16px;
  font-family: futura-pt, sans-serif;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.news-thumbnail__category {
  font-weight: 500;
  margin-right: 8px;
}
.news-thumbnail__date {
  font-weight: 400;
}
a:hover .news-thumbnail__link {
  color: #1464de;
}
a:hover .news-thumbnail__link {
  color: #1464de;
}
.news-thumbnail__background-wrap {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 0;
  padding-bottom: 68%;
  margin-bottom: 20px;
}
@media (min-width: 992px) {
  .news-thumbnail__background-wrap {
    margin-bottom: 30px;
  }
}
.news-thumbnail__background {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-position: center;
  background-size: cover;
  overflow: hidden;
  transition: transform 1.25s ease;
}
a:hover .news-thumbnail__background, a:focus .news-thumbnail__background {
  transform: scale(1.1);
}

.program-card {
  display: block;
  height: 100%;
  color: #13120e;
  text-decoration: none;
  background: #f7f7f5;
  padding: 32px;
  border-radius: 5px;
  position: relative;
  word-break: break-word;
}
.program-card:hover {
  color: #13120e;
}
.program-card:after {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  height: 20px;
  bottom: 5px;
  border-radius: 5px;
  transition: bottom 0.35s ease;
  background: #e36113;
}
.program-card:hover:after, .program-card:focus:after {
  bottom: -4px;
}
.program-card__subtitle {
  color: #002f6d;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  font-family: lato, sans-serif;
  margin-bottom: 8px;
}
.program-card__coursecode {
  font-size: 14px;
  color: #464540;
  font-family: lato, sans-serif;
  font-weight: 700;
}
.program-card__title h3, .program-card__title .h3 {
  font-size: 26px;
  color: #13120e;
  margin-bottom: 10px;
}
.program-card__title h2::after, .program-card__title .h2::after, .program-card__title h3::after, .program-card__title .h3::after {
  content: "\f0a9";
  background: white;
  border-radius: 100px;
  position: absolute;
  margin-top: 9px;
  margin-left: 8px;
  font-family: "Font Awesome 6 Pro";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-weight: 900;
  transition: transform 0.35s ease;
  color: #f0bf20;
  font-size: 18px;
  margin-top: 6px;
  will-change: transform;
}
@media (min-width: 768px) {
  .program-card__title h2::after, .program-card__title .h2::after, .program-card__title h3::after, .program-card__title .h3::after {
    font-size: 22px;
    margin-top: 6px;
  }
}
@media (min-width: 992px) {
  .program-card__title h2::after, .program-card__title .h2::after, .program-card__title h3::after, .program-card__title .h3::after {
    font-size: 18px;
    margin-top: 6px;
  }
}
@media (min-width: 1200px) {
  .program-card__title h2::after, .program-card__title .h2::after, .program-card__title h3::after, .program-card__title .h3::after {
    font-size: 16px;
    margin-top: 5px;
  }
}
.program-card:hover .program-card__title h2, .program-card:hover .program-card__title .h2, .program-card:hover .program-card__title h3, .program-card:hover .program-card__title .h3, .program-card:focus .program-card__title h2, .program-card:focus .program-card__title .h2, .program-card:focus .program-card__title h3, .program-card:focus .program-card__title .h3 {
  color: #001a3d !important;
}
.program-card:hover .program-card__title h2::after, .program-card:hover .program-card__title .h2::after, .program-card:hover .program-card__title h3::after, .program-card:hover .program-card__title .h3::after, .program-card:focus .program-card__title h2::after, .program-card:focus .program-card__title .h2::after, .program-card:focus .program-card__title h3::after, .program-card:focus .program-card__title .h3::after {
  transform: translateX(12px);
}
.program-card:before {
  content: "";
  background: linear-gradient(to bottom, #f7f7f5, #f1f0eb);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: background-color 0.35s ease, opacity 0.35s ease;
}
.program-card:before {
  z-index: 0;
  opacity: 0;
  border-radius: 5px;
}
.program-card > * {
  position: relative;
  z-index: 1;
}
.program-card--featured:before {
  content: "";
  background: linear-gradient(to bottom, #edf4ff, #d8e6fa);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: background-color 0.35s ease, opacity 0.35s ease;
}
.program-card--featured:before {
  z-index: 0;
  opacity: 0;
  border-radius: 5px;
}
.program-card--featured > * {
  position: relative;
  z-index: 1;
}
.program-card:hover:before, .program-card:focus:before {
  opacity: 1;
}
.program-card__details {
  padding-bottom: 10px;
  border-bottom: 1px solid #bcbbb6;
  margin-bottom: 18px;
}
.program-card__detail-icon {
  width: 24px;
}
.program-card__detail-icon .fa {
  margin-right: 8px;
  color: #0e468f;
}
.program-card__detail {
  font-size: 16px;
  margin-bottom: 8px;
  padding-left: 32px;
}
.program-card__detail--bold {
  font-weight: 700;
}
.program-card__detail--italic {
  font-style: italic;
  color: #464540;
}
.program-card__detail-wrap {
  margin-left: -32px;
}
.program-card__teaser {
  font-size: 16px;
  font-family: lato, sans-serif;
}
.program-card__teaser p:last-child {
  margin-bottom: 0;
}
.program-card__preq {
  font-size: 14px;
  font-family: futura-pt, sans-serif;
  font-weight: 500;
  color: #0c3c7a;
}
.program-card--featured {
  background: #d8e6fa;
}
.program-card--featured .program-card__details {
  border-bottom: 1px solid #a6c7f4;
}
.program-card--list .program-card__details {
  display: flex;
  flex-wrap: wrap;
  border-bottom: none;
  margin-bottom: 0;
}
.program-card--list .program-card__detail {
  font-size: 18px;
  margin-right: 20px;
}
.program-card--list .program-card__teaser {
  font-size: 20px;
}
@keyframes program_card_show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes program_card_after_show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
.program-card--list, .program-card:not(.program-card--list) {
  animation-duration: 1.5s;
  animation-name: program_card_show;
}
.program-card--list:after, .program-card:not(.program-card--list):after {
  animation-duration: 1.5s;
  animation-name: program_card_after_show;
}

.body-content a:not(.btn) {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-decoration-color: #1464de;
  text-underline-offset: 4px;
  transition: color 0.35s ease, text-decoration-color 0.35s ease;
}
.body-content a:not(.btn):hover, .body-content a:not(.btn):focus {
  text-decoration-color: transparent;
}

.search-form {
  position: relative;
}
.search-form button {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 50px;
  border: none;
  background: transparent;
}
.search-form button:after {
  content: "\f002";
  font-family: "Font Awesome 6 Pro";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-weight: 400;
  color: #f0bf20;
  font-size: 23px;
}
.search-form input {
  padding-right: 50px;
}

.tabs {
  border-top: 1px solid #e1e0db;
  background: #f7f7f5;
}

.nav-tabs {
  border: none;
}

@media (max-width: 767.98px) {
  .tabs.tabs--horizontal > .container {
    padding: 0;
  }
}

.nav-tabs--extra-space {
  padding-top: 60px !important;
}

.nav-tabs.nav-tabs--default.nav-tabs--horizontal {
  border-bottom: none;
  justify-content: center;
  align-items: stretch;
  flex-wrap: nowrap;
}
@media (min-width: 768px) {
  .nav-tabs.nav-tabs--default.nav-tabs--horizontal {
    padding-top: 16px;
  }
}
.nav-tabs.nav-tabs--default.nav-tabs--horizontal .nav-item {
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  flex-grow: 1;
  max-width: 415px;
}
.nav-tabs.nav-tabs--default.nav-tabs--horizontal .nav-item .nav-link {
  flex-grow: 1;
  font-family: futura-pt, sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  color: #1464de;
  border: none;
  border-radius: 0;
  border-left: 0;
  border-right: 0;
  border-top: 5px solid transparent;
  border-bottom: 4px solid transparent;
  margin: 0;
  padding: 24px;
}
.nav-tabs.nav-tabs--default.nav-tabs--horizontal .nav-item .nav-link.active {
  border-top-color: #e36113 !important;
  border-bottom-color: transparent !important;
  color: #001a3d;
}
.nav-tabs.nav-tabs--default.nav-tabs--horizontal .nav-item .nav-link:hover, .nav-tabs.nav-tabs--default.nav-tabs--horizontal .nav-item .nav-link:focus {
  border-bottom-color: #e36113;
  color: #001a3d;
  border-top-color: transparent;
}

.tabs.tabs--vertical {
  border-top: none;
  height: 100%;
}

.nav-tabs.nav-tabs--default.nav-tabs--vertical {
  padding: 16px 0 16px 16px;
  border-top: none;
}
.nav-tabs.nav-tabs--default.nav-tabs--vertical .nav-item {
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  flex-grow: 1;
}
.nav-tabs.nav-tabs--default.nav-tabs--vertical .nav-item .nav-link {
  flex-grow: 1;
  font-family: futura-pt, sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  color: #1464de;
  border-bottom: none;
  border-left: 5px solid transparent;
  border-right: 4px solid transparent;
  border-top: none;
  text-align: left;
  border-radius: 0;
  margin: 0;
  padding: 24px;
  position: relative;
}
.nav-tabs.nav-tabs--default.nav-tabs--vertical .nav-item .nav-link:after {
  content: "";
  position: absolute;
  height: 1px;
  background: #e1e0db;
  bottom: 0;
  left: 0;
  right: -4px;
}
.nav-tabs.nav-tabs--default.nav-tabs--vertical .nav-item .nav-link.active {
  border-left-color: #e36113;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  color: #001a3d;
  position: relative;
  top: -1px;
  padding-top: 25px;
  margin-bottom: -1px;
}
.nav-tabs.nav-tabs--default.nav-tabs--vertical .nav-item .nav-link.active:after {
  background: transparent;
}
.nav-tabs.nav-tabs--default.nav-tabs--vertical .nav-item .nav-link:hover, .nav-tabs.nav-tabs--default.nav-tabs--vertical .nav-item .nav-link:focus {
  border-right-color: #e36113;
  color: #001a3d;
}
.nav-tabs.nav-tabs--default.nav-tabs--vertical .nav-item:last-child .nav-link:after {
  content: none;
}

.text-and-image__image .figure {
  margin-bottom: 0;
}
.text-and-image__image .figure--circle {
  padding-bottom: 0;
  width: 155px;
  height: 155px;
}
@media (min-width: 768px) {
  .text-and-image__image .figure--circle {
    width: 291px;
    height: 291px;
  }
}
@media (min-width: 992px) {
  .text-and-image__image-right {
    background: linear-gradient(20deg, #002f6d 0%, #0e4aa5 40%);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
}
.text-and-image__image-right .figure {
  margin-bottom: 0;
  border-left: 0 none;
}
@media (min-width: 992px) {
  .text-and-image__image-right .figure {
    width: 60%;
    height: 100%;
    display: flex;
    margin-left: auto;
  }
  .text-and-image__image-right .figure img {
    object-fit: cover;
    object-position: right;
    width: 100%;
    height: 100%;
  }
  .text-and-image__image-right .figure:before {
    content: "";
    background: transparent url("../images/overlay-featured-destination-cta-style-3.png") no-repeat scroll center top;
    background-size: auto 100%;
    position: absolute;
    left: -21%;
    top: 0;
    bottom: 0;
    right: 0;
  }
}
@media (min-width: 1400px) {
  .text-and-image__image-right .figure {
    width: 760px;
    position: relative;
  }
  .text-and-image__image-right .figure:before {
    left: -100%;
  }
}
.text-and-image__content {
  padding-top: 27px;
}
.text-and-image__content .blockquote-figure {
  border: 0 none;
  padding-bottom: 0;
}
@media (min-width: 992px) {
  .text-and-image__content .blockquote-figure {
    padding-bottom: 1px;
  }
}
.text-and-image__content .blockquote-figure .blockquote {
  border: 0 none;
  padding-top: 0;
  padding-left: 38px;
}
@media (min-width: 992px) {
  .text-and-image__content .blockquote-figure .blockquote {
    padding-left: 0;
  }
}
@media (max-width: 991.98px) {
  .text-and-image__content .blockquote-figure .blockquote {
    padding-bottom: 42px;
  }
}
.text-and-image__content .blockquote-figure .blockquote p {
  text-align: left;
  font-size: 28px;
  line-height: 1.143;
  font-family: futura-pt, sans-serif;
  letter-spacing: -0.005em;
}
@media (min-width: 1200px) {
  .text-and-image__content .blockquote-figure .blockquote p {
    font-size: 46px;
    line-height: 1.043;
  }
}
.text-and-image__content .blockquote-figure .blockquote p:first-of-type:before {
  left: 0;
  top: 2px;
}
@media (min-width: 992px) {
  .text-and-image__content .blockquote-figure .blockquote p:first-of-type:before {
    left: -56px;
    top: -5px;
  }
}
.text-and-image__content .blockquote-figure .blockquote-footer {
  letter-spacing: 0.05em;
  font-size: 16px;
  text-transform: uppercase;
  color: #0c3c7a;
  font-family: futura-pt, sans-serif;
  font-weight: 500;
  padding-left: 0;
}
@media (min-width: 992px) {
  .text-and-image__content .blockquote-figure .blockquote-footer {
    font-size: 20px;
  }
}
.text-and-image__content .blockquote-figure .blockquote-footer::before {
  display: none;
}
.text-and-image__content .blockquote-figure .blockquote-footer small, .text-and-image__content .blockquote-figure .blockquote-footer .small {
  letter-spacing: normal;
  text-transform: none;
  color: #13120e;
  font-family: lato, sans-serif;
  font-weight: 400;
}
@media (max-width: 991.98px) {
  .text-and-image__content .blockquote-figure .blockquote-footer small, .text-and-image__content .blockquote-figure .blockquote-footer .small {
    font-size: 16px;
  }
}
@media (max-width: 575.98px) {
  .text-and-image__content .blockquote-figure .blockquote-footer small, .text-and-image__content .blockquote-figure .blockquote-footer .small {
    margin-top: 2px;
  }
}
@media (max-width: 991.98px) {
  .text-and-image__content {
    text-align: center;
  }
}
@media (max-width: 767.98px) {
  .text-and-image__content h2, .text-and-image__content .h2 {
    font-size: 34px;
    line-height: 1.059;
  }
  .text-and-image__content h2 small, .text-and-image__content .h2 small, .text-and-image__content h2 .small, .text-and-image__content .h2 .small {
    font-size: 16px;
  }
}
.text-and-image__content.text-center .button-set {
  justify-content: center;
}
.text-and-image__content--white-text {
  color: #ffffff;
}
.text-and-image__content--white-text h2, .text-and-image__content--white-text .h2, .text-and-image__content--white-text h3, .text-and-image__content--white-text .h3 {
  color: #ffffff;
}
@media (max-width: 991.98px) {
  .text-and-image .button-set {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.text-and-image__full-background {
  background-size: cover;
  background-position: center;
  position: relative;
  padding-top: 38px;
  padding-bottom: 80px;
}
.text-and-image__full-background .text-and-image__content {
  padding-left: 35px;
  padding-right: 35px;
  position: relative;
  z-index: 3;
  color: #ffffff;
}
.text-and-image__full-background .text-and-image__content h2, .text-and-image__full-background .text-and-image__content .h2, .text-and-image__full-background .text-and-image__content h3, .text-and-image__full-background .text-and-image__content .h3, .text-and-image__full-background .text-and-image__content h4, .text-and-image__full-background .text-and-image__content .h4 {
  color: #ffffff;
}
.text-and-image__full-background .text-and-image__content .button-set {
  margin-top: 30px;
}
.text-and-image__full-background:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  background: linear-gradient(to bottom, rgba(10, 27, 52, 0.65) 0%, rgba(2, 6, 12, 0.74) 76%, rgba(0, 0, 0, 0.86) 100%);
}
.text-and-image__full-background:after {
  content: "";
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background: transparent url(../images/overlay-banner-cta.png) no-repeat scroll right -350px top;
  background-size: auto 100%;
}
@media (min-width: 992px) {
  .text-and-image__full-background:after {
    background-position: right top;
    background-size: auto 100%;
    width: 90%;
  }
}

.ccbc-alert {
  height: 100%;
  border-top: 5px solid #c20101;
  background: #464540;
}
.ccbc-alert__panel {
  padding: 20px;
}
.ccbc-alert__icon {
  width: 45px;
  height: 45px;
  background: #c20101;
  border-radius: 100%;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ccbc-alert__icon:before {
  content: "\f848";
  font-size: 26px;
  line-height: 0;
  letter-spacing: 0;
  font-family: "Font Awesome 6 Pro";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-weight: 300;
  color: white;
}
.ccbc-alert__body {
  color: white;
}
.ccbc-alert__info {
  color: #C14915;
  font-family: futura-pt, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 18px;
  letter-spacing: 0.05em;
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .ccbc-alert__info {
    font-size: 20px;
  }
}
.ccbc-alert__info:before {
  content: "\f05a" " ";
  font-family: "Font Awesome 6 Pro";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-weight: 900;
  font-weight: 500;
}
.ccbc-alert__info--collapsed {
  font-size: 16px;
  margin: 0 30px 0 0;
}
.ccbc-alert__title {
  color: #ffffff;
  font-size: 24px;
  line-height: 1.1667;
  font-family: futura-pt, sans-serif;
  font-weight: 400;
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .ccbc-alert__title {
    font-size: 32px;
    line-height: 1.1875;
  }
}
.ccbc-alert__title--collapsed {
  font-size: 20px;
  margin: 0;
}
.ccbc-alert__text {
  color: #e1e0db;
  font-size: 17px;
  line-height: 22px;
}
@media (min-width: 992px) {
  .ccbc-alert__text {
    font-size: 18px;
    line-height: 1.556;
  }
}
@media (max-width: 767.98px) {
  .ccbc-alert__links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
@media (min-width: 768px) {
  .ccbc-alert__links {
    display: flex;
    flex-wrap: wrap;
  }
}
.ccbc-alert__link {
  border-top: 1px solid #6a6964;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 20px;
}
@media (min-width: 768px) {
  .ccbc-alert__link {
    flex-grow: 0;
    margin-right: 50px;
  }
}
@media (min-width: 992px) {
  .ccbc-alert__link {
    font-size: 24px;
  }
}
.ccbc-alert--advisory {
  background: transparent;
  border-top: 0;
}
.ccbc-alert--advisory .ccbc-alert__collapsed {
  padding: 20px 30px;
}
.ccbc-alert--advisory .ccbc-alert__panel {
  padding: 30px 35px 15px 35px;
}
.ccbc-alert--advisory .ccbc-alert__title {
  color: black;
}
.ccbc-alert--advisory .ccbc-alert__collapsed .ccbc-alert__title {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-decoration-color: transparent;
  text-underline-offset: 4px;
  transition: color 0.35s ease, text-decoration-color 0.35s ease;
}
.ccbc-alert--advisory .ccbc-alert__collapsed:hover .ccbc-alert__title {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-decoration-color: black;
  text-underline-offset: 4px;
  transition: color 0.35s ease, text-decoration-color 0.35s ease;
}
.ccbc-alert--advisory .ccbc-alert__text {
  color: black;
}
.ccbc-alert--advisory .ccbc-alert__collapsed .ccbc-alert__info {
  margin-bottom: 5px;
  transition: color 0.3s;
}
.ccbc-alert--advisory .ccbc-alert__collapsed:hover .ccbc-alert__info, .ccbc-alert--advisory .ccbc-alert__collapsed:focus .ccbc-alert__info {
  color: #9a410c;
}
.ccbc-alert--advisory .ccbc-alert__link {
  border-top: 1px solid #bcbbb6;
}
@media (max-width: 767.98px) {
  .ccbc-alert--advisory .ccbc-alert__panel {
    padding-top: 20px;
    padding-bottom: 30px;
  }
}
@keyframes advisory_show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.ccbc-alert--advisory .ccbc-alert__collapsed, .ccbc-alert--advisory .ccbc-alert__panel {
  animation-duration: 1.5s;
  animation-name: advisory_show;
}

@media (min-width: 992px) {
  .alertset--single .ccbc-alert--advisory .ccbc-alert__collapsed-inner {
    display: flex;
    flex-wrap: nowrap;
  }
}
.alertset {
  margin-bottom: 80px;
}
@media (min-width: 992px) {
  .alertset {
    margin-bottom: 100px;
  }
}
.alertset__alerts {
  background: #f7f7f5;
}
@media (min-width: 992px) {
  .alertset--multiple .alertset__alerts {
    display: flex;
    flex-wrap: nowrap;
  }
  .alertset--multiple .alertset__alert {
    flex-grow: 0;
    width: 100%;
  }
}
.alertset__alert {
  position: relative;
}
.alertset__alert--hidden {
  display: none;
}
.alertset__alert:not(.alertset__alert--hidden) + .alertset__alert:before {
  content: "";
  height: 1px;
  background: #e1e0db;
  position: absolute;
  left: 80px;
  right: 80px;
  top: 0;
}
@media (min-width: 992px) {
  .alertset--multiple .alertset__alert:not(.alertset__alert--hidden) + .alertset__alert:before {
    content: "";
    width: 1px;
    height: auto;
    top: 16px;
    bottom: 16px;
    left: 0;
  }
}

.site-footer {
  border-top: 5px solid #e1e0db;
  margin-top: 70px;
}
.site-footer p {
  margin-bottom: 0;
}
.site-footer a[href*=tel] {
  color: #000000;
  letter-spacing: 0.1em;
  font-weight: 700;
  font-family: futura-pt, sans-serif;
  font-size: 22px;
}
@media (min-width: 992px) {
  .site-footer a[href*=tel] {
    text-decoration: none;
    font-size: 24px;
  }
}
.site-footer ul {
  margin-bottom: 0;
  list-style: none;
}
@media (max-width: 991.98px) {
  .site-footer__logo {
    margin-bottom: 15px;
    display: block;
  }
}
.site-footer__logo img {
  width: 162px;
}
@media (min-width: 992px) {
  .site-footer__logo img {
    width: 296px;
  }
}
.site-footer__links {
  margin-top: 19px;
}
@media (min-width: 992px) {
  .site-footer__links {
    margin-top: 4px;
  }
}
.site-footer__links ul {
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
@media (min-width: 992px) {
  .site-footer__links ul {
    justify-content: flex-end;
  }
}
.site-footer__links ul a {
  font-weight: 500;
  font-family: futura-pt, sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-size: 17px;
  padding: 6px 10px 0;
  display: inline-block;
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-decoration-color: transparent;
  text-underline-offset: 4px;
  transition: color 0.35s ease, text-decoration-color 0.35s ease;
}
@media (min-width: 768px) {
  .site-footer__links ul a {
    padding: 3px 0 0 20px;
    font-size: 18px;
  }
}
.site-footer__links ul a:hover, .site-footer__links ul a:focus {
  text-decoration-color: #0c3c7a;
}
.site-footer__band {
  background-color: #002f6d;
  padding: 27px 0;
  font-size: 15px;
}
@media (min-width: 992px) {
  .site-footer__band {
    font-size: 16px;
  }
}
.site-footer__band,
.site-footer__band a {
  color: #ffffff;
}
.site-footer__band p {
  letter-spacing: 0.05em;
}
.site-footer__band__links {
  padding-left: 13px;
}
@media (min-width: 992px) {
  .site-footer__band__links li:before {
    content: "|";
    color: #a6c7f4;
    padding-right: 10px;
  }
}
.site-footer__band__links li a {
  font-weight: 700;
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-decoration-color: #ffffff;
  text-underline-offset: 4px;
  transition: color 0.35s ease, text-decoration-color 0.35s ease;
}
@media (max-width: 991.98px) {
  .site-footer__band__links li a {
    display: block;
    margin-top: 20px;
  }
}
.site-footer__band__links li a:hover, .site-footer__band__links li a:focus {
  text-decoration-color: transparent;
}
.site-footer__social-media {
  padding-left: 11px;
  display: flex;
}
@media (max-width: 991.98px) {
  .site-footer__social-media {
    flex-wrap: wrap;
    justify-content: center;
  }
  .site-footer .site-footer__social-media {
    margin-bottom: 15px;
  }
}
.site-footer__social-media li:not(:last-child) {
  margin-right: 11px;
}
@media (max-width: 991.98px) {
  .site-footer__social-media li {
    margin-bottom: 5px;
  }
}
.site-footer__social-media li a {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1464de;
  width: 39px;
  height: 39px;
  font-size: 20px;
  color: #ffffff;
  border-radius: 50%;
  text-decoration: none;
}
.site-footer__social-media li a:hover, .site-footer__social-media li a:focus {
  background-color: #f27930;
  color: #000000;
}

.site-header .navbar {
  padding: 0;
  position: absolute;
  z-index: 10;
  width: 100%;
  align-items: flex-start;
}
.site-header .navbar::before {
  content: "";
  height: 5px;
  width: 100%;
  background: linear-gradient(to right, #002f6d 0%, #19519a 100%);
}
.site-header .navbar-brand {
  margin-top: 20px;
}
.site-header .navbar-brand img {
  height: auto;
  filter: drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.5));
  width: 195px;
}
@media (min-width: 768px) {
  .site-header .navbar-brand img {
    width: 313px;
  }
}
@media (max-width: 767.98px) {
  .site-header .navbar-brand img {
    position: relative;
    top: -16px;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .site-header__main-menu {
    position: relative;
    top: 34px;
  }
}
.site-header__main-menu ul {
  display: flex;
  padding: 0;
}
.site-header__main-menu li {
  display: block;
}
@media (min-width: 768px) {
  .site-header__main-menu li {
    margin-left: 10px;
  }
  .site-header__main-menu li:last-child {
    margin-left: 20px;
  }
}
.site-header__utility-menu {
  margin-top: 15px;
}
.site-header__utility-menu ul {
  display: flex;
}
.site-header__utility-menu li {
  display: block;
  margin-left: 20px;
}
.site-header__utility-menu a {
  color: #dad9d2;
  font-family: futura-pt, sans-serif;
  font-weight: 400;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.23);
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-decoration-color: transparent;
  text-underline-offset: 4px;
  transition: color 0.35s ease, text-decoration-color 0.35s ease;
}
.site-header__utility-menu a:hover, .site-header__utility-menu a:focus {
  text-decoration-color: #ffffff;
}
.site-header__utility-menu a i {
  font-size: 16px;
}
.site-header__utility-menu a:hover {
  color: white;
}
.site-header__utility-menu .alert-active {
  padding-left: 23px;
  position: relative;
}
.site-header__utility-menu .alert-active:before {
  content: "!";
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: futura-pt, sans-serif;
  font-weight: 700;
  font-size: 13px;
  color: white;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  background: #c20101;
  border-radius: 100%;
  box-shadow: 2px 2px 1px -1px rgba(0, 0, 0, 0.3);
  text-shadow: none;
}
@media (max-width: 991.98px) {
  .site-header--has-alert .site-header__main-menu {
    position: relative;
  }
  .site-header--has-alert .site-header__main-menu:before {
    content: "!";
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-family: futura-pt, sans-serif;
    font-weight: 700;
    font-size: 13px;
    color: white;
    width: 18px;
    height: 18px;
    position: absolute;
    top: 0;
    right: 0;
    transform: translateY(-50%);
    background: #c20101;
    border-radius: 100%;
    box-shadow: 2px 2px 1px -1px rgba(0, 0, 0, 0.3);
    text-shadow: none;
  }
}
.site-header .sticky-nav {
  position: fixed;
  z-index: 1000;
  top: 55px;
  right: 0;
  display: none;
}
@media (min-width: 768px) {
  .site-header .sticky-nav {
    display: block;
    transform: translateX(200px);
  }
  .site-header .sticky-nav.show {
    transform: translateX(0);
    animation: sticky_header_flyin 0.5s;
  }
  .site-header .sticky-nav.hide {
    transform: translateX(200px);
    animation: sticky_header_flyout 0.5s;
  }
}
.site-header .sticky-nav ul, .site-header .sticky-nav li {
  display: block;
  padding: 0;
  margin: 0;
}
.site-header .sticky-nav a {
  height: 48px;
  padding-left: 24px;
  padding-right: 16px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.site-header .sticky-nav a:before {
  margin-top: -2px;
  width: 36px;
}
.site-header .sticky-nav a span {
  display: none;
}
@keyframes sticky_header_flyin {
  0% {
    transform: translateX(200px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes sticky_header_flyout {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(200px);
  }
}

.menu-flyout {
  padding: 1px;
}
@media (min-width: 768px) {
  .menu-flyout {
    padding: 50px 75px 50px 50px;
  }
}
@media (min-width: 992px) {
  .menu-flyout {
    padding-right: 100px;
  }
}
.menu-flyout__logo {
  width: 100%;
}
@media (max-width: 991.98px) {
  .menu-flyout__logo {
    margin-bottom: 17px;
  }
}
.menu-flyout__logo img {
  width: 146px;
}
.menu-flyout__mobile-action-links ul,
.menu-flyout__mobile-action-links li, .menu-flyout__mobile-utility-links ul,
.menu-flyout__mobile-utility-links li {
  display: block;
  margin: 0;
  padding: 0;
}
.menu-flyout__mobile-action-links ul, .menu-flyout__mobile-utility-links ul {
  display: flex;
  justify-content: space-evenly;
}
.menu-flyout__mobile-action-links a, .menu-flyout__mobile-utility-links a {
  display: block;
  padding: 5px;
  text-transform: uppercase;
  font-family: futura-pt, sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: #1464de;
  text-decoration: none;
}
.menu-flyout__mobile-action-links a:hover, .menu-flyout__mobile-utility-links a:hover {
  color: #1464de;
  text-decoration: underline;
}
.menu-flyout__action-links ul, .menu-flyout__utility-links ul {
  display: block;
  margin: 0;
  padding: 0;
}
.menu-flyout__action-links li, .menu-flyout__utility-links li {
  display: block;
  margin: 0;
  padding: 0;
}
@media (max-width: 767.98px) {
  .menu-flyout__action-links li a, .menu-flyout__utility-links li a {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (min-width: 768px) {
  .menu-flyout__action-links, .menu-flyout__utility-links {
    padding-left: 95px;
  }
}
.menu-flyout__close-button {
  width: 42px;
  height: 42px;
  background: #1464de;
  color: #ffffff;
  display: flex;
  padding: 0;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 100%;
  font-size: 27px;
  transition: color 0.35s ease, background-color 0.35s ease, border-color 0.35s ease, box-shadow 0.35s ease;
}
@media (min-width: 768px) {
  .menu-flyout__close-button {
    width: 48px;
    height: 48px;
    margin-bottom: 6px;
  }
}
.menu-flyout__close-button:after {
  font-family: "Font Awesome 6 Pro";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-weight: 400;
  content: "\f00d";
}
.menu-flyout__close-button:hover, .menu-flyout__close-button:focus {
  background-color: #19519a;
}

.site-header-mlp {
  position: absolute;
  top: 0;
  width: 100%;
  background: transparent;
  z-index: 2;
  padding-top: 15px;
}
@media (min-width: 768px) {
  .site-header-mlp {
    padding-top: 45px;
  }
}
.site-header-mlp::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  display: block;
  height: 5px;
  width: 100%;
  background: linear-gradient(to right, #002f6d 0%, #19519a 100%);
}
.site-header-mlp img {
  width: 230px;
}
@media (min-width: 768px) {
  .site-header-mlp img {
    width: 380px;
  }
}

.blue-cta-block {
  background: linear-gradient(90deg, #002f6d 0%, #19519a 100%);
  color: #ffffff;
  margin-bottom: 40px;
  padding: 22px 25px;
}
@media (min-width: 992px) {
  .blue-cta-block {
    padding: 26px 30px;
    margin-bottom: 60px;
  }
}
.blue-cta-block h2, .blue-cta-block .h2 {
  font-weight: 500;
  color: #ffffff;
  font-size: 26px;
  line-height: 1.077;
  position: relative;
  margin-bottom: 25px;
  letter-spacing: 0.04em;
  padding-bottom: 18px;
}
.blue-cta-block h2:after, .blue-cta-block .h2:after {
  content: "";
  position: absolute;
  bottom: 0;
  height: 4px;
  width: 130px;
  background-color: #e36113;
  left: -25px;
}
@media (min-width: 992px) {
  .blue-cta-block h2:after, .blue-cta-block .h2:after {
    left: -30px;
  }
}
@media (min-width: 992px) {
  .blue-cta-block h2, .blue-cta-block .h2 {
    font-size: 28px;
    line-height: 1.214;
  }
}
.blue-cta-block .tertiary-button {
  font-size: 20px;
}
@media (min-width: 992px) {
  .blue-cta-block .tertiary-button {
    font-size: 26px;
  }
}

.cards {
  margin-bottom: 80px;
}
@media (min-width: 992px) {
  .cards {
    margin-bottom: 100px;
  }
}

.contact-block {
  margin-bottom: 20px;
}
@media (min-width: 992px) {
  .contact-block {
    margin-bottom: 50px;
  }
}

.directory__intro h2, .directory__intro .h2 {
  font-family: lato, sans-serif;
  font-size: 22px;
  line-height: 34px;
  color: #0c3c7a;
  margin-bottom: 40px;
}
@media (min-width: 992px) {
  .directory__intro h2, .directory__intro .h2 {
    font-size: 26px;
    line-height: 38px;
  }
}
.directory__count {
  font-size: 24px;
  font-family: futura-pt, sans-serif;
  font-weight: 400;
  color: #464540;
}

.embed--border-accent {
  border-left: 4px solid #e36113;
}

.events-listing.hh-events .date-img {
  font-family: futura-pt, sans-serif;
  border-color: #002f6d;
  transition: border-color 0.35s ease;
  display: flex;
  flex-direction: column;
}
.events-listing.hh-events .date-img .month {
  background-color: #002f6d;
  padding: 7px;
  transition: background-color 0.35s ease;
}
.events-listing.hh-events .date-img .day {
  color: #1c5ec2;
  line-height: 1.1;
  font-weight: 500;
  height: calc(100% - 54px);
  font-size: 65px;
}
@media (min-width: 576px) {
  .events-listing.hh-events .date-img .day {
    font-size: 60px;
  }
}
@media (min-width: 992px) {
  .events-listing.hh-events .date-img .day {
    font-size: 70px;
    line-height: 1.2;
  }
}
.events-listing.hh-events .date-img .weekday {
  font-weight: 700;
  font-family: lato, sans-serif;
  font-size: 21px;
  text-transform: none;
  color: #13120e;
}
.events-listing.hh-events .event {
  border-bottom: 1px solid #e1e0db;
  padding-bottom: 30px;
  margin-bottom: 30px;
}
.events-listing.hh-events .event .image {
  overflow: hidden;
}
@media (max-width: 767.98px) {
  .events-listing.hh-events .event .image {
    height: auto;
    margin-bottom: 20px;
  }
}
.events-listing.hh-events .event .event-image {
  transition: transform 1.25s ease;
  height: 180px;
}
@media (min-width: 992px) {
  .events-listing.hh-events .event .event-image {
    height: 230px;
  }
}
.events-listing.hh-events a {
  text-decoration: none;
}
.events-listing.hh-events a:hover .date-img, .events-listing.hh-events a:focus .date-img {
  border-color: #1464de;
}
.events-listing.hh-events a:hover .date-img .month, .events-listing.hh-events a:focus .date-img .month {
  background-color: #1464de;
}
.events-listing.hh-events a:hover .event-details .title, .events-listing.hh-events a:focus .event-details .title {
  color: #002f6d;
}
.events-listing.hh-events a:hover .event-details .title:after, .events-listing.hh-events a:focus .event-details .title:after {
  padding-left: 16px;
}
.events-listing.hh-events a:hover .event-image:not(.date-img), .events-listing.hh-events a:focus .event-image:not(.date-img) {
  transform: scale(1.1);
}
.events-listing.hh-events .details {
  color: #464540;
  font-size: 20px;
  font-family: futura-pt, sans-serif;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 700;
}
.events-listing.hh-events .details .date:before {
  content: "|";
  font-size: 16px;
  font-weight: 700;
  position: relative;
  top: -2px;
}
.events-listing.hh-events .details .recurrence:after,
.events-listing.hh-events .details .featured:after {
  content: "|";
  padding-left: 11px;
  font-size: 16px;
  position: relative;
  top: -2px;
}
.events-listing.hh-events .sub-details {
  display: flex;
  color: #464540;
  font-family: lato, sans-serif;
  font-style: italic;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.05em;
}
.events-listing.hh-events .sub-details .location:before {
  content: "|";
  font-size: 16px;
  font-weight: 700;
  position: relative;
  top: -2px;
  padding-left: 10px;
  padding-right: 11px;
}
.events-listing.hh-events .feeds {
  margin-bottom: 0;
}
.events-listing.hh-events .feeds a {
  color: #1464de;
  font-size: 18px;
}
.events-listing .hh-events__filters {
  border-bottom: 5px solid #e1e0db;
  padding-bottom: 40px;
  margin-bottom: 40px;
}
.events-listing .hh-events__filters .title--compact {
  margin-bottom: 8px;
}
@media (min-width: 992px) {
  .events-listing .hh-events__filters .title--compact {
    margin-top: -4px;
  }
}
.events-listing .event-details .title {
  color: #000000;
  font-size: 30px;
  letter-spacing: 0.04em;
  padding-right: 28px;
  position: relative;
}
.events-listing .event-details .title:after {
  content: "\f0a9";
  color: #f0bf20;
  font-size: 22px;
  padding-left: 10px;
  font-family: "Font Awesome 6 Pro";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-weight: 900;
  transition: padding 0.35s ease;
  position: absolute;
  margin-top: 8px;
}
.events-listing .summary {
  margin-top: 22px;
  font-size: 20px;
  letter-spacing: 0.05em;
  color: #13120e;
}

.hh-events .loading-spinner {
  color: #43739c;
  margin-bottom: 50px;
}

/* Event details */
/* Sidebar */
.datepicker.datepicker-dropdown {
  z-index: 21 !important;
}

.hh-events #mini-calendar .input-daterange input {
  width: 100%;
  height: 40px;
  padding: 0 15px;
  background-color: #fff;
  border-radius: 2px;
  border: 2px solid #e8eaed;
}

/* Accessibility fix */
.hh-events :focus {
  outline-offset: 2px;
  outline-color: #43739c;
}

/* Bootstrap Tooltips */
.hh-events .tooltip {
  font-family: muli, sans-serif;
  font-size: 14px;
}

/* Opposite button */
.hh-events .primary-button-white {
  color: #43739c;
  background-color: white;
}

.hh-events .primary-button-white:hover, .hh-events .primary-button-white:focus {
  color: white;
  background-color: #43739c;
  outline: none;
}

.hh-events .primary-button.primary-button-white.dropdown-toggle {
  width: 100%;
  height: 50px;
  color: #11151c;
  font-weight: bold;
  background-color: white;
  border: 2px solid #e8eaed;
  border-radius: 2px;
  padding-left: 10px;
  cursor: pointer;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  text-align: left;
  -webkit-box-shadow: none;
  box-shadow: none;
  text-transform: capitalize;
}

.hh-events .primary-button.primary-button-white.dropdown-toggle:focus, .hh-events .primary-button.primary-button-white.dropdown-toggle:active {
  border-color: #e8eaed;
  outline: none;
}

.hh-events .primary-button.primary-button-white.dropdown-toggle:hover {
  border-color: #43739c;
  outline: none;
}

.hh-events .sidebar .dropdown-menu {
  margin-top: 0px;
  width: 100%;
  border: 2px solid #e8eaed;
  border-radius: 0;
  box-shadow: none;
  padding: 10px;
  border-top: none;
  background-clip: border-box;
  max-height: 30vh;
  overflow-y: auto;
  padding-bottom: 0;
}

/* .hh-events .sidebar .dropdown.open:hover .dropdown-menu{
	 */
/* border-color: #43739c;
 */
/*
}
*/
.hh-events .sidebar .dropdown.open:hover .primary-button.primary-button-white.dropdown-toggle {
  border-color: #43739c;
}

/* Events on Listing */
.hh-events#main {
  margin-top: 30px;
}

.hh-events .page-title h1, .hh-events .page-title .h1 {
  display: inline-block;
}

.hh-events .events-results {
  color: #11151c;
  font-size: 14px;
  font-family: montserrat, sans-serif;
  margin-bottom: 20px;
}

.hh-events .events-results .event-count span, .hh-events .events-results .filter-count span {
  font-weight: bold;
}

.hh-events .events .event {
  margin-bottom: 30px;
}

@media only screen and (max-width: 991px) {
  .hh-events .events .event:not(:last-child) {
    padding-bottom: 20px;
    box-shadow: 0px 16px 1px -15px #e8eaed;
  }
}
.hh-events .event .event-image {
  border-radius: 2px;
  height: 140px;
  display: block;
  background-position: center;
  background-size: cover;
}

.hh-events .featured-events-list .event-image {
  height: 70px;
}

.hh-events .featured-events-list .event-details {
  height: 70px;
  display: flex;
  align-items: center;
}

.hh-events .event .image {
  height: 100%;
  margin-bottom: 10px;
}

.hh-events .date-img {
  text-align: center;
  font-size: 30px;
  text-transform: uppercase;
  border: 2px solid #43739c;
  border-radius: 2px;
  height: 100%;
  box-shadow: 1px 1px #e8eaed;
}

.hh-events .date-img .month {
  background: #43739c;
  color: #fff;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  margin: -1px;
}

.hh-events .date-img .day {
  padding: 10px;
  height: calc(100% - 42px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hh-events .sidebar .date-img .day {
  height: auto;
}

.hh-events .date-img .weekday {
  font-size: 20px;
}

.hh-events .sidebar .date-img .weekday {
  font-size: 14px;
}

.hh-events .sidebar .date-img .weekday span {
  display: none;
}

.hh-events a:hover .date-img {
  border-color: #23527c;
}

.hh-events a:hover .date-img .month {
  background: #23527c;
}

.hh-events .events-list .row.event {
  display: none;
}

.hh-events .events-list .row.event.filter-match {
  display: block;
}

.hh-events .events .event .title {
  margin-bottom: 10px;
}

.hh-events .event a:hover .title, .hh-events .event a:focus .title {
  color: #43739c;
}

.hh-events .details {
  color: #505050;
  font-family: montserrat, serif;
  font-size: 12px;
  line-height: 1.5;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.hh-events .details div:not(.tooltip):not(.tooltip-arrow):not(.tooltip-inner) {
  display: inline;
  margin-right: 8px;
  margin-top: 10px;
  font-weight: 500;
}

.hh-events .details .date:before, .hh-events .details .location:before {
  content: "•";
  display: inline-block;
  margin-right: 10px;
}

.hh-events .details .featured:after {
  content: "•";
  display: inline-block;
  margin-left: 4px;
}

.hh-events .details .categories:before, .hh-events .details .time:before {
  content: "";
}

.hh-events .dot {
  margin-right: 3px;
  font-size: 10px;
  height: 14px;
  vertical-align: middle;
}

.hh-events .dot span.sr-only {
  color: #11151c;
}

.hh-events .events .event p {
  color: #11151c;
  margin: 0;
}

.hh-events .events .load-more button:focus {
  outline: none;
}

.hh-events .events .load-more {
  display: none;
  margin-bottom: 30px;
  text-align: center;
}

.hh-events .events .events-message {
  display: none;
  text-align: center;
}

/* Sidebar on Events Listing */
@media only screen and (max-width: 991px) {
  .hh-events .sidebar {
    border: 2px solid #e8eaed;
    border-radius: 2px;
    padding: 25px 15px 0 15px;
    margin-top: 10px;
  }
  .hh-events .sidebar .section-title:nth-child(2) {
    margin-top: 20px;
  }
  .hh-events .sidebar.collapsed .gradient-overlay {
    color: black;
    -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(rgb(0, 0, 0)), to(rgba(0, 0, 0, 0)));
    max-height: 200px;
    overflow: auto;
  }
}
.hh-events .sidebar .row {
  margin-bottom: 20px;
}

.hh-events .mobile-title {
  position: absolute;
  background-color: white;
  text-transform: uppercase;
  padding: 0 10px;
  z-index: 30;
  top: -3px;
  left: 20px;
}

.hh-events .mobile-title i {
  color: #43739c;
}

.hh-events .search-expand {
  color: #43739c;
  font-size: 25.629px;
  top: -20px;
  position: relative;
  text-align: center;
  z-index: 2;
}

.hh-events .search-expand i {
  background-color: white;
  padding: 0 10px;
}

.hh-events .plus-toggle {
  cursor: pointer;
}

.hh-events .sidebar input:focus {
  outline: 2px solid #43739c;
}

.hh-events .sidebar .date-views {
  margin-top: 20px;
  font-weight: bold;
  font-size: 14px;
}

.hh-events .sidebar .date-views button {
  color: #11151c;
  text-align: center;
  display: block;
  padding: 15px 10px;
  border: 2px solid #e8eaed;
  border-radius: 2px;
  background-color: white;
}

.hh-events .sidebar .date-views button:hover, .hh-events .sidebar .date-views button:focus, .hh-events .sidebar .date-views button.active {
  color: #43739c;
  border-color: #43739c;
  background-color: white;
  z-index: 1;
  outline: none;
}

.hh-events .sidebar .row.event {
  margin-bottom: 5px;
}

.hh-events .sidebar .event h3.title, .hh-events .sidebar .event .title.h3 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
}

.hh-events .sidebar .event .image .date-img {
  font-size: 16px;
}

.hh-events .sidebar .event .image .date-img .day {
  padding: 2px;
}

.hh-events .sidebar .event .details .featured, .hh-events .sidebar .event .details .location, .hh-events .sidebar .event .details .categories, .hh-events .sidebar .event p {
  display: none !important;
}

.hh-module .sidebar .primary-button.btn-block, .hh-module .sidebar .secondary-button.btn-block {
  padding: 10px 10px;
}

/* Remove duplicate borders for certain screen sizes */
@media only screen and (max-width: 991px) and (min-width: 768px), (min-width: 1200px) {
  .hh-events .sidebar .date-views button:nth-child(3n+2), .hh-events .sidebar .date-views button:nth-child(3n+3) {
    margin-left: -2px;
  }
  .hh-events .sidebar .date-views button:nth-child(n+4) {
    margin-top: -2px;
  }
}
@media only screen and (max-width: 767px), (min-width: 992px) and (max-width: 1199px) {
  .hh-events .sidebar .date-views button:nth-child(even) {
    margin-left: -2px;
  }
  .hh-events .sidebar .date-views button:nth-child(n+3) {
    margin-top: -2px;
  }
}
.hh-events .sidebar .checkboxes {
  color: #11151c;
}

.hh-events .sidebar .checkboxes label {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.hh-events .sidebar .checkboxes input[type=checkbox] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.hh-events .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border-radius: 12px;
  background-color: #767676;
}

.hh-events .sidebar .checkboxes label:hover, .hh-events .sidebar .checkboxes label input:focus ~ span {
  color: #43739c;
}

.hh-events .sidebar .checkboxes label:hover .checkmark, .hh-events .sidebar .checkboxes label input:focus ~ .checkmark {
  -webkit-box-shadow: inset 0 -200px 0 0 rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 -200px 0 0 rgba(0, 0, 0, 0.2);
}

.hh-events .sidebar .checkboxes .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.hh-events .sidebar .checkboxes label input:checked ~ .checkmark:after {
  display: block;
}

.hh-events .sidebar .checkboxes label .checkmark:after {
  left: 9px;
  top: 4px;
  width: 8px;
  height: 14px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.hh-events .sidebar .dropdown select, .hh-events .sidebar-detail .dropdown select {
  width: 100%;
  height: 50px;
  color: #11151c;
  font-weight: bold;
  background-color: white;
  border: 2px solid #e8eaed;
  border-radius: 2px;
  padding-left: 10px;
  cursor: pointer;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.hh-events .sidebar .dropdown select option, .hh-events .sidebar-detail .dropdown select option {
  font-weight: normal;
}

.hh-events .sidebar .dropdown select:hover, .hh-events .sidebar .dropdown select:focus, .hh-events .sidebar-detail .dropdown select:hover, .hh-events .sidebar-detail .dropdown select:focus {
  border-color: #43739c;
  outline: none;
}

.hh-events .sidebar .dropdown:after, .hh-events .sidebar-detail .dropdown:after {
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  position: absolute;
  top: 10px;
  right: 15px;
  z-index: 5;
  font-weight: 900;
  font-size: 24px;
  pointer-events: none;
}

.hh-events .feeds a {
  color: #11151c;
  margin-right: 15px;
  white-space: nowrap;
}

.hh-events .feeds a:hover {
  color: #43739c;
}

.hh-events .back-to-top {
  background-color: #43739c;
  cursor: pointer;
  position: fixed;
  bottom: 15px;
  right: 15px;
  border-radius: 2px;
  display: none;
  z-index: 10;
}

.hh-events .back-to-top:hover {
  background-color: #23527c;
}

/* Event Page Details */
.hh-events.breadcrumbs {
  background-color: #f5f5f5;
}

.hh-events.breadcrumbs ol {
  padding-top: 15px;
  padding-bottom: 15px;
}

.hh-events.breadcrumbs ol li {
  display: inline-block;
}

.hh-events.breadcrumbs ol li + li:before {
  display: inline-block;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  color: #767676;
  content: "/";
}

.hh-events.breadcrumbs ol li a {
  color: #11151c;
}

.hh-events.breadcrumbs ol li:hover a {
  color: #43739c;
}

.hh-events .content-detail {
  margin-bottom: 40px;
}

.hh-events .sidebar-detail .row {
  margin-bottom: 20px;
}

.hh-events .sidebar-detail .save-date {
  margin-bottom: 20px;
}

.hh-events .info > div {
  margin-bottom: 10px;
  display: table;
}

.hh-events .info > div > div {
  display: table-cell;
  vertical-align: middle;
}

.hh-events .icon {
  width: 40px;
  height: 40px;
  color: #43739c;
  border: 1px solid #e8eaed;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
  margin-right: 10px;
}

.hh-events .filters .filter {
  font-family: montserrat, serif;
  font-size: 12px;
}

.hh-events .filters .filter .filter-label {
  font-weight: bold;
  margin-bottom: 5px;
  margin-top: 20px;
  text-transform: uppercase;
  color: #11151c;
  font-size: 12px;
}

.hh-events .filters .filter .dot + .dot {
  margin-left: 10px;
}

.hh-events .filters .filter div {
  display: inline-block;
  margin-right: 8px;
}

.hh-events .filters .filter:not(.dots) div + div:before {
  content: "•";
  display: inline-block;
  margin-right: 10px;
}

.hh-events .icon-list {
  margin-bottom: 20px;
}

.hh-events .icon-list span {
  color: #11151c;
  font-size: 16px;
  margin-right: 10px;
}

.hh-events .icon-list a:hover span {
  color: #43739c;
}

.hh-events .feeds {
  margin-bottom: 20px;
}

.hh-events .related-events .event, .hh-events.hh-events-block .event {
  margin-bottom: 30px;
}

.hh-events .related-events .event .title, .hh-events.hh-events-block .event .title {
  font-size: 16px;
  padding-top: 10px;
}

.hh-events .related-events.mt-5 {
  margin-top: 3rem !important;
}

.hh-events .show-hhe {
  display: block !important;
}

.hh-events .hide-hhe {
  display: none !important;
}

.hh-events .related-events.mobile {
  display: block;
}

.hh-events .related-events.desktop {
  display: none;
}

@media (min-width: 767px) {
  .hh-events .related-events.mobile {
    display: none;
  }
  .hh-events .related-events.desktop {
    display: block;
  }
}
.featured-destination-cta {
  margin-bottom: 60px;
}
.featured-destination-cta--style-1 .text-and-image {
  position: relative;
}
@media (max-width: 575.98px) {
  .featured-destination-cta--style-1 .text-and-image {
    margin-left: 0;
    margin-right: 0;
  }
}
@media (min-width: 992px) {
  .featured-destination-cta--style-1 .text-and-image {
    overflow: hidden;
  }
}
.featured-destination-cta--style-1 .text-and-image__image {
  padding-left: 0;
  padding-right: 0;
}
.featured-destination-cta--style-1 .text-and-image__image:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(to bottom, rgba(0, 47, 109, 0) 0%, rgba(0, 47, 109, 0) 35%, #002f6d 100%);
  opacity: 0.25;
}
@media (max-width: 991.98px) {
  .featured-destination-cta--style-1 .text-and-image__image {
    position: relative;
    overflow: hidden;
  }
}
@media (min-width: 992px) {
  .featured-destination-cta--style-1 .text-and-image__image {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
}
.featured-destination-cta--style-1 .text-and-image__content {
  padding-top: 0;
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 992px) {
  .featured-destination-cta--style-1 .text-and-image__content {
    padding: 70px 0 70px 45px;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 510px;
  }
  .featured-destination-cta--style-1 .text-and-image__content .tertiary-button {
    font-size: 26px;
    text-shadow: 1.414px 1.414px 0px rgba(0, 0, 0, 0.4);
  }
  .featured-destination-cta--style-1 .text-and-image__content .tertiary-button a {
    color: #ffffff;
  }
}
@media (min-width: 1200px) {
  .featured-destination-cta--style-1 .text-and-image__content {
    padding-left: 60px;
    padding-bottom: 90px;
    min-height: 680px;
  }
}
@media (min-width: 1400px) {
  .featured-destination-cta--style-1 .text-and-image__content {
    padding-left: 111px;
  }
}
@media (max-width: 991.98px) {
  .featured-destination-cta--style-1 .text-and-image__content {
    text-align: left;
  }
}
.featured-destination-cta--style-1 .text-and-image__content h2, .featured-destination-cta--style-1 .text-and-image__content .h2 {
  margin-bottom: 27px;
  font-family: futura-pt, sans-serif;
  font-weight: 500;
  font-size: 48px;
  letter-spacing: 0.02em;
  line-height: 1;
  color: #19519a;
}
@media (min-width: 992px) {
  .featured-destination-cta--style-1 .text-and-image__content h2, .featured-destination-cta--style-1 .text-and-image__content .h2 {
    font-size: 62px;
    text-shadow: 1.414px 1.414px 0px rgba(0, 0, 0, 0.4);
    color: #ffffff;
  }
}
@media (max-width: 1399.98px) {
  .featured-destination-cta--style-1 .text-and-image__content h2, .featured-destination-cta--style-1 .text-and-image__content .h2 {
    position: relative;
    padding-bottom: 10px;
  }
}
.featured-destination-cta--style-1 .text-and-image__content h2:after, .featured-destination-cta--style-1 .text-and-image__content .h2:after {
  content: "";
  position: absolute;
  background-color: #e36113;
  height: 4px;
  width: 80px;
  left: -15px;
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .featured-destination-cta--style-1 .text-and-image__content h2:after, .featured-destination-cta--style-1 .text-and-image__content .h2:after {
    left: -44px;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .featured-destination-cta--style-1 .text-and-image__content h2:after, .featured-destination-cta--style-1 .text-and-image__content .h2:after {
    left: -60px;
  }
}
@media (max-width: 1399.98px) {
  .featured-destination-cta--style-1 .text-and-image__content h2:after, .featured-destination-cta--style-1 .text-and-image__content .h2:after {
    bottom: -11px;
  }
}
@media (min-width: 1400px) {
  .featured-destination-cta--style-1 .text-and-image__content h2:after, .featured-destination-cta--style-1 .text-and-image__content .h2:after {
    left: 0;
    margin-top: 10px;
  }
}
.featured-destination-cta--style-1 .text-and-image__content h2 .subtitle, .featured-destination-cta--style-1 .text-and-image__content .h2 .subtitle {
  text-transform: none;
  color: #ffffff;
  line-height: 1.421;
  text-shadow: 1.414px 1.414px 0px rgba(0, 0, 0, 0.4);
  font-size: 32px;
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .featured-destination-cta--style-1 .text-and-image__content h2 .subtitle, .featured-destination-cta--style-1 .text-and-image__content .h2 .subtitle {
    font-size: 29px;
  }
}
@media (min-width: 992px) {
  .featured-destination-cta--style-1 .text-and-image__content h2 .subtitle, .featured-destination-cta--style-1 .text-and-image__content .h2 .subtitle {
    font-size: 38px;
    margin-bottom: -5px;
  }
}
@media (max-width: 991.98px) {
  .featured-destination-cta--style-1 .text-and-image__content h2 .subtitle, .featured-destination-cta--style-1 .text-and-image__content .h2 .subtitle {
    margin-top: -50px;
    margin-bottom: 17px;
  }
}
@media (min-width: 992px) {
  .featured-destination-cta--style-1 .text-and-image__content p {
    line-height: 1.5;
    text-shadow: 1.414px 1.414px 0px rgba(19, 41, 71, 0.75);
  }
}
.featured-destination-cta--style-1 .text-and-image__content .button-set {
  margin-top: 12px;
}
@media (min-width: 992px) {
  .featured-destination-cta--style-1 .text-and-image__content .button-set .btn,
  .featured-destination-cta--style-1 .text-and-image__content .button-set .tertiary-button a {
    white-space: nowrap;
  }
}
@media (max-width: 767.98px) {
  .featured-destination-cta--style-1 .text-and-image__content .button-set .tertiary-button a {
    font-size: 20px;
  }
}
.featured-destination-cta--style-1 .figure {
  border-left: 0 none;
}
@media (min-width: 992px) {
  .featured-destination-cta--style-1 .figure {
    height: 100%;
    width: 100%;
    display: flex;
  }
  .featured-destination-cta--style-1 .figure-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: right;
  }
}
.featured-destination-cta--style-1 .figure:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.17) 0%, #002f6d 100%);
  right: 78%;
  transform: scale(3) rotate(-35deg);
  left: 0px;
  opacity: 0.5;
}
@media (min-width: 992px) {
  .featured-destination-cta--style-1 .figure:before {
    right: 75.5%;
  }
}
@media (min-width: 1200px) {
  .featured-destination-cta--style-1 .figure:before {
    right: 80%;
  }
}
.featured-destination-cta--style-1 .figure:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  background: linear-gradient(188deg, rgba(0, 47, 109, 0) 0%, rgba(0, 47, 109, 0) 46%, #002f6d 100%);
  right: 83%;
  transform: scale(3) rotate(-35deg);
  left: 0px;
}
@media (min-width: 992px) {
  .featured-destination-cta--style-1 .figure:after {
    right: 78.5%;
  }
}
@media (min-width: 1200px) {
  .featured-destination-cta--style-1 .figure:after {
    right: 83%;
  }
}
@media (max-width: 575.98px) {
  .featured-destination-cta--style-1 .container {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (max-width: 991.98px) {
  .featured-destination-cta--style-2 {
    margin-top: 130px;
  }
}
@media (max-width: 991.98px) and (max-width: 991.98px) {
  .featured-destination-cta--style-2 .text-and-image__image {
    display: flex;
    justify-content: center;
    margin-top: -79px;
  }
}
@media (max-width: 767.98px) {
  .featured-destination-cta--style-2 h2 .subtitle, .featured-destination-cta--style-2 .h2 .subtitle {
    margin-bottom: 5px;
  }
}
.featured-destination-cta--style-3 {
  position: relative;
  overflow: hidden;
}
@media (min-width: 992px) {
  .featured-destination-cta--style-3 {
    padding-left: 15px;
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media (max-width: 991.98px) {
  .featured-destination-cta--style-3 .container {
    max-width: none;
  }
}
@media (max-width: 991.98px) {
  .featured-destination-cta--style-3 .text-and-image {
    background-image: linear-gradient(180deg, rgb(0, 47, 109) 0%, rgb(20, 100, 222) 100%);
  }
  .featured-destination-cta--style-3 .text-and-image:after {
    content: "";
    background: transparent url("../images/overlay-featured-destination-cta-style-3_mobile.png") no-repeat scroll left top;
    background-size: auto 100%;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
  }
}
.featured-destination-cta--style-3 .text-and-image__content {
  padding-left: 40px;
  padding-top: 25px;
  padding-bottom: 25px;
}
@media (min-width: 992px) {
  .featured-destination-cta--style-3 .text-and-image__content {
    padding: 0 4% 0 55px;
  }
}
.featured-destination-cta--style-3 .text-and-image__content:before {
  content: "";
  position: absolute;
  top: 25px;
  left: 15px;
  width: 4px;
  background-color: #e36113;
  height: 0;
  transition: height 1.5s ease-in-out;
}
@media (min-width: 992px) {
  .featured-destination-cta--style-3 .text-and-image__content:before {
    top: 0;
    left: 0;
  }
}
.featured-destination-cta--style-3 .text-and-image__content.animate-in-view--intersecting:before {
  height: calc(100% - 50px);
}
@media (min-width: 992px) {
  .featured-destination-cta--style-3 .text-and-image__content.animate-in-view--intersecting:before {
    height: 100%;
  }
}
@media (max-width: 991.98px) {
  .featured-destination-cta--style-3 .text-and-image__content {
    text-align: left;
    z-index: 1;
  }
  .featured-destination-cta--style-3 .text-and-image__content .button-set {
    align-items: flex-start;
  }
}
.featured-destination-cta--style-3 .text-and-image__content h2, .featured-destination-cta--style-3 .text-and-image__content .h2, .featured-destination-cta--style-3 .text-and-image__content h3, .featured-destination-cta--style-3 .text-and-image__content .h3 {
  line-height: 1.074;
}
@media (min-width: 992px) {
  .featured-destination-cta--style-3 .text-and-image__content h2, .featured-destination-cta--style-3 .text-and-image__content .h2, .featured-destination-cta--style-3 .text-and-image__content h3, .featured-destination-cta--style-3 .text-and-image__content .h3 {
    padding-right: 17%;
  }
}
@media (max-width: 991.98px) {
  .featured-destination-cta--style-3 .text-and-image__content p {
    font-size: 18px;
    line-height: 1.556;
  }
}
.featured-destination-cta--style-3 .text-and-image__content .tertiary-button a {
  font-size: 20px;
}
.featured-destination-cta--style-4 h2, .featured-destination-cta--style-4 .h2 {
  margin-bottom: 16px;
}
.featured-destination-cta--style-4 .title--bottom-divider {
  padding-bottom: 30px;
}
.featured-destination-cta--style-4 p {
  letter-spacing: 0.02em;
}
@media (max-width: 767.98px) {
  .featured-destination-cta--style-4 p {
    font-size: 18px;
    line-height: 1.556;
  }
}

.featured-events-feed {
  margin-bottom: 80px;
}
@media (min-width: 992px) {
  .featured-events-feed {
    margin-bottom: 100px;
  }
}
.featured-events-feed__more-link {
  margin-top: 20px;
  font-size: 26px;
}
.featured-events-feed__mobile-more-link {
  font-size: 20px;
}
.featured-events-feed__rotator-item {
  height: 100%;
}
.featured-events-feed__controls {
  height: 70px;
  display: flex;
  align-items: center;
  position: relative;
  margin-top: -5px;
  border-bottom: 1px solid #e1e0db;
  margin-bottom: 10px;
}
.featured-events-feed__controls .splide__arrows {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.featured-events-feed__controls .splide__pagination {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.featured-events-feed__controls .splide__arrow {
  position: absolute;
  z-index: 1;
  background: transparent;
  border: 0;
}
.featured-events-feed__controls .splide__arrow svg {
  display: none;
}
.featured-events-feed__controls .splide__arrow:before {
  font-family: "Font Awesome 6 Pro";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-weight: 400;
  font-size: 24px;
  color: #797978;
  transition: color 0.35s ease;
}
.featured-events-feed__controls .splide__arrow:hover:before {
  color: #13120e;
}
.featured-events-feed__controls .splide__arrow--prev {
  position: absolute;
  left: calc(50% - 110px);
}
.featured-events-feed__controls .splide__arrow--prev:before {
  content: "\f060";
}
.featured-events-feed__controls .splide__arrow--next {
  position: absolute;
  right: calc(50% - 110px);
}
.featured-events-feed__controls .splide__arrow--next:before {
  content: "\f061";
}
.featured-events-feed__controls .splide__pagination__page {
  background: transparent;
  border: none;
  display: block;
  padding: 5px;
  margin: 0 2px;
}
.featured-events-feed__controls .splide__pagination__page:before {
  content: "";
  display: block;
  height: 12px;
  width: 12px;
  border-radius: 100px;
  background: #9b9b99;
  transition: background-color 0.35s ease;
}
.featured-events-feed__controls .splide__pagination__page.is-active:before, .featured-events-feed__controls .splide__pagination__page:hover:before {
  background: #e36113;
}
.featured-events-feed .splide__track {
  padding-bottom: 5px;
}

.featured-news-feed {
  margin-bottom: 80px;
  background: #f7f7f5;
  padding: 35px;
}
@media (min-width: 992px) {
  .featured-news-feed {
    margin-bottom: 100px;
  }
}
@media (min-width: 992px) {
  .featured-news-feed {
    padding: 40px;
  }
}
.featured-news-feed__more-link {
  margin-top: 20px;
  font-size: 26px;
}
.featured-news-feed__mobile-more-link {
  font-size: 20px;
}
.featured-news-feed__more-button {
  display: table;
  margin: 35px auto 0 auto;
}
.featured-news-feed__controls {
  height: 70px;
  display: flex;
  align-items: center;
  position: relative;
  border-bottom: 1px solid #e1e0db;
  margin-bottom: 10px;
}
.featured-news-feed__controls .splide__arrows {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.featured-news-feed__controls .splide__pagination {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.featured-news-feed__controls .splide__arrow {
  position: absolute;
  z-index: 1;
  background: transparent;
  border: 0;
}
.featured-news-feed__controls .splide__arrow svg {
  display: none;
}
.featured-news-feed__controls .splide__arrow:before {
  font-family: "Font Awesome 6 Pro";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-weight: 400;
  font-size: 24px;
  color: #797978;
  transition: color 0.35s ease;
}
.featured-news-feed__controls .splide__arrow:hover:before {
  color: #13120e;
}
.featured-news-feed__controls .splide__arrow--prev {
  position: absolute;
  left: calc(50% - 110px);
}
.featured-news-feed__controls .splide__arrow--prev:before {
  content: "\f060";
}
.featured-news-feed__controls .splide__arrow--next {
  position: absolute;
  right: calc(50% - 110px);
}
.featured-news-feed__controls .splide__arrow--next:before {
  content: "\f061";
}
.featured-news-feed__controls .splide__pagination__page {
  background: transparent;
  border: none;
  display: block;
  padding: 5px;
  margin: 0 2px;
}
.featured-news-feed__controls .splide__pagination__page:before {
  content: "";
  display: block;
  height: 12px;
  width: 12px;
  border-radius: 100px;
  background: #9b9b99;
  transition: background-color 0.35s ease;
}
.featured-news-feed__controls .splide__pagination__page.is-active:before, .featured-news-feed__controls .splide__pagination__page:hover:before {
  background: #e36113;
}

.herofeature {
  margin-bottom: 80px;
  position: relative;
}
@media (min-width: 992px) {
  .herofeature {
    margin-bottom: 100px;
  }
}
.herofeature__parallax-clip {
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.herofeature__parallax {
  position: absolute;
  top: 0;
  height: 100%;
  left: 0;
  right: 0;
}
.herofeature:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.08) 0, rgba(0, 0, 0, 0) 50px);
  min-height: 50px;
}
.herofeature--large:before, .herofeature--full:before {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 0, rgba(0, 0, 0, 0.3) 30px, rgba(0, 0, 0, 0) 159px);
  min-height: 159px;
}
@media (min-width: 992px) {
  .herofeature--large:before, .herofeature--full:before {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 0, rgba(0, 0, 0, 0.3) 60px, rgba(0, 0, 0, 0) 390px);
    min-height: 390px;
  }
}
.herofeature__top {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  position: relative;
  padding-top: 110px;
}
@media (min-width: 768px) {
  .herofeature__top {
    padding-top: 160px;
  }
}
.herofeature__background {
  position: absolute;
  top: 0;
  height: 100%;
  left: 0;
  right: 0;
  background: #114594;
  background-position: center;
  background-size: cover;
}
.herofeature__background-overlays {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  overflow: hidden;
}
.herofeature--full .herofeature__background-overlays:before {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 0, rgba(0, 0, 0, 0.3) 100%), linear-gradient(to top, rgba(0, 0, 0, 0.5) 0, rgba(0, 0, 0, 0.5) 110px, rgba(0, 0, 0, 0) 365px);
}
.herofeature__background-overlays:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.3);
}
.herofeature__background-overlays:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 80%;
  z-index: 1;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: left bottom;
}
@media (max-width: 767.98px) {
  .herofeature__background-overlays:after {
    min-width: 0;
    width: 125%;
    right: 10%;
  }
}
.herofeature--diagonal-overlay-full .herofeature__background-overlays:after {
  background-image: url("../images/home-hero-bkgd-overlay.png");
}
.herofeature--diagonal-overlay-large .herofeature__background-overlays:after {
  background-image: url("../images/landing-hero-bkgd-overlay.png");
}
.herofeature--diagonal-overlay-small .herofeature__background-overlays:after {
  background-image: url("../images/content-hero-bkgd-overlay.png");
}
.herofeature video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.herofeature__videocontrol {
  position: absolute;
  bottom: 70px;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 46px;
  height: 46px;
  border-radius: 100%;
  border: 2px solid #f7f7f5;
  background: #13120e;
  opacity: 0.4;
  color: #f7f7f5;
  transition: opacity 0.2s;
}
.herofeature__videocontrol:hover {
  opacity: 1;
}
.herofeature__videocontrol:focus-visible {
  opacity: 1;
}
.herofeature__videocontrol-pause:before {
  font-family: "Font Awesome 6 Pro";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-weight: 900;
  display: block;
  content: "\f04c";
  font-size: 20px;
}
.herofeature__videocontrol-play:before {
  font-family: "Font Awesome 6 Pro";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-weight: 900;
  display: block;
  content: "\f04b";
  font-size: 20px;
  margin-right: -2px;
}
.herofeature__columns {
  position: relative;
  width: 100%;
  bottom: -30px;
}
@media (min-width: 992px) {
  .herofeature__columns {
    bottom: -30px;
  }
}
.herofeature__back-link {
  position: relative;
  z-index: 2;
}
@media (max-width: 991.98px) {
  .herofeature--no-thumbnail .herofeature__content {
    padding-bottom: 35px;
  }
}
.herofeature__content {
  position: relative;
  z-index: 2;
  padding-top: 16px;
  padding-left: 24px;
  padding-right: 24px;
}
.herofeature__content:before {
  content: "";
  z-index: 1;
  display: block;
  width: 4px;
  left: 0;
  bottom: 0;
  top: 0;
  position: absolute;
  background: #e36113;
}
@media (min-width: 992px) {
  .herofeature__content {
    padding-top: 25px;
    padding-left: 40px;
    padding-right: 0;
    border-right: none;
  }
}
@media (min-width: 992px) {
  .herofeature__content {
    padding-bottom: 80px;
  }
}
.herofeature__title {
  font-family: futura-pt, sans-serif;
  font-weight: 400;
  line-height: 1.1;
  padding-bottom: 5px;
  color: white;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.3);
}
@media (min-width: 992px) {
  .herofeature__title {
    margin-top: -14px;
  }
}
.herofeature__title .herofeature__eyebrow {
  font-size: 26px;
  display: block;
}
@media (min-width: 992px) {
  .herofeature__title .herofeature__eyebrow {
    font-size: 30px;
  }
}
.herofeature__title .herofeature__course-code {
  font-size: 28px;
}
@media (min-width: 992px) {
  .herofeature__title .herofeature__course-code {
    font-size: 46px;
  }
}
.herofeature__h1 {
  color: white;
  line-height: 1.1;
  font-size: 44px;
  font-weight: 500;
}
@media (min-width: 768px) {
  .herofeature__h1 {
    font-size: 84px;
  }
}
.herofeature--smaller-title .herofeature__h1 {
  font-size: 34px;
}
@media (min-width: 768px) {
  .herofeature--smaller-title .herofeature__h1 {
    font-size: 55px;
  }
}
@media (min-width: 1200px) {
  .herofeature--smaller-title .herofeature__h1 {
    font-size: 72px;
  }
}
.herofeature__paragraph {
  color: white;
  font-size: 18px;
  letter-spacing: 0.03em;
  padding-bottom: 35px;
  font-style: italic;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.3);
}
@media (min-width: 992px) {
  .herofeature__paragraph {
    font-size: 22px;
    padding-bottom: 0;
  }
}
.herofeature__paragraph p:last-child {
  margin-bottom: 0;
}
.herofeature__paragraph--mobile {
  color: #001a3d;
  font-weight: 500;
  text-shadow: none;
  padding-bottom: 20px;
}
@media (min-width: 992px) {
  .herofeature--large .herofeature__paragraph {
    font-size: 32px;
  }
}
.herofeature__learnmore--mobile {
  font-size: 20px;
}
.herofeature__learnmore {
  font-size: 26px;
  margin-top: 5px;
}
.herofeature__links {
  margin-top: 45px;
  margin-bottom: 30px;
}
@media (min-width: 992px) {
  .herofeature__links {
    margin-bottom: 0;
  }
}
.herofeature__link + .herofeature__link {
  margin-left: 20px;
}
.herofeature__mobile-panel {
  background: #f7f7f5;
  position: relative;
  z-index: 1;
  border-bottom: 1px solid #e1e0db;
}
.herofeature__mobile-elements {
  padding: 35px 20px 30px 20px;
}
.herofeature__links-mobile {
  margin-top: 20px;
}
.herofeature__links-mobile:first-child {
  margin-top: 0;
}
.herofeature__link-mobile {
  display: inline-block;
  margin-top: 15px;
  margin-right: 20px;
}
.herofeature__thumbnail {
  position: relative;
  z-index: 2;
  padding-left: 24px;
  padding-right: 24px;
}
.herofeature__thumbnail:before {
  content: "";
  z-index: 1;
  display: block;
  width: 4px;
  height: 100%;
  left: 0;
  bottom: 0;
  position: absolute;
  background: #e36113;
}
@media (min-width: 992px) {
  .herofeature__thumbnail {
    padding-right: 0;
  }
  .herofeature__thumbnail:before {
    content: none;
  }
}
.herofeature__thumbnail img {
  display: block;
  width: 100%;
  height: auto;
}
.herofeature--small.herofeature--smaller-title.herofeature--no-thumbnail .herofeature__content {
  padding-bottom: 50px;
}
.herofeature--small .herofeature__top {
  min-height: 310px;
}
@media (min-width: 992px) {
  .herofeature--small .herofeature__top {
    min-height: 450px;
  }
}
.herofeature--large .herofeature__top {
  min-height: 440px;
}
@media (min-width: 992px) {
  .herofeature--large .herofeature__top {
    min-height: 700px;
  }
}
.herofeature--full .herofeature__top {
  min-height: 100vh;
  min-height: calc(100vh - var(--vh-offset, 0px));
}
.herofeature__center {
  width: 100%;
}
.herofeature__center .herofeature__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 860px;
  margin: 0 auto;
  padding: 0 0 25px 0;
}
@media (min-width: 992px) {
  .herofeature__center .herofeature__content {
    padding-bottom: 45px;
  }
}
.herofeature__center .herofeature__content:before {
  content: none;
}
.herofeature--center .herofeature__h1 {
  font-size: 42px;
  padding-bottom: 25px;
  margin-bottom: 20px;
}
@media (min-width: 992px) {
  .herofeature--center .herofeature__h1 {
    font-size: 84px;
    padding-bottom: 30px;
  }
}
@media screen and (min-width: 992px) and (max-height: 900px) {
  .herofeature--center .herofeature__h1 {
    font-size: 64px;
  }
}
.herofeature--center .herofeature__paragraph {
  margin-bottom: 0;
  padding-bottom: 0;
}
@media (min-width: 992px) {
  .herofeature--center .herofeature__paragraph {
    font-size: 32px;
    margin-bottom: 40px;
  }
}
@media screen and (min-width: 992px) and (max-height: 900px) {
  .herofeature--center .herofeature__paragraph {
    font-size: 24px;
    margin-bottom: 20px;
  }
}
.herofeature--center .herofeature__links {
  margin-top: 20px;
  margin-bottom: 25px;
}
.herofeature--center .herofeature__links-mobile {
  margin-top: 25px;
}
.herofeature--center .herofeature__link-mobile {
  display: table;
  margin: 0 auto;
}
.herofeature--center .herofeature__learnmore--mobile {
  text-align: center;
  margin-top: 30px;
}
.herofeature--lift-text .herofeature__top {
  align-items: center;
}
@media (min-width: 992px) {
  .herofeature--lift-text .herofeature__top {
    align-items: bottom;
  }
}
.herofeature--lift-text .herofeature__content {
  margin-top: 100px;
  padding-bottom: 0;
}
@keyframes herofeature_orangebar {
  0% {
    bottom: 100%;
    opacity: 0;
  }
  15% {
    bottom: 85%;
    opacity: 0;
  }
  100% {
    bottom: 0;
    opacity: 1;
  }
}
@keyframes herofeature_full_orangebar {
  0% {
    top: -35px;
    bottom: 100%;
    opacity: 0;
  }
  100% {
    top: 0;
    bottom: 0;
    opacity: 1;
  }
}
@keyframes herofeature_full_title {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  40% {
    opacity: 0;
    transform: translateY(-20px);
  }
  80% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes herofeature_full_links {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  60% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@media (min-width: 992px) {
  .animate .herofeature__content:before {
    animation-duration: 1.5s;
    animation-timing-function: ease-out;
    animation-name: herofeature_orangebar;
  }
  .herofeature--full.animate .herofeature__content:before {
    animation-duration: 1.75s;
    animation-timing-function: ease-out;
    animation-name: herofeature_full_orangebar;
  }
  .animate .herofeature__thumbnail:before {
    animation-duration: 2s;
    animation-timing-function: ease-out;
    animation-name: herofeature_orangebar;
  }
  .herofeature--full.animate .herofeature__title {
    animation-duration: 2.5s;
    animation-timing-function: ease-out;
    animation-name: herofeature_full_title;
  }
  .herofeature--full.animate .herofeature__links {
    animation-duration: 2.5s;
    animation-timing-function: ease-out;
    animation-name: herofeature_full_links;
  }
}
.herofeature .post-meta {
  padding-left: 2px;
  line-height: 1;
  margin-top: -10px;
  margin-bottom: 15px;
}
@media (min-width: 992px) {
  .herofeature .post-meta {
    margin-bottom: 32px;
  }
}
.herofeature .post-meta__type {
  text-transform: uppercase;
  font-style: normal;
  font-weight: 500;
}
.herofeature .post-meta__date {
  text-transform: none;
}
.herofeature .post-meta li {
  font-family: futura-pt, sans-serif;
  letter-spacing: 0.04em;
  color: #b3ccef;
  font-size: 18px;
}
@media (min-width: 768px) {
  .herofeature .post-meta li {
    font-size: 24px;
  }
  .herofeature .post-meta li:not(:last-child):after {
    content: "|";
    font-weight: 400;
    padding-left: 11px;
    position: relative;
    top: -2px;
  }
}
@media (max-width: 767.98px) {
  .herofeature .post-meta li {
    display: block;
    margin-bottom: 8px;
  }
  .herofeature .post-meta li:after {
    display: none;
  }
}
.herofeature--no-overflow .herofeature__columns {
  bottom: 0;
}
@media (min-width: 992px) {
  .herofeature--no-overflow .herofeature__content {
    padding-bottom: 50px;
  }
}
.herofeature--no-overflow.herofeature--small.herofeature--smaller-title.herofeature--no-thumbnail .herofeature__content {
  padding-bottom: 20px;
}

.image-slider {
  margin-bottom: 80px;
}
@media (min-width: 992px) {
  .image-slider {
    margin-bottom: 100px;
  }
}

.splide--image-slider {
  background-color: #f7f7f5;
}
.splide--image-slider:before {
  content: "";
  background-color: #bcbbb6;
  width: 4px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
}
.splide--image-slider .figure {
  margin-bottom: 0;
  width: 100%;
}
.splide--image-slider .figure-caption {
  padding-bottom: 0;
}
.splide--image-slider .figure-img {
  width: 100%;
}
.splide--image-slider .splide__slide__container {
  border-left: 4px solid #e36113;
}
.splide--image-slider .splide__slide__container__caption {
  font-style: italic;
  letter-spacing: 0.05em;
  color: #464540;
  border-left: 4px solid #bcbbb6;
  background-color: #f7f7f5;
  padding: 17px;
  font-size: 14px;
  line-height: 1.714;
  padding-bottom: 0;
}
@media (min-width: 992px) {
  .splide--image-slider .splide__slide__container__caption {
    font-size: 16px;
    line-height: 1.625;
  }
}
.splide--image-slider .splide__slide__container__image {
  width: 100%;
}
.splide--image-slider .splide__arrows {
  display: flex;
  justify-content: center;
  padding-top: 12px;
  padding-bottom: 14px;
}
.splide--image-slider .splide__arrow {
  background-color: transparent;
  border: 0 none;
  transition: color 0.35s ease;
}
.splide--image-slider .splide__arrow svg {
  display: none;
}
.splide--image-slider .splide__arrow:before {
  content: "\f061";
  font-family: "Font Awesome 6 Pro";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-weight: 400;
}
.splide--image-slider .splide__arrow--prev:before {
  content: "\f060";
}
.splide--image-slider .splide__arrow[disabled] {
  color: rgba(0, 0, 0, 0.5);
}
.splide--image-slider .splide__pagination {
  padding-left: 22px;
  padding-right: 22px;
  counter-reset: pagination-num;
}
.splide--image-slider .splide__pagination > li:last-child .splide__pagination__page {
  width: auto;
  height: auto;
  visibility: visible;
  font-size: 19px;
  display: block;
}
.splide--image-slider .splide__pagination > li:last-child .splide__pagination__page.is-active:before {
  counter-increment: pagination-num;
  content: counter(pagination-num) " / " counter(pagination-num);
}
.splide--image-slider .splide__pagination > li:last-child .splide__pagination__page.is-active:after {
  display: none;
}
.splide--image-slider .splide__pagination__page {
  padding: 0;
  border: 0 none;
  line-height: 1.1;
  background-color: transparent;
  font-weight: 500;
  width: 0;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  font-size: 0;
  pointer-events: none;
}
.splide--image-slider .splide__pagination__page:before {
  display: inline-block;
}
.splide--image-slider .splide__pagination__page.is-active {
  font-size: 19px;
  display: block;
  width: auto;
  height: auto;
  visibility: visible;
}
.splide--image-slider .splide__pagination__page.is-active:after {
  content: "/";
  padding: 0 4px;
}
.splide--image-slider .splide__pagination__page:before {
  counter-increment: pagination-num;
  content: counter(pagination-num);
}
.splide--image-slider .splide__video__play {
  background-color: #171711;
  border: 4px solid #ffffff;
  width: 75px;
  height: 75px;
  opacity: 0.75;
}
.splide--image-slider .splide__video__play:after {
  border: 0 none;
  color: #ffffff;
  font-size: 28px;
  content: "\f04b";
  font-family: "Font Awesome 6 Pro";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-weight: 900;
}

.gallery--ccbc {
  margin-bottom: 80px;
}
@media (min-width: 992px) {
  .gallery--ccbc {
    margin-bottom: 100px;
  }
}
.gallery--ccbc.nGY2 .nGY2paginationRectangle,
.gallery--ccbc.nGY2 .nGY2paginationRectangleCurrentPage {
  width: 12px;
  height: 12px;
  border: 0 none;
  margin-left: 7px;
  margin-right: 7px;
  margin-top: 25px;
  border-radius: 50%;
  transition: background-color 0.35s ease;
}
.gallery--ccbc.nGY2 .nGY2paginationRectangle {
  background: #9b9b99;
  opacity: 1;
}
.gallery--ccbc.nGY2 .nGY2paginationRectangleCurrentPage {
  background-color: #e36113;
}

.nGY2 .toolbar .pageCounter {
  font-size: 19px !important;
}
.nGY2 .toolbar .label {
  text-align: center !important;
}
.nGY2 .toolbar .label .title {
  font-size: 20px !important;
}
.nGY2 .nGY2ViewerAreaNext > i,
.nGY2 .nGY2ViewerAreaPrevious > i {
  background-color: transparent !important;
}
.nGY2 .nGY2ViewerAreaNext > i:before,
.nGY2 .nGY2ViewerAreaPrevious > i:before {
  font-weight: 400;
}
.nGY2 .closeButton .fa:before {
  font-weight: 400;
}
.nGY2 .nGY2GThumbnail:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  background-color: #e36113;
  height: 4px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.35s ease;
}
.nGY2 .nGY2GThumbnail:hover:before, .nGY2 .nGY2GThumbnail:focus:before {
  opacity: 1;
}
.nGY2 .nGY2GThumbnail:hover .nGY2GThumbnailImage, .nGY2 .nGY2GThumbnail:focus .nGY2GThumbnailImage {
  transform: scale(1.1);
}
.nGY2 .nGY2GThumbnailImage {
  transition: transform 1.25s ease;
}
.nGY2 .nGY2Navigationbar .nGY2NavPagination {
  background-color: transparent;
  color: #000000;
  border-radius: 50%;
  width: 46px;
  height: 46px;
  padding: 0;
  border: 2px solid #f0bf20;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.35s ease;
}
.nGY2 .nGY2Navigationbar .nGY2NavPagination .nGY2Icon-ngy2_chevron-right {
  margin-right: 0;
}
.nGY2 .nGY2Navigationbar .nGY2NavPagination:hover, .nGY2 .nGY2Navigationbar .nGY2NavPagination:focus {
  background-color: #f0bf20;
  color: #000000;
}

.multi-link-feature {
  margin-bottom: 80px;
}
@media (min-width: 992px) {
  .multi-link-feature {
    margin-bottom: 100px;
  }
}
.multi-link-feature__link {
  position: relative;
  z-index: 0;
  display: block;
  height: 150px;
  border-radius: 5px;
  margin-bottom: 10px;
}
.multi-link-feature__link:after {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  height: 20px;
  bottom: 5px;
  border-radius: 5px;
  transition: bottom 0.35s ease;
  background: #e36113;
}
.multi-link-feature__background-wrap {
  height: 150px;
}
.multi-link-feature--image .multi-link-feature__link {
  height: 260px;
}
.multi-link-feature--image .multi-link-feature__background-wrap {
  height: 260px;
}
.multi-link-feature__background-wrap {
  position: relative;
  overflow: hidden;
  border-radius: 5px;
}
.multi-link-feature__background {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-position: center;
  background-size: cover;
  overflow: hidden;
  transition: transform 1.25s ease;
}
.multi-link-feature--image .multi-link-feature__background:before {
  content: "";
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: background-color 0.35s ease, opacity 0.35s ease;
}
.multi-link-feature:not(.multi-link-feature--image) .multi-link-feature__background {
  background: linear-gradient(to bottom, rgba(6, 47, 110, 0) 0, #062f6e 100%), url("../images/overlay-cards-cta.png"), linear-gradient(to bottom, #062f6e 0, #062f6e 100%) !important;
  background-position: left bottom !important;
  background-size: cover !important;
}
.multi-link-feature--image .multi-link-feature__link:hover .multi-link-feature__background, .multi-link-feature--image .multi-link-feature__link:focus .multi-link-feature__background {
  transform: scale(1.1);
}
.multi-link-feature__text {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: flex-end;
  text-align: center;
  padding: 36px;
  transition: transform 1.25s ease;
  color: white;
  font-family: futura-pt, sans-serif;
  font-size: 24px;
  line-height: 28px;
  font-weight: 500;
  text-transform: uppercase;
  z-index: 1;
  text-decoration: none;
}
@media (min-width: 768px) {
  .multi-link-feature__text {
    font-size: 28px;
    line-height: 32px;
  }
}
.multi-link-feature:not(.multi-link-feature--image) .multi-link-feature__text {
  justify-content: center;
  font-size: 20px;
  line-height: 24px;
}
@media (min-width: 768px) {
  .multi-link-feature:not(.multi-link-feature--image) .multi-link-feature__text {
    font-size: 24px;
    line-height: 28px;
  }
}
.multi-link-feature__link:hover:after {
  bottom: -5px;
}
.multi-link-feature:not(.multi-link-feature--image) .multi-link-feature__background:after {
  content: "";
  background: rgba(0, 26, 61, 0);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: background-color 0.35s ease, opacity 0.35s ease;
}
.multi-link-feature:not(.multi-link-feature--image) .multi-link-feature__link:hover .multi-link-feature__background:after {
  content: "";
  background: rgba(0, 26, 61, 0.6);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: background-color 0.35s ease, opacity 0.35s ease;
}
.multi-link-feature--image .multi-link-feature__link:hover .multi-link-feature__text {
  transform: translateY(-18px);
}

.news-detail__meta {
  background-color: #f7f7f5;
  padding: 20px 30px 18px;
  display: flex;
  margin-bottom: 40px;
  flex-wrap: wrap;
}
@media (min-width: 992px) {
  .news-detail__meta {
    flex-wrap: nowrap;
    margin-bottom: 70px;
  }
}
.news-detail__meta .post-meta {
  padding-left: 0;
}
.news-detail__meta .post-meta li {
  text-transform: none;
  color: #000000;
  font-weight: 400;
  font-size: 18px;
  font-family: lato, sans-serif;
}
.news-detail__meta .post-meta li::after {
  display: none;
}
@media (min-width: 992px) {
  .news-detail__meta .post-meta + .post-category {
    padding-left: 20px;
    margin-left: 20px;
    position: relative;
  }
  .news-detail__meta .post-meta + .post-category:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 1px;
    background-color: #bcbbb6;
  }
}
@media (max-width: 991.98px) {
  .news-detail__meta .post-category {
    width: 100%;
  }
}
.news-detail__meta .post-category span,
.news-detail__meta .post-category a {
  letter-spacing: 0.05em;
  text-transform: none;
  font-size: 18px;
  font-family: lato, sans-serif;
  font-weight: 700;
}
.news-detail__meta .post-category span {
  color: #000000;
}
.news-detail__meta .post-category a {
  color: #1464de;
  text-decoration: underline;
}
.news-detail__meta .post-category a:hover, .news-detail__meta .post-category a:focus {
  color: #0c3c7a;
}
.news-detail__tags {
  border-top: 1px solid #e1e0db;
  padding-top: 26px;
  margin-top: 50px;
  display: flex;
  margin-bottom: 20px;
}
.news-detail__tags h2, .news-detail__tags .h2 {
  line-height: 1;
  padding-right: 7px;
}
@media (min-width: 768px) {
  .news-detail__tags h2, .news-detail__tags .h2 {
    line-height: 1.1;
  }
}
.news-detail__tags .post-tag {
  line-height: 1;
}
.news-detail__tags .post-tag span,
.news-detail__tags .post-tag a {
  display: inline-block;
  font-family: lato, sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 1;
  letter-spacing: 0.05em;
  margin-bottom: 3px;
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-decoration-color: #1464de;
  text-underline-offset: 4px;
  transition: color 0.35s ease, text-decoration-color 0.35s ease;
}
.news-detail__tags .post-tag span:hover, .news-detail__tags .post-tag span:focus,
.news-detail__tags .post-tag a:hover,
.news-detail__tags .post-tag a:focus {
  text-decoration-color: transparent;
}
.news-detail__tags .post-tag span:not(:last-child)::after,
.news-detail__tags .post-tag a:not(:last-child)::after {
  content: ",";
  display: inline-block;
}
.news-detail__sharethis + .news-detail__tags {
  border-top: 0;
  margin-top: -40px;
}
.news-detail__sharethis {
  border-bottom: 1px solid #e1e0db;
  padding-bottom: 40px;
  margin-bottom: 40px;
}
@media (min-width: 768px) {
  .news-detail__sharethis h2, .news-detail__sharethis .h2 {
    margin-bottom: 0;
  }
}

.post > .post-body:first-child {
  margin-left: 0 !important;
}

.section--news-teaser p {
  font-style: italic;
  font-weight: 500;
  color: #001a3d;
  font-size: 18px;
  letter-spacing: 0.02em;
  line-height: 1.278;
  padding-left: 24px;
}
@media (min-width: 992px) {
  .section--news-teaser p {
    padding-left: 40px;
    line-height: 1.545;
    font-size: 22px;
    font-weight: 400;
  }
}
.herofeature + .section--news-teaser {
  margin-top: -45px;
}
@media (min-width: 992px) {
  .herofeature + .section--news-teaser {
    margin-top: -65px;
  }
}
.herofeature--has-thumbnail + .section--news-teaser {
  margin-top: -20px;
}
@media (min-width: 992px) {
  .herofeature--has-thumbnail + .section--news-teaser {
    margin-top: -45px;
  }
}

.aside-widget .col-lg-4 .post-title {
  font-family: futura-pt, sans-serif;
  font-size: 34px !important;
  line-height: 1.17;
  font-weight: 400;
}
.aside-widget .col-lg-4 .post-title a {
  text-decoration: none;
}
.aside-widget .col-lg-4 .post-meta {
  padding-left: 0;
}
.aside-widget .col-lg-4 .post-meta li {
  text-transform: none;
  color: #13120e;
}

.post {
  position: relative;
  margin-bottom: 30px;
}

.post .post-body {
  position: relative;
  margin: -50px 0 0 3%;
  padding: 5%;
  background: #fff;
  z-index: 10;
}

.post .post-img {
  display: block;
  overflow: hidden;
  position: relative;
}

.post.post-widget .post-img > img {
  height: 100%;
  object-fit: cover;
  position: absolute;
  margin-right: auto;
  margin-left: auto;
}

.post-img > img {
  width: 100%;
  -webkit-transition: 1.6s -webkit-transform;
  transition: 1.6s -webkit-transform;
  transition: 1.6s transform;
  transition: 1.6s transform, 1.6s -webkit-transform;
}

.post-img:hover > img {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}

.post .post-title {
  font-size: 18px;
}

.post .post-title.title-sm {
  font-size: 16px;
}

.post .post-title.title-lg {
  font-size: 25.629px;
}

.post .post-category {
  margin-bottom: 10px;
}

.post-category span, .post-category a {
  color: #ee4266;
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
}

.post-category span:after, .post-category a:after {
  content: ",";
  display: inline-block;
}

.post-category span:last-child:after, .post-category a:last-child:after {
  display: none;
}

ul.post-meta {
  margin-bottom: 5px;
}

.post .post-meta {
  margin-bottom: 10px;
}

.post-meta li {
  display: inline-block;
  color: #97989b;
  font-size: 12px;
  text-transform: uppercase;
}

.post-meta li a {
  color: #97989b;
  font-weight: 700;
}

.post-meta li a:hover, .post-meta li a:focus {
  color: #ee4266;
}

.post-meta li:after {
  content: "•";
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
}

.post-meta li:last-child:after {
  display: none;
}

.hot-post {
  padding: 0 15px;
}

.hot-post .hot-post-left {
  padding-right: 0;
  padding-left: 0;
}

.hot-post .hot-post-right {
  padding-right: 0;
  padding-left: 5px;
}

.hot-post .post {
  margin-bottom: 7px;
}

.hot-post {
  margin-bottom: 25px;
}

.post.post-thumb .post-img:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(27, 28, 30, 0)), color-stop(90%, rgba(27, 28, 30, 0.8)));
  background: linear-gradient(180deg, rgba(27, 28, 30, 0) 0%, rgba(27, 28, 30, 0.8) 90%);
}

.post.post-thumb .post-body {
  position: absolute;
  margin: 0;
  padding: 0 5%;
  z-index: 20;
  bottom: 5%;
  background-color: transparent;
}

.post.post-thumb .post-title a {
  color: #fff;
}

.post.post-thumb .post-title a:hover, .post.post-thumb .post-title a:focus {
  color: #ee4266;
}

.post.post-thumb .post-meta li {
  color: #fff;
}

.post.post-thumb .post-meta li a {
  color: #fff;
}

.post.post-thumb .post-meta li a:hover, .post.post-thumb .post-meta li a:focus {
  color: #ee4266;
}

.post.post-sm .post-body {
  margin: 15px 0 0;
  background: 0 0;
  padding: 0;
}

.post.post-widget:after {
  content: "";
  display: table;
  clear: both;
}

.post.post-widget .post-img {
  float: left;
  height: 87px;
  width: 130px;
}

.post.post-widget .post-body {
  position: relative;
  background: 0 0;
  margin: 0 0 0 150px;
  padding: 0;
}

.post.post-widget .post-title {
  font-size: 16px;
  margin-bottom: 0;
}

.post.post-row:after {
  content: "";
  display: table;
  clear: both;
}

.post.post-row .post-img {
  width: 40%;
  float: left;
}

.post.post-row .post-body {
  margin: 0 0 0 calc(40% + 30px);
  padding: 0;
}

.page-header .post-category {
  margin-bottom: 10px;
}

.page-header .post-meta li {
  color: #fff;
}

.page-header .post-meta li a {
  color: #fff;
}

.page-header .post-meta li a:hover, .page-header .post-meta li a:focus {
  color: #ee4266;
}

.post-share a {
  display: inline-block;
  padding: 5px 15px;
  border-radius: 5px;
  color: #fff;
  background-color: #97989b;
  margin-right: 4px;
  margin-bottom: 6px;
  -webkit-transition: 0.2s opacity;
  transition: 0.2s opacity;
}

.post-share a:hover {
  opacity: 0.9;
}

.post-share a i {
  width: 20px;
  text-align: center;
}

.post-share a span {
  padding-left: 10px;
  border-left: 2px solid rgba(255, 255, 255, 0.1);
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  margin-left: 10px;
}

.post-content .post-attachement.pull-left {
  margin-right: 15px;
}

.post-content .post-attachement.pull-right {
  margin-left: 15px;
}

.post-tags ul li {
  display: inline-block;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
}

.post-tags ul li:first-child {
  font-weight: 400;
}

.post-tags ul li:after {
  content: ",";
  display: inline-block;
}

.post-tags ul li:first-child:after {
  display: none;
}

.post-tags ul li:last-child:after {
  display: none;
}

.post-nav:after {
  content: "";
  display: table;
  clear: both;
}

.post-nav .post-title {
  font-size: 14px;
  margin: 0;
}

.post-nav .prev-post {
  float: left;
  width: 50%;
  padding-right: 15px;
}

.post-nav .next-post {
  float: right;
  width: 50%;
  text-align: right;
  padding-left: 15px;
}

.post-nav .post-img {
  display: block;
  width: 100px;
  overflow: hidden;
}

.post-nav .prev-post .post-img {
  float: left;
  margin-right: 15px;
}

.post-nav .next-post .post-img {
  float: right;
  margin-left: 15px;
}

.post-nav .next-post span, .post-nav .prev-post span {
  font-weight: 700;
  color: #97989b;
  text-transform: uppercase;
  font-size: 12px;
}

.post-nav .next-post:after, .post-nav .prev-post:after {
  content: "";
  display: table;
  clear: both;
}

.author.media .media-left {
  position: relative;
  padding-right: 30px;
}

.author .author-img {
  width: 100px;
  border-radius: 50%;
  margin-bottom: 15px;
}

.author .author-social li {
  display: inline-block;
  margin-right: 10px;
}

.author .author-social li a {
  display: block;
}

.page-header .author .author-social li a {
  color: #fff;
}

.page-header .author .author-social li a:hover, .page-header .author .author-social li a:focus {
  color: #ee4266;
}

.post-comments .media {
  margin-top: 30px;
}

.post-comments .media:nth-child(1) {
  margin-top: 0;
}

.post-comments .media .media-left {
  position: relative;
  padding-right: 15px;
}

.post-comments .media .media-left .media-object {
  width: 50px;
  border-radius: 50%;
  position: relative;
  z-index: 20;
}

.post-comments .media .media-left:after {
  content: "";
  position: absolute;
  left: calc(50% - 9px);
  top: 80px;
  bottom: 15px;
  width: 1px;
  background-color: #e8eaed;
}

.post-comments .media .media-heading h4, .post-comments .media .media-heading .h4 {
  display: inline-block;
  font-size: 14px;
  text-transform: uppercase;
}

.post-comments .media .media-heading .time {
  color: #97989b;
  margin-left: 10px;
  font-size: 12px;
}

.post-comments .media .reply {
  text-transform: uppercase;
  display: inline-block;
  padding: 5px 13px;
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  background-color: #323335;
  border-radius: 2px;
  -webkit-transition: 0.2s opacity;
  transition: 0.2s opacity;
}

.post-comments .media .reply:hover {
  opacity: 0.9;
}

.post-comments .media.media-author .media-heading h4, .post-comments .media.media-author .media-heading .h4 {
  color: #ee4266;
}

.post-comments .media.media-author .media-left:after {
  background-color: #ee4266;
}

@media only screen and (max-width: 767px) {
  .post-nav .prev-post {
    float: none;
    width: 100%;
    margin-bottom: 15px;
    padding-right: 0;
  }
  .post-nav .prev-post:last-child {
    margin-bottom: 0;
  }
  .post-nav .next-post {
    float: none;
    width: 100%;
  }
  .post-nav .next-post {
    float: none;
    width: 100%;
    padding-left: 0;
    text-align: left;
  }
  .post-nav .next-post .post-img {
    margin-right: 15px;
    float: left;
    margin-left: 0;
  }
}
@media (min-width: 992px) {
  .hot-post {
    margin-bottom: 50px;
  }
}
.hot-post .post-title {
  margin-bottom: 0;
}
.hot-post .post {
  margin-bottom: 6px;
}
.hot-post .post.post-thumb .post-title a {
  color: #ffffff;
  margin-bottom: 0;
}
.hot-post .post.post-thumb .post-title a:hover, .hot-post .post.post-thumb .post-title a:focus {
  color: #ffffff;
  text-decoration: underline;
}
.hot-post .post-meta {
  padding-left: 0;
}
.hot-post .post-category span,
.hot-post .post-category a {
  color: #ffffff;
}
.hot-post .post-category a:hover, .hot-post .post-category a:focus {
  text-decoration: none;
}
@media (max-width: 767.98px) {
  .hot-post .hot-post-right {
    padding-left: 0;
  }
}
.hot-post .post.post-thumb .post-body {
  bottom: 20px;
}

.hh-news__filters {
  border-bottom: 5px solid #e1e0db;
  padding-bottom: 40px;
  margin-bottom: 40px;
}
.hh-news .post {
  border-bottom: 1px solid #e1e0db;
  padding-bottom: 30px;
}
@media (min-width: 576px) {
  .hh-news .post.post-row .post-img {
    width: 25%;
  }
}
@media (max-width: 575.98px) {
  .hh-news .post.post-row .post-img {
    width: 100%;
    float: none;
    margin-bottom: 15px;
  }
}
@media (min-width: 576px) {
  .hh-news .post.post-row .post-body {
    margin-left: calc(25% + 30px);
  }
}
@media (max-width: 575.98px) {
  .hh-news .post.post-row .post-body {
    margin-left: 0;
  }
}
.hh-news .post-meta {
  padding-left: 0;
}
.hh-news .post-meta__type {
  color: #464540;
}
.hh-news .post-meta li {
  color: #464540;
}
.hh-news .post-category span,
.hh-news .post-category a {
  color: #1464de;
}
.hh-news .post-category span:hover, .hh-news .post-category span:focus,
.hh-news .post-category a:hover,
.hh-news .post-category a:focus {
  color: #002f6d;
}
.hh-news .post-title a {
  color: #000000;
  margin-bottom: 10px;
}
.hh-news .post-title a:hover, .hh-news .post-title a:focus {
  color: #002f6d;
}
.hh-news .post-body p {
  letter-spacing: 0.05em;
  margin-top: 22px;
  margin-bottom: 0;
  color: #13120e;
}

.hh-news .post-img img,
.hot-post .post-img img {
  transition: transform 1.25s ease;
}
.hh-news .post-img:hover img, .hh-news .post-img:focus img,
.hot-post .post-img:hover img,
.hot-post .post-img:focus img {
  transform: scale(1.1);
}
.hh-news .post-title,
.hot-post .post-title {
  font-size: 30px;
  letter-spacing: 0.04em;
}
@media (min-width: 992px) {
  .hh-news .post-title.title-lg,
  .hot-post .post-title.title-lg {
    font-size: 36px;
  }
}
.hh-news .post-title a,
.hot-post .post-title a {
  text-decoration: none;
  padding-right: 28px;
  position: relative;
  display: inline-block;
}
.hh-news .post-title a:after,
.hot-post .post-title a:after {
  content: "\f0a9";
  color: #f0bf20;
  font-size: 22px;
  padding-left: 10px;
  font-family: "Font Awesome 6 Pro";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-weight: 900;
  transition: padding 0.35s ease;
  position: absolute;
  margin-top: 8px;
}
.hh-news .post-title a:hover:after, .hh-news .post-title a:focus:after,
.hot-post .post-title a:hover:after,
.hot-post .post-title a:focus:after {
  padding-left: 16px;
}
.hh-news .post-meta,
.hot-post .post-meta {
  line-height: 1;
  font-style: italic;
  margin-bottom: 10px;
}
.hh-news .post-meta__type,
.hot-post .post-meta__type {
  font-family: futura-pt, sans-serif;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 700;
}
.hh-news .post-meta__date,
.hot-post .post-meta__date {
  text-transform: none;
}
.hh-news .post-meta li,
.hot-post .post-meta li {
  letter-spacing: 0.05em;
  font-size: 18px;
  white-space: nowrap;
}
.hh-news .post-meta li:not(:last-child):after,
.hot-post .post-meta li:not(:last-child):after {
  content: "|";
  padding-left: 11px;
  font-size: 16px;
  position: relative;
  top: -2px;
}
.hh-news .post-category,
.hot-post .post-category {
  line-height: 1;
  margin-top: 15px;
}
.hh-news .post-category span,
.hh-news .post-category a,
.hot-post .post-category span,
.hot-post .post-category a {
  letter-spacing: 0.05em;
  font-size: 16px;
  text-transform: none;
}

.program-finder-landing {
  margin-bottom: 80px;
}
@media (min-width: 992px) {
  .program-finder-landing {
    margin-bottom: 100px;
  }
}
.program-finder-landing--at-page-top {
  margin-bottom: 40px;
  margin-top: -80px;
}
@media (min-width: 992px) {
  .program-finder-landing--at-page-top {
    margin-bottom: 80px;
    margin-top: -100px;
  }
}

.program-finder {
  margin-bottom: 80px;
}
@media (min-width: 992px) {
  .program-finder {
    margin-bottom: 100px;
  }
}
.program-finder__heading h2, .program-finder__heading .h2 {
  text-align: center;
  font-size: 34px;
  font-family: futura-pt, sans-serif;
  font-weight: 500;
}
@media (min-width: 768px) {
  .program-finder__heading h2, .program-finder__heading .h2 {
    font-size: 44px;
  }
}
@media (min-width: 992px) {
  .program-finder__heading h2, .program-finder__heading .h2 {
    font-size: 54px;
  }
}
.program-finder__intro {
  text-align: center;
  font-size: 20px;
  line-height: 1.5;
  margin-top: 0;
  margin-bottom: 20px;
  font-style: italic;
  color: #13120e;
}
@media (min-width: 768px) {
  .program-finder__intro {
    font-size: 25px;
    line-height: 1.3;
    margin-top: 10px;
    margin-bottom: 35px;
  }
}
@media (min-width: 992px) {
  .program-finder__intro {
    font-size: 30px;
    margin-top: 20px;
    margin-bottom: 50px;
  }
}
.program-finder__form {
  border-top: 5px solid #e36113;
  background: #f7f7f5;
  padding: 40px 20px;
}
@media (min-width: 768px) {
  .program-finder__form {
    padding: 40px;
  }
}
.program-finder__form .form-control,
.program-finder__form .multiselect-dropdown {
  margin-bottom: 15px;
}
@media (min-width: 992px) {
  .program-finder__form .form-control,
  .program-finder__form .multiselect-dropdown {
    margin-bottom: 30px;
  }
}
.program-finder__form-top {
  display: flex;
  align-items: center;
  justify-content: stretch;
}
.program-finder__keywords {
  flex-grow: 1;
}
.program-finder__check2 {
  margin-left: 30px;
  flex-grow: 0;
  margin-bottom: 30px;
}
.program-finder__check1 {
  margin-left: 60px;
  flex-grow: 0;
  margin-bottom: 30px;
}
.program-finder__form-bottom {
  display: flex;
  align-items: flex-start;
  justify-content: stretch;
}
.program-finder__select1, .program-finder__select2 {
  flex-grow: 1;
  margin-right: 30px;
}
.program-finder__submit {
  flex-grow: 0;
}
.program-finder__mobile-checks {
  display: none;
}
@media (max-width: 991.98px) {
  .program-finder__form-top, .program-finder__form-bottom {
    display: block;
  }
  .program-finder__select1, .program-finder__select2 {
    margin-left: 0;
    margin-right: 0;
  }
  .program-finder__check1, .program-finder__check2 {
    display: none;
  }
  .program-finder__mobile-checks {
    display: block;
  }
  .program-finder__mobile-checks .form-check {
    margin-bottom: 10px;
  }
  .program-finder__submit {
    margin-right: auto;
    margin-top: 30px;
  }
}

.quickfacts {
  margin-bottom: 80px;
  padding-top: 35px;
  padding-bottom: 40px;
}
@media (min-width: 992px) {
  .quickfacts {
    margin-bottom: 100px;
  }
}
.quickfacts__heading {
  text-align: center;
  font-weight: 400;
}
.quickfacts__facts {
  width: 100%;
  margin-top: 80px;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}
.quickfacts__fact {
  position: relative;
  width: 100%;
  min-height: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  padding: 0 30px 40px 30px;
}
@media (min-width: 1200px) {
  .quickfacts--four .quickfacts__fact {
    width: 25%;
  }
  .quickfacts--three .quickfacts__fact {
    width: 33.3%;
  }
}
.quickfacts__facts .quickfacts__fact + .quickfacts__fact:before {
  content: "";
  position: absolute;
  display: block;
  width: 1px;
  top: 83px;
  bottom: 40px;
  left: 0;
  background: #e1e0db;
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .quickfacts__rotator .splide__slide:not(.is-visible) .quickfacts__fact:before, .quickfacts__rotator .splide__slide.is-visible + .is-visible .quickfacts__fact:before {
    content: "";
    position: absolute;
    display: block;
    width: 1px;
    top: 83px;
    bottom: 40px;
    left: 0;
    background: #e1e0db;
  }
}
.quickfacts__icon {
  background: #e36113;
  width: 110px;
  height: 110px;
  margin-top: -55px;
  color: white;
  border: 4px solid white;
  border-radius: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
}
.quickfacts__statistic {
  color: #1c5ec2;
  font-size: 65px;
  font-weight: 500;
  font-family: futura-pt, sans-serif;
}
.quickfacts__body {
  font-size: 21px;
}
.quickfacts__body p {
  margin-bottom: 18px;
  line-height: 26px;
}
.quickfacts__body em {
  font-size: 16px;
  line-height: 24px;
  font-style: italic;
  color: #464540;
}
.quickfacts__link {
  font-size: 18px;
}
@media (min-width: 768px) {
  .quickfacts__link {
    font-size: 20px;
  }
}
.quickfacts__button {
  display: table;
  margin: 30px auto 0 auto;
}
.quickfacts--light .quickfacts__fact, .quickfacts--light .quickfacts__controls {
  background: #f7f7f5;
}
.quickfacts--dark {
  background: url("../images/quickfacts-background.jpg");
  background-size: cover;
  background-position: center;
}
.quickfacts--dark .quickfacts__heading {
  color: white;
}
.quickfacts--dark .quickfacts__fact, .quickfacts--dark .quickfacts__controls {
  background: #ffffff;
}
.quickfacts__rotator-item {
  padding-top: 80px;
  height: 100%;
}
.quickfacts__controls {
  height: 70px;
  display: flex;
  align-items: center;
  position: relative;
}
.quickfacts__controls .splide__arrows {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.quickfacts__controls .splide__pagination {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.quickfacts__controls .splide__arrow {
  position: absolute;
  z-index: 1;
  background: transparent;
  border: 0;
}
.quickfacts__controls .splide__arrow svg {
  display: none;
}
.quickfacts__controls .splide__arrow:before {
  font-family: "Font Awesome 6 Pro";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-weight: 400;
  font-size: 24px;
  color: #797978;
  transition: color 0.35s ease;
}
.quickfacts__controls .splide__arrow:hover:before {
  color: #13120e;
}
.quickfacts__controls .splide__arrow--prev {
  position: absolute;
  left: calc(50% - 110px);
}
.quickfacts__controls .splide__arrow--prev:before {
  content: "\f060";
}
.quickfacts__controls .splide__arrow--next {
  position: absolute;
  right: calc(50% - 110px);
}
.quickfacts__controls .splide__arrow--next:before {
  content: "\f061";
}
.quickfacts__controls .splide__pagination__page {
  background: transparent;
  border: none;
  display: block;
  padding: 5px;
  margin: 0 2px;
}
.quickfacts__controls .splide__pagination__page:before {
  content: "";
  display: block;
  height: 12px;
  width: 12px;
  border-radius: 100px;
  background: #9b9b99;
  transition: background-color 0.35s ease;
}
.quickfacts__controls .splide__pagination__page.is-active:before, .quickfacts__controls .splide__pagination__page:hover:before {
  background: #e36113;
}

.quote {
  margin-bottom: 60px;
}
@media (max-width: 991.98px) {
  .quote {
    margin-top: 130px;
  }
}
@media (max-width: 991.98px) and (max-width: 991.98px) {
  .quote .text-and-image__image {
    display: flex;
    justify-content: center;
    margin-top: -79px;
  }
}

.sectional-navigation {
  border-bottom: 1px solid #e1e0db;
  background: white;
  transition: background-color 0.2s;
  margin-bottom: 40px;
  margin-top: -80px;
}
@media (min-width: 992px) {
  .sectional-navigation {
    margin-bottom: 80px;
    margin-top: -100px;
  }
}
.sectional-navigation--expanded {
  background: #f1f0eb;
}
.sectional-navigation__explore button {
  display: block;
  padding: 0;
  width: 100%;
  font-family: futura-pt, sans-serif;
  font-weight: 500;
  font-size: 22px;
  color: #13120e;
  border: 0;
  background: transparent;
  text-align: left;
}
.sectional-navigation__explore button .sectional-navigation__title > span {
  position: relative;
}
.sectional-navigation__explore button .sectional-navigation__title > span:before {
  content: "";
  position: absolute;
  height: 2px;
  left: 0;
  bottom: -2px;
  width: 0;
  background-color: #114594;
  transition: width 0.5s ease;
}
.sectional-navigation__explore button:hover .sectional-navigation__title > span:before {
  width: 100%;
}
.sectional-navigation__explore button:hover, .sectional-navigation__explore button:hover .sectional-navigation__title:after {
  color: #114594;
}
.sectional-navigation__title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 35px 20px 20px 20px;
}
.sectional-navigation__links {
  padding: 0 20px 35px 20px;
}
.sectional-navigation__title:after {
  display: block;
  position: relative;
  margin-left: 30px;
  font-size: 22px;
  content: "\f078";
  font-family: "Font Awesome 6 Pro";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-weight: 600;
  color: #1464de;
  transition: transform 0.2s;
}
.sectional-navigation--expanded .sectional-navigation__title:after {
  transform: rotate(180deg);
}
.sectional-navigation h2, .sectional-navigation .h2 {
  margin: 0;
}
.sectional-navigation a {
  font-family: futura-pt, sans-serif;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.05em;
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-decoration-color: #1464de;
  text-underline-offset: 4px;
  transition: color 0.35s ease, text-decoration-color 0.35s ease;
}
.sectional-navigation a:hover, .sectional-navigation a:focus {
  text-decoration-color: transparent;
}
.sectional-navigation ul {
  display: block;
  margin: 0;
  padding: 0;
}
.sectional-navigation li {
  flex-grow: 0;
  flex-shrink: 0;
  display: block;
  margin: 20px 50px 0 0;
  padding: 0;
}
.sectional-navigation li:first-child {
  margin-top: 0;
}
@media (min-width: 768px) {
  .sectional-navigation ul {
    display: flex;
    flex-wrap: wrap;
  }
  .sectional-navigation li, .sectional-navigation li:first-child {
    margin-top: 0;
    margin-bottom: 10px;
  }
}
@media (min-width: 992px) {
  .sectional-navigation__title {
    padding: 40px 40px 30px 40px;
  }
  .sectional-navigation__links {
    padding: 0 40px 30px 40px;
  }
  .sectional-navigation__explore button {
    font-size: 24px;
  }
  .sectional-navigation__explore button:after {
    font-size: 22px;
  }
}

.hh-mashup #back_to_top {
  position: fixed;
  bottom: 0px;
  right: 15px;
  z-index: 100;
  text-align: center;
  display: none;
  border: none;
  width: 50px;
  height: 40px;
  font-size: 32px;
  opacity: 0.8;
}

.hh-mashup #loading {
  padding: 30px;
  text-align: center;
  height: 100vh;
  font-style: italic;
}

.hh-mashup .debug-output {
  border: 1px red solid;
  padding: 5px;
  margin-bottom: 10px;
  overflow-wrap: break-word;
}

.hh-mashup .debug-output span {
  font-weight: bold;
}

.hh-mashup .header.row {
  padding: 24px 0px;
}

.hh-mashup .content {
  padding-right: 15px;
  padding-left: 15px;
  margin-left: auto;
  margin-right: auto;
}

.hh-mashup .grid-item {
  overflow-wrap: break-word;
  float: left;
  -webkit-box-shadow: -2px 8px 15px 0px rgba(0, 0, 0, 0.11);
  -moz-box-shadow: -2px 8px 15px 0px rgba(0, 0, 0, 0.11);
  box-shadow: -2px 8px 15px 0px rgba(0, 0, 0, 0.11);
}

.hh-mashup .grid-item img {
  width: 100%;
}

.hh-mashup .grid-item .title {
  display: block;
  font-size: 18px;
  font-weight: 500;
}

.hh-mashup .grid-item .video iframe {
  width: 100%;
  border: none;
}

.hh-mashup .grid-item .fullLink {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: block;
  z-index: 1;
}

.hh-mashup .grid-item .grid-content {
  padding: 20px;
}

.hh-mashup .grid-item .logoLink, .grid-content .text, .grid-content .logoLink span, .grid-item .video {
  z-index: 10;
  position: relative;
}

.hh-mashup .grid-content .text {
  margin-bottom: 10px;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.hh-mashup .grid-content .postDate {
  font-size: 12px;
  margin-bottom: 0px;
  position: absolute;
  bottom: 15px;
  color: #767676;
}

.hh-mashup .grid-content .date {
  display: none;
}

.hh-mashup .grid-content .logoLink {
  float: right;
  bottom: 15px;
}

.hh-mashup a.logoLink i {
  color: #424242;
  font-size: 30px;
  margin-top: 12px;
}

/* Filter Buttons */
.hh-mashup .media_bar {
  float: right;
}

.hh-mashup .btn-filter:focus {
  outline: none;
}

.hh-mashup .btn-filter {
  border: none;
  padding: 0 0 0 2px;
  width: 34px;
  height: 34px;
  background-color: white;
}

.hh-mashup .filter-button-group button:hover {
  opacity: 0.5;
}

.hh-mashup .filter-button-group button:active, .filter-button-group button.is-checked {
  opacity: 0.5;
}

.hh-mashup .filter-button-group button:disabled {
  opacity: 0.5;
}

.hh-mashup button.btn-filter i {
  font-size: 34px;
  color: #424242;
}

.hh-mashup button.btn-filter i.fas.fa-sync-alt {
  font-size: 32px;
}

.hh-mashup i.fab.fa-facebook-square:hover, button.is-checked i.fab.fa-facebook-square {
  color: #3c5A99;
}

.hh-mashup i.fab.fa-flickr:hover, button.is-checked i.fab.fa-flickr {
  color: #ff0084;
}

.hh-mashup i.fab.fa-instagram:hover, button.is-checked i.fab.fa-instagram {
  color: #c13584;
}

.hh-mashup i.fab.fa-pinterest-square:hover, button.is-checked i.fab.fa-pinterest-square {
  color: #e60023;
}

.hh-mashup i.fas.fa-rss-square:hover, button.is-checked i.fas.fa-rss-square {
  color: #f26522;
}

.hh-mashup i.fab.fa-twitter-square:hover, button.is-checked i.fab.fa-twitter-square {
  color: #1da1f2;
}

.hh-mashup i.fab.fa-youtube-square:hover, button.is-checked i.fab.fa-youtube-square {
  color: #ff0000;
}

/* Font Awesome Icons */
.hh-mashup .fa {
  vertical-align: top;
  /*font-size: 40px;*/
}

.hh-mashup .fa.pull-left {
  margin-right: 0.2em;
}

.hh-mashup .fa-white {
  color: white !important;
  font-size: 26px;
}

/* Column Wdiths */
.hh-mashup .grid-item {
  width: 100%;
}

.hh-mashup .grid-item-2 {
  width: 100%;
}

.hh-mashup .grid-item .video iframe {
  min-height: 350px;
}

.hh-mashup .gutter-sizer {
  width: 1%;
}

@media screen and (min-width: 768px) {
  .hh-mashup .grid-item {
    width: 49.5%;
  }
  .hh-mashup .grid-item-2 {
    width: 100%;
  }
  .hh-mashup .grid-item .video iframe {
    min-height: 250px;
  }
  .hh-mashup .grid-item-2 .video iframe {
    min-height: 500px;
  }
}
@media screen and (min-width: 992px) {
  .hh-mashup .grid-item {
    width: 32.66%;
  }
  .hh-mashup .grid-item-2 {
    width: 66.33%;
  }
  .hh-mashup .grid-item .video iframe {
    min-height: 200px;
  }
  .hh-mashup .grid-item-2 .video iframe {
    min-height: 400px;
  }
}
@media screen and (min-width: 1200px) {
  .hh-mashup .grid-item {
    width: 24.25%;
  }
  .hh-mashup .grid-item-2 {
    width: 49.5%;
  }
  .hh-mashup .grid-item .video iframe {
    min-height: 150px;
  }
  .hh-mashup .grid-item-2 .video iframe {
    min-height: 300px;
  }
}
.hh-mashup .grid-item {
  box-shadow: none;
  background-color: #f7f7f5;
}
.hh-mashup .grid-item a .fullLink {
  border-top: 4px solid transparent;
  transition: border-color 0.35s ease;
}
.hh-mashup .grid-item a:hover .fullLink, .hh-mashup .grid-item a:focus .fullLink {
  border-top-color: #e36113;
}
.hh-mashup .grid-item a:focus-visible .fullLink {
  outline-offset: 1px;
  outline: #e36113 auto 1px;
}
.hh-mashup button.btn-filter:focus-visible {
  outline-offset: 1px;
  outline: #005fcc auto 1px;
}
.hh-mashup button.btn-filter i {
  color: #114594;
}
.hh-mashup .filter-button-group button:hover {
  opacity: 1;
}
.hh-mashup .filter-button-group button:hover i {
  color: #1464de;
}

.success-stories-listing .loadmore .btn {
  min-width: 95px;
  padding-top: 0;
  padding-bottom: 0;
}
.success-stories-listing .loadmore .btn > [class*=fa] {
  font-size: 24px;
}
.success-stories-listing .post:first-of-type {
  padding-top: 25px;
  border-top: 4px solid #e1e0db;
}
@media (min-width: 576px) {
  .success-stories-listing .post {
    display: flex;
  }
}
@media (min-width: 576px) {
  .success-stories-listing .post-body {
    margin-left: 0 !important;
  }
}
.success-stories-listing .post-body p {
  margin-top: 17px;
}
.success-stories-listing .post-img img {
  border-left: 4px solid #e36113;
}
@media (min-width: 576px) {
  .success-stories-listing .post-img {
    order: 2;
    margin-left: 50px;
    width: 40% !important;
  }
}
@media (min-width: 992px) {
  .success-stories-listing .post-img {
    width: 30% !important;
  }
}
@media (min-width: 576px) {
  .success-stories-listing .post-img + .post-body {
    width: 60%;
  }
}
@media (min-width: 992px) {
  .success-stories-listing .post-img + .post-body {
    width: 70%;
  }
}
.success-stories-listing .post-title {
  font-weight: 500;
  margin-bottom: 0;
}
.success-stories-listing .post-meta li {
  font-style: normal;
  text-transform: none;
  letter-spacing: 0.05em;
  color: #464540;
  font-size: 16px;
  line-height: 1.375;
}
@media (min-width: 992px) {
  .success-stories-listing .post-meta li {
    font-size: 20px;
    line-height: 1.3;
  }
}
.success-stories-listing .post-meta li a {
  color: #1464de;
  font-weight: 400;
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-decoration-color: #1464de;
  text-underline-offset: 4px;
  transition: color 0.35s ease, text-decoration-color 0.35s ease;
}
.success-stories-listing .post-meta li a:hover, .success-stories-listing .post-meta li a:focus {
  text-decoration-color: transparent;
}
.success-stories-listing .post-meta li a:hover, .success-stories-listing .post-meta li a:focus {
  color: #0c3c7a;
}
.success-stories-listing .post-meta li:not(:last-child):after {
  content: ",";
  padding-left: 0;
  font-size: inherit;
  position: static;
}

.tabs-component {
  margin-bottom: 80px;
}
@media (min-width: 992px) {
  .tabs-component {
    margin-bottom: 100px;
  }
}
.tabs-component__content {
  padding-top: 40px;
}
@media (min-width: 768px) {
  .tabs-component__content .figure.float-end {
    margin-right: 0;
  }
}
@media (min-width: 768px) {
  .tabs-component__content .figure.float-start {
    margin-left: 0;
  }
}
@media (min-width: 992px) {
  .tabs-component--vertical .tabs-component__outer {
    border-top: 1px solid #e1e0db;
    border-bottom: 1px solid #e1e0db;
  }
}
.tabs-component--vertical .tabs-component__content {
  padding: 40px 65px;
}

html:not(.no-js) .animate-in-view--fade-in-and-up {
  opacity: 0;
}
.animate-in-view--fade-in-and-up.animate-in-view--intersecting {
  animation: fadeInAndUp 0.75s cubic-bezier(0.645, 0.045, 0.355, 1) 0s forwards;
}

.animate-in-view__stagger [class*=col]:nth-of-type(1) .animate-in-view--fade-in-and-up {
  animation-delay: 0.15s;
}

.animate-in-view__stagger [class*=col]:nth-of-type(2) .animate-in-view--fade-in-and-up {
  animation-delay: 0.3s;
}

.animate-in-view__stagger [class*=col]:nth-of-type(3) .animate-in-view--fade-in-and-up {
  animation-delay: 0.45s;
}

.animate-in-view__stagger [class*=col]:nth-of-type(4) .animate-in-view--fade-in-and-up {
  animation-delay: 0.6s;
}

.animate-in-view__stagger [class*=col]:nth-of-type(5) .animate-in-view--fade-in-and-up {
  animation-delay: 0.75s;
}

.animate-in-view__stagger [class*=col]:nth-of-type(6) .animate-in-view--fade-in-and-up {
  animation-delay: 0.9s;
}

.animate-in-view__stagger [class*=col]:nth-of-type(7) .animate-in-view--fade-in-and-up {
  animation-delay: 1.05s;
}

.animate-in-view__stagger [class*=col]:nth-of-type(8) .animate-in-view--fade-in-and-up {
  animation-delay: 1.2s;
}

.animate-in-view__stagger [class*=col]:nth-of-type(9) .animate-in-view--fade-in-and-up {
  animation-delay: 1.35s;
}

.animate-in-view__stagger [class*=col]:nth-of-type(10) .animate-in-view--fade-in-and-up {
  animation-delay: 1.5s;
}

.animate-in-view__stagger [class*=col]:nth-of-type(11) .animate-in-view--fade-in-and-up {
  animation-delay: 1.65s;
}

.animate-in-view__stagger [class*=col]:nth-of-type(12) .animate-in-view--fade-in-and-up {
  animation-delay: 1.8s;
}

.animate-in-view__stagger [class*=col]:nth-of-type(13) .animate-in-view--fade-in-and-up {
  animation-delay: 1.95s;
}

.animate-in-view__stagger [class*=col]:nth-of-type(14) .animate-in-view--fade-in-and-up {
  animation-delay: 2.1s;
}

.animate-in-view__stagger [class*=col]:nth-of-type(15) .animate-in-view--fade-in-and-up {
  animation-delay: 2.25s;
}

.animate-in-view__stagger [class*=col]:nth-of-type(16) .animate-in-view--fade-in-and-up {
  animation-delay: 2.4s;
}

.animate-in-view__stagger [class*=col]:nth-of-type(17) .animate-in-view--fade-in-and-up {
  animation-delay: 2.55s;
}

.animate-in-view__stagger [class*=col]:nth-of-type(18) .animate-in-view--fade-in-and-up {
  animation-delay: 2.7s;
}

.animate-in-view__stagger [class*=col]:nth-of-type(19) .animate-in-view--fade-in-and-up {
  animation-delay: 2.85s;
}

.animate-in-view__stagger [class*=col]:nth-of-type(20) .animate-in-view--fade-in-and-up {
  animation-delay: 3s;
}

@keyframes fadeInAndUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 2rem, 0);
  }
  to {
    opacity: 1;
    transform: translateZ(0);
  }
}
@media screen and (prefers-reduced-motion: reduce), (update: slow) {
  .animate-in-view {
    animation-duration: 0.0001ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.0001ms !important;
    opacity: 1 !important;
  }
  .title--bottom-divider.animate-in-view:after {
    width: 130px !important;
  }
  .animate-in-view .card-header::after {
    width: 89px !important;
  }
}
@media print {
  * {
    color: #000000 !important;
    text-shadow: none !important;
    background-color: transparent !important;
  }
  body {
    font: 13pt;
    line-height: 1.3;
  }
  h2, .h2, h3, .h3, h4, .h4 {
    page-break-after: avoid;
  }
}
@keyframes splide-loading {
  0% {
    transform: rotateZ(0);
  }
  100% {
    transform: rotateZ(360deg);
  }
}
.splide__track--draggable {
  -webkit-touch-callout: none;
  user-select: none;
}

.splide__track--fade > .splide__list > .splide__slide {
  margin: 0 !important;
  opacity: 0;
  z-index: 0;
}

.splide__track--fade > .splide__list > .splide__slide.is-active {
  opacity: 1;
  z-index: 1;
}

.splide--rtl {
  direction: rtl;
}

.splide__track--ttb > .splide__list {
  display: block;
}

.splide__container {
  box-sizing: border-box;
  position: relative;
}

.splide__list {
  backface-visibility: hidden;
  display: flex;
  height: 100%;
  margin: 0 !important;
  padding: 0 !important;
}

.splide.is-initialized:not(.is-active) .splide__list {
  display: block;
}

.splide__pagination {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  pointer-events: none;
}

.splide__pagination li {
  display: inline-block;
  line-height: 1;
  list-style-type: none;
  margin: 0;
  pointer-events: auto;
}

.splide:not(.is-overflow) .splide__pagination {
  display: none;
}

.splide__progress__bar {
  width: 0;
}

.splide {
  position: relative;
  visibility: hidden;
}

.splide.is-initialized, .splide.is-rendered {
  visibility: visible;
}

.splide__slide {
  backface-visibility: hidden;
  box-sizing: border-box;
  flex-shrink: 0;
  list-style-type: none !important;
  margin: 0;
  position: relative;
}

.splide__slide img {
  vertical-align: bottom;
}

.splide__spinner {
  animation: splide-loading 1s infinite linear;
  border: 2px solid #999;
  border-left-color: transparent;
  border-radius: 50%;
  bottom: 0;
  contain: strict;
  display: inline-block;
  height: 20px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
}

.splide__sr {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.splide__toggle__pause {
  display: none;
}

.splide__toggle.is-active .splide__toggle__play {
  display: none;
}

.splide__toggle.is-active .splide__toggle__pause {
  display: inline;
}

.splide__track {
  overflow: hidden;
  position: relative;
  z-index: 0;
}

.splide__slide--has-video {
  cursor: pointer;
}

.splide__slide--has-video:hover .splide__video__play {
  opacity: 1;
}

.splide__slide__container--has-video {
  cursor: pointer;
  position: relative;
}

.splide__slide__container--has-video:hover .splide__video__play {
  opacity: 1;
}

.splide__video {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.splide__video__wrapper {
  background: #000;
  height: inherit;
  width: inherit;
}

.splide__video__wrapper div, .splide__video__wrapper iframe, .splide__video__wrapper video {
  height: 100%;
  width: 100%;
}

.splide__video__play {
  align-items: center;
  background: #ccc;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  left: 50%;
  opacity: 0.7;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.1s linear;
  width: 40px;
}

.splide__video__play:after {
  border-color: transparent transparent transparent #000;
  border-style: solid;
  border-width: 9px 0 9px 17px;
  content: "";
  display: inline-block;
  margin-left: 4px;
}

@media (max-width: 767.98px) {
  body {
    font-size: 16px;
  }
}

a {
  transition: color 0.35s ease, background-color 0.35s ease;
}
a .fa-caret-right {
  padding-left: 8px;
}

.offcanvas.offcanvas-end {
  top: 5px;
}

img {
  min-width: 1px;
  min-height: 1px;
}

.alert {
  border-radius: 0;
  font-size: 1.25rem;
}