.featured-events-feed {
    @include default-margin-bottom();

    &__more-link {
        margin-top: 20px;
        font-size: 26px;
    }
    &__mobile-more-link {
        font-size: 20px;
    }

    &__rotator-item {
        height: 100%;
    }

    &__controls {
        @include splide-controls;

        margin-top: -5px;
        border-bottom: 1px solid $grey-highlight-2;
        margin-bottom: 10px;
    }

    .splide__track {
        padding-bottom: 5px;
    }

}