.back-link {
    margin-bottom: 20px;
    a {
        font-size: 14px;
        font-weight: $font-weight-medium-web;
        letter-spacing: 0.05em;
        text-decoration: none;

        color: white;

        &:before {
            content: '\f053';
            font-size: 12px;
            padding-right: 10px;
            color: $blue-highlight-6;
            @include fontawesome();
            transition: transform 0.35s ease;
        }

        &:hover,
        &:focus {
            &:before {
                transform: translateX(-5px);
            }
            text-decoration: underline;
        }
    }
}