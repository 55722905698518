.btn {

    // with 3px border, adds up to 44px
    font-size: 18px;
    line-height:22px;
    padding: 8px 35px;

    > [class*="fa"] {
        line-height: 22px;
    }

    &:focus-visible {
        outline: -webkit-focus-ring-color auto 1px;
        outline-offset: 1px;
    }

    &-white-text {
        color: $white !important;
    }

    &-sm {
        // with 3px border, adds up to 38px
        font-size: 16px;
        line-height:22px;
        padding: 5px 16px;

        > [class*="fa"] {
            line-height: 22px;
        }
    }
    @include media-breakpoint-up(md) {
        // with 3px border, adds up to 50px
        font-size: 24px;
        line-height:34px;
        padding: 5px 35px;

        > [class*="fa"] {
            line-height: 34px;
        }
        &-sm {
            // with 3px border, adds up to 38px
            font-size: 16px;
            line-height:22px;
            padding: 5px 16px;

            > [class*="fa"] {
                line-height: 22px;
            }
        }
    }

    font-family: $font-family-sans-serif;
    font-weight: $font-weight-demi-web;

    &-sm {
        font-weight: $font-weight-book;
    }

    > [class*="fa"] {
        position:relative; // prevent getting covered by pseudo element background gradients
        font-size: 19px;
    }
    &-sm > [class*="fa"] {
        font-size: 16px;
    }
    > span + [class*="fa"] {
        padding-left: 5px;
    }
    [class*=fa]:first-child {
        padding-right:6px;
    }

    color: black;

    &--white-text {
        color: $white;
    }

    &--circle-icon {
        display:inline-flex;
        align-items: center;
        justify-content: center;
        padding:0;
        width: 42px;
        height: 42px;
        [class*=fa] {
            display:block;
            line-height: 0;
            letter-spacing: 0;
            padding:0 !important;
            margin:0;
            font-size: 22px;
        }
    }
    @include media-breakpoint-up(md) {
        &--circle-icon {
            width: 48px;
            height: 48px;
        }
    }
    &-sm.btn--circle-icon {
        width: 36px;
        height: 36px;
    }
    &--fullwidth {
        display:block;
    }


    &-primary {
        position: relative;
        > i:first-child,
        > span {
            position: relative;
        }

        &:before {
            content: '';
            background: linear-gradient(to left, $gold-lowlight-1 0, #fade78 100%);
            position: absolute;
            border-radius: 1000px;
            top: -2px;
            right: -2px;
            bottom: -2px;
            width: 50px;
            opacity: 0;
            transition: opacity 0.3s ease, width 0.7s ease;
        }

        &.btn-sm {
            &:before {
                width: 35px;
            }
        }

        &:hover {
            outline-width: 10px;
            &:before {
                opacity: 1;
                width: calc(100% + 4px);
                transition: opacity 0.15s ease, width 0.35s ease;
            }
        }
    }

    &-blue {
        background: $blue-highlight-3;
        color: white;

        &:hover {
            background: $blue-highlight-2;
            color: white;
        }
    }

    &-dark {
        background: $grey-lowlight-1;
        border-color: $grey-lowlight-1;
        color: white;

        &:hover {
            background: $grey-lowlight-3;
            border-color: $grey-lowlight-3;
        }
    }
    &-darker {
        background: #31302d;
        border-color: #31302d;
        color: white;

        &:hover {
            background: #151513;
            border-color: #151513;
            color: white;
        }
    }
    &-utility {
        background: transparent;
        border-color: $gold;
        font-size: 17px;
        font-weight: $font-weight-demi-web;
        text-transform: uppercase;

        &:hover {
            background: $gold;
        }
    }
    &-utility-link {
        color: $blue-highlight-3;
        background: transparent;
        border-color: transparent;
        font-size: 18px;
        font-weight: $font-weight-demi-web;

        padding-left: 5px;
        padding-right: 5px;

        &:hover {
            color: $blue-highlight-3;
        }
        @include media-breakpoint-up(md) {
            font-size: 20px;
        }

        > span {
            @include underline(transparent);
        }
        &:hover > span {
            @include underline($blue-highlight-3);
        }
    }

    &-link {
        text-decoration: none;
        color: $blue-highlight-3;
        &:hover {
            color: $blue-highlight-3;
        }

        > span {
            @include underline(transparent);
        }
        &:hover > span {
            @include underline($blue-highlight-3);
        }
    }

    &-alert-link {
        text-transform: uppercase;
        color: $red;
        &:hover {
            color: $red;
        }

        padding-left: 5px;
        padding-right: 5px;

        @include media-breakpoint-up(md) {
            font-size: 20px;
        }
        
        > span {
            @include underline(transparent);
        }
        &:hover > span {
            @include underline($red);
        }
    }
    &-alert-link:before {
        content: '!';
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-family: $font-family-sans-serif;
        font-weight: $font-weight-bold;
        font-size: 13px;
        color: white;
        width: 18px;
        height: 18px;
        position: relative;
        top: -2px;
        margin-right: 6px;
        background: $red;
        border-radius: 100%;
        text-shadow: none;
    }


    &[class*=btn-outline] {
        font-weight: $font-weight-medium-web;
    }



    &-header {
        background: rgba(0, 0, 0, 0.30);
        color: white;
        text-transform: uppercase;

        &:hover,
        &:focus {
            background: $gold;
            color: black;
        }

        font-size: 17px;
        line-height: 24px;
        padding: 7px 30px;

        @include media-breakpoint-up(lg) {
            padding: 4px 15px;
        }
        @include media-breakpoint-up(xl) {
            padding: 7px 20px;
        }

    }

    &-menu {
        background: $blue-highlight-3;
        color: white;
        text-transform: uppercase;

        &:hover,
        &:focus {
            background: $blue-highlight-2;
            color: white;
        }

        font-size: 19px;
        line-height: 26px;
        padding: 3px 14px;
        @include media-breakpoint-up(md) {
            padding: 5px 30px;
        }
        @include media-breakpoint-up(lg) {
            padding: 3px 15px;
        }
        @include media-breakpoint-up(xl) {
            padding: 5px 30px;
        }

        display: flex;
        align-items: center;

        &:before {
            content: url('../images/icon-search-menu.svg');
            width: 32px;
            height: 22px;
            margin-top: 2px;
            margin-right: -4px;
            @include media-breakpoint-up(md) {
                margin-right: 7px;
            }
        }
    }





    &--animate-slide {
        [class*=fa] {
            transition: transform 0.35s ease;
        }
        &:hover [class*=fa],
        &:focus [class*=fa] {
            transform: translateX(12px);
        }
    }

    &--animate-slide.btn-sm {
        &:hover [class*=fa],
        &:focus [class*=fa] {
            transform: translateX(6px);
        }
    }


    @keyframes button_bounce {
        0% {
            transform:translateY(0);
        }
        33% {
            transform:translateY(-3px);
        }
        66% {
            transform:translateY(2px);
        }
        100% {
            transform:translateY(0);
        }
    }

    &--animate-bounce:hover [class*=fa],
    &--animate-bounce:focus [class*=fa] {
        animation-duration:0.6s;
        animation-name: button_bounce;
    }

    &--toggle {
        .btn__icon {
            &--expand {
                display: inline-block;
            }
            &--collapse {
                display: none
            }
        }
        &[aria-expanded="true"] {
            .btn__icon {
                &--expand {
                    display: none
                }
                &--collapse {
                    display: inline-block;
                }
            }
        }
    }


}