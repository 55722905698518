.text {
    &--intro {
        line-height: 1.46;
        color: $blue;
        font-weight: $font-weight-normal;
        font-size: 22px;

        @include media-breakpoint-up(lg) {
            font-size: 26px;
        }
    }

    &--subhead {
        font-style: italic;
        font-size: 20px;
        line-height: 1.5;
        @include media-breakpoint-up(lg) {
            font-size: 30px;
        }

        .title--bottom-divider + & {
            margin-top: -24px;
        }
    }
}

p {
    letter-spacing: 0.02em;
}