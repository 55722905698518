.gsc-control-cse {
    &,
    .gsc-table-result,
    .gsc-results {
        font-family: $font-family-primary !important;
    }

    .gsc-search-box {
        .gsc-input-box {
            border-width: 2px;
            border-radius: 5px;
            line-height:1.3;
            box-shadow: inset 2px 2px 5px -2px rgba($black, 0.2);
            padding: 8px;
            @include media-breakpoint-up(md) {
                padding: 9px 11px;
            }

            .gsc-input {
                color: $grey-lowlight-3;
                font-size: 18px;
                @include media-breakpoint-up(lg) {
                    font-size: 20px;
                }
            }
        }

        .gsc-search-button {
            .gsc-search-button-v2 {
                height: 52px;
                width: 52px;
                border-radius: 50%;
                padding: 0;
                position: relative;

                &:before {
                    content: '';
                    background: linear-gradient(to left, $gold-lowlight-1 0, #fade78 100%);
                    position: absolute;
                    border-radius: 1000px;
                    top: -1px;
                    right: -1px;
                    bottom: -1px;
                    width: 35px;
                    opacity: 0;
                    transition: opacity 0.3s ease, width 0.7s ease;
                }

                &:hover,
                &:focus {
                    &:before {
                        opacity: 1;
                        width: calc(100% + 2px);
                        transition: opacity 0.15s ease, width 0.35s ease;
                    }
                }

                svg {
                    position: relative;
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }

    .gsc-results {
        .gs-result {
            .gs-title {
                &,
                * {
                    font-size: 30px;
                    font-family: $font-family-secondary;
                    font-weight: $font-weight-demi-web;
                    letter-spacing: 0.030em;
                }

                a.gs-title {
                    display: block;
                    padding-right: 43px;
                    line-height: 1.17;

                    &:after {
                        content: "\f0a9";
                        font-size: 85%;
                        color: $gold;
                        @include fontawesome();
                        margin-left: 15px;
                        line-height: 0;
                        position: absolute;
                        margin-top: 19px;
                        transition: padding 0.35s ease;
                    }

                    &:hover,
                    &:focus {
                        &:after {
                            padding-left: 16px;
                        }
                    }
                }
            }
        }
    }

    .gsc-webResult.gsc-result {
        border-bottom: 1px solid $grey-highlight-2 !important;
        padding-bottom: 30px;
        margin-bottom: 15px;

        .gsc-url-top {
            padding-bottom: 16px;
            font-size: 15px !important;
        }

        .gsc-table-result {
            font-size: 16px !important;
            @include media-breakpoint-up(lg) {
                font-size: 20px !important;
            }
        }
    }

    .gsc-cursor-box {
        font-size: 16px !important;
        font-family: $font-family-secondary;
        font-weight: $font-weight-demi-web;
        display: flex;
        justify-content: center;

        .gsc-cursor-page {
            color: $blue-highlight-3 !important;
            margin-right: 11px;

            &.gsc-cursor-current-page {
                color: $black !important;
                border-bottom: 4px solid $orange-highlight-1;
            }
        }
    }

    .gsc-selected-option-container {
        background-color: transparent;
        border-color: $grey-highlight-1;
        border-width: 2px;
        border-radius: 5px;
        box-shadow: inset 2px 2px 5px -2px rgba($black, 0.2);
        color: $grey-lowlight-3;
        height: 30px;
    }
}