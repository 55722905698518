.button-set {
    @include media-breakpoint-up(lg) {
        display: flex;
        align-items: center;
    }

    .tertiary-button,
    .btn {
        margin-bottom: 10px;

        @include media-breakpoint-up(lg) {
            + .btn {
                margin-left: 15px;
            }

            + .tertiary-button {
                margin-left: 25px;
            }
        }

        @include media-breakpoint-down(lg) {
            + .tertiary-button {
                margin-top: 10px;
            }

        }
    }
}