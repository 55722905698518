//Copied from HH
.hh-mashup #back_to_top {
    position: fixed;
    bottom: 0px;
    right: 15px;
    z-index: 100;
    text-align: center;
    display: none;
    border: none;
    width: 50px;
    height: 40px;
    font-size: 32px;
    opacity: 0.8;
}
.hh-mashup #loading {
    padding: 30px;
    text-align: center;
    height: 100vh;
    font-style: italic;
}

.hh-mashup .debug-output {
    border: 1px red solid;
    padding: 5px;
    margin-bottom: 10px;
    overflow-wrap: break-word;
}
.hh-mashup .debug-output span{
    font-weight: bold;
}

.hh-mashup .header.row {
    padding: 24px 0px;
}
.hh-mashup .content {
    padding-right: 15px;
    padding-left: 15px;
    margin-left: auto;
    margin-right: auto;
}
.hh-mashup .grid-item {
    overflow-wrap: break-word;
    float: left;
    -webkit-box-shadow: -2px 8px 15px 0px rgba(0,0,0,0.11);
    -moz-box-shadow: -2px 8px 15px 0px rgba(0,0,0,0.11);
    box-shadow: -2px 8px 15px 0px rgba(0,0,0,0.11);
}
.hh-mashup .grid-item img {
    width: 100%;
}
.hh-mashup .grid-item .title {
    display: block;
    font-size: 18px;
    font-weight: 500;
}
.hh-mashup .grid-item .video iframe{
    width: 100%;
    border: none;
}
.hh-mashup .grid-item .fullLink {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: block;
    z-index: 1;
}
.hh-mashup .grid-item .grid-content {
    padding: 20px;
}
.hh-mashup .grid-item .logoLink, .grid-content .text, .grid-content .logoLink span, .grid-item .video{
    z-index: 10;
    position: relative;
}
.hh-mashup .grid-content .text {
    margin-bottom: 10px;
    overflow-wrap: break-word;
    word-wrap: break-word;
}
.hh-mashup .grid-content .postDate {
    font-size: 12px;
    margin-bottom: 0px;
    position: absolute;
    bottom: 15px;
    color: #767676;
}
.hh-mashup .grid-content .date {
    display: none;
}
.hh-mashup .grid-content .logoLink {
    float: right;
    bottom: 15px;
}

.hh-mashup a.logoLink i {
    color: #424242;
    font-size: 30px;
    margin-top: 12px;
}

/* Filter Buttons */
.hh-mashup .media_bar {
    float:right;
}

.hh-mashup .btn-filter:focus {
    outline: none;
}
.hh-mashup .btn-filter {
    border: none;
    padding: 0 0 0 2px;
    width: 34px;
    height: 34px;
    background-color:white;
}

.hh-mashup .filter-button-group button:hover {
    opacity: .5;
}
.hh-mashup .filter-button-group button:active, .filter-button-group button.is-checked{
    opacity: .5;
}
.hh-mashup .filter-button-group button:disabled {
    opacity: .5;
}

.hh-mashup button.btn-filter i {
    font-size: 34px;
    color: #424242;
}

.hh-mashup button.btn-filter i.fas.fa-sync-alt {
    font-size: 32px;
}

.hh-mashup i.fab.fa-facebook-square:hover, button.is-checked i.fab.fa-facebook-square      { color: #3c5A99; }
.hh-mashup i.fab.fa-flickr:hover, button.is-checked i.fab.fa-flickr                        { color: #ff0084; }
.hh-mashup i.fab.fa-instagram:hover, button.is-checked i.fab.fa-instagram                  { color: #c13584; }
.hh-mashup i.fab.fa-pinterest-square:hover, button.is-checked i.fab.fa-pinterest-square    { color: #e60023; }
.hh-mashup i.fas.fa-rss-square:hover, button.is-checked i.fas.fa-rss-square                { color: #f26522; }
.hh-mashup i.fab.fa-twitter-square:hover, button.is-checked i.fab.fa-twitter-square        { color: #1da1f2; }
.hh-mashup i.fab.fa-youtube-square:hover, button.is-checked i.fab.fa-youtube-square        { color: #ff0000; }




/* Font Awesome Icons */
.hh-mashup .fa {
    vertical-align: top;
    /*font-size: 40px;*/
}
.hh-mashup .fa.pull-left {
    margin-right: .2em;
}
.hh-mashup .fa-white {
    color: white !important;
    font-size: 26px;
}

/* Column Wdiths */
.hh-mashup .grid-item {
    width: 100%;
}
.hh-mashup .grid-item-2 {
    width: 100%;
}
.hh-mashup .grid-item .video iframe {
    min-height: 350px;
}
.hh-mashup .gutter-sizer {
    width: 1%;
}
@media screen and (min-width: 768px) {
    .hh-mashup .grid-item {
        width: 49.5%;
    }
    .hh-mashup .grid-item-2 {
        width: 100%;
    }
    .hh-mashup .grid-item .video iframe {
        min-height: 250px;
    }
    .hh-mashup .grid-item-2 .video iframe {
        min-height: 500px;
    }
}
@media screen and (min-width: 992px) {
    .hh-mashup .grid-item {
        width: 32.66%;
    }
    .hh-mashup .grid-item-2 {
        width: 66.33%;
    }
    .hh-mashup .grid-item .video iframe {
        min-height: 200px;
    }
    .hh-mashup .grid-item-2 .video iframe {
        min-height: 400px;
    }
}
@media screen and (min-width: 1200px) {
    .hh-mashup .grid-item {
        width: 24.25%;
    }
    .hh-mashup .grid-item-2 {
        width: 49.5%;
    }
    .hh-mashup .grid-item .video iframe {
        min-height: 150px;
    }
    .hh-mashup .grid-item-2 .video iframe {
        min-height: 300px;
    }
}