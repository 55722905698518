.search-form {
    position:relative;

    button {
        position:absolute;
        top:0;
        bottom:0;
        right:0;
        width: 50px;

        border:none;
        background:transparent;

        &:after {
            content: "\f002";
            @include fontawesome(400);
            color: $gold;
            font-size:23px;
        }
    }

    input {
        padding-right: 50px;
    }
}