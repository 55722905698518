.featured-destination-cta {
    margin-bottom: 60px;

    &--style-1 {

        .text-and-image {
            position: relative;
            @include media-breakpoint-down(sm) {
                margin-left: 0;
                margin-right: 0;
            }
            @include media-breakpoint-up(lg) {
                overflow: hidden;
            }

            &__image {
                padding-left: 0;
                padding-right: 0;

                &:before {
                    content: '';
                    @include cover-all;
                    background: linear-gradient(to bottom,  rgba($blue,0) 0%,rgba($blue,0) 35%,rgba($blue,1) 100%);
                    opacity: 0.25;
                }

                @include media-breakpoint-down(lg) {
                    position: relative;
                    overflow: hidden;
                }

                @include media-breakpoint-up(lg) {
                    @include cover-all;
                }
            }

            &__content {
                padding-top: 0;
                position: relative;
                padding-left: 15px;
                padding-right: 15px;

                @include media-breakpoint-up(lg) {
                    padding: 70px 0 70px 45px;
                    color: $white;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    min-height: 510px;

                    .tertiary-button {
                        font-size: 26px;
                        text-shadow: 1.414px 1.414px 0px rgba(0, 0, 0, 0.4);
                        a {
                            color: $white;
                        }
                    }
                }

                @include media-breakpoint-up(xl) {
                    padding-left: 60px;
                    padding-bottom: 90px;
                    min-height: 680px;
                }

                @include media-breakpoint-up(xxl) {
                    padding-left: 111px;
                }

                @include media-breakpoint-down(lg) {
                    text-align: left;
                }

                h2 {
                    margin-bottom: 27px;
                    font-family: $font-family-secondary;
                    font-weight: $font-weight-demi-web;
                    font-size: 48px;
                    letter-spacing: 0.02em;
                    line-height: 1;
                    color: $blue-highlight-2;
                    @include media-breakpoint-up(lg) {
                        font-size: 62px;
                        text-shadow: 1.414px 1.414px 0px rgba(0, 0, 0, 0.4);
                        color: $white;
                    }
                    @include media-breakpoint-down(xxl) {
                        position: relative;
                        padding-bottom: 10px;
                    }

                    &:after {
                        content: '';
                        position: absolute;
                        background-color: $orange;
                        height: 4px;
                        width: 80px;
                        left: -15px;
                        @include media-breakpoint-only(lg) {
                            left: -44px;
                        }
                        @include media-breakpoint-only(xl) {
                            left: -60px;
                        }
                        @include media-breakpoint-down(xxl) {
                            bottom: -11px;
                        }
                        @include media-breakpoint-up(xxl) {
                            left: 0;
                            margin-top: 10px;
                        }
                    }

                    .subtitle {
                        text-transform: none;
                        color: $white;
                        line-height: 1.421;
                        text-shadow: 1.414px 1.414px 0px rgba(0, 0, 0, 0.4);
                        font-size: 32px;
                        @include media-breakpoint-only(sm) {
                            font-size: 29px;
                        }
                        @include media-breakpoint-up(lg) {
                            font-size: 38px;
                            margin-bottom: -5px;
                        }
                        @include media-breakpoint-down(lg) {
                            margin-top: -50px;
                            margin-bottom: 17px;
                        }
                    }
                }

                p {
                    @include media-breakpoint-up(lg) {
                        line-height: 1.5;
                        text-shadow: 1.414px 1.414px 0px rgba(19, 41, 71, 0.75);
                    }
                }

                .button-set {
                    margin-top: 12px;
                    @include media-breakpoint-up(lg) {
                        .btn,
                        .tertiary-button a {
                            white-space: nowrap;
                        }
                    }
                    @include media-breakpoint-down(md) {
                        .tertiary-button a {
                            font-size: 20px;
                        }
                    }
                }
            }
        }

        .figure {
            border-left: 0 none;

            @include media-breakpoint-up(lg) {
                height: 100%;
                width: 100%;
                display: flex;

                &-img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: right;
                }
            }


            &:before {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                background: linear-gradient(to bottom,  rgba(255,255,255,0.17) 0%,rgba($blue,1) 100%);
                right: 78%;
                transform: scale(3) rotate(-35deg);
                left: 0px;
                opacity: .5;
                @include media-breakpoint-up(lg) {
                    right: 75.5%;
                }
                @include media-breakpoint-up(xl) {
                    right: 80%;
                }
            }

            &:after {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                background: linear-gradient(188deg, rgba(0, 47, 109, 0) 0%, rgba(0, 47, 109, 0) 46%, #002f6d 100%);
                right: 83%;
                transform: scale(3) rotate(-35deg);
                left: 0px;
                @include media-breakpoint-up(lg) {
                    right: 78.5%;
                }
                @include media-breakpoint-up(xl) {
                    right: 83%;
                }
            }
        }

        .container {
            @include media-breakpoint-down(sm) {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    &--style-2 {
        @include media-breakpoint-down(lg) {
            margin-top: 130px;

            .text-and-image {

                &__image {
                    @include media-breakpoint-down(lg) {
                        display: flex;
                        justify-content: center;
                        margin-top: -79px;
                    }
                }
            }
        }
        @include media-breakpoint-down(md) {
            h2 {
                .subtitle {
                    margin-bottom: 5px;
                }
            }
        }
    }

    &--style-3 {
        position: relative;
        overflow: hidden;

        @include media-breakpoint-up(lg) {
            padding-left: 15px;
            padding-top: 80px;
            padding-bottom: 80px;
        }

        .container {
            @include media-breakpoint-down(lg) {
                max-width: none;
            }
        }

        .text-and-image {
            @include media-breakpoint-down(lg) {
                background-image: linear-gradient(180deg, rgb(0, 47, 109) 0%, rgb(20, 100, 222) 100%);
                &:after {
                    content: '';
                    background: transparent url('../images/overlay-featured-destination-cta-style-3_mobile.png') no-repeat scroll left top;
                    background-size: auto 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    right: 0;
                }
            }

            &__content {
                padding-left: 40px;
                padding-top: 25px;
                padding-bottom: 25px;

                @include media-breakpoint-up(lg) {
                    padding: 0 4% 0 55px;
                }

                &:before {
                    content: '';
                    position: absolute;
                    top: 25px;
                    left: 15px;
                    width: 4px;
                    background-color: $orange;
                    height: 0;
                    transition: height 1.5s ease-in-out;

                    @include media-breakpoint-up(lg) {
                        top: 0;
                        left: 0;
                    }
                }

                &.animate-in-view--intersecting {
                    &:before {
                        height: calc(100% - 50px);
                        @include media-breakpoint-up(lg) {
                            height: 100%;
                        }
                    }
                }


                @include media-breakpoint-down(lg) {
                    text-align: left;
                    z-index: 1;

                    .button-set {
                        align-items: flex-start;
                    }
                }

                h2, h3 {
                    line-height: 1.074;

                    @include media-breakpoint-up(lg) {
                        padding-right: 17%;
                    }
                }

                p {
                    @include media-breakpoint-down(lg) {
                        font-size: 18px;
                        line-height: 1.556;
                    }
                }

                .tertiary-button {
                    a {
                        font-size: 20px;
                    }
                }
            }
        }
    }

    &--style-4 {

        h2 {
            margin-bottom: 16px;
        }

        .title--bottom-divider {
            padding-bottom: 30px;
        }

        p {
            letter-spacing: 0.02em;
            @include media-breakpoint-down(md) {
                font-size: 18px;
                line-height: 1.556;
            }
        }

    }
}