// For unknown reasons, glob importing variables fails inside compile-bootstrap, so the variable imports were moved here.

@import "functions";
@import 'variables';
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/02-atoms/00-heading/_heading.variables.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/02-atoms/01-paragraph/_paragraph.variables.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/02-atoms/07-hr/_hr.variables.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/02-atoms/10-table/_table.variables.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/02-atoms/form-elements/_checkbox.variables.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/02-atoms/form-elements/_form-elements.variables.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/03-molecules/accordion/_accordion.variables.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/03-molecules/buttons/button/_button.variables.scss";
@import "compile-bootstrap";

@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/02-atoms/00-heading/_heading.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/02-atoms/00-heading/_heading.variables.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/02-atoms/01-paragraph/_paragraph.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/02-atoms/01-paragraph/_paragraph.variables.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/02-atoms/04-list/_list.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/02-atoms/07-hr/_hr.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/02-atoms/07-hr/_hr.variables.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/02-atoms/10-table/_table.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/02-atoms/10-table/_table.variables.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/02-atoms/back-link/_back-link.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/02-atoms/blockquote/_blockquote.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/02-atoms/form-elements/_checkbox.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/02-atoms/form-elements/_checkbox.variables.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/02-atoms/form-elements/_form-elements.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/02-atoms/form-elements/_form-elements.variables.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/02-atoms/form-elements/_multiselect-dropdown.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/02-atoms/form-elements/_upload.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/02-atoms/image/_image.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/02-atoms/search-results/_search-results.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/02-atoms/video/_video.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/03-molecules/accordion/_accordion.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/03-molecules/accordion/_accordion.variables.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/03-molecules/button-set/_button-set.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/03-molecules/buttons/button-cheatsheet/_button-cheatsheet.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/03-molecules/buttons/button/_button.variables.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/03-molecules/buttons/button/_buttons.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/03-molecules/buttons/tertiary-button/tertiary-button.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/03-molecules/card/_card.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/03-molecules/course-card/_course-card.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/03-molecules/detail-box/_detail-box.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/03-molecules/event-card/event-card.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/03-molecules/filter-band-results/_filter-band-results.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/03-molecules/filter-band/_filter-band.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/03-molecules/news-thumbnail/news-thumbnail.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/03-molecules/program-card/program-card.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/03-molecules/richtext/_richtext.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/03-molecules/search-form/_search-form.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/03-molecules/tabs/tabs.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/03-molecules/text-and-image/_text-and-image.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/04-organisms/00-global/alert/_alert.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/04-organisms/00-global/alertset/_alertset.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/04-organisms/00-global/footer/_footer.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/04-organisms/00-global/header/_header.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/04-organisms/00-global/menu-flyout/_menu-flyout.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/04-organisms/00-global/mlp-header/_mlp-header.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/04-organisms/blue-cta-block/_blue-cta-block.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/04-organisms/cards/_cards.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/04-organisms/contact-block/_contact-block.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/04-organisms/directory/directory.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/04-organisms/embed/_embed.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/04-organisms/events-listing/_events-listing.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/04-organisms/events-listing/_hh-events.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/04-organisms/featured-destination-cta/_featured-desintation-cta.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/04-organisms/featured-events-feed/featured-events-feed.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/04-organisms/featured-news-feed/featured-news-feed.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/04-organisms/herofeature/_herofeature.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/04-organisms/image-slider/_images-slider.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/04-organisms/media-gallery/_media-gallery.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/04-organisms/multi-link-feature/multi-link-feature.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/04-organisms/news-detail/_news-detail.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/04-organisms/news-listing/_hh-styles.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/04-organisms/news-listing/_news-listing.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/04-organisms/program-finder-landing/program-finder-landing.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/04-organisms/program-finder/program-finder.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/04-organisms/quickfacts/_quickfacts.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/04-organisms/quote/_quote.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/04-organisms/sectional-navigation/_sectional-navigation.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/04-organisms/social-feed/_hh-mash-up.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/04-organisms/social-feed/_social-feed.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/04-organisms/success-stories-listing/_success-stories-listing.scss";
@import "/Users/elisabethhutzel/Documents/GitHub/services/cascade/CCBC/ccbc-front-end/patterns/04-organisms/tabs-component/_tabs-component.scss";
@import "animations";
@import "print";
@import '../../node_modules/@splidejs/splide/src/css/core/index.scss';
@import '../../node_modules/@splidejs/splide-extension-video/dist/css/splide-extension-video.min';

body {
    @include media-breakpoint-down(md) {
        font-size: 16px;
    }
}

a {
    transition: color 0.35s ease, background-color 0.35s ease;

    .fa-caret-right {
        padding-left: 8px;
    }
}

.offcanvas.offcanvas-end {
    top: 5px;
}

img {
    min-width: 1px;
    min-height: 1px;
}

.alert {
    border-radius:0;
    font-size: $font-size-base;
}