.tabs-component {
    @include default-margin-bottom;
    &__content {
        padding-top: 40px;

        .figure {
            &.float-end {
                @include media-breakpoint-up(md) {
                    margin-right: 0;
                }
            }
            &.float-start {
                @include media-breakpoint-up(md) {
                    margin-left: 0;
                }
            }
        }
    }
    &--vertical &__outer {

        @include media-breakpoint-up(lg) {
            border-top: 1px solid $grey-highlight-2;
            border-bottom: 1px solid $grey-highlight-2;
        }
    }
    &--vertical &__content {
        padding: 40px 65px;
    }
}