.site-header-mlp {
    position:absolute;
    top:0;
    width:100%;
    background:transparent;
    z-index:2;

    padding-top: 15px;
    @include media-breakpoint-up(md) {
        padding-top: 45px;
    }

    &::before {
        position:absolute;
        top:0;
        left:0;

        content: '';
        display:block;
        height: 5px;
        width: 100%;
        background: linear-gradient(to right,  $blue 0%,$blue-highlight-2 100%);
    }

    img {
        width: 230px;
        @include media-breakpoint-up(md) {
            width: 380px;
        }
    }
}