.hot-post {
    @include media-breakpoint-up(lg) {
        margin-bottom: 50px;
    }

    .post-title {
        margin-bottom: 0;
    }

    .post {
        margin-bottom: 6px;

        &.post-thumb .post-title a {
            color: $white;
            margin-bottom: 0;

            &:hover,
            &:focus {
                color: $white;
                text-decoration: underline;
            }
        }
    }

    .post-meta {
        padding-left: 0;
    }

    .post-category {
        span,
        a {
            color: $white;
        }

        a {
            &:hover,
            &:focus {
                text-decoration: none;
            }
        }
    }

    .hot-post-right {
        @include media-breakpoint-down(md) {
            padding-left: 0;
        }
    }

    .post.post-thumb .post-body {
        bottom: 20px;
    }
}

.hh-news {
    &__filters {
        border-bottom: 5px solid $grey-highlight-2;
        padding-bottom: 40px;
        margin-bottom: 40px;
    }

    .post {
        border-bottom: 1px solid $grey-highlight-2;
        padding-bottom: 30px;

        &.post-row {
            .post-img {
                @include media-breakpoint-up(sm) {
                    width: 25%;
                }
                @include media-breakpoint-down(sm) {
                    width: 100%;
                    float: none;
                    margin-bottom: 15px;
                }
            }
            .post-body {
                @include media-breakpoint-up(sm) {
                    margin-left: calc(25% + 30px);
                }
                @include media-breakpoint-down(sm) {
                    margin-left: 0;
                }
            }
        }
    }
    .post-meta {
        padding-left: 0;

        &__type {
            color: $grey-lowlight-2;
        }
        li {
            color: $grey-lowlight-2;
        }
    }

    .post-category {
        span,
        a {
            color: $blue-highlight-3;

            &:hover,
            &:focus {
                color: $blue;
            }
        }
    }

    .post-title {
        a {
            color: $black;
            margin-bottom: 10px;

            &:hover,
            &:focus {
                color: $blue;
            }
        }
    }

    .post-body {
        p {
            letter-spacing: 0.05em;
            margin-top: 22px;
            margin-bottom: 0;
            color: $grey-lowlight-3;
        }
    }
}

.hh-news,
.hot-post {

    .post-img {
        img {
            transition: transform 1.25s ease;
        }

        &:hover,
        &:focus {
            img {
                transform: scale(1.1);
            }
        }
    }

    .post-title {
        font-size: 30px;
        letter-spacing: 0.04em;

        &.title-lg {
            @include media-breakpoint-up(lg) {
                font-size: 36px;
            }
        }

        a {
            text-decoration: none;
            padding-right: 28px;
            position: relative;
            display: inline-block;

            &:after {
                content: '\f0a9';
                color: $gold;
                font-size: 22px;
                padding-left: 10px;
                @include fontawesome;
                transition: padding 0.35s ease;
                position: absolute;
                margin-top: 8px;
            }

            &:hover,
            &:focus {
                &:after {
                    padding-left: 16px;
                }
            }
        }
    }

    .post-meta {
        line-height: 1;
        font-style: italic;
        margin-bottom: 10px;

        &__type {
            font-family: $font-family-secondary;
            text-transform: uppercase;
            font-style: normal;
            font-weight: $font-weight-heavy;
        }

        &__date {
            text-transform: none;
        }

        li {
            letter-spacing: 0.05em;
            font-size: 18px;
            white-space: nowrap;

            &:not(:last-child) {
                &:after {
                    content: "|";
                    padding-left: 11px;
                    font-size: 16px;
                    position: relative;
                    top: -2px;
                }
            }
        }
    }

    .post-category {
        line-height: 1;
        margin-top: 15px;

        span,
        a {
            letter-spacing: 0.05em;
            font-size: 16px;
            text-transform: none;
        }

    }

}