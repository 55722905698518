.multiselect-dropdown {
    position:relative;

    a {
        text-decoration: none;
        transition: background-color 0.35s ease;
    }
    ul {
        padding:0;
        margin:0;
    }
    ul, li {
        display:block;
    }

    &__panel {
        position:absolute;
        top: 100%;
        left:0;
        width:100%;
        padding: 4px 13px 0 13px;
        background:white;
        border: 2px solid $grey-highlight-2;
        border-top: none;
        z-index:3;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    &__checkbox {
        padding: 8px;
        border-bottom: 1px solid $grey-highlight-1;
    }

    &--open > a {
        background-color: $grey-highlight-2;
        border-color: $grey-highlight-1;
        border-bottom:0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    &__close {
        text-align:right;
    }
    &__close a {
        display:inline-block;
        font-family: $font-family-sans-serif;
        font-weight: $font-weight-medium;
        font-size: 16px;
        margin: 8px 0;
        color: $body-color;
        &:after {
            content: "\00A0" "\f0d8";
            @include fontawesome();
            color: $blue-highlight-2;
            position:relative;
            top: 2px;
        }
    }
}