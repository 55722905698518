.gallery--ccbc {
    @include default-margin-bottom;

    &.nGY2 {
        .nGY2paginationRectangle,
        .nGY2paginationRectangleCurrentPage {
            width: 12px;
            height: 12px;
            border: 0 none;
            margin-left: 7px;
            margin-right: 7px;
            margin-top: 25px;
            border-radius: 50%;
            transition: background-color 0.35s ease;
        }

        .nGY2paginationRectangle {
            background: #9b9b99;
            opacity: 1;
        }

        .nGY2paginationRectangleCurrentPage {
            background-color: $orange;
        }
    }
}

.nGY2 {
    .toolbar {
        .pageCounter {
            font-size: 19px !important;
        }

        .label {
            text-align: center !important;

            .title {
                font-size: 20px !important;
            }
        }
    }

    .nGY2ViewerAreaNext,
    .nGY2ViewerAreaPrevious {
        > i {
            background-color: transparent !important;

            &:before {
                font-weight: $font-weight-normal;
            }
        }
    }
    .closeButton {
        .fa {
            &:before {
                font-weight: $font-weight-normal;
            }
        }
    }

    .nGY2GThumbnail {
        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            background-color: $orange;
            height: 4px;
            z-index: 1;
            opacity: 0;
            transition: opacity 0.35s ease;
        }
        &:hover,
        &:focus {
            &:before {
                opacity: 1;
            }
            .nGY2GThumbnailImage {
                transform: scale(1.1);
            }
        }
    }
    .nGY2GThumbnailImage {
        transition: transform 1.25s ease;
    }

    .nGY2Navigationbar {
        .nGY2NavPagination {
            background-color: transparent;
            color: $black;
            border-radius: 50%;
            width: 46px;
            height: 46px;
            padding: 0;
            border: 2px solid $gold;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            transition: background-color 0.35s ease;

            .nGY2Icon-ngy2_chevron-right {
                margin-right: 0;
            }

            &:hover,
            &:focus {
                background-color: $gold;
                color: $black;
            }
        }
    }
}