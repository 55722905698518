//Copied from HH
.post{
    position:relative;
    margin-bottom:30px
}
.post .post-body{
    position:relative;
    margin:-50px 0 0 3%;
    padding:5%;
    background:#fff;
    z-index:10
}

.post .post-img{
    display:block;
    overflow:hidden;
    position:relative;
}


.post.post-widget .post-img>img{
    height:100%;
    object-fit:cover;
    position:absolute;
    margin-right:auto;
    margin-left:auto;
}

.post-img>img{
    width:100%;
    -webkit-transition:1.6s -webkit-transform;
    transition:1.6s -webkit-transform;
    transition:1.6s transform;
    transition:1.6s transform,1.6s -webkit-transform
}
.post-img:hover>img{
    -webkit-transform:scale(1.2);
    -ms-transform:scale(1.2);
    transform:scale(1.2)
}
.post .post-title{
    font-size:18px
}
.post .post-title.title-sm{
    font-size:16px
}
.post .post-title.title-lg{
    font-size:25.629px
}
.post .post-category{
    margin-bottom:10px
}
.post-category span, .post-category a{
    color:#ee4266;
    font-weight:700;
    font-size:12px;
    text-transform:uppercase
}
.post-category span:after, .post-category a:after{
    content:',';
    display:inline-block
}
.post-category span:last-child:after, .post-category a:last-child:after{
    display:none
}
ul.post-meta{
    margin-bottom:5px
}
.post .post-meta{
    margin-bottom:10px
}
.post-meta li{
    display:inline-block;
    color:#97989b;
    font-size:12px;
    text-transform:uppercase
}
.post-meta li a{
    color:#97989b;
    font-weight:700
}
.post-meta li a:hover,.post-meta li a:focus{
    color:#ee4266
}
.post-meta li:after{
    content:'•';
    display:inline-block;
    padding-left:5px;
    padding-right:5px
}
.post-meta li:last-child:after{
    display:none
}
.hot-post{
    padding:0 15px
}
.hot-post .hot-post-left{
    padding-right:0;
    padding-left:0
}
.hot-post .hot-post-right{
    padding-right:0;
    padding-left:5px
}
.hot-post .post{
    margin-bottom:7px
}
.hot-post{
    margin-bottom:25px
}
.post.post-thumb .post-img:after{
    content:"";
    position:absolute;
    left:0;
    right:0;
    top:0;
    bottom:0;
    background:-webkit-gradient(linear,left top,left bottom,from(rgba(27,28,30,0)),color-stop(90%,rgba(27,28,30,0.8)));
    background:linear-gradient(180deg,rgba(27,28,30,0) 0%,rgba(27,28,30,0.8) 90%)
}
.post.post-thumb .post-body{
    position:absolute;
    margin:0;
    padding:0 5%;
    z-index:20;
    bottom:5%;
    background-color:transparent
}
.post.post-thumb .post-title a{
    color:#fff
}
.post.post-thumb .post-title a:hover,.post.post-thumb .post-title a:focus{
    color:#ee4266
}
.post.post-thumb .post-meta li{
    color:#fff
}
.post.post-thumb .post-meta li a{
    color:#fff
}
.post.post-thumb .post-meta li a:hover,.post.post-thumb .post-meta li a:focus{
    color:#ee4266
}
.post.post-sm .post-body{
    margin:15px 0 0;
    background:0 0;
    padding:0
}
.post.post-widget:after{
    content:"";
    display:table;
    clear:both
}
.post.post-widget .post-img{
    float:left;
    height:87px;
    width:130px
}
.post.post-widget .post-body{
    position:relative;
    background:0 0;
    margin:0 0 0 150px;
    padding:0
}
.post.post-widget .post-title{
    font-size:16px;
    margin-bottom:0
}
.post.post-row:after{
    content:'';
    display:table;
    clear:both
}
.post.post-row .post-img{
    width:40%;
    float:left
}


.post.post-row .post-body{
    margin:0 0 0 calc(40% + 30px);
    padding:0
}
.page-header .post-category{
    margin-bottom:10px
}
.page-header .post-meta li{
    color:#fff
}
.page-header .post-meta li a{
    color:#fff
}
.page-header .post-meta li a:hover,.page-header .post-meta li a:focus{
    color:#ee4266
}
.post-share a{
    display:inline-block;
    padding:5px 15px;
    border-radius:5px;
    color:#fff;
    background-color:#97989b;
    margin-right:4px;
    margin-bottom:6px;
    -webkit-transition:.2s opacity;
    transition:.2s opacity
}
.post-share a:hover{
    opacity:.9
}
.post-share a i{
    width:20px;
    text-align:center
}
.post-share a span{
    padding-left:10px;
    border-left:2px solid rgba(255,255,255,.1);
    text-transform:uppercase;
    font-size:12px;
    font-weight:700;
    margin-left:10px
}
.post-content .post-attachement.pull-left{
    margin-right:15px
}
.post-content .post-attachement.pull-right{
    margin-left:15px
}
.post-tags ul li{
    display:inline-block;
    text-transform:uppercase;
    font-size:12px;
    font-weight:700
}
.post-tags ul li:first-child{
    font-weight:400
}
.post-tags ul li:after{
    content:',';
    display:inline-block
}
.post-tags ul li:first-child:after{
    display:none
}
.post-tags ul li:last-child:after{
    display:none
}
.post-nav:after{
    content:"";
    display:table;
    clear:both
}
.post-nav .post-title{
    font-size:14px;
    margin:0
}
.post-nav .prev-post{
    float:left;
    width:50%;
    padding-right:15px
}
.post-nav .next-post{
    float:right;
    width:50%;
    text-align:right;
    padding-left:15px
}
.post-nav .post-img{
    display:block;
    width:100px;
    overflow:hidden
}
.post-nav .prev-post .post-img{
    float:left;
    margin-right:15px
}
.post-nav .next-post .post-img{
    float:right;
    margin-left:15px
}
.post-nav .next-post span,.post-nav .prev-post span{
    font-weight:700;
    color:#97989b;
    text-transform:uppercase;
    font-size:12px
}
.post-nav .next-post:after,.post-nav .prev-post:after{
    content:'';
    display:table;
    clear:both
}
.author.media .media-left{
    position:relative;
    padding-right:30px
}
.author .author-img{
    width:100px;
    border-radius:50%;
    margin-bottom:15px
}
.author .author-social li{
    display:inline-block;
    margin-right:10px
}
.author .author-social li a{
    display:block
}
.page-header .author .author-social li a{
    color:#fff
}
.page-header .author .author-social li a:hover,.page-header .author .author-social li a:focus{
    color:#ee4266
}
.post-comments .media{
    margin-top:30px
}
.post-comments .media:nth-child(1){
    margin-top:0
}
.post-comments .media .media-left{
    position:relative;
    padding-right:15px
}
.post-comments .media .media-left .media-object{
    width:50px;
    border-radius:50%;
    position:relative;
    z-index:20
}
.post-comments .media .media-left:after{
    content:'';
    position:absolute;
    left:calc(50% - 9px);
    top:80px;
    bottom:15px;
    width:1px;
    background-color:#e8eaed
}
.post-comments .media .media-heading h4{
    display:inline-block;
    font-size:14px;
    text-transform:uppercase
}
.post-comments .media .media-heading .time{
    color:#97989b;
    margin-left:10px;
    font-size:12px
}
.post-comments .media .reply{
    text-transform:uppercase;
    display:inline-block;
    padding:5px 13px;
    font-size:12px;
    font-weight:700;
    color:#fff;
    background-color:#323335;
    border-radius:2px;
    -webkit-transition:.2s opacity;
    transition:.2s opacity
}
.post-comments .media .reply:hover{
    opacity:.9
}
.post-comments .media.media-author .media-heading h4{
    color:#ee4266
}
.post-comments .media.media-author .media-left:after{
    background-color:#ee4266
}

@media only screen and (max-width:767px){
    .post-nav .prev-post{
        float:none;
        width:100%;
        margin-bottom:15px;
        padding-right:0
    }
    .post-nav .prev-post:last-child{
        margin-bottom:0
    }
    .post-nav .next-post{
        float:none;
        width:100%
    }
    .post-nav .next-post{
        float:none;
        width:100%;
        padding-left:0;
        text-align:left
    }
    .post-nav .next-post .post-img{
        margin-right:15px;
        float:left;
        margin-left:0
    }
}
