.herofeature {

    $herofeature-overlap: 30px;
    $herofeature-overlap-lg: 30px;

    @include default-margin-bottom;

    position:relative;

    &__parallax-clip {
        overflow:hidden;
        position:absolute;
        top:0;
        bottom:0;
        left:0;
        right:0;
    }
    &__parallax {
        position:absolute;
        top:0;
        height:100%;
        left:0;
        right:0;
    }

    &:before {
        content: "";
        position:absolute;
        top:0;
        left:0;
        width:100%;
        z-index:1;

        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.08) 0, rgba(0, 0, 0, 0) 50px);
        min-height: 50px;
        //@include media-breakpoint-up(lg) {
        //    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3*0.08) 0, rgba(0, 0, 0, 0.3*0.08) 60px, rgba(0, 0, 0, 0) 190px);
        //    min-height: 190px;
        //}
    }
    &--large:before,
    &--full:before {
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 0, rgba(0, 0, 0, 0.3) 30px, rgba(0, 0, 0, 0) 159px);
        min-height: 159px;
        @include media-breakpoint-up(lg) {
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 0, rgba(0, 0, 0, 0.3) 60px, rgba(0, 0, 0, 0) 390px);
            min-height: 390px;
        }
    }

    &__top {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        position:relative;

        padding-top: 140px - $herofeature-overlap;
        @include media-breakpoint-up(md) {
            padding-top: 190px - $herofeature-overlap-lg;
        }
    }

    &__background {
        position:absolute;
        top:0;
        height:100%;
        left:0;
        right:0;
        background: $blue-highlight-1;
        background-position:center;
        background-size: cover;
    }


    &__background-overlays {
        position:absolute;
        top:0;
        bottom:0;
        left:0;
        right:0;
        z-index:1;
        overflow:hidden;
    }
    &--full &__background-overlays:before {
        background: linear-gradient(to bottom, rgba(0,0,0,0.30) 0, rgba(0,0,0,0.30) 100%), linear-gradient(to top, rgba(0,0,0,0.5) 0, rgba(0,0,0,0.5) 110px, rgba(0,0,0,0) 365px);
    }
    &__background-overlays:before {
        content:"";
        position:absolute;
        top:0;
        bottom:0;
        left:0;
        right:0;
        z-index:1;
        background: rgba(0,0,0,0.30);
    }

    &__background-overlays:after {
        content:"";
        position:absolute;
        top:0;
        bottom:0;
        left:0;
        width:80%;
        z-index:1;

        background-size: 100%;
        background-repeat: no-repeat;
        background-position: left bottom;

        @include media-breakpoint-down(md) {
            min-width:0;
            width:125%;
            right: 10%;
            //background-position: center bottom;
            //background-size: cover;
        }
    }
    &--diagonal-overlay-full &__background-overlays:after {
        background-image: url('../images/home-hero-bkgd-overlay.png');
    }
    //&--center &--diagonal-overlay-full &__background-overlays:after {
    //    background-image: url('/images/home-hero-bkgd-overlay.png'), linear-gradient(to top, rgba(0,0,0,0.50) 0, rgba(0,0,0,0.50) 100px, rgba(0,0,0,0) 360px);
    //}
    &--diagonal-overlay-large &__background-overlays:after {
        background-image: url('../images/landing-hero-bkgd-overlay.png');
    }
    &--diagonal-overlay-small &__background-overlays:after {
        background-image: url('../images/content-hero-bkgd-overlay.png');
    }

    video {
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        object-fit: cover;
    }
    &__videocontrol {
        position:absolute;
        bottom: 70px;
        z-index:2;
        display:flex;
        justify-content: center;
        align-items: center;
        width:46px;
        height:46px;
        border-radius: 100%;
        border: 2px solid $grey-highlight-3;
        background: $grey-lowlight-3;
        opacity: 0.40;
        color: $grey-highlight-3;
        transition: opacity 0.2s;

        &:hover {
            opacity:1;
        }

        &:focus-visible {
            opacity: 1;
        }
    }
    &__videocontrol-pause:before {
        @include fontawesome();
        display:block;
        content:"\f04c";
        font-size: 20px;
    }
    &__videocontrol-play:before {
        @include fontawesome();
        display:block;
        content:"\f04b";
        font-size: 20px;
        margin-right: -2px;
    }

    &__columns {
        position:relative;
        width:100%;
        bottom: -$herofeature-overlap;
        @include media-breakpoint-up(lg) {
            bottom: -$herofeature-overlap-lg;
        }
    }


    &__back-link {
        position:relative;
        z-index:2;
    }

    &--no-thumbnail &__content {
        @include media-breakpoint-down(lg) {
            padding-bottom: 35px;
        }
    }
    &__content {
        position:relative;
        z-index:2;
        padding-top: 16px;
        padding-left: 24px;
        padding-right: 24px;;

        &:before {
            content:"";
            z-index:1;
            display:block;
            width: 4px;
            left:0;
            bottom:0;
            top:0;
            position:absolute;
            background: $orange;

        }

        @include media-breakpoint-up(lg) {
            padding-top: 25px;
            padding-left: 40px;
            padding-right:0;
            border-right:none;
        }

        @include media-breakpoint-up(lg) {
            padding-bottom: 80px;
        }
    }


    &__title {
        font-family: $font-family-sans-serif;
        font-weight: $font-weight-medium-web;
        line-height: 1.1;
        padding-bottom:5px;
        @include media-breakpoint-up(lg) {
            margin-top: -14px;
        }
        color: white;
        @include text-shadow();
        .herofeature__eyebrow {
            font-size: 26px;
            @include media-breakpoint-up(lg) {
                font-size: 30px;
            }
            display:block;
        }
        .herofeature__course-code {
            font-size: 28px;
            @include media-breakpoint-up(lg) {
                font-size: 46px;
            }
        }

    }

    &__h1 {
        color: white;
        line-height: 1.1;
        font-size: 44px;
        font-weight: $font-weight-demi-web;

        @include media-breakpoint-up(md) {
            font-size: 84px;
        }
    }
    &--smaller-title &__h1 {
        font-size: 34px;
        @include media-breakpoint-up(md) {
            font-size: 55px;
        }
        @include media-breakpoint-up(xl) {
            font-size: 72px;
        }
    }

    &__paragraph {
        color: white;
        font-size: 18px;
        letter-spacing: 0.030em;
        padding-bottom: 35px;
        @include media-breakpoint-up(lg) {
            font-size: 22px;
            padding-bottom: 0;
        }
        font-style:italic;
        @include text-shadow();

        p:last-child {
            margin-bottom: 0;
        }

        &--mobile {
            color: $blue-lowlight-1;
            font-weight: $font-weight-demi-web;
            text-shadow: none;
            padding-bottom: 20px;
        }
    }
    &--large &__paragraph {
        @include media-breakpoint-up(lg) {
            font-size: 32px;
        }
    }

    &__learnmore--mobile {
        font-size: 20px;
    }
    &__learnmore {
        font-size: 26px;
        margin-top: 5px;
    }

    &__links {
        margin-top: 45px;
        margin-bottom: 30px;
        @include media-breakpoint-up(lg) {
            margin-bottom: 0;
        }

    }
    &__link + &__link {
        margin-left: 20px;
    }
    &__mobile-panel {
        background: $grey-highlight-3;
        position:relative;
        z-index:1;
        border-bottom: 1px solid $grey-highlight-2;
    }
    &__mobile-elements {
        padding: 35px 20px 30px 20px;
    }
    &__links-mobile {
        margin-top: 20px;
    }
    &__links-mobile:first-child {
        margin-top: 0;
    }
    &__link-mobile {
        display: inline-block;
        margin-top: 15px;
        margin-right: 20px;
    }
    &__thumbnail {
        position:relative;
        z-index:2;
        padding-left: 24px;
        padding-right: 24px;

        &:before {
            content:"";
            z-index:1;
            display:block;
            width: 4px;
            height:100%;
            left:0;
            bottom:0;
            position:absolute;
            background: $orange;
        }

        @include media-breakpoint-up(lg) {
            padding-right:0;
            &:before {
                content:none;
            }
        }
        img {
            display:block;
            width:100%;
            height: auto;
        }
    }

    &--small.herofeature--smaller-title.herofeature--no-thumbnail &__content {
        padding-bottom: 50px;
    }

    &--small &__top {
        min-height: 310px;
        @include media-breakpoint-up(lg) {
            min-height: 450px;
        }
    }
    &--large &__top {
        min-height: 440px;
        @include media-breakpoint-up(lg) {
            min-height: 700px;
        }
    }
    &--full &__top {
        min-height: 100vh;
        min-height: calc(100vh - var(--vh-offset, 0px));
    }

    &__center {
        width:100%;
    }
    &__center &__content {
        display:flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        max-width: 860px;
        margin: 0 auto;
        padding: 0 0 25px 0;
        @include media-breakpoint-up(lg) {
            padding-bottom: 45px;
        }
    }
    &__center &__content:before {
        content:none;
    }
    &--center &__h1 {
        font-size: 42px;
        padding-bottom: 25px;
        margin-bottom: 20px;
        @include media-breakpoint-up(lg) {
            font-size: 84px;
            padding-bottom: 30px;

            @media screen and (max-height: 900px) {
                font-size: 64px;
            }
        }
    }
    &--center &__paragraph {
        margin-bottom: 0;
        padding-bottom: 0;
        @include media-breakpoint-up(lg) {
            font-size: 32px;
            margin-bottom: 40px;
            @media screen and (max-height: 900px) {
                font-size: 24px;
                margin-bottom: 20px;
            }
        }
    }
    &--center &__links {
        margin-top: 20px;
        margin-bottom: 25px;
    }
    &--center &__links-mobile {
        margin-top: 25px;
    }
    &--center &__link-mobile {
        display:table;
        margin: 0 auto;
    }
    &--center &__learnmore--mobile {
        text-align:center;
        margin-top: 30px;
    }

    &--lift-text &__top {
        align-items: center;
        @include media-breakpoint-up(lg) {
            align-items: bottom;
        }
    }
    &--lift-text &__content {
        margin-top: 100px;
        padding-bottom:0;
    }

    @keyframes herofeature_orangebar {
        0% {
            bottom:100%;
            opacity:0;
        }
        15% {
            bottom:85%;
            opacity:0;
        }
        100% {
            bottom:0;
            opacity:1;
        }
    }

    @keyframes herofeature_full_orangebar {
        0% {
            top: -35px;
            bottom:100%;
            opacity:0;
        }
        100% {
            top:0;
            bottom:0;
            opacity:1;
        }
    }
    @keyframes herofeature_full_title {
        0% {
            opacity:0;
            transform: translateY(-20px);
        }
        40% {
            opacity:0;
            transform: translateY(-20px);
        }
        80% {
            opacity:1;
            transform: translateY(0);
        }
    }
    @keyframes herofeature_full_links {
        0% {
            opacity:0;
            transform: translateY(-20px);
        }
        60% {
            opacity:0;
            transform: translateY(-20px);
        }
        100% {
            opacity:1;
            transform: translateY(0);
        }
    }


    @include media-breakpoint-up(lg) {
        .animate &__content:before {
            animation-duration: 1.5s;
            animation-timing-function: ease-out;
            animation-name: herofeature_orangebar;
        }
        &--full.animate &__content:before {
            animation-duration: 1.75s;
            animation-timing-function: ease-out;
            animation-name: herofeature_full_orangebar;
        }
        .animate &__thumbnail:before {
            animation-duration: 2s;
            animation-timing-function: ease-out;
            animation-name: herofeature_orangebar;
        }
        &--full.animate &__title {
            animation-duration: 2.5s;
            animation-timing-function: ease-out;
            animation-name: herofeature_full_title;
        }
        &--full.animate &__links {
            animation-duration: 2.5s;
            animation-timing-function: ease-out;
            animation-name: herofeature_full_links;
        }
    }

    .post-meta {
        padding-left: 2px;
        line-height: 1;
        margin-top: -10px;
        margin-bottom: 15px;
        @include media-breakpoint-up(lg) {
            margin-bottom: 32px;
        }

        &__type {
            text-transform: uppercase;
            font-style: normal;
            font-weight: $font-weight-demi-web;
        }

        &__date {
            text-transform: none;
        }

        li {
            font-family: $font-family-secondary;
            letter-spacing: 0.04em;
            color: #b3ccef;
            font-size: 18px;

            @include media-breakpoint-up(md) {
                font-size: 24px;

                &:not(:last-child) {
                    &:after {
                        content: "|";
                        font-weight: $font-weight-normal;
                        padding-left: 11px;
                        position: relative;
                        top: -2px;
                    }
                }
            }

            @include media-breakpoint-down(md) {
                display: block;
                margin-bottom: 8px;
                &:after {
                    display: none;
                }
            }
        }
    }

    &--no-overflow {
        .herofeature { //CSS Path Only
            &__columns {
                bottom: 0;
            }
            &__content {
                @include media-breakpoint-up(lg) {
                    padding-bottom: 50px;
                }
            }
        }

        &.herofeature--small.herofeature--smaller-title.herofeature--no-thumbnail {
            .herofeature__content {
                padding-bottom: 20px;
            }
        }

    }
}