.blockquote {
    margin-bottom: 30px;
    position: relative;
    border-color: $grey-highlight-2;
    border-style: solid;
    border-width: 1px 0;
    padding: 26px 5px 24px 39px;
    @include media-breakpoint-up(lg) {
        padding: 56px 30px 56px 113px;
    }

    a {
        @include link-underline();
    }

    p {
        font-family: $font-family-primary;
        color: $blue-lowlight-1;
        font-weight: $font-weight-normal;
        font-size: 20px;
        line-height: 1.6;

        @include media-breakpoint-up(lg) {
            font-size: 26px;
            line-height: 1.46;
        }

        &:first-of-type::before {
            content: url("../images/icon-quotation-mark.svg");
            position: absolute;
            left: 0;
            top: 24px;
            width: 26px;
            height: 18px;
            @include media-breakpoint-up(lg) {
                left: 48px;
                top: 62px;
                width: 38px;
                height: 27px;
            }
        }

        &:last-of-type::after {
            content: '\0022';
        }
    }

    &-figure {
        margin-bottom: 30px;
        padding-bottom: 12px;
        border-bottom: 1px solid $grey-highlight-2;
        @include media-breakpoint-up(lg) {
            padding-bottom: 40px;
        }

        a {
            @include link-underline();
        }

        .blockquote {
            margin-bottom: 0;
            border-bottom: 0 none;
            padding-bottom: 32px;
            @include media-breakpoint-up(lg) {
                padding-bottom: 37px;
            }
        }

        .blockquote-footer {
            position: relative;
            padding-left: 88px;
            font-size: 18px;
            @include media-breakpoint-up(lg) {
                font-size: 24px;
                padding-left: 170px;
            }
            &::before {
                content: '';
                width: 41px;
                height: 5px;
                background-color: $orange-highlight-1;
                position: absolute;
                top: 12px;
                left: 38px;
                @include media-breakpoint-up(lg) {
                    top: 16px;
                    left: 113px;
                }
            }
        }

        small {
            font-size: 12px;
            font-style: normal;
            @include media-breakpoint-up(lg) {
                font-size: 18px;
            }
            @include media-breakpoint-down(sm) {
                display: block;
                margin-top: 8px;
            }
        }
    }
}
