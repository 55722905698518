//Responsive behavior
$rfs-breakpoint: 1280px;
$rfs-factor: 2.5;

$rem-base: 20px;

//base colors
$white: #ffffff;
$black: #000000;
$light: #f3f3f1;

//Color variants
$blue-lowlight-1: #001a3d;
$blue: #002f6d;
$blue-highlight-1: #114594;
$blue-highlight-2: #19519a;
$blue-highlight-3: #1464de;
$blue-highlight-4: #3d87f9;
$blue-highlight-5: #4377bd;
$blue-highlight-6: #a6c7f4;

$red: #c20101;

$orange-lowlight-1: #9a410c;
$orange: #e36113;
$orange-highlight-1: #f27930;
$orange-highlight-2: #f4955c;
$orange-highlight-3: #fed8be;

$gold-lowlight-1: #cb9900;
$gold: #f0bf20;
$gold-highlight-1: #fade78;

$grey-lowlight-3: #13120e;
$grey-lowlight-2: #464540;
$grey-lowlight-1: #6a6964;
$grey: #91908b;
$grey-highlight-1: #bcbbb6;
$grey-highlight-2: #e1e0db;
$grey-highlight-3: #f7f7f5;
$grey-highlight-4: #f3f3f1;

$dark: $grey-lowlight-3;

$primary: $gold;
$secondary: $white;
$info: $blue;

$font-weight-book: 400;
$font-weight-medium-web: 400;
$font-weight-medium: 500;
$font-weight-demi-web: 500;
$font-weight-heavy: 700;

$text-muted: $grey;
$color-contrast-dark: $grey-lowlight-1;

$body-color: $grey-lowlight-3;

$link-color: $blue-highlight-3;
$link-hover-color: #0c3c7a;

// Spacing
$spacer: 1rem;
$spacers: (
    0: 0,
    1: (
        $spacer * 0.25
    ),
    2: (
        $spacer * 0.5
    ),
    3: $spacer,
    4: (
        $spacer * 1.5
    ),
    5: (
        $spacer * 3
    ),
    6: (
        $spacer * 4
    ),
    7: (
        $spacer * 5
    ),
    8: (
        $spacer * 6
    ),
    9: (
        $spacer * 7
    )
);

//Font
$font-family-sans-serif: futura-pt, sans-serif;
$font-family-base: lato, sans-serif;
$font-family-primary: $font-family-base;
$font-family-secondary: $font-family-sans-serif;

$font-size-base: 1.25rem;
$font-size-sm: 1rem;
$line-height-base: 1.4;

$border-radius: 8px;

$offcanvas-horizontal-width: 830px;
$offcanvas-padding-x: 0;
$offcanvas-padding-y: 0;
$offcanvas-backdrop-opacity: 0.80;

$input-btn-focus-color:rgba($blue-highlight-3, 0.25);