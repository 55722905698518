.quote {
    margin-bottom: 60px;
    @include media-breakpoint-down(lg) {
        margin-top: 130px;

        .text-and-image {

            &__image {
                @include media-breakpoint-down(lg) {
                    display: flex;
                    justify-content: center;
                    margin-top: -79px;
                }
            }
        }
    }
}