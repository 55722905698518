@media print {
    * {
        color: $black !important;
        text-shadow: none !important;
        background-color: transparent !important;
    }

    body {
        font: 13pt;
        line-height: 1.3;
    }

    h2, h3, h4 {
        page-break-after: avoid;
    }
}