//Headings
$h1-font-size: 84px;
$h2-font-size: 54px;
$h3-font-size: 40px;
$h4-font-size: 36px;
$h5-font-size: 30px;
$h6-font-size: 22px;

$headings-margin-bottom: $spacer * .25;
$headings-font-family: $font-family-sans-serif;
$headings-font-weight: $font-weight-medium-web;
$headings-color: $blue-highlight-1;
$headings-line-height: 1.17;