.site-footer {
    border-top: 5px solid $grey-highlight-2;
    margin-top: 70px;

    p {
        margin-bottom: 0;
    }

    a[href*=tel] {
        color: $black;
        letter-spacing: 0.1em;
        font-weight: $font-weight-bold;
        font-family: $font-family-secondary;
        font-size: 22px;
        @include media-breakpoint-up(lg) {
            text-decoration: none;
            font-size: 24px;
        }
    }

    ul {
        margin-bottom: 0;
        list-style: none;
    }

    &__logo {
        @include media-breakpoint-down(lg) {
            margin-bottom: 15px;
            display: block;
        }
        img {
            width: 162px;
            @include media-breakpoint-up(lg) {
                width: 296px;
            }
        }
    }

    &__links {
        margin-top: 19px;
        @include media-breakpoint-up(lg) {
            margin-top: 4px;
        }

        ul {
            padding-left: 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            @include media-breakpoint-up(lg) {
                justify-content: flex-end;
            }

            a {
                font-weight: $font-weight-demi-web;
                font-family: $font-family-secondary;
                text-transform: uppercase;
                letter-spacing: 0.05em;
                font-size: 17px;
                padding: 6px 10px 0;
                display: inline-block;
                @include media-breakpoint-up(md) {
                    padding: 3px 0 0 20px;
                    font-size: 18px;
                }

                @include link-underline(transparent, $link-hover-color);
            }
        }
    }

    &__band {
        background-color: $blue;
        padding: 27px 0;

        font-size: 15px;
        @include media-breakpoint-up(lg) {
            font-size: 16px;
        }

        &,
        a {
            color: $white;
        }

        p {
            letter-spacing: 0.05em;
        }

        &__links {
            padding-left: 13px;

            li {
                @include media-breakpoint-up(lg) {
                    &:before {
                        content: '|';
                        color: $blue-highlight-6;
                        padding-right: 10px;
                    }
                }

                a {
                    font-weight: $font-weight-bold;
                    @include media-breakpoint-down(lg) {
                        display: block;
                        margin-top: 20px;
                    }

                    @include link-underline($white);
                }
            }
        }

    }

    &__social-media {
        padding-left: 11px;
        display: flex;
        @include media-breakpoint-down(lg) {
            flex-wrap: wrap;
            justify-content: center;

            .site-footer & {
                margin-bottom: 15px;
            }
        }

        li {
            &:not(:last-child) {
                margin-right: 11px;
            }

            @include media-breakpoint-down(lg) {
                margin-bottom: 5px;
            }

            a {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $blue-highlight-3;
                width: 39px;
                height: 39px;
                font-size: 20px;
                color: $white;
                border-radius: 50%;
                text-decoration: none;

                &:hover,
                &:focus {
                    background-color: $orange-highlight-1;
                    color: $black;
                }
            }

        }
    }
}