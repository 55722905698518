.program-finder {

    @include default-margin-bottom();

    &__heading h2 {
        text-align: center;
        font-size: 34px;
        @include media-breakpoint-up(md) {
            font-size: 44px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 54px;
        }
        font-family: $font-family-sans-serif;
        font-weight: $font-weight-medium;
    }
    &__intro {
        text-align: center;
        font-size: 20px;
        line-height: 1.5;
        margin-top: 0;
        margin-bottom: 20px;
        @include media-breakpoint-up(md) {
            font-size: 25px;
            line-height: 1.3;
            margin-top: 10px;
            margin-bottom: 35px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 30px;
            margin-top: 20px;
            margin-bottom: 50px;
        }
        font-style: italic;

        color: $grey-lowlight-3;
    }

    &__form {

        border-top: 5px solid $orange;
        background: $grey-highlight-3;
        padding: 40px 20px;
        @include media-breakpoint-up(md) {
            padding: 40px;
        }

        .form-control,
        .multiselect-dropdown {
            margin-bottom: 15px;
            @include media-breakpoint-up(lg) {
                margin-bottom: 30px;
            }
        }

    }

    &__form-top {
        display:flex;
        align-items:center;
        justify-content: stretch;
    }
    &__keywords {
        flex-grow: 1;
    }
    &__check2 {
        margin-left: 30px;
        flex-grow:0;
        margin-bottom: 30px;
    }
    &__check1 {
        margin-left: 60px;
        flex-grow:0;
        margin-bottom: 30px;
    }


    &__form-bottom {
        display:flex;
        align-items:flex-start;
        justify-content: stretch;
    }
    &__select1,
    &__select2, {
        flex-grow: 1;
        margin-right: 30px;
    }
    &__submit {
        flex-grow:0;
    }

    &__mobile-checks {
        display:none;
    }

    @include media-breakpoint-down(lg) {
        &__form-top, &__form-bottom {
            display:block;
        }

        &__select1,
        &__select2 {
            margin-left: 0;
            margin-right: 0;
        }

        &__check1,
        &__check2 {
            display:none;
        }

        &__mobile-checks {
            display:block;
            .form-check {
                margin-bottom: 10px;
            }
        }

        &__submit {
            margin-right: auto;
            margin-top: 30px;
        }
    }

}