.featured-news-feed {
    @include default-margin-bottom();
    background: $grey-highlight-3;

    padding: 35px;
    @include media-breakpoint-up(lg) {
        padding: 40px;
    }

    &__more-link {
        margin-top: 20px;
        font-size: 26px;
    }
    &__mobile-more-link {
        font-size: 20px;
    }
    &__more-button {
        display:table;
        margin: 35px auto 0 auto;
    }

    &__controls {
        @include splide-controls;

        border-bottom: 1px solid $grey-highlight-2;
        margin-bottom: 10px;
    }
}