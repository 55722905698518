//$btn-padding-y:               $input-btn-padding-y !default;
//$btn-padding-x:               $input-btn-padding-x !default;
//$btn-font-family:             $input-btn-font-family !default;
//$btn-font-size:               $input-btn-font-size !default;
//$btn-line-height:             $input-btn-line-height !default;
//$btn-white-space:             null !default; // Set to `nowrap` to prevent text wrapping
//
//$btn-padding-y-sm:            $input-btn-padding-y-sm !default;
//$btn-padding-x-sm:            $input-btn-padding-x-sm !default;
//$btn-font-size-sm:            $input-btn-font-size-sm !default;
//
//$btn-padding-y-lg:            $input-btn-padding-y-lg !default;
//$btn-padding-x-lg:            $input-btn-padding-x-lg !default;
//$btn-font-size-lg:            $input-btn-font-size-lg !default;
//
//$btn-border-width:            $input-btn-border-width !default;
//
//$btn-font-weight:             $font-weight-normal !default;
//$btn-box-shadow:              inset 0 1px 0 rgba($white, .15), 0 1px 1px rgba($black, .075) !default;
//$btn-focus-width:             $input-btn-focus-width !default;
//$btn-focus-box-shadow:        $input-btn-focus-box-shadow !default;
//$btn-disabled-opacity:        .65 !default;
//$btn-active-box-shadow:       inset 0 3px 5px rgba($black, .125) !default;
//
//$btn-link-color:              var(--#{$prefix}link-color) !default;
//$btn-link-hover-color:        var(--#{$prefix}link-hover-color) !default;
//$btn-link-disabled-color:     $gray-600 !default;
//
//// Allows for customizing button radius independently from global border radius
//$btn-border-radius:           $border-radius !default;
//$btn-border-radius-sm:        $border-radius-sm !default;
//$btn-border-radius-lg:        $border-radius-lg !default;
//
//$btn-transition:              color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;
//
//$btn-hover-bg-shade-amount:       15% !default;
//$btn-hover-bg-tint-amount:        15% !default;
//$btn-hover-border-shade-amount:   20% !default;
//$btn-hover-border-tint-amount:    10% !default;
//$btn-active-bg-shade-amount:      20% !default;
//$btn-active-bg-tint-amount:       20% !default;
//$btn-active-border-shade-amount:  25% !default;
//$btn-active-border-tint-amount:   10% !default;


$btn-border-radius: 1000px;
$btn-border-width: 2px;
$btn-border-radius-sm: 1000px;
$btn-transition: color .35s ease, background-color .35s ease, border-color .35s ease, box-shadow .35s ease;
$btn-box-shadow: none;